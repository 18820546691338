import React from "react";
import { Stack } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import { Moment } from "moment-timezone";
import { DateRangePicker } from "../DateRangePicker";
import { SelectItemType } from "../../types/SelectItemType";
import { SAME_PERIOD_LAST_YEAR_OPTION } from "../../types/DataStreamChartComparisonState";
import { initialDateRangeState } from "../DataStreamChart/DataStreamChartContainer";

interface Props {
    initFirstRange?: DateRange<Moment>;
    initSecondRange?: DateRange<Moment> | null;
    onSelectFirstRange: (dateRange: DateRange<Moment>) => void;
    onSelectSecondRange: (dateRange: DateRange<Moment>) => void;
    selectedQuickOption: SelectItemType | null;
    selectedCompareOption: SelectItemType | null;
}

export const DateRangeDates: React.FC<Props> = ({
    initFirstRange,
    initSecondRange,
    onSelectFirstRange,
    onSelectSecondRange,
    selectedQuickOption,
    selectedCompareOption 
}) => {
    return (
        <Stack direction={"column"}>
            <DateRangePicker
                showicon={false}
                onDateRangeChanged={onSelectFirstRange}
                isOnlyCallbackWhenClose={false}
                initialDateRangeState={initFirstRange}
                data-test="chart-comparison-date-range-picker"
            />
            {
                selectedQuickOption?.value === "compare"
                &&
                <DateRangePicker
                    disabled={selectedCompareOption?.value === SAME_PERIOD_LAST_YEAR_OPTION}
                    showicon={false}
                    onDateRangeChanged={onSelectSecondRange}
                    isOnlyCallbackWhenClose={false}
                    initialDateRangeState={initSecondRange || initialDateRangeState}
                    data-test="chart-comparison-compare-date-range-picker"
                />
            }
        </Stack>
    );
};
