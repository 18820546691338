import React from "react";
import { Box, CircularProgress, Divider, Grid } from "@mui/material";
import { SearchResultComparisonState } from "../../types/SearchResultComparisonState";
import { ConnectionPointResult } from "./ConnectionPointResult";
import { MetersResult } from "./MetersResult";
import { DataStreamsResult } from "./DataStreamsResult";
import { Footer } from "./Footer";
import { CALLBACK_NAME } from "../GroupAddComparison/GroupAddComparison";
import { i18n } from "../../global/i18n";

interface Props {
  loading: boolean;
  content: SearchResultComparisonState | null;
  maxItems: number;
  connectionPointId: string;
  meterId: string;
  datastreamSelected: Set<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (type: CALLBACK_NAME, payload?: any) => void;
}

export const SearchResultComparison: React.FC<Props> = ({
  loading,
  content,
  maxItems,
  connectionPointId,
  meterId,
  datastreamSelected,
  callback,
}) => {
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "250px",
        }}
      >
        <CircularProgress sx={{ mt: 2 }} />
      </Box>
    );
  }

  if (content === null) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "250px",
          }}
        >
          {i18n.t("SEARCH_RESULT_COMPARISON.NO_DATA", {ns: "component"})}
        </Box>
        <Divider />
        <Footer disableApply callback={callback} />
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            key={1}
            sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <ConnectionPointResult connectionPointId={content.connectionPointId} />
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            key={2}
            sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <MetersResult
              callback={callback}
              activeMeterId={meterId}
              meterIds={content.meterIds}
            />
          </Grid>
          <Grid item xs={8} sm={8} md={8} key={3}>
            <DataStreamsResult
              maxItems={maxItems}
              datastreamSelected={datastreamSelected}
              connectionPointId={connectionPointId}
              meterId={meterId}
              callback={callback}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Footer
        disableApply={datastreamSelected.size === 0}
        callback={callback}
      />
    </>
  );
};
