import React from "react";
import ReactDOM from "react-dom";
import fetchIntercept from "fetch-intercept";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { LOGIN_TOKEN_KEY } from "./commons/userInfo"
import { AuthInterceptor, authRedirect } from "./global/fetch-interceptors";
import * as serviceWorker from "./serviceWorker";
import "./setupTimezone"; // Must be defined before App
import App from "./App";
import theme from "./theme";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import env from "./environment";
import './global/i18n';

import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(env.muiLicenseKey);

fetchIntercept.register(AuthInterceptor);

if (window.location.pathname !== "/login") {
  const loginToken = window.sessionStorage.getItem(LOGIN_TOKEN_KEY);
  if (!loginToken) {
    authRedirect();
  }
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <App />
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
