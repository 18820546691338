import React from "react";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const HomePage: React.FC<Props> = () => {

  const { t } = useTranslation("page");

  return (
  <>
      <Helmet>
          <title>{t("HOME_PAGE.LABEL")}</title>
      </Helmet>
    <Typography>{t("HOME_PAGE.LABEL")}</Typography>
  </>
);
}
