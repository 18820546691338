import React, { useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { DataStream } from "../../types/DataStream";
import { useFetchDatastreamByMeterId } from "../../hooks/useSearchResultComparison";
import { CALLBACK_NAME } from "../GroupAddComparison/GroupAddComparison";
import { i18n } from "../../global/i18n";

interface Props {
  maxItems: number;
  connectionPointId: string;
  meterId: string;
  datastreamSelected: Set<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (type: CALLBACK_NAME, payload?: any) => void;
}

export const DataStreamsResult: React.FC<Props> = ({
  maxItems,
  connectionPointId,
  meterId,
  callback,
  datastreamSelected,
}) => {
  const [datastreams, setDatastreams] = useState<DataStream[]>([]);
  const [loading, setLoading] = useState(false);

  useFetchDatastreamByMeterId(setLoading, setDatastreams, { connectionPointId, meterId });
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "250px",
        }}
      >
        <CircularProgress sx={{ mt: 2 }} />
      </Box>
    );
  }

  return (
    <Box sx={{ pt: 3, pb: 2 }}>
      <Typography
        sx={{
          px: 3,
          color: "rgba(0, 0, 0, 0.38)",
          fontSize: "0.8125rem",
          fontWeight: 500,
        }}
      >
        {i18n.t("DATA_STREAMS_RESULT.DATASTREAM", {ns: "component"})} ({datastreams.length})
      </Typography>
      <Box sx={{ mt: 1, overflowY: "auto", maxHeight: "240px" }}>
        {datastreams.map((datastream: DataStream, index) => {
          return (
            <List key={datastream.key || index} sx={{ py: 0 }}>
              <ListItem disablePadding>
                <ListItemButton sx={{ px: 3, py: 0 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={datastreamSelected.size >= maxItems && !datastreamSelected.has(datastream.key || "")}
                        checked={datastreamSelected.has(datastream.key || "")}
                        onChange={() =>
                          callback("CLICK_CHECKBOX_DATASTREAM", datastream.key)
                        }
                        name={datastream.key}
                        data-test="data-stream-list"
                      />
                    }
                    label={datastream.name}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          );
        })}
      </Box>
    </Box>
  );
};
