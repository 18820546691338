import React, { useCallback } from "react";
import { withSeparator } from "../../styles";

export const SeparatedList: React.FC = ({ children }) => {
  const childNum = React.Children.count(children);

  const isLast = useCallback(
    index => (index + 1 === childNum),
    [childNum]
  );

  return (
    <ul style={{ listStyle: "none", padding: 0, margin: 0, display: "inline" }}>
      {React.Children.map(children, (child, index) => {
        if (isLast(index)) {
          return (
            <li
              key={index}
              style={{ display: "inline-block", padding: "0 0 0 16px", margin: "4px 0" }}
            >
              {child}
            </li>
          );
        }

        return (
          <StyledLi
            key={index}
            style={{ display: "inline-block", padding: (index === 0 ? "0 16px 0 0" : "0 16px"), margin: "4px 0" }}
          >
            {child}
          </StyledLi>
        );
      })}
    </ul>
  );
};

export const StyledLi = withSeparator("li");
