import React, { ReactNode, useMemo } from "react";
import { Alert, Box, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { DateRangePicker } from "../DateRangePicker";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { Moment } from "moment-timezone";
import { MeterEvents } from "../../types/MeterEvents";
import { DataGridPro, GridColumns, GridRenderCellParams, GridRowHeightParams } from "@mui/x-data-grid-pro";
import { datetimeValueFormatter, dateValueGetter, styleDataGridHeader } from "../../commons/dataGrid";
import { i18n } from "../../global/i18n";
import { styleDataGridCell, styleDataGridRow } from "../DataStreamConsumptionIntervals/DataStreamConsumptionIntervals";
import { NoRowsOverlay } from "../NoRowsOverlay";
import { isEmpty } from "lodash";
import WarningIcon from "@mui/icons-material/Warning";

interface Props {
  currentState: MeterEvents[],
  dateRange: DateRange<Moment>,
  handleDateRangeChange: (dateRange: DateRange<Moment>) => void,
  loading: boolean,
  dateValidationError: string
}

export const renderParameter = (params: GridRenderCellParams<Array<any>>): ReactNode => {

  const field = params.field;

  if (!params?.row?.parameters || params.row.parameters.length < 1) {
    return (<></>);
  }

  return (
    <Table>
      <TableBody>
        {params.row.parameters.map((parameter: { [x: string]: string | null | undefined; }, index: React.Key) => (
          <TableRow key={index}>
            <TableCell
              sx={{
                borderBottom: "0px",
              }}
            >
              {!parameter[field] || parameter[field] === "" ? "\u00A0" : parameter[field]}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export const alignTopClassName = () => {
  return "align-top";
};

export const MeterEventsNoRowsOverlay = () => (
  <NoRowsOverlay prefix="meter-events">
    {i18n.t("METER_EVENTS.NO_DATA", { ns: "component" })}
  </NoRowsOverlay>
);

export const getLocaleText = () => ({
  MuiTablePagination: {
    labelRowsPerPage: i18n.t("METER_EVENTS.FOOTER.PAGINATION_ROWS_PER_PAGE_LABEL", {ns: "component"}),
    labelDisplayedRows: ({from, to, count} : {from: number, to: number, count: number}) =>
      i18n.t("METER_EVENTS.FOOTER.PAGINATION_DISPLAYED_ROWS_LABEL", {ns: "component", from, to, count})
  }
});

export const getRowHeight = ({ model }: GridRowHeightParams) => {
  return model?.parameters?.length > 1 ? "auto" : 52;
}

export const MeterEventsTable: React.FC<Props> = ({
  currentState,
  dateRange,
  handleDateRangeChange,
  loading,
  dateValidationError
}) => {

  const rows = useMemo(() => {
    return currentState.map((events, index) => ({
      id: index,
      ...events
    }));
  }, [currentState]);

  const dataGridComponents = useMemo(() => {
    if (!loading && isEmpty(currentState)) {
      return {
        components: {
          NoRowsOverlay: MeterEventsNoRowsOverlay,
        },
      };
    }
  }, [loading, currentState]);

  const columns = useMemo(() => {
    const columnsValueDef: GridColumns = [
      {
        field: "timestamp",
        headerName: i18n.t("METER_EVENTS.HEADERS.DATETIME", {
          ns: "component",
        }),
        valueGetter: dateValueGetter,
        type: "dateTime",
        valueFormatter: datetimeValueFormatter,
        cellClassName: alignTopClassName,
        sortable: true,
      },
      {
        field: "eventType",
        headerName: i18n.t("METER_EVENTS.HEADERS.EVENT_TYPE", {
          ns: "component",
        }),
        cellClassName: alignTopClassName,
        sortable: true,
      },
      {
        field: "name",
        headerName: i18n.t("METER_EVENTS.HEADERS.NAME", {
          ns: "component",
        }),
        type: "string",
        renderCell: renderParameter,
        sortable: false
      },
      {
        field: "value",
        headerName: i18n.t("METER_EVENTS.HEADERS.VALUE", {
          ns: "component",
        }),
        type: "string",
        renderCell: renderParameter,
        sortable: false
      }
    ];

    return styleDataGridHeader(columnsValueDef);
  }, []);

  return (
    <Box
      data-test={"meter-events"}
      sx={{
        width: 1,
        "& .grid-header-column--header": {
          backgroundColor: "rgba(0, 0, 0, 0.03)",
          color: "text.secondary",
        },
        "& .MuiDataGrid-root": {
          border: 0,
          position: "relative",
        },
      }}
    >
      <Box>
        <DateRangePicker
          onDateRangeChanged={handleDateRangeChange}
          initialDateRangeState={dateRange}
        />
        { dateValidationError && (
          <Alert
            severity="warning"
            icon={<WarningIcon sx={{ color: "warning.main" }} />}
            sx={{ mb: 1, alignItems: "center" }}
            data-test={"meter-events-max-date-range-error"}
          >
            {i18n.t(dateValidationError)}
          </Alert>
        )
        }
      </Box>
      <Box
        sx={{
          height: 400,
          width: "100%",
          "& .align-top": {
            alignItems: "flex-start !important",
            padding: "16px !important"
          }
        }}
      >
        <DataGridPro
          autoHeight
          disableColumnMenu
          disableColumnReorder
          disableSelectionOnClick
          pagination={true}
          initialState={{
            pagination: {
              pageSize: 50,
            },
            sorting: {
              sortModel: [{ field: "timestamp", sort: "asc" }],
            }
          }}
          rows={rows}
          columns={columns}
          loading={loading}
          getRowClassName={styleDataGridRow}
          getCellClassName={styleDataGridCell}
          {...dataGridComponents}
          localeText={getLocaleText()}
          getRowHeight={getRowHeight}
        />
      </Box>
    </Box>
  );
}
