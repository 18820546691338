import {
  GridCellValue,
  GridColDef,
  GridColumnHeaderClassNamePropType,
  GridValueFormatterParams,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { mapExceptionTypeDisplayValue } from "../mappers/exceptionTypeMapper";
import { formatDate, formatDateTime, toMomentFromDateString } from "./dates";

interface DataGridColumnHeaderStyle {
  width?: number;
  flex?: number;
  sortable?: boolean;
  minWidth?: number;
  maxWidth?: number;
  headerClassName?: GridColumnHeaderClassNamePropType;
}

export const styleDataGridHeader = (columnDef: GridColDef[], headerStyle?: DataGridColumnHeaderStyle): GridColDef[] => {
  const defaultStyle = {minWidth: 200, flex: 1};

  return columnDef.map(
    (col) => {
      return {
        sortable: true,
        sortingOrder: ['desc', 'asc'],
        headerClassName: "grid-header-column--header",
        ...defaultStyle,
        ...headerStyle,
        ...col
      };
    }
  );
};

// Customise DataGrid cell value and format
export const dateValueGetter = (
  {value}: GridValueGetterParams,
): GridCellValue => {
  return value ? toMomentFromDateString(value).toDate() : undefined;
}

export const dateValueFormatter = (
  {value}: GridValueFormatterParams,
): GridCellValue => {
  return value ? formatDate(value) : undefined;
}

export const datetimeValueFormatter = (
  {value}: GridValueFormatterParams,
): GridCellValue => {
  return value ? formatDateTime(value) : undefined;
}

export const exceptionTypeGetter = ({value}: GridValueGetterParams): GridCellValue => {
  return value ? mapExceptionTypeDisplayValue(value) : undefined;
}

export const floatNumberGetter = ({value}: GridValueGetterParams): GridCellValue => {
  const num = Number(value);
  return isNaN(num) ? undefined : num;
}

export const integerValueGetter = ({value}: GridValueGetterParams): GridCellValue => {
  const num = Number(value);
  return isNaN(num) ? undefined : num;
}

export const datastreamNameFormatter = ({value}: GridValueFormatterParams): GridCellValue => {
  return value?.toUpperCase() ?? "-";
}
