import React from "react";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useTranslation } from "react-i18next";

interface Props {
  openSideNav: boolean;
  onClick: () => void;
}

export const MenuButtonCompareData: React.FC<Props> = ({
  openSideNav,
  onClick,
}) => {
  const { t }  = useTranslation("component");
  if (openSideNav) {    
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          padding: "0 16px",
        }}
      >
        <Button
          data-test="menu-button-compare-data"
          variant="contained"
          sx={{ textTransform: "capitalize", boxShadow: "none", width: "100%" }}
          onClick={onClick}
          startIcon={<AddIcon />}
        >
          {t("MENU_BUTTON_COMPARE_DATA.COMPARE_DATA")}
        </Button>
      </Box>
    );
  }
  return (
    <Box
      data-test="menu-button-compare-data"
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        color: "rgba(0, 0, 0, 0.54)",
        paddingLeft: "16px",
        cursor: "pointer",
      }}
    >
      <AddBoxIcon />
    </Box>
  );
};
