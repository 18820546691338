import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { AddCircle, Clear } from "@mui/icons-material";
import { i18n } from "../../global/i18n";

interface Props {
  setFilesUploadDialog: (files: File[]) => void;
  setMessageUploadDialog: (message: string) => void;
}

export const errorMessages: { [key: string]: string } = {
  fileFormat: i18n.t("DRAG_DROP_FILE.FILE_FORMAT", { ns: "component" }),
  fileSize: i18n.t("DRAG_DROP_FILE.FILE_SIZE", { ns: "component" }),
};

export const DragDropFile: React.FC<Props> = ({
  setFilesUploadDialog,
  setMessageUploadDialog,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [message, setMessage] = useState("");

  const fileUploaded = files.length > 0;

  const { getRootProps, getInputProps } = useDropzone({
    disabled: fileUploaded,
    onDrop: (acceptedFiles: File[]) => handleDragDrop(acceptedFiles),
  });

  useEffect(() => {
    files.forEach((file: File) => URL.revokeObjectURL(file.name));
  }, [files]);

  const setFilesState = useCallback(
    (files: File[]) => {
      setFiles(files);
      setFilesUploadDialog(files);
    },
    [setFiles, setFilesUploadDialog],
  );

  const setMessageState = useCallback(
    (message: string) => {
      setMessage(message);
      setMessageUploadDialog(message);
    },
    [setMessage, setMessageUploadDialog],
  );

  const setFilePreview = useCallback(
    (acceptedFiles: File[]) => {
      const uniqueAcceptedFiles = Array.from(new Set<File>(acceptedFiles));

      const droppedFiles = uniqueAcceptedFiles.map((file: File) =>
        Object.assign(file, { preview: URL.createObjectURL(file) }),
      );

      setFilesState(droppedFiles);
    },
    [setFilesState],
  );

  const handleDragDrop = useCallback(
    (acceptedFiles: File[]) => {
      const maxFileSize = 6291456;
      const inValidCsvFileFormat = acceptedFiles.some((f: File) => {
        const fileExtension = f.name.match(/\.[0-9a-z]+$/i)?.[0];
        return fileExtension !== ".csv";
      });

      if (inValidCsvFileFormat) {
        setMessageState(errorMessages.fileFormat);
      } else if (acceptedFiles.some((f) => f.size > maxFileSize)) {
        setMessageState(errorMessages.fileSize);
      } else {
        setMessageState("");
      }
      setFilePreview(acceptedFiles);
    },
    [setFilePreview, setMessageState],
  );

  const handleDelete = useCallback(
    (fileName: string) => {
      const remainFiles = files.filter((file: File) => file.name !== fileName);
      setFilesState(remainFiles);
      setMessageState("");
    },
    [files, setFilesState, setMessageState],
  );

  return (
    <Box id="drag-drop-container" sx={{ mb: 2 }}>
      <Box
        id="drag-drop-upload"
        sx={{
          width: "100%",
          border: "dashed",
          cursor: "pointer",
          overflow: "hidden",
          boxSizing: "border-box",
          borderWidth: "3px",
          borderColor: "rgba(0, 0, 0, 0.12)",
          borderRadius: "15px",
          color: "rgba(0, 0, 0, 0.38)",
          backgroundColor: "rgba(0, 0, 0, 0.01)",
          textAlign: "center",
          p: 1,
        }}
      >
        <div
          data-testid="drop-zone"
          {...getRootProps({ className: "dropzone" })}
        >
          <input data-testid="drop-input" {...getInputProps()} />
          <IconButton
            size="large"
            color="inherit"
            sx={Object.assign(
              fileUploaded
                ? { color: "rgba(0, 0, 0, 0.38)" }
                : { color: "#0053DE" },
              {
                fontSize: "inherit",
                "&:hover": { background: "transparent" },
                "&:active": { background: "transparent" },
              },
            )}
          >
            <AddCircle sx={{ mr: 1 }} />
            {i18n.t("DRAG_DROP_FILE.UPLOAD_FILE", {ns: "component"})}
          </IconButton>
        </div>
      </Box>
      {fileUploaded && (
        <Box data-testid="drag-drop-preview" sx={{ mt: 3 }}>
          {files.map((file: File) => (
            <Box
              key={file.name}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tooltip
                title={file.name}
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                <Typography noWrap sx={{ p: 0, lineHeight: 2 }}>
                  {file.name}
                </Typography>
              </Tooltip>

              <IconButton
                size="small"
                color="inherit"
                data-testid="delete-file"
                onClick={() => handleDelete(file.name)}
              >
                <Clear fontSize="small" />
              </IconButton>
            </Box>
          ))}
          {message ? (
            <>
              <Divider
                sx={{ borderColor: "#F44336", p: 0, borderBottomWidth: 2 }}
              />
              <Typography
                sx={{ color: "#F44336", fontSize: "small", mt: 0.5 }}
                data-test="upload-state-message"
                data-testid="upload-error-message"
              >
                {message}
              </Typography>
            </>
          ) : (
            <Divider sx={{ p: 0, borderBottomWidth: 2 }} />
          )}
        </Box>
      )}
    </Box>
  );
};
