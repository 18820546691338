/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  actionSingleMeterException,
  actionSingleMeterExceptionStatus,
} from "../services/ExceptionListService";
import { UnitOfWorkPart } from "../types/MeterException";

export const useSingleMeterExceptionAction = (
  setLoading: (loading: boolean) => void,
  setActionResult: (state: string, paramsReturn?: any) => void,
  params: {
    actionType?: string;
    index: number;
    meterId: string;
    unitOfWorkId: string;
    exceptionType: string;
    unitOfWorkPart: UnitOfWorkPart;
  },
  action: string,
  bodyData: {
    [x: string]: any;
  },
  postUpdate?: () => Promise<void>,
): void => {
  setActionResult("");
  setLoading(true);
  actionSingleMeterException(params, action, bodyData)
    .then((response: any) => {
      handelResponseSuccess(
        params,
        response,
        setLoading,
        setActionResult,
        postUpdate,
      );
    })
    .catch(() => {
      setActionResult("fail");
      setLoading(false);
    });
};

export const useSingleMeterExceptionStatusAction = (
  setLoading: (loading: boolean) => void,
  setActionResult: (state: string, paramsReturn?: any) => void,
  params: {
    actionType?: string;
    index: number;
    meterId: string;
    unitOfWorkId: string;
    exceptionType: string;
    unitOfWorkPart: UnitOfWorkPart;
  },
  action: string,
  bodyData: {
    [x: string]: any;
  },
  postUpdate?: () => Promise<void>,
): void => {
  setActionResult("");
  setLoading(true);
  actionSingleMeterExceptionStatus(params, action, bodyData)
    .then((response: any) => {
      handelResponseSuccess(
        params,
        response,
        setLoading,
        setActionResult,
        postUpdate,
      );
    })
    .catch(() => {
      setActionResult("fail");
      setLoading(false);
    });
};

function handelResponseSuccess(
  params: {
    actionType?: string;
    index: number;
    meterId: string;
    unitOfWorkId: string;
    exceptionType: string;
    unitOfWorkPart: UnitOfWorkPart;
  },
  response: any,
  setLoading: (loading: boolean) => void,
  setActionResult: (state: string, paramsReturn?: any) => void,
  postUpdate?: () => Promise<void>,
): void {
  if (response) {
    if (params.actionType === "single") {
      setActionResult(response.errors.length > 0 ? "fail" : "success");
    } else {
      setActionResult(response);
    }
    if (postUpdate && response.success.length > 0) {
      postUpdate().finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }
}
