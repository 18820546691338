import {i18n} from "../global/i18n";

export function mapAuditSourceDisplayValue(source: string): string {
  if (source === "uds") {
    return i18n.t("AUDIT_LOG_SOURCE_MAPPING.UDS", {ns: "component"});
  } else if (source === "exceptions") {
    return i18n.t("AUDIT_LOG_SOURCE_MAPPING.EXCEPTIONS", {ns: "component"});
  } else if (source === "system") {
    return i18n.t("AUDIT_LOG_SOURCE_MAPPING.SYSTEM", {ns: "component"});
  }
  return "";
}
