import { Select, styled } from "@mui/material";

export const StyledSelect = styled(Select)(
  () => ({
    backgroundColor: "#e8eaed",
    width: 500,
    height: 32,
    borderRadius: 4,
    fontSize: 14,
  }),
);
