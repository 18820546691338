import { ConnectionPoint } from "../types/ConnectionPoint";
import { ConnectionPoints } from "../types/ConnectionPoints";

export const retrieveConnectionPointByConnectionPointId = async (connectionPointId: string): Promise<ConnectionPoint> =>
  fetch(`/service/v1/connectionpoint/${connectionPointId}`, {
    headers: new Headers({"Content-Type": "application/json; charset=utf-8"}),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });

export const retrieveConnectionPointByMeterId = async (meterId: string): Promise<ConnectionPoints> =>
  fetch(`/service/v1/connectionpoint?meterId=${meterId}`, {
    headers: new Headers({"Content-Type": "application/json; charset=utf-8"}),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
