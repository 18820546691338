import React, { useMemo } from "react";
import { IntervalAuditLogsState } from "../../types/IntervalAuditLogsState";
import {
  GridCellValue,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import {
  datastreamNameFormatter,
  datetimeValueFormatter,
  dateValueGetter,
  styleDataGridHeader,
} from "../../commons/dataGrid";
import { AuditLogDataGrid } from "../AuditLog";
import { i18n } from "../../global/i18n";

interface Props {
  source: string;
  content: IntervalAuditLogsState;
  loading: boolean;
}

export const intervalVersionFormatter = ({
  value,
}: GridValueFormatterParams): GridCellValue => {
  return `V${value}`;
};

const getColumnValueDef = () : GridColDef[] => ([
  {
    field: "ingestedDateTime",
    headerName: i18n.t("INTERVAL_AUDIT_LOGS.HEADERS.INGESTED_DATETIME", {
      ns: "component",
    }),
    type: "dateTime",
    valueGetter: dateValueGetter,
    valueFormatter: datetimeValueFormatter,
  },
  {
    field: "processedDateTime",
    headerName: i18n.t("INTERVAL_AUDIT_LOGS.HEADERS.PROCESSED_DATETIME", {
      ns: "component",
    }),
    type: "dateTime",
    valueGetter: dateValueGetter,
    valueFormatter: datetimeValueFormatter,
  },
  {
    field: "datastreamName",
    headerName: i18n.t("INTERVAL_AUDIT_LOGS.HEADERS.DATASTREAM", {
      ns: "component",
    }),
    sortable: false,
    valueFormatter: datastreamNameFormatter,
  },
  {
    field: "version",
    headerName: i18n.t("INTERVAL_AUDIT_LOGS.HEADERS.VERSION", {
      ns: "component",
    }),
    sortable: false,
    type: "number",
    align: "left",
    headerAlign: "left",
    valueFormatter: intervalVersionFormatter,
  },
]);

export const IntervalAuditLogs: React.FC<Props> = ({
  source,
  content,
  loading,
}) => {
  const rows = useMemo(() => {
    return content.auditLogs.map((item, index) => {
      return {
        id: index,
        ...item,
      };
    });
  }, [content]);

  const columns = useMemo(() =>  styleDataGridHeader(getColumnValueDef(), {
    headerClassName: "auditlog-grid-header-column--header",
  }), []);

  return (
    <AuditLogDataGrid
      source={source}
      containerDataTest={`${source}-audit-log-rows`}
      rows={rows}
      columns={columns}
      loading={loading}
      defaultSortField={"processedDateTime"}
    />
  );
};
