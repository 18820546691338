import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Divider, Paper, Popover, SelectChangeEvent, Typography, useTheme, } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import moment, { Moment } from "moment-timezone";
import { initialDateRangeState } from "../DataStreamChart/DataStreamChartContainer";
import { SelectItemType } from "../../types/SelectItemType";
import { DateRangeLabel } from "./DateRangeLabel";
import { compareOptions, DateRangeOptions, quickOptions, } from "./DateRangeOptions";
import { DateRangeDates } from "./DateRangeDates";
import { DATE_PATTERN, isYesterday } from "../../commons/dates";
import { DateRangeAction } from "./DateRangeAction";
import { COMPARE, SAME_PERIOD_LAST_YEAR_OPTION, YESTERDAY, } from "../../types/DataStreamChartComparisonState";
import { i18n } from "../../global/i18n";

interface Props {
  onApply: (
    firstDateRange: DateRange<Moment>,
    secondDateRange: DateRange<Moment> | null,
    compareOption: SelectItemType | null,
  ) => void;
}

export const setSecondDateRangeByCompareOption = (
  option: string,
  firstDateRange: DateRange<Moment>,
  setSecondDateRange: (dateRange: DateRange<Moment>) => void,
): void => {
  if (option === SAME_PERIOD_LAST_YEAR_OPTION) {
    setSamePeriodLastYear(firstDateRange, setSecondDateRange);
  }
};

export const setSamePeriodLastYear = (
  firstDateRange: DateRange<Moment>,
  setSecondDateRange: (dateRange: DateRange<Moment>) => void,
): void => {
  if (firstDateRange && firstDateRange[0] && firstDateRange[1]) {
    const sameDateRangeLastYear: DateRange<Moment> = [
      moment(firstDateRange[0]).subtract(1, "year"),
      moment(firstDateRange[1]).subtract(1, "year"),
    ];
    setSecondDateRange(sameDateRangeLastYear);
  }
};

export const validateDaterange = (
  selectedCompareOption: SelectItemType | null,
  firstDateRange: DateRange<Moment>,
  secondDateRange: DateRange<Moment> | null,
): { status: boolean; message: string } => {
  let status = true;
  let message = "";
  if (selectedCompareOption && selectedCompareOption.value === "custom") {
    if (firstDateRange[0] && secondDateRange?.[0]) {
      const first = firstDateRange[0].diff(firstDateRange[1], "days");
      const second = secondDateRange[0].diff(secondDateRange[1], "days");
      status = first === second;
      message = i18n.t("DATE_RANGE_COMPARISON.VALIDATE_MESSAGE", 
      { ns: "component", 
        param1: Math.abs(first) + 1,
        param2: firstDateRange[0].format(DATE_PATTERN),
        param3: firstDateRange[1]?.format(DATE_PATTERN),
        param4: Math.abs(second) + 1,
        param5: secondDateRange[0].format(DATE_PATTERN),
        param6: secondDateRange[1]?.format(DATE_PATTERN)
      });
    }
  }

  return { status, message };
};

export const DateRangeComparison: React.FC<Props> = ({ onApply }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedQuickOption, setSelectedQuickOption] =
    useState<SelectItemType | null>(quickOptions[1]);
  const [selectedCompareOption, setSelectedCompareOption] =
    useState<SelectItemType | null>(null);
  const [firstDateRange, setFirstDateRange] = useState<DateRange<Moment>>(
    initialDateRangeState,
  );
  const [secondDateRange, setSecondDateRange] =
    useState<DateRange<Moment> | null>(null);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (selectedQuickOption?.value === COMPARE) {
      setSecondDateRangeByCompareOption(
        selectedCompareOption?.value || "",
        firstDateRange,
        setSecondDateRange,
      );
    }

    if (selectedQuickOption?.value === YESTERDAY) {
      setSelectedCompareOption(null);
      setSecondDateRange(null);
    }
  }, [
    selectedCompareOption?.value,
    selectedQuickOption?.value,
    firstDateRange,
  ]);

  const onSelectCompareOption = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const id = event.target.value as string;
      const option = compareOptions.find((s) => s.id === id);
      setSelectedCompareOption(option || null);
    },
    [],
  );

  const onSelectQuickOption = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const id = event.target.value as string;
      const option = quickOptions.find((s) => s.id === id);
      setSelectedQuickOption(option || null);

      // check and set yesterday for firstDateRange is id = yesterday-item
      if (id === quickOptions[1].id) {
        setFirstDateRange([
          moment(new Date()).subtract(1, "days"),
          moment(new Date()).subtract(1, "days"),
        ]);
      }

      if (option?.value === "compare") {
        setSelectedCompareOption(compareOptions[0]);
      } else {
        setSelectedCompareOption(null);
      }
      setSecondDateRange(null);
    },
    [],
  );

  const onChangeFirstDateRange = (dateRange: DateRange<Moment>) => {
    if (
      dateRange[0] &&
      dateRange[1] &&
      (!isYesterday(dateRange[0]) || !isYesterday(dateRange[1])) &&
      selectedQuickOption?.id === quickOptions[1].id
    ) {
      setSelectedQuickOption(quickOptions[0]);
    }
    setFirstDateRange(dateRange);
  };

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [anchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangeSecondDateRange = (dateRange: DateRange<Moment>) => {
    setErrorMessage(""); // hide error message if change second date range
    setSecondDateRange(dateRange);
  };

  const handleApply = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { status: isValidDateRange, message } = validateDaterange(
        selectedCompareOption,
        firstDateRange,
        secondDateRange,
      );
      if (isValidDateRange) {
        setAnchorEl(null);
        setErrorMessage("");
        onApply(firstDateRange, secondDateRange || null, selectedCompareOption);
      } else {
        setErrorMessage(message);
      }
    },
    [firstDateRange, secondDateRange, selectedCompareOption],
  );

  return (
    <>
      <Box
        sx={{ display: "inline-block" }}
        data-test="chart-comparison-date-range-trigger"
      >
        <DateRangeLabel
          firstDateRange={firstDateRange}
          secondDateRange={secondDateRange}
          openPopup={handleClick}
        />
      </Box>
      <Popover
        open={!!anchorEl}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper
          data-test={"chart-comparison-popover"}
          sx={{
            backgroundColor: theme.palette.common.white,
            maxWidth: "550px",
          }}
        >
          <Box sx={{ p: theme.spacing(2) }}>
            <Typography>{i18n.t("DATE_RANGE_COMPARISON.DATE_RANGE", {ns: "component"})}</Typography>
            <Box
              sx={{
                mb: 0,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <DateRangeOptions
                containerStyle={{ pr: 2 }}
                selectedQuickOption={selectedQuickOption}
                onSelectQuickOption={onSelectQuickOption}
                selectedCompareOption={selectedCompareOption}
                onSelectCompareOption={onSelectCompareOption}
              />
              <DateRangeDates
                initFirstRange={firstDateRange}
                initSecondRange={secondDateRange}
                onSelectFirstRange={onChangeFirstDateRange}
                onSelectSecondRange={handleChangeSecondDateRange}
                selectedQuickOption={selectedQuickOption}
                selectedCompareOption={selectedCompareOption}
              />
            </Box>
            {selectedCompareOption && selectedCompareOption.value === "custom" && (
              <Alert severity="error" sx={{ mb: 1, fontSize: "13px" }}>
                <Box sx={{ mr: 1, color: "#E31B0C" }}>{i18n.t("DATE_RANGE_COMPARISON.IMPORTANT", {ns: "component"})}</Box>
                {i18n.t("DATE_RANGE_COMPARISON.IMPORTANT_MESSAGE", {ns: "component"})}
              </Alert>
            )}
            {errorMessage && (
              <Alert
                sx={{ fontSize: "13px" }}
                severity="error"
                data-test="chart-comparison-date-range-error"
              >
                {errorMessage}
              </Alert>
            )}
          </Box>

          <Divider />
          <DateRangeAction
            onClose={handleClose}
            onApply={handleApply}
            isValidDateRange={true}
            data-test="chart-comparison-date-range-apply-button"
          />
        </Paper>
      </Popover>
    </>
  );
};
