import React, { useCallback, useState } from "react";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { Moment } from "moment-timezone";
import { EMPTY, MeterEventsState } from "../../types/MeterEvents";
import { useMeterEvents } from "../../hooks/useMeterEvents";
import { MeterEventsTable } from "./MeterEventsTable";
import { getStartOfYesterday } from "../../commons/dates";

interface Props {
  meterId: string;
}

const validateDateRange = (dateRange: DateRange<Moment>) => {

  const dateRangeDiff = dateRange[1]?.diff(dateRange[0], 'days');

  if (!dateRange[0] || !dateRange[1] || dateRange[0].isAfter(dateRange[1])) {
    return "METER_EVENTS.INVALID_DATE_RANGE_ERROR_MESSAGE";
  }

  if (dateRangeDiff && dateRangeDiff > 6) {
    return "METER_EVENTS.MAX_DATE_RANGE_ERROR_MESSAGE";
  }

  return "";
}

export const initialDateRangeState: DateRange<Moment> = [
  getStartOfYesterday(),
  getStartOfYesterday()
];

export const MeterEventsTableContainer: React.FC<Props> = ({ meterId }) => {
  const [dateRange, setDateRange] = useState<DateRange<Moment>>(initialDateRangeState);
  const [currentState, setState] = useState<MeterEventsState>(EMPTY);
  const [, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [dateValidationError, setDateValidationError] = useState<string>("");

  const handleSetError = useCallback((error: string) => {
    setError(error);
    setLoading(false);
    setState(EMPTY);
  }, []);

  const handleSetState = useCallback((state: MeterEventsState) => {
    setLoading(false);
    setState(state);
  }, []);

  const handleDateRangeChange = useCallback((dateRange: DateRange<Moment>) => {

    const validationError = validateDateRange(dateRange);

    if (validationError) {
      setDateValidationError(validationError);
      setLoading(false);
      return;
    }

    setDateValidationError("");
    setLoading(true);
    setState(EMPTY);
    setDateRange(dateRange);
  }, []);

  useMeterEvents(handleSetState, handleSetError, meterId, dateRange);

  return (
    <MeterEventsTable
      currentState={currentState.meterEvents}
      dateRange={dateRange}
      handleDateRangeChange={handleDateRangeChange}
      loading={loading}
      dateValidationError={dateValidationError}
    />
  );
}
