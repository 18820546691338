import { Checkbox, TableCell, TableRow } from "@mui/material";
import React from "react";
import { DataStream } from "../../types/DataStream";
import { withSeparator } from "../../styles";
import { DataStreamLink } from "../DataStreamLink";

interface Props {
  item: DataStream;
  link: string;
  checked: boolean;
  enableCheckbox?: boolean;
  onChangeSelectedDataStream: (item: DataStream, checked: boolean) => void;
  preventCheckbox?: boolean;
}

const ROW_HEIGHT = "40px";

const DataStreamRow: React.FC<Props> = ({
  item,
  link,
  checked,
  enableCheckbox = true,
  onChangeSelectedDataStream,
  preventCheckbox = false
}) => (
  <TableRow
    data-test={"data-stream-summary"}
    sx={{
      height: ROW_HEIGHT,
      '&:last-child td, &:last-child th': { border: 0 },
    }}
  >
    {enableCheckbox && (
      <TableCell sx={{ padding: 0, paddingLeft: 2, width: "40px" }}>
        <Checkbox
          size={"small"}
          checked={checked}
          onChange={(event) => {
            onChangeSelectedDataStream(item, event.target.checked)
          }}
          disabled={preventCheckbox}
        />
      </TableCell>
    )}
    <StyledTableCell sx={{ padding: 1, paddingLeft: (enableCheckbox ? 0 : 2), width: "150px" }} data-test={"data-stream-name"}>
      <DataStreamLink name={item.name} link={link} intent={item.intent}/>
    </StyledTableCell>
    <StyledTableCell data-test={"data-stream-service-category"} sx={{ padding: 1 }} align={"center"}>
      {item.serviceCategory}
    </StyledTableCell>
    <TableCell data-test={"data-stream-channel-name"} sx={{ padding: 1, paddingLeft: 2 }}>
      {item.channelName}
    </TableCell>
  </TableRow>
);

export default React.memo(DataStreamRow);

export const StyledTableCell = withSeparator(TableCell, {
  height: "50%"
});
