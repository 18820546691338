/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import moment from "moment";
import { IntervalConsumption, IntervalsConsumption } from "../types/IntervalConsumption";
import {
  CHART_COLORS,
  ChartPlot,
  ChartSchema,
  COMPARE,
  COMPARISON_SUFFIX,
  DataStreamChartComparisonParams,
  DataStreamChartComparisonResponse,
  DataStreamChartComparisonVersionState,
  DynamicChartLine,
  EMPTY_CHART_RESPONSE,
  TIME_COMPARISON_SCHEMA,
  TIME_SCHEMA,
} from "../types/DataStreamChartComparisonState";
import { retrieveChartIntervals } from "../services/IntervalService";
import { convertToVersionItems } from "./useDataStreamIntervalVersions";
import {
  calculateDateTimeWithTimeZone,
  getDefaultVersion,
  returnDateRangeParams,
} from "../hooks/useDataStreamChart";
import { IntervalVersion } from "../types/IntervalVersion";
import { i18n } from "../global/i18n";

 const FUSION_DATE_TIME_PATTERN = i18n.t("FUSION_TIME_DATE_TIME_PATTERN", {ns: "component"});

const areSetsEqual = (a: any, b: any) =>
  a.size === b.size && [...a].every((value) => b.has(value));

export const useDataStreamChartComparison = (
  setLoading: (state: boolean) => void,
  setError: (state: string) => void,
  setState: (state: DataStreamChartComparisonResponse) => void,
  versionState: DataStreamChartComparisonVersionState,
  compareOption: string | undefined,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  propsParams: any,
): void => {
  useEffect(() => {
    setLoading(true);

    const params = versionState;

    const chartParams = params.versions;
    if (chartParams.length <= 0) {
      setLoading(false);
      setState(EMPTY_CHART_RESPONSE);
      setError("");
      return;
    }

    (async () => {
      try {
        let response = await retrieveChartIntervalServices(chartParams);

        if (
          compareOption &&
          compareOption?.length > 0 &&
          params.compareVersions &&
          params.compareVersions.length > 0
        ) {
          const compareChartResponse = await retrieveChartIntervalServices(
            params.compareVersions,
            compareOption,
          );
          response = transformComparisonChartData(
            compareOption,
            response,
            compareChartResponse,
          );
        }
        response.schemas = addChartPlotColors(response.schemas);

        // check current request is latest or not
        // check datastrem name
        const excepted = new Set(
          propsParams.map(
            (item: any) => `${item.datastreamName}@@${item.meterId}@@${item.intent}`,
          ),
        );
        const actual = new Set(
          chartParams.map(
            (item: any) => `${item.datastreamName}@@${item.meterId}@@${item.intent}`,
          ),
        );

        if (areSetsEqual(excepted, actual)) {
          setState(response);
          setLoading(false);
          setError("");
        }
      } catch (error: any) {
        // check current request is latest or not
        // check datastrem name
        const excepted = new Set(
          propsParams.map(
            (item: any) => `${item.datastreamName}@@${item.meterId}`,
          ),
        );
        const actual = new Set(
          chartParams.map(
            (item: any) => `${item.datastreamName}@@${item.meterId}`,
          ),
        );

        if (areSetsEqual(excepted, actual)) {
          setLoading(false);
          setState(EMPTY_CHART_RESPONSE);
          setError(error.message);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionState]);
};

export const retrieveChartIntervalServices = async (
  params: DataStreamChartComparisonParams[],
  compareOption?: string,
): Promise<DataStreamChartComparisonResponse> => {
  const response: DataStreamChartComparisonResponse = { data: [], schemas: [] };

  const executeServiceResponses = await (async () => {
    const serviceResponses: IntervalsConsumption[] = [];

    for (let i = 0; i < params.length; i++) {
      const param = params[i];
      if (!param.datastreamName || !param.intent || param.versions.length == 0) {
        continue;
      }
      const processedVersion = getProcessedVersion(param.versions);
      const versionsMissing =
        !processedVersion || processedVersion.version === 0;
      const dateRangeParam = returnDateRangeParams(param.dateRange);
      const dateRangeMissing =
        dateRangeParam.start.length <= 0 || dateRangeParam.end.length <= 0;
      if (versionsMissing || dateRangeMissing) {
        continue;
      }

      try {
        const response = await retrieveChartIntervals(
          param.meterId,
          param.datastreamName,
          param.intent,
          dateRangeParam,
          processedVersion?.version,
          "processed",
        );

        serviceResponses.push(response);
      } catch (error: any) {
        console.log("retrieveChartIntervals error", error);
      }
    }
    return serviceResponses;
  })();

  if (executeServiceResponses.length > 0) {
    response.data = transformToChartLinesData(
      params,
      executeServiceResponses,
      compareOption,
    );
    response.schemas = getChartSchemas(params, compareOption);
  }
  return response;
};

const getProcessedVersion = (versions: IntervalVersion[]) => {
  const versionItems = convertToVersionItems(versions);
  const latestProcessedVersion = getDefaultVersion(versionItems);
  return latestProcessedVersion;
};

export const transformToChartLinesData = (
  params: DataStreamChartComparisonParams[],
  intervalsResponses: IntervalsConsumption[],
  compareOption?: string,
): DynamicChartLine[] => {
  const linesOfLines: DynamicChartLine[][] = [];
  const availableDateRanges =
    returnAvailableDateTimeWithFiveMinutesInterval(intervalsResponses);

  params.forEach((param: DataStreamChartComparisonParams, index: number) => {
    const chartPlotNames = returnChartPlotNames(
      param.datastreamName,
      param.meterId,
      compareOption,
    );
    const mappedChartLines = mapMissingValues(
      intervalsResponses[index].intervals,
      availableDateRanges,
      chartPlotNames,
    );
    linesOfLines.push(mappedChartLines);
  });

  const linesData = mapMultipleLinesSameTimeXaxis(linesOfLines, compareOption);
  return linesData;
};

export const mapMultipleLinesSameTimeXaxis = (
  linesOfLines: DynamicChartLine[][],
  compareOption?: string,
): DynamicChartLine[] => {
  const linesData: DynamicChartLine[] = [];

  const suffix =
    compareOption && compareOption.length > 0 ? ` ${COMPARISON_SUFFIX}` : "";
  const timeField = TIME_SCHEMA + suffix;

  linesOfLines.forEach((lines) => {
    let yAxisValue = "";
    if (lines.length > 0) {
      yAxisValue = Object.keys(lines[0]).filter(
        (key) => key !== timeField,
      )[0];
    }
    lines.forEach((line) => {
      
      const currentTimeFieldValue = calculateDateTimeWithTimeZone(line[timeField]+"");
      let lineData = linesData.find(
        (lineData) => lineData[timeField] === currentTimeFieldValue,
      );
      if (!lineData) {
        // change format timeField
        lineData = { [timeField]: currentTimeFieldValue };
        lineData[yAxisValue] = line[yAxisValue];
        // add new key value with data timestamp for easy filter
        lineData["timestamp" + suffix] = getTimestampValueLineData(line[timeField]+"");
        linesData.push(lineData);
      } else {
        lineData[yAxisValue] = line[yAxisValue];
        // add new key value with data timestamp for easy filter
        lineData["timestamp" + suffix] = getTimestampValueLineData(line[timeField]+"");
      }
    });
  });
  return linesData;
};

export const returnAvailableDateTimeWithFiveMinutesInterval = (
  intervalsResponses: IntervalsConsumption[],
): string[] => {
  const result: string[] = [];
  const minMaxDateTime = returnMinMaxDateTimesFromIntervals(intervalsResponses);
  let availableMinDate = new Date(minMaxDateTime[0]);
  const availableMaxDate = new Date(minMaxDateTime[1]);

  while (moment(availableMinDate).isBefore(availableMaxDate)) {
    const minDateString = availableMinDate.toISOString().split(".")[0] + "Z";
    result.push(minDateString);
    availableMinDate = moment(availableMinDate).add(5, "minutes").toDate();
  }

  result.push(minMaxDateTime[1]);

  return result;
};

export const returnMinMaxDateTimesFromIntervals = (
  responses: IntervalsConsumption[],
): string[] => {
  let minDate = "";
  let maxDate = "";

  responses.forEach((response) => {
    response.intervals.forEach((interval) => {
      if (!minDate) {
        minDate = interval.dateTime;
      } else {
        if (moment(interval.dateTime).isBefore(minDate)) {
          minDate = interval.dateTime;
        }
      }
      if (!maxDate) {
        maxDate = interval.dateTime;
      } else {
        if (moment(interval.dateTime).isAfter(maxDate)) {
          maxDate = interval.dateTime;
        }
      }
    });
  });

  return [minDate, maxDate];
};

export const mapMissingValues = (
  intervals: IntervalConsumption[],
  availableDateRanges: string[],
  chartPlotNames: ChartPlot,
): DynamicChartLine[] => {
  const chartLinesMappedMissingValues = availableDateRanges.map((dateTime) => {
    const line = { [chartPlotNames.timeXaxis]: dateTime };
    const existingInterval = intervals.find(
      (interval) => interval.dateTime === dateTime,
    );
    line[chartPlotNames.plotYaxis] = existingInterval
      ? existingInterval.latestQuantity
      : "0";
    return line;
  });

  return chartLinesMappedMissingValues;
};

export const transformComparisonChartData = (
  compareOption: string,
  chartResponse: DataStreamChartComparisonResponse,
  compareChartResponse: DataStreamChartComparisonResponse,
): DataStreamChartComparisonResponse => {
  let combinedData: DataStreamChartComparisonResponse = {
    data: [],
    schemas: [],
  };

  combinedData = mergeChartResponseByIndex(chartResponse, compareChartResponse);

  return combinedData;
};

export const mergeChartResponseByIndex = (
  chartResponse: DataStreamChartComparisonResponse,
  compareChartResponse: DataStreamChartComparisonResponse,
): DataStreamChartComparisonResponse => {
  const dataResponse = new Set<DynamicChartLine>();

  chartResponse.data.forEach((line: DynamicChartLine, index: number) => {
    const compareLine = compareChartResponse.data[index];

    if (compareLine) {
      // eslint-disable-next-line max-len
      // filter key !== timestamp for keep timestamp using current date, if not filter, timestamp will be compare data (ex last preiod last year)
      Object.keys(compareLine)
        .filter((key) => key !== TIME_COMPARISON_SCHEMA)
        .forEach((key) => {
          line[key] = compareLine[key];
        });
      dataResponse.add(line);
    }
  });

  const response: DataStreamChartComparisonResponse = {
    data: Array.from(dataResponse),
    schemas: chartResponse.schemas.concat(
      compareChartResponse.schemas.filter(
        (x) => x.name !== TIME_COMPARISON_SCHEMA,
      ),
    ),
  };

  return response;
};

export const addChartPlotColors = (schemas: ChartSchema[]): ChartSchema[] => {
  const yAxisSchemas = schemas.filter((schema) => schema.name !== TIME_SCHEMA);
  const basePlotColors = CHART_COLORS.split(",");

  const isComparing = schemas.some((schema) =>
    schema.name.includes(COMPARISON_SUFFIX),
  );
  let availablePlotColors = basePlotColors.slice(0, yAxisSchemas.length);
  if (isComparing) {
    const plotColorsWithComparison = basePlotColors.slice(
      0,
      yAxisSchemas.length / 2,
    );
    availablePlotColors = [
      ...plotColorsWithComparison,
      ...plotColorsWithComparison,
    ];
  }

  let plotColorIndex = 0;

  const returnSchemas = schemas.map((schema: ChartSchema) => {
    if (schema.name !== TIME_SCHEMA) {
      schema.plotColor = availablePlotColors[plotColorIndex];
      // eslint-disable-next-line no-plusplus
      plotColorIndex++;
    }
    return schema;
  });

  return returnSchemas;
};

export const timeSchema: ChartSchema = {
  name: TIME_SCHEMA,
  type: "date",
  format: FUSION_DATE_TIME_PATTERN,
};

export const timeCompareSchema: ChartSchema = {
  name: TIME_COMPARISON_SCHEMA,
  type: timeSchema.type,
  format: timeSchema.format,
};

export const getChartSchemas = (
  params: DataStreamChartComparisonParams[],
  compareOption?: string,
): ChartSchema[] => {
  const schemas = new Set<ChartSchema>();

  schemas.add(compareOption ? timeCompareSchema : timeSchema);

  for (let i = 0; i < params.length; i++) {
    const plotSchema = returnChartPlotNames(
      params[i].datastreamName,
      params[i].meterId,
      compareOption,
    );

    schemas.add({
      name: plotSchema.plotYaxis,
      type: "number",
      plotStyle: compareOption ? COMPARE : "",
    });
  }

  return Array.from(schemas);
};

export const returnChartPlotNames = (
  datastreamName: string,
  meterId: string,
  compareOption?: string,
): ChartPlot => {
  const suffix =
    compareOption && compareOption.length > 0 ? ` ${COMPARISON_SUFFIX}` : "";

  return {
    timeXaxis: TIME_SCHEMA + suffix,
    plotYaxis: `${datastreamName} ${
      meterId.replace("-EDMI-ELECTRICITY", "") + suffix
    }`,
  };
};

export const getTimestampValueLineData = (dateTime: string) => {
  return moment(dateTime).toDate().getTime();
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const filterTableDataByEventClickDataPlot = (
  eventObject: any,
  dataChart: DynamicChartLine[],
): DynamicChartLine[] => {
  if (dataChart.length === 0) {
    return [];
  }
  const { startText } = eventObject.data;
  const res = dataChart.filter((item) => item.Time === startText);
  
  if (res.length === 0) {
    return [dataChart[0]];
  }
  return [res[0]];
};
