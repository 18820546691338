import React from "react";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { SearchResultTag } from "../SearchResultTag";
import { i18n } from "../../global/i18n";
import { Link } from "react-router-dom";
import { getConnectionPointNumberFromConnectionPointId, } from "../DataStreamBreadCrumbs/DataStreamBreadCrumbs";


interface Props {
  connectionPointId: string;
  meterId: string;
  commodity: string;
  serialNumber: string;
}

export const getConnectionPointLink = (id: string): string => {
  return `/connectionpoint/${id}`;
}

export const getMeterLink = (connectionPointId: string, id: string): string => {
  return `/connectionpoint/${connectionPointId}/meter/${id}`;
};

export const MeterEventsBreadCrumbs: React.FC<Props> = ({
    connectionPointId,
    meterId,
    commodity,
    serialNumber
  }
) => {
  const isContentDisplay = serialNumber.length > 0;

  return (
    <Box role="presentation">
      <Breadcrumbs aria-label="breadcrumb" data-test={"datastream-breadcrumb"}>
        <Typography component={"span"} sx={{ fontSize: "0.8rem" }}>
          <SearchResultTag color="#FFDAC3" fonstSize="0.8rem">
            {i18n.t("CONNECTION_POINT_SEARCH_TOGGLE.CONNECTION_POINT", { ns: "component" })}
          </SearchResultTag>
          {isContentDisplay && (
            <Link to={getConnectionPointLink(connectionPointId)}>
              {getConnectionPointNumberFromConnectionPointId(connectionPointId, commodity)}
            </Link>
          )}
        </Typography>

        <Typography component={"span"} sx={{ fontSize: "0.8rem" }}>
          <SearchResultTag color="#2196F34A" fonstSize="0.8rem">
            {i18n.t("CONNECTION_POINT_SEARCH_TOGGLE.METER", { ns: "component" })}
          </SearchResultTag>
          {isContentDisplay && (
            <Link to={getMeterLink(connectionPointId, meterId)}>{serialNumber}</Link>
          )}
        </Typography>

        <Typography component={"span"} sx={{ fontSize: "0.8rem" }}>
          Meter Events
        </Typography>
      </Breadcrumbs>
    </Box>
  );
};
