import React from "react";
import { MenuItem, Select, SelectChangeEvent, Stack, styled, SxProps, Theme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SelectItemType } from "../../types/SelectItemType";
import { YESTERDAY, COMPARE, SAME_PERIOD_LAST_YEAR_OPTION, CUSTOM } from "../../types/DataStreamChartComparisonState";
import { i18n } from "../../global/i18n";

interface Props {
    containerStyle?: SxProps<Theme>;
    selectedQuickOption: SelectItemType | null;
    onSelectQuickOption: (event: SelectChangeEvent<unknown>) => void;
    selectedCompareOption: SelectItemType | null;
    onSelectCompareOption: (event: SelectChangeEvent<unknown>) => void;
}

export const quickOptions: SelectItemType[] = [
    {
        id: "custom-item",
        name: "DATE_RANGE_OPTIONS.QUICK_OPTIONS.CUSTOM",
        value: "custom",
    },
    {
        id: "yesterday-item",
        name: "DATE_RANGE_OPTIONS.QUICK_OPTIONS.YESTERDAY",
        value: YESTERDAY,
    },
    {
        id: "compare-item",
        name: "DATE_RANGE_OPTIONS.QUICK_OPTIONS.COMPARE",
        value: COMPARE,
    }
];

export const compareOptions: SelectItemType[] = [
    {
        id: "same-period-last-year-item",
        name: "DATE_RANGE_OPTIONS.COMPARE_OPTIONS.PERIOD",
        value: SAME_PERIOD_LAST_YEAR_OPTION,
    },
    {
        id: "custom-item",
        name: "DATE_RANGE_OPTIONS.COMPARE_OPTIONS.CUSTOM",
        value: CUSTOM,
    }
];

export const StyledSelectOptions = styled(Select)(
    () => ({
      backgroundColor: "#f4f5f7",
      width: 200,
      height: 32,
      borderRadius: 4,
      fontSize: 14,
      marginTop: 8,
      marginBottom: 8,
      "& .MuiSelect-select": {
        p: 0
      }
    }),
);

export const DateRangeOptions: React.FC<Props> = ({
    containerStyle,
    selectedQuickOption,
    onSelectQuickOption,
    selectedCompareOption,
    onSelectCompareOption
}) => {
    return (
        <Stack direction={"column"} sx={containerStyle || null}>
            <StyledSelectOptions
                value={selectedQuickOption?.id ?? ""}
                onChange={onSelectQuickOption}
                IconComponent={KeyboardArrowDownIcon}
                data-test={"original-dropdown"}
            >
                {quickOptions.map((option: SelectItemType) => (
                    <MenuItem value={option.id} key={option.id} sx={{ fontSize: 14 }}>
                        {i18n.t(option.name, {ns: "component"})}
                    </MenuItem>
                ))}
            </StyledSelectOptions>
            {
                selectedQuickOption && selectedQuickOption.value === "compare"
                && (
                    <StyledSelectOptions
                        value={selectedCompareOption?.id ?? ""}
                        onChange={onSelectCompareOption}
                        IconComponent={KeyboardArrowDownIcon}
                        data-test={"compared-dropdown"}
                    >
                        {compareOptions.map((option: SelectItemType) => (
                            <MenuItem value={option.id} key={option.id} sx={{ fontSize: 14 }}>
                                {i18n.t(option.name, {ns: "component"})}
                            </MenuItem>
                        ))}
                    </StyledSelectOptions>
                )
            }
        </Stack>
    );
};
