import moment, { Moment } from "moment-timezone";
import { getI18nDateTimePattern } from "./localisation";

export const DATE_TIME_PATTERN = getI18nDateTimePattern("DATE_TIME_PATTERN");
export const DATE_PATTERN = getI18nDateTimePattern("DATE_PATTERN");
export const EMPTY_DATE = "-";

type FormatterDateType = string | undefined | Date;

export type DateTimeRange = {
  startTime: string;
  endTime: string;
}

export const toMomentFromDateString = (dateString: string): Moment => {
  return moment(dateString);
}

export const isMidNight = (momentDate: moment.Moment): boolean => {
  return momentDate.format("Hms") === "000";
}

export const isEndOfDay = (momentDate: moment.Moment): boolean => {
  return momentDate.format("Hms") === "235959";
}

export const isYesterday = (momentDate: moment.Moment) :boolean => {
  return moment(momentDate).isSame(moment().subtract(1, 'day'), 'day')
}

export const getStartOfToday = (): Moment => {
  return moment().startOf("day");
}

export const getStartOfDateString = (dateString: string): Moment => {
  return getStartOfDay(toMomentFromDateString(dateString));
}

export const getStartOfDay = (date: Moment): Moment => {
  return moment(date).startOf("day");
}

export const getStartOfYesterday = (): Moment => {
  return getStartOfToday().subtract(1, "days");
}

export const getEndOfYesterday = (): Moment => {
  return getStartOfToday().subtract(1, "seconds");
}

export const getEndOfDay = (date: Moment): Moment => {
  return getStartOfDay(date).add(1, "days").subtract(1, "seconds");
}

export const toDayRange = (date: Moment): DateTimeRange => {
  const littleAfterMidnightToday = date.hours(0).minutes(0).seconds(0).millisecond(0);

  const tomorrow = moment(date);
  tomorrow.add(1, "days");
  const tomorrowMidnight = tomorrow.hours(0).minutes(0).seconds(0).millisecond(0);

  return {
    startTime: littleAfterMidnightToday.toISOString(),
    endTime: tomorrowMidnight.toISOString(),
  };
};

export const toDateRange = (startDate: Moment, endDate: Moment): DateTimeRange => {
  const startMidnightToday = startDate.hours(0).minutes(0).seconds(0).millisecond(0);

  const tomorrowEndDate = moment(endDate);
  tomorrowEndDate.add(1, "days");
  const tomorrowEndDateMidnight = tomorrowEndDate.hours(0).minutes(0).seconds(0).millisecond(0);

  return {
    startTime: startMidnightToday.toISOString(),
    endTime: tomorrowEndDateMidnight.toISOString(),
  };
};

export const getUnitOfWorkTimeStampOf = (date: Moment): string => {
  return getStartOfDay(date).add(1, "days").format();
};

export const formatDateTime = (date: FormatterDateType): string => {
  return dateTimeFormatter(date, DATE_TIME_PATTERN);
}

export const formatDate = (date: FormatterDateType): string => {
  return dateTimeFormatter(date, DATE_PATTERN);
}

const dateTimeFormatter = (date: FormatterDateType, outputFormat: string): string => {
  if (!date) return EMPTY_DATE;

  const momentDate = moment(date);
  return momentDate.isValid()
    ? momentDate.format(outputFormat)
    : EMPTY_DATE;
}

export const formatToYesterdayDefaultTimezone = (date: string | undefined): string => {
  if (!date) return EMPTY_DATE;

  const momentDate = moment(date);
  return momentDate.isValid()
    ? momentDate.subtract(1, "days").format(DATE_TIME_PATTERN)
    : EMPTY_DATE;
}
