import React, { useMemo } from "react";
import { styled, Typography } from "@mui/material";
import { VersionItem, VersionType } from "../../types/IntervalVersion";
import { i18n } from "../../global/i18n";

interface Props {
  versionItem: VersionItem;
}

const t = (key: string) => {
  return i18n.t(key, { ns: "component" });
};

export const IntervalVersionDropdownItemText: React.FC<Props> = ({
  versionItem,
}) => {
  const displayText = useMemo(
    () => getDisplayTextOfVersionItem(versionItem),
    [versionItem],
  );

  return (
    <>
      {versionItem.type === VersionType.PROCESSED && (
        <Typography sx={{ paddingLeft: 1, paddingRight: 1 }}>
          {"\u2022"}
        </Typography>
      )}
      {displayText}
    </>
  );
};

export const getTypeName = (type: string): string => {
  return type === VersionType.PROCESSED
    ? t("INTERVAL_VERSION_DROPDOWN_ITEM_TEXT.PROCESSED_TYPE")
    : t("INTERVAL_VERSION_DROPDOWN_ITEM_TEXT.UNPROCESSED_TYPE");
};

export const LATEST_ON_MARKET = "LATEST_ON_MARKET";
export const ON_MARKET = "ON_MARKET";
export const LATEST = "LATEST";

export const getSuffixType = (versionItem: VersionItem): string => {
  if (versionItem.isLatestOnMarket) {
    return LATEST_ON_MARKET;
  }
  if (versionItem.onMarket) {
    return ON_MARKET;
  }
  if (versionItem.isLatest) {
    return LATEST;
  }
  return "";
};

export const LatestOnMarketTypography = styled(Typography)`
  color: #3b873e;
` as typeof Typography;

export const getDisplayTextOfVersionItem = (
  versionItem: VersionItem | undefined,
): React.ReactNode => {
  if (!versionItem) {
    return "";
  }

  const suffixType = getSuffixType(versionItem);

  return (
    <Typography sx={{ "p,span": { fontSize: 14 } }}>
      <Typography component={"span"}>
        {getTypeName(versionItem.type)}
      </Typography>
      {versionItem.version}
      {suffixType === LATEST_ON_MARKET && (
        <Typography component={"span"} sx={{ color: "text.secondary" }}>
          {" - "}
          <LatestOnMarketTypography component={"span"}>
            {t("INTERVAL_VERSION_DROPDOWN_ITEM_TEXT.ON_MARKET")}
          </LatestOnMarketTypography>
        </Typography>
      )}
      {suffixType === ON_MARKET && (
        <Typography component={"span"} sx={{ color: "text.secondary" }}>
          {" "}
          - {t("INTERVAL_VERSION_DROPDOWN_ITEM_TEXT.ON_MARKET")}
        </Typography>
      )}
      {suffixType === LATEST && (
        <Typography component={"span"} sx={{ color: "text.secondary" }}>
          {" "}
          - {t("INTERVAL_VERSION_DROPDOWN_ITEM_TEXT.LATEST")}
        </Typography>
      )}
    </Typography>
  );
};
