/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Moment } from "moment-timezone";
import { Box, InputAdornment, Stack, TextField, useTheme } from "@mui/material";
import { DateRange, DateRangePicker as MuiDateRangePicker, } from "@mui/x-date-pickers-pro/DateRangePicker";
import { MuiTextFieldProps } from "@mui/x-date-pickers/internals";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { DATE_PATTERN, getStartOfYesterday } from "../../commons/dates";

export const DEFAULT_DATE_RANGE_PICKER_DATA_TEST = "date-range-picker";
const startOfYesterday = getStartOfYesterday();
export const defaultDateRangeState: DateRange<Moment> = [
  startOfYesterday,
  startOfYesterday,
];

interface Props {
  showicon?: boolean;
  disabled?: boolean;
  "data-test"?: string;
  onDateRangeChanged: (dateRange: DateRange<Moment>) => void;
  initialDateRangeState?: DateRange<Moment>; // init default date range, default is today
  isOnlyCallbackWhenClose?: boolean; // param for dynamic only callback when close the date range or not. true for case in chart component
  canSelectFuture?: boolean;
}

type StyledDatePickerInputType = MuiTextFieldProps & {
  "data-test"?: string;
  showicon?: string;
};

export const StyledDatePickerInput: React.FC<StyledDatePickerInputType> =
  // eslint-disable-next-line react/display-name
  React.forwardRef((props, ref?: React.Ref<HTMLDivElement>): ReactElement => {
    const theme = useTheme();
    const inputRef: any = useRef();
    const canShowIcon = Boolean(props["showicon"]);
    return (
      <Stack
        direction={"row"}
        {...(props["data-test"] ? { "data-test": props["data-test"] } : {})}
        sx={{
          alignItems: "center",
          position: "relative",
          width: canShowIcon ? "126px" : "auto",
          "div.MuiOutlinedInput-root input": {
            width: canShowIcon ? "126px" : "auto",
            zIndex: 1,
          },
        }}
      >
        <TextField
          {...props}
          onChange={props.onChange}
          sx={
            canShowIcon
              ? {
                ".MuiOutlinedInput-input": {
                  width: "110px !important",
                },
              }
              : {}
          }
          ref={ref}
          inputRef={inputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  pointerEvents: "none",
                  position: "absolute",
                  right: "4px",
                }}
                disablePointerEvents={true}
              >
                {canShowIcon && (
                  <InsertInvitationIcon
                    sx={{
                      pointerEvents: "none",
                      path: { fill: theme.palette.text.secondary },
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    );
  });

export const renderInput = (
  dataTest: string = DEFAULT_DATE_RANGE_PICKER_DATA_TEST,
  showicon?: boolean,
) => {
  // eslint-disable-next-line react/display-name
  return (
    startProps: MuiTextFieldProps,
    endProps: MuiTextFieldProps,
  ): ReactElement => (
    <>
      <StyledDatePickerInput
        {...startProps}
        data-test={`${dataTest}-from`}
        showicon={showicon?.toString()}
      />
      <Box
        data-test={`${dataTest}-from-element-close`}
        sx={{
          width: "18px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span>-</span>
      </Box>
      <StyledDatePickerInput
        {...endProps}
        data-test={`${dataTest}-to`}
        showicon={showicon?.toString()}
      />
    </>
  );
};

export const DateRangePicker: React.FC<Props> = ({
  showicon = true,
  disabled = false,
  onDateRangeChanged,
  initialDateRangeState = defaultDateRangeState,
  isOnlyCallbackWhenClose = false,
  canSelectFuture= true,
  ...props
}) => {
  const [dateRange, setDateRange] = useState<DateRange<Moment>>(
    initialDateRangeState,
  );

  const onChange = (value: any) => {
    setDateRange(value);
  };

  const onAccept = (newDateRange: any) => {
    if (!isOnlyCallbackWhenClose) {
      handleDateRangeChanged(newDateRange);
    }
  };

  const handleDateRangeChanged = (newDateRange: DateRange<Moment>) => {
    onDateRangeChanged(newDateRange);
  };

  useEffect(() => {
    setDateRange(initialDateRangeState);
  }, [initialDateRangeState]);

  useEffect(() => {
    // Make sure update the dates to the parent when it is loaded.
    onDateRangeChanged(initialDateRangeState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      direction={"row"}
      data-test={props["data-test"] || DEFAULT_DATE_RANGE_PICKER_DATA_TEST}
      className={"date-range-picker"}
      sx={{
        my: 1,
        width: "fit-content",
      }}
    >
      <MuiDateRangePicker
        className="date-range-picker-component"
        startText={""}
        endText={""}
        disabled={disabled}
        closeOnSelect={false}
        value={dateRange}
        inputFormat={DATE_PATTERN}
        onChange={onChange}
        onAccept={onAccept}
        renderInput={renderInput(props["data-test"], showicon)}
        desktopModeMediaQuery={"@media (min-width:368px)"}
        onClose={() => {
          isOnlyCallbackWhenClose && handleDateRangeChanged(dateRange);
        }}
        maxDate={ canSelectFuture ? undefined : startOfYesterday }
      />
    </Stack>
  );
};
