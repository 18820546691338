/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const LOGIN_TOKEN_KEY = "login_token";

const getUserJson = () => {
  const loginTokenStr = window.sessionStorage.getItem(LOGIN_TOKEN_KEY);
  if (loginTokenStr) {
    const loginToken = JSON.parse(loginTokenStr);
    if (loginToken.id_token) {
      return JSON.parse(window.atob(loginToken.id_token.split(".")[1]));
    }
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getInitials = () => {
  const userJson = getUserJson();
  if (!userJson) {
    return "";
  }
  const firstName = userJson["given_name"] || "";
  const lastName = userJson["family_name"] || "";
  return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getEmail = () => {
  const userJson = getUserJson();
  if (!userJson) {
    return "";
  }
  return userJson.email || "";
};

export {
  LOGIN_TOKEN_KEY,
  getInitials,
  getEmail
};
