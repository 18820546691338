import React, { useCallback, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Grid, Table, TableBody, TableContainer, Typography } from "@mui/material";
import { DataStream } from "../../types/DataStream";
import DataStreamRow from "./DataStreamRow";
import { ApplySubScenarioButton } from "../ApplySubScenarioButton";
import { ApplyResult } from "../ApplySubScenarioButton/ApplySubScenarioButton";
import { useTranslation } from "react-i18next";
import { FeatureFlag } from "../FeatureFlag";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { useAllowedSubScenarios } from "../../hooks/useAllowedSubScenarios";

interface Props {
  content: DataStream[];
}

type Params = {
  connectionPointId: string;
  meterId: string;
};

const datastreamOrder: { [key: string]: number } = { 'ON-MARKET/BILLING': 1, 'OFF-MARKET/BILLING': 2, 'OFF-MARKET/INFORMATIONAL': 3 }

export const sortContent = (content: DataStream[]): DataStream[] => content.sort((a: DataStream, b: DataStream) => {
  return (
    (datastreamOrder[a.serviceCategory] || Number.MAX_VALUE) - (datastreamOrder[b.serviceCategory] || Number.MAX_VALUE)
    ||
    a.name.localeCompare(b.name)
  );
});

export const DataStreams: React.FC<Props> = ({ content }) => {
  const { connectionPointId = "", meterId = "" } = useParams<Params>();
  const [params] = useSearchParams();
  const overlay = Boolean(params.get("overlay"));
  const [selectedDataStreams, setSelectedDataStreams] = useState<DataStream[]>([]);
  const { t } = useTranslation("component");
  const enableDatastreamCheckbox = useFeatureFlag("enable_uds");
  const allowedSubScenarios = useAllowedSubScenarios();

  const shouldPreventCheckbox = (intent: string) => !allowedSubScenarios.includes(intent);

  const canApplyFutureUds = useMemo(() => {
    return content.length === selectedDataStreams.length;
  }, [content, selectedDataStreams]);

  const sortedContent = sortContent(content);

  const handleSelectedDataStream = useCallback((item: DataStream, checked: boolean) => {
    setSelectedDataStreams(prevState => {
      const newArray = [...prevState];
      if (checked) {
        return [...newArray, item];
      }
      return newArray.filter(dataStream => dataStream.name !== item.name);
    });
  }, []);

  const handleAfterApply = useCallback((result: ApplyResult) => {
    if (result === ApplyResult.SUCCESS) {
      setSelectedDataStreams([]);
    }
  }, []);

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography data-test="data-stream-heading" variant={"h6"}>{t("DATA_STREAM_HEADER.DATA_STREAM_HEADING")}</Typography>
        </Grid>
        <FeatureFlag flag="enable_uds">
          <Grid item>
            <ApplySubScenarioButton
              meterId={meterId}
              selectedDataStreams={selectedDataStreams}
              onFinish={handleAfterApply}
              canApplyFutureUds={canApplyFutureUds}
            />
          </Grid>
        </FeatureFlag>
      </Grid>

      <TableContainer sx={{ boxShadow: "none", maxHeight: "199px", overflow: "auto !important" }}>
        <Table aria-label="Data stream table" data-test={"data-stream-list"}>
          <TableBody>
            {sortedContent.map((item: DataStream) => (
              <DataStreamRow
                key={item.name}
                item={item}
                link={`/connectionpoint/${connectionPointId}/meter/${meterId}/datastream/${item.name}/${item.intent}${overlay ?
                  `?overlay=true` : ''}`}
                onChangeSelectedDataStream={handleSelectedDataStream}
                checked={selectedDataStreams.some(dataStream => dataStream === item)}
                enableCheckbox={enableDatastreamCheckbox}
                preventCheckbox={shouldPreventCheckbox(item.intent)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
