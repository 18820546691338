import { IntervalsQuality } from "../types/IntervalQuality";
import { retrieveQualityIntervals } from "../services/IntervalService";


export const retrieveDataStreamQualityIntervals = (
  setState: (state: IntervalsQuality) => void,
  setError: (state: string) => void,
  meterId: string,
  datastreamName: string,
  date: string
): void => {

  if (!datastreamName) {
    return;
  }

  retrieveQualityIntervals(
    meterId,
    datastreamName,
    date,
  ).then(intervalsQualityResponse => {
    setError("");
    setState(intervalsQualityResponse);
  }).catch((err) => {
    if (err.name === "AbortError") {
      return;
    }
    setError(err.message)
  });
}
