/* eslint-disable max-len */
import env from "../environment";
import { i18n } from "../global/i18n";
import { SelectItemType } from "../types/SelectItemType";

const {
  scenarios: scenariosFromEnv,
  qualities: qualitiesFromEnv,
  reasonCodes: reasonCodesFromEnv,
} = env.reprocessing.substitutionScenarios;

export const mapTranslateKeyScenario: {[x: string]: string} = {
  "Type 4 - Meter Installation - New Connection/Basic To Interval": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4_METER_INSTALLATION_NEW_CONNECTION_BASIC_TO_INTERVAL",
  "Type 4a - Meter Installation - New Connection/Basic To Interval": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4A_METER_INSTALLATION_NEW_CONNECTION_BASIC_TO_INTERVAL",
  "Meter Installation - Installation Period": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.METER_INSTALLATION_INSTALLATION_PERIOD",
  "Type 4 - Meter Installation - Meter Removed": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4_METER_INSTALLATION_METER_REMOVED",
  "Type 4a - Meter Installation - Meter Removed": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4A_METER_INSTALLATION_METER_REMOVED",
  "Type 4 - De-energized Site": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4_DE_ENERGIZED_SITE",
  "Type 4a - De-energized Site": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4A_DE_ENERGIZED_SITE",
  "Communication Fault": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.COMMUNICATION_FAULT",
  "Type 4a - Meter Estimates": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4A_METER_ESTIMATES",
  "Validation Failure": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.VALIDATION_FAILURE",
  "Type 4 - Agreed Method": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4_AGREED_METHOD",
  "Type 4a - Agreed Substitution Method": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4A_AGREED_SUBSTITUTION_METHOD",
  "Type 4a - Prior to First Reading - Agreed Method": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4A_PRIOR_TO_FIRST_READING_AGREED_METHOD",
  "Type 4a - Prior to First Reading - Customer Class Method": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_4A_PRIOR_TO_FIRST_READING_CUSTOMER_CLASS_METHOD",
  "Type 19 - Zero": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_19_ZERO",
  "Type 58 - Zero": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_58_ZERO",
  "Type 17 - Linear Interpolation": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_17_LINEAR_INTERPOLATION",
  "Type 14 - Like Day": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_14_LIKE_DAY",
  "Type 15 - Average Like Day": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_15_AVERAGE_LIKE_DAY",
  "Type 54 - Linear Interpolation": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_54_LINEAR_INTERPOLATION",
  "Type 51 - Previous Year": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_51_PREVIOUS_YEAR",
  "Type 52 - Previous Meter Reading": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_52_PREVIOUS_METER_READING",
  "Type 57 - Prior to First Reading - Customer Class": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_57_PRIOR_TO_FIRST_READING_CUSTOMER_CLASS",
  "Type 16 - Agreed Method": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_16_AGREED_METHOD",
  "Type 55 - Agreed Substitution Method": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_55_AGREED_SUBSTITUTION_METHOD",
  "Type 56 - Prior to First Reading - Agreed Method": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_56_PRIOR_TO_FIRST_READING_AGREED_METHOD",
  "Type 57 - Prior to First Reading Customer Class Method": "REPROCESSING.SUBSTITUTION_SCENARIOS.SCENARIOS.TYPE_57_PRIOR_TO_FIRST_READING_CUSTOMER_CLASS_METHOD",
}

const mapTranslateKeyQuality: {[x: string]: string} = {
  "Temporary Substitute":"REPROCESSING.SUBSTITUTION_SCENARIOS.QUALITIES.TEMPORARY_SUBSTITUTE", 
  "Final Substitute":"REPROCESSING.SUBSTITUTION_SCENARIOS.QUALITIES.FINAL_SUBSTITUTE", 
  "Forward Estimate":"REPROCESSING.SUBSTITUTION_SCENARIOS.QUALITIES.FORWARD_ESTIMATE", 
}

const mapTranslateKeyReasonCode: {[x: string]: string} = {
  "Meter/equipment changed":"REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.METER_EQUIPMENT_CHANGED",
  "Extreme weather conditions": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.EXTREME_WEATHER_CONDITIONS",
  "Quarantined premises": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.QUARANTINED_PREMISES",
  "Blank screen": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.BLANK_SCREEN",
  "De-energised premises":"REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.DE_ENERGISED_PREMISES",
  "Unable to locate meter": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.UNABLE_TO_LOCATE_METER",
  "Vacant premises": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.VACANT_PREMISES",
  "Under investigation":"REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.UNDER_INVESTIGATION",
  "Lock damaged unable to open": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.LOCK_DAMAGED_UNABLE_TO_OPEN",
  "In wrong route": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.IN_WRONG_ROUTE",
  "Locked premises": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.LOCKED_PREMISES",
  "Locked gate": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.LOCKED_GATE",
  "Locked meter box": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.LOCKED_METER_BOX",
  "Overgrown vegetation": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.OVERGROWN_VEGETATION",
  "Unsafe equipment/location": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.UNSAFE_EQUIPMENT_LOCATION",
  "Read less than previous": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.READ_LESS_THAN_PREVIOUS",
  "Damaged equipment/panel": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.DAMAGED_EQUIPMENT_PANEL",
  "Main switch off": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.MAIN_SWITCH_OFF",
  "Meter/equipment seals missing": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.METER_EQUIPMENT_SEALS_MISSING",
  "Reader error": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.READER_ERROR",
  "Substituted/replaced data (data correction)": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.SUBSTITUTED_REPLACED_DATA",
  "Unable to locate premises": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.UNABLE_TO_LOCATE_PREMISES",
  "Negative consumption (generation)": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.NEGATIVE_CONSUMPTION",
  "RoLR": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.ROLR",
  "CT/VT fault": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.CT_VT_FAULT",
  "Relay faulty/damaged": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.RELAY_FAULTY_DAMAGED",
  "Not all meters read": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.NOT_ALL_METERS_READ",
  "Re-energised without readings": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.RE_ENERGISED_WITHOUT_READINGS",
  "De-energised without readings": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.DE_ENERGISED_WITHOUT_READINGS",
  "Meter not in handheld": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.METER_NOT_IN_HANDHELD",
  "Timeswitch faulty/reset required": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.TIMESWITCH_FAULTY_RESET_REQUIRED",
  "Meter high/ladder required": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.METER_HIGH_LADDER_REQUIRED",
  "Meter under churn":"REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.METER_UNDER_CHURN",
  "Unmarried lock": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.UNMARRIED_LOCK",
  "Reverse energy observed": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.REVERSE_ENERGY_OBSERVED",
  "Unrestrained livestock": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.UNRESTRAINED_LIVESTOCK",
  "Faulty Meter display/dials": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.FAULTY_METER_DISPLAY_DIALS",
  "Channel added/removed": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.CHANNEL_ADDED_REMOVED",
  "Power outage": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.POWER_OUTAGE",
  "Meter testing": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.METER_TESTING",
  "Readings failed to validate": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.READINGS_FAILED_TO_VALIDATE",
  "Refused access": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.REFUSED_ACCESS",
  "Dog on premises": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.DOG_ON_PREMISES",
  "Installation demolished": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.INSTALLATION_DEMOLISHED",
  "Access - blocked": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.ACCESS_BLOCKED",
  "Pests in meter box": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.PESTS_IN_METER_BOX",
  "Meter box damaged/faulty": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.METER_BOX_DAMAGED_FAULTY",
  "Dials obscured": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.DIALS_OBSCURED",
  "Illegal connection": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.ILLEGAL_CONNECTION",
  "Equipment tampered": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.EQUIPMENT_TAMPERED",
  "NSRD window expired": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.NSRD_WINDOW_EXPIRED",
  "Key required": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.KEY_REQUIRED",
  "Wrong key provided": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.WRONG_KEY_PROVIDED",
  "Transfer": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.TRANSFER",
  "Zero consumption":"REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.ZERO_CONSUMPTION",
  "Reading exceeds Substitute": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.READING_EXCEEDS_SUBSTITUTE",
  "Probe read error": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.PROBE_READ_ERROR",
  "Re-calculated based on Actual Metering Data": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.RE_CALCULATED_BASED_ON_ACTUAL_METERING_DATA",
  "Low consumption": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.LOW_CONSUMPTION",
  "High consumption": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.HIGH_CONSUMPTION",
  "Customer read": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.CUSTOMER_READ",
  "Communications fault":"REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.COMMUNICATIONS_FAULT",
  "Estimation Forecast":"REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.ESTIMATION_FORECAST",
  "Null Data": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.NULL_DATA",
  "Power Outage Alarm": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.POWER_OUTAGE_ALARM",
  "Short Interval Alarm": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.SHORT_INTERVAL_ALARM",
  "Long Interval Alarm": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.LONG_INTERVAL_ALARM",
  "Reset occurred": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.RESET_OCCURRED",
  "Time reset occurred": "REPROCESSING.SUBSTITUTION_SCENARIOS.REASON_CODES.TIME_RESET_OCCURRED",
}

export const scenarios: SelectItemType[] = (() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return scenariosFromEnv.map((scenario: any, index: number) => ({
    id: `scenario-${index + 1}`,
    ...scenario,
    label: scenario.name,
    name: i18n.t(mapTranslateKeyScenario[scenario.name]),
    methods: scenario.methods.map((method: string) => ({value: method, translateKey: mapTranslateKeyScenario[method]}))
  }));
})();

export const qualities: SelectItemType[] = (() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return qualitiesFromEnv.map((quality: any, index: number) => ({
    id: `quality-${index + 1}`,
    ...quality,
    name: `${quality.qualityFlag} - ${i18n.t(mapTranslateKeyQuality[quality.qualityDescription])}`,
    label: `${quality.qualityFlag} - ${quality.qualityDescription}`,
    value: quality.qualityFlag,
  }));
})();

export const reasonCodes: SelectItemType[] = (() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reasonCodesFromEnv.map((reasonCode: any, index: number) => ({
    id: `reasonCode-${index + 1}`,
    ...reasonCode,
    name: `${reasonCode.reasonCode} - ${i18n.t(mapTranslateKeyReasonCode[reasonCode.reasonCodeDescription])}`,
    label: `${reasonCode.reasonCode} - ${reasonCode.reasonCodeDescription}`,
    value: reasonCode.reasonCode,
  }));
})();
