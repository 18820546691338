/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactNode, useCallback, useMemo, useState } from "react";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Button, Collapse } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

interface Props {
  title: string | ReactNode;
  type: AlertColor;
  children?: ReactNode;
  onClose: (event?: React.SyntheticEvent | Event) => void;
  dataTestPrefix?: string;
  showExpandData: boolean;
  keepShowing?: boolean;
}

export const SnackbarMessage: React.FC<Props> = (props: Props) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handelOnClose = () => {
    props.onClose();
    setExpanded(false);
  };

  const dataTestPrefix = useMemo(() =>{
    return props.dataTestPrefix ? props.dataTestPrefix + "-" : "";
  }, [props.dataTestPrefix]);

  const { t } = useTranslation("component");

  return (
    <Box data-test={`${dataTestPrefix}${props.type}-alert`} sx={{ minWidth: "300px" }}>
      <MuiAlert
        severity={props.type}
        sx={[
          {
            width: "100%",
            alignItems: "start",
            boxShadow: "none",
            "& .MuiAlert-icon": {
              fontSize: "18.43px",
              padding: "0px",
              height: "36px",
              display: "flex",
              alignItems: "center",
            },
          },
          props.type === "success" && {
            color: "#1E4620",
            backgroundColor: "#EEF7ED",
          },
          props.type === "error" && {
            color: "#621B16",
            backgroundColor: "#FEECEB",
          },
          props.type === "warning" && {
            color: "#7B4E2B",
            backgroundColor: "#FDF1E6",
          },
        ]}
        iconMapping={{
          success: (<CheckCircleOutlineIcon fontSize="inherit" sx={{ color: "#4CAF50" }} />),
          error: (<InfoOutlinedIcon fontSize="inherit" sx={{ color: "#F44336" }} />),
          warning: (<WarningAmberIcon fontSize="inherit" sx={{ color: "warning.main" }} />)
        }}
      >
        <>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "20px",
            }}
          >
            <span data-test={`${dataTestPrefix}${props.type}-message`}>{props.title}</span>
            {props.showExpandData && (
              <>
                <Button
                  className="button-view-detail"
                  sx={{ ml: 2 }}
                  variant="text"
                  size="small"
                  onClick={handleExpandClick}
                  data-test={`${dataTestPrefix}details-${props.type}-button`}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    {t("SNACKBAR_MESSAGE.VIEW_DETAILS")}
                  </span>
                </Button>
                <span
                  data-test={`${dataTestPrefix}button-icon-close`}
                  onClick={handelOnClose}
                  style={{
                    color: "#0053de",
                    marginTop: "4px",
                    cursor: "pointer",
                    marginLeft: "4px",
                  }}
                >
                  <CloseOutlined fontSize="small" />
                </span>
              </>
            )}
            {props.keepShowing && (
              <span
                data-test={`${dataTestPrefix}button-icon-close`}
                onClick={handelOnClose}
                style={{
                  color: "#0053de",
                  marginTop: "4px",
                  cursor: "pointer",
                  marginLeft: "4px",
                }}
              >
                <CloseOutlined fontSize="small" />
              </span>
            )}
          </Box>
          {props.showExpandData && (
            <Collapse
              sx={{
                color: "#0053DE",
                minHeight: "100px",
                overflow: "auto",
              }}
              in={expanded}
              timeout="auto"
              unmountOnExit
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {props.children}
              </div>
            </Collapse>
          )}
        </>
      </MuiAlert>
    </Box>
  );
};
