import React from "react";
import { Box, InputLabel, MenuItem, SelectChangeEvent } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SelectItemType } from "../../types/SelectItemType";
import { FormControlLabel } from "../FormControlLabel";
import { StyledSelect } from "../StyledSelect";
import { FormControlContent } from "../FormControlContent";

interface Props {
  "data-test"?: string;
  label: string,
  items: SelectItemType[];
  selectedItem: SelectItemType | null,
  onChange: (event: SelectChangeEvent<unknown>) => void,
  disabled: boolean,
}

export const SelectBoxBlock: React.FC<Props> = ({
  label,
  items,
  selectedItem,
  onChange,
  disabled,
  ...props
}) => (
  <Box
    {...(props["data-test"] ? { "data-test": props["data-test"] } : {})}
    component="form"
    sx={{ display: 'flex' }}
  >
    <FormControlLabel>
      <InputLabel>{label}</InputLabel>
    </FormControlLabel>
    <FormControlContent>
      <StyledSelect
        value={selectedItem?.id ?? ""}
        onChange={onChange}
        disabled={disabled}
        IconComponent={KeyboardArrowDownIcon}
      >
        {items.map((scenario: SelectItemType) => (
          <MenuItem value={scenario.id} key={scenario.id} sx={{ fontSize: 14 }}>
            {scenario.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControlContent>
  </Box>
);
