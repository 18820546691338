import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Snapshots } from "../../types/SnapshotState";
import { NoRowsOverlay } from "../NoRowsOverlay";
import {
  datastreamNameFormatter,
  datetimeValueFormatter,
  dateValueGetter,
  floatNumberGetter,
  styleDataGridHeader
} from "../../commons/dataGrid";
import { i18n } from "../../global/i18n";

interface Props {
  content: Snapshots;
  loading: boolean;
}

export const SnapshotNoRowsOverlay = (): JSX.Element => <NoRowsOverlay prefix="snapshot" />;

const getColumnValueDef = () : GridColumns => ([
  {
    field: 'dateTime',
    headerName: i18n.t("SNAPSHOT.HEADERS.DATETIME", {ns: "component"}),
    valueGetter: dateValueGetter,
    type: "dateTime",
    valueFormatter: datetimeValueFormatter,
  },
  {
    field: 'dataStreamName',
    headerName: i18n.t("SNAPSHOT.HEADERS.DATASTREAM", {ns: "component"}),
    valueFormatter: datastreamNameFormatter,
  },
  {
    field: 'snapshotValue',
    headerName: i18n.t("SNAPSHOT.HEADERS.SNAPSHOT", {ns: "component"}),
    type: "number",
    valueGetter: floatNumberGetter,
    headerAlign: "left",
    align: "left"
  }
]);



export const Snapshot: React.FC<Props> = ({content, loading}) => {
  
  const columns = useMemo(() => styleDataGridHeader(getColumnValueDef()), []);
  const rows = useMemo(() => {
    return content.snapshots.map((item, index) => ({
      id: index,
      ...item,
    }))
  }, [content]);

  const dataGridComponents = useMemo(() => {
    return {
      NoRowsOverlay: SnapshotNoRowsOverlay
    }
}, []);

  return (

    <Box
      data-test={"snapshot"}
      sx={{
        height: 400,
        width: 1,
        '& .grid-header-column--header': {
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
          color: "text.secondary",
        },
        '& .MuiDataGrid-root': {
          border: 0
        }
      }}
    >
      <Typography variant={"body1"}>{i18n.t("SNAPSHOT.SNAPSHOT", {ns: "component"})}</Typography>
      <DataGridPro
        disableColumnMenu
        disableColumnReorder
        sx={{
          '.MuiDataGrid-virtualScroller': { maxHeight: "260px", overflow: "auto !important" }
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'dateTime', sort: 'desc' }],
          },
        }}
        rows={rows}
        columns={columns}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        components={dataGridComponents}
        loading={loading}
      />
    </Box>

  );
};

