import { ExceptionCounts } from "../types/ExceptionCounts";

export const retrieveExceptionState = async (): Promise<ExceptionCounts> =>
  fetch(`/service/v1/exception`, {
    headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("404")
    }
    return response.json();
  });
