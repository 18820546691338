import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  prefix?: string,
  children?: ReactNode,
}

export const NoRowsOverlay: React.FC<Props> = ({
  prefix = "data-gird",
  children
}) => {
  const { t }  = useTranslation("component");
  return (
  <Box
    data-test={`${prefix}-no-row-overlay`}
    sx={{
      height: "100%",
      width: "100%",
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
    }}
  >
    <Box sx={{ mt: "40px" }}>{children || t("NO_ROWS_OVERLAY.NO_DATA")}</Box>
  </Box>
)}