import { Snapshots } from "../types/SnapshotState";
import moment from "moment-timezone";
import env from "../environment";
import { DateTimeRange, getStartOfToday } from "../commons/dates";

export const DEFAULT_SNAPSHOT_SEARCH_MAX_DAYS = 3;

export const getSearchDateRange = (): DateTimeRange => {
  const snapshotSearchMaxDays = env.snapshotSearchMaxDays || DEFAULT_SNAPSHOT_SEARCH_MAX_DAYS;
  const endTime = getStartOfToday().add(1, "days");
  const startTime = moment(endTime).subtract(snapshotSearchMaxDays, "days");

  return {
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
  };
}

export const retrieveSnapshots = async (meterId: string): Promise<Snapshots> => {
  return fetch(
    `/service/v1/meter/${meterId}/snapshot?` + new URLSearchParams(getSearchDateRange()),
    {
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
    }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error("404");
  });

}
