import React, { useState } from "react";
import { Button, SxProps } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { i18n } from "../../global/i18n";

interface OnclickType {
  (value: string): void;
}

interface Props {
  sx?: SxProps;
  onClick: OnclickType;
}

export const uploadOptions = [
  {
    value: "manual",
    label: i18n.t("INGESTION_ACTION_UPLOAD.UPLOAD_OPTIONS.MANUAL", {
      ns: "component",
    }),
  },
  {
    value: "churn",
    label: i18n.t("INGESTION_ACTION_UPLOAD.UPLOAD_OPTIONS.CHURN", {
      ns: "component",
    }),
  },
];

export const mapTypeUploadOptions: { [key: string]: string } = {
  manual: uploadOptions[0].label,
  churn: uploadOptions[1].label,
};

export const IngestionActionUpload: React.FC<Props> = ({ sx, onClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    const ele = event.target as HTMLElement;
    if (ele.hasAttribute("data-value")) {
      onClick(ele.getAttribute("data-value") + ""); // parse string if null value for pass validate type
    }
  };

  return (
    <>
      <Button
        data-test="file-upload-action"
        sx={{
          height: "30px",
          width: "133px",
          fontSize: "0.875rem",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          textTransform: "capitalize",
          boxShadow: "none",
          border: "none",
          "&:hover": {
            border: "none",
          },
          ...sx,
        }}
        aria-controls={anchorEl ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        variant="outlined"
        size="small"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {i18n.t("INGESTION_ACTION_UPLOAD.FILE_UPLOAD", { ns: "component" })}
      </Button>
      <Menu
        sx={{ ".MuiMenu-paper": { backgroundColor: "#FFF" } }}
        id="long-menu"
        data-test={"popover-menu"}
        MenuListProps={{
          "aria-labelledby": "long-button",
          dense: true,
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {uploadOptions.map((option) => (
          <MenuItem
            sx={{ width: "160px" }}
            key={option.value}
            data-value={option.value}
            onClick={handleClose}
            data-test={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
