import React, { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import moment, { Moment } from "moment-timezone";
import { EMPTY, IntervalsConsumption } from "../../types/IntervalConsumption";
import { VersionItem } from "../../types/IntervalVersion";
import { retrieveDataStreamDefaultIntervals } from "../../hooks/useDataStreamConsumptionIntervals";
import { useDataStreamIntervalVersions } from "../../hooks/useDataStreamIntervalVersions";
import { DataStreamConsumptionIntervals } from "./DataStreamConsumptionIntervals";

interface Props {
  meterId: string;
  datastreamName: string;
  intent: string;
  unitOfMeasure: string;
}

export const defaultDate: Moment = moment().subtract(1, "days");

export const DataStreamConsumptionIntervalsContainer: React.FC<Props> = (
  {
    meterId,
    datastreamName,
    intent,
    unitOfMeasure
  }) => {
  const [currentState, setState] = useState<IntervalsConsumption>(EMPTY);
  const [, setError] = useState<string>("");
  const [date, setDate] = useState<Moment>(defaultDate);
  const [loading, setLoading] = useState(true);
  
  const {
    setSelectedVersion,
    versionItems,
    selectedVersion,
    loading: loadingVersions,
  } = useDataStreamIntervalVersions({
    meterId,
    datastreamName,
    intent,
    startDate: date,
    endDate: date,
    setLoading,
    setError,
  });

  const handleSetState = useCallback((intervals: IntervalsConsumption) => {
    setLoading(false);
    setState(intervals);
  }, []);

  const handleSetError = useCallback((error: string) => {
    setError(error);
    setLoading(false);
    setState(EMPTY);
  }, []);

  const handleChangeDate = useCallback((selectedDate: Moment) => {
    setSelectedVersion(undefined);
    setDate(selectedDate);
  }, [setSelectedVersion]);

  const handleSelectVersion = useCallback((versionItem: VersionItem) => {
    setSelectedVersion(versionItem);
  }, []);

  useEffect(() => {
    setState(EMPTY);
  }, [date, selectedVersion]);

  useEffect(() => {
    if (loadingVersions){
      return;
    }

    if (isEmpty(versionItems)) {
      setLoading(false);
      return;
    }

    if (selectedVersion) {
      setLoading(true);
      const { version, type } = selectedVersion;
      retrieveDataStreamDefaultIntervals(
        handleSetState,
        handleSetError,
        meterId,
        datastreamName,
        intent,
        version,
        date.toISOString(),
        type
      );
    }

  }, [loadingVersions, selectedVersion, versionItems]);

  return (
    <DataStreamConsumptionIntervals
      loading={loading}
      content={currentState}
      date={date}
      versions={versionItems}
      selectedVersion={selectedVersion}
      onSelectDate={handleChangeDate}
      onSelectVersion={handleSelectVersion}
      unitOfMeasure={unitOfMeasure}
    />
  );
};
