export interface IntervalConsumption {
  dateTime: string;
  latestQuantity: string;
  quality: string;
  method: string;
  reasonCode: string;
  cause?: string;
  isException?: boolean;
  unitOfMeasure: string;
}

export interface IntervalsConsumption {
  ingestionDateTime: string;
  processedDateTime: string;
  intervals: IntervalConsumption[];
}

export const EMPTY_LIST: IntervalConsumption[] = [];

export const EMPTY: IntervalsConsumption = {
  ingestionDateTime: "",
  processedDateTime: "",
  intervals: EMPTY_LIST,
}