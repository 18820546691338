import React, { useMemo } from "react";
import { Avatar, Box, TableCell } from "@mui/material";
import { AuditLogs } from "../../types/AuditLogs";
import { AuditLogCollapsibleTable } from "./AuditLogCollapsibleTable";
import { CollapsibleHeader } from "../../types/AuditLog";
import { i18n } from "../../global/i18n";

interface Props {
  source: string;
  content: AuditLogs;
  loading: boolean;
}

export const renderAssigneeColumn = (
  width: string,
  field: string,
  value: string | JSX.Element | undefined,
): React.ReactNode => {
  let avatar = <Box sx={{ height: "40px" }}></Box>;

  if (value) {
    value = value?.toString();

    const splitByDot: string[] = value.split(".");

    if (splitByDot.length > 1) {
      const abbreviationName = (
        splitByDot[0]?.charAt(0) + splitByDot[1]?.charAt(0)
      ).toUpperCase();

      avatar = (
        <Box sx={{ ml: 3.5, wordWrap: "break-word" }}>
          <Avatar alt={value}>{abbreviationName}</Avatar>
        </Box>
      );
    }
  }

  return (
    <TableCell
      className="collapsible-grid-sub-header-column--header"
      key={field}
      sx={{
        p: 0,
        maxWidth: { width },
        minWidth: { width },
        border: "none",
      }}
    >
      {avatar}
    </TableCell>
  );
};

export const getHeaders = () : CollapsibleHeader[] => ([
  {
    field: "readDate",
    headerName: i18n.t("AUDIT_LOG_TABLE_EXCEPTION.HEADERS.READ_DATE", {
      ns: "component",
    }),
    order: 1,
    format: "date",
  },
  {
    field: "exceptionType",
    headerName: i18n.t("AUDIT_LOG_TABLE_EXCEPTION.HEADERS.EXCEPTION_TYPE", {
      ns: "component",
    }),
    order: 2,
  },
  {
    field: "actionType",
    headerName: i18n.t("AUDIT_LOG_TABLE_EXCEPTION.HEADERS.ACTION_TYPE", {
      ns: "component",
    }),
    order: 3,
  },
  {
    field: "actionDateTime",
    headerName: i18n.t("AUDIT_LOG_TABLE_EXCEPTION.HEADERS.ACTION_DATETIME", {
      ns: "component",
    }),
    order: 4,
    format: "dateTime",
  },
  {
    field: "assignedTo",
    headerName: i18n.t("AUDIT_LOG_TABLE_EXCEPTION.HEADERS.ASSIGNEE", {
      ns: "component",
    }),
    order: 5,
    renderCustomHeaderColumn: renderAssigneeColumn,
  },
]);

export const excludedFields = ["unitOfWorkDate", "unitOfWorkDateTime"];

export const AuditLogTableExceptions: React.FC<Props> = ({
  source,
  content,
  loading,
}) => {
  const headers = useMemo(() => getHeaders(), []);
  return (
    <AuditLogCollapsibleTable
      source={source}
      content={content}
      loading={loading}
      headers={headers}
      excludedFields={excludedFields}
    />
  );
};
