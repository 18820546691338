import React, { ReactElement, useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment-timezone";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Box, Stack, TextField } from "@mui/material";
import { MuiTextFieldProps } from "@mui/x-date-pickers/internals";
import { DATE_PATTERN, getEndOfDay, getStartOfYesterday } from "../../commons/dates";

interface Props {
  "data-test"?: string;
  onDateRangeChanged: (dateRange: DateRange<Moment>) => void;
}

export const renderTimePickerInput = (dataTest: string) => {
  // eslint-disable-next-line react/display-name
  return (props: MuiTextFieldProps): ReactElement => (
    <Stack direction={"row"}>
      <TextField
        sx={{
          "div.MuiOutlinedInput-root input": {
            minWidth: "120px"
          }
        }}
        data-test={`${dataTest}`}
        {...props}
      />
    </Stack>
  );
};

const DEFAULT_DATE_RANGE_TIME_PICKER_DATA_TEST = "date-range-time-picker";
const inputDateFormat = DATE_PATTERN + " HH:mm";
const startOfYesterday = getStartOfYesterday();
const endOfYesterday = getEndOfDay(startOfYesterday);

export const DateTimeRangePicker: React.FC<Props> = ({
  onDateRangeChanged,
  ...props
}) => {

  // time boundary set up with local timezone
  const [selectedStartDateTime, setSelectedStartDateTime] = useState<Moment | null>(startOfYesterday);
  const [selectedEndTime, setSelectedEndTime] = useState<Moment | null>(endOfYesterday);

  const handleStartTimeChange = (startDateTime: Moment | null) => {
    setSelectedStartDateTime(startDateTime);
    const newStartDateTime = endOfYesterday.isSameOrAfter(startDateTime) ? startDateTime : null;
    onDateRangeChanged([newStartDateTime, selectedEndTime]);
  }

  const handleEndTimeChange = (endDateTime: Moment | null) => {
    setSelectedEndTime(endDateTime);
    const newEndDateTime = endOfYesterday.isSameOrAfter(endDateTime) ? endDateTime : null;
    onDateRangeChanged([selectedStartDateTime, newEndDateTime]);
  }

  const maxDateTimeForEndDateTime = useMemo(() => {
    if (selectedStartDateTime === null) {
      return endOfYesterday;
    }

    const endOfDay = getEndOfDay(selectedStartDateTime);
    if (endOfDay.isBefore(endOfYesterday)) {
      return endOfDay;
    }

    return endOfYesterday;
  }, [selectedStartDateTime]);

  const minDateTimeForEndDateTime = useMemo(() => {
    if (selectedStartDateTime === null) {
      return undefined;
    }

    const minDateTimeFromStartDateTime = moment(selectedStartDateTime).add(1, "minutes");
    if (endOfYesterday.isBefore(minDateTimeFromStartDateTime)) {
      // set end of yesterday or date time picker will remove date in the input when opening the date time picker dialog
      return endOfYesterday;
    }

    return minDateTimeFromStartDateTime;
  }, [selectedStartDateTime]);

  useEffect(() => {
    // Make sure update the dates to parent when it is loaded.
    onDateRangeChanged([selectedStartDateTime, selectedEndTime]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      direction={"row"}
      data-test={props["data-test"] || DEFAULT_DATE_RANGE_TIME_PICKER_DATA_TEST}
      sx={{
        my: 1,
        width: "fit-content",
      }}
    >
      <DateTimePicker
        renderInput={renderTimePickerInput(`${DEFAULT_DATE_RANGE_TIME_PICKER_DATA_TEST}-from`)}
        ampm={false}
        showToolbar={true}
        inputFormat={inputDateFormat}
        minutesStep={1}
        value={selectedStartDateTime}
        maxDateTime={endOfYesterday} // time range only apply for the past, today excluded
        closeOnSelect
        onChange={handleStartTimeChange}
        desktopModeMediaQuery={'@media (min-width:368px)'}
      />
      <Box sx={{ width: "18px", display: "flex", justifyContent: "center", alignItems: "center" }}> - </Box>
      <DateTimePicker
        renderInput={renderTimePickerInput(`${DEFAULT_DATE_RANGE_TIME_PICKER_DATA_TEST}-to`)}
        ampm={false}
        // showTodayButton={false}
        showToolbar={true}
        minutesStep={1}
        inputFormat={inputDateFormat}
        value={selectedEndTime}
        minDateTime={minDateTimeForEndDateTime}
        maxDateTime={maxDateTimeForEndDateTime}
        closeOnSelect
        onChange={handleEndTimeChange}
        desktopModeMediaQuery={'@media (min-width:368px)'}
      />
    </Stack>
  );
};
