import { useEffect } from "react";
import { EMPTY, ConnectionPoint } from "../types/ConnectionPoint";
import { retrieveConnectionPointByConnectionPointId } from "../services/ConnectionPointPageService";

export const useConnectionPointPage = (
  setState: (state: ConnectionPoint) => void,
  connectionPointId: string,
): void =>
  useEffect(() => {
    retrieveConnectionPointByConnectionPointId(connectionPointId).then(setState).catch(() => setState(EMPTY));
  }, [connectionPointId, setState]);
