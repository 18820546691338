import React from "react";
import { Box, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { CALLBACK_NAME } from "../GroupAddComparison/GroupAddComparison";
import { i18n } from "../../global/i18n";

interface Props {
  meterIds: string[];
  activeMeterId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (type: CALLBACK_NAME, payload?: any) => void;
}

export const MetersResult: React.FC<Props> = ({
  meterIds,
  activeMeterId,
  callback,
}) => {
  return (
    <Box sx={{ pt: 3, pb: 2 }} data-test={"meter-results-comparison-container"}>
      <Typography
        sx={{
          px: 3,
          color: "rgba(0, 0, 0, 0.38)",
          fontSize: "0.8125rem",
          fontWeight: 500,
        }}
      >
        {i18n.t("METERS_RESULT.METER", {ns: "component"})} ({meterIds.length})
      </Typography>
      <Box sx={{ mt: 1, overflowY: "auto", maxHeight: "240px" }}>
        {meterIds.map((meterId) => {
          return (
            <List
              key={meterId}
              sx={{
                py: 0,
                background: activeMeterId === meterId ? "#EBEBEB" : "#FFFFFF",
              }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => callback("CHANGE_METER_ID", meterId)}
                  sx={{ px: 3, py: 1.25 }}
                >
                  <ListItemText
                    primary={(meterId + "").replace("-EDMI-ELECTRICITY", "")}
                    sx={{ my: 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          );
        })}
      </Box>
    </Box>
  );
};
