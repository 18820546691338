import React from "react";
import { Box, Typography } from "@mui/material";
import { ConnectionPointHeaderState } from "../../types/ConnectionPointHeaderState";
import { useTranslation } from "react-i18next";

interface Props extends ConnectionPointHeaderState {
  id: string;
}

export const ConnectionPointHeader: React.FC<Props> = ({id, address, timeZone, market}) => {
  const { t }  = useTranslation("component");
  return (
    <Box>
      <Typography data-test={"connectionpoint-header"} sx={{ pt: 1 }} variant={"h5"}>
        {t("CONNECTION_POINT_HEADER.CONNECTION_POINT")} - {id}
      </Typography>
      <Typography sx={{ pt: 1 }} data-test={"connectionpoint-address"} variant={"body2"}>
        {address.postcode}, {address.country}
      </Typography>
      <Box sx={{display: "flex", pt: 1, pb: 1.5}}>
        <Typography
          sx={{ marginInlineEnd: 1 }}
          data-test={"connectionpoint-timezone"}
          variant={"body2"}
        >
          {t("CONNECTION_POINT_HEADER.TIMEZONE")}: {timeZone}
        </Typography>
        {market && (
          <Typography
            sx={{ marginInlineEnd: 1 }}
            data-test={"connectionpoint-market"}
            variant={"body2"}
          >
            {t("CONNECTION_POINT_HEADER.MARKET")}: {market}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
