import { useEffect } from "react";
import { ReportDownloadPageState } from "../types/ReportDownloadPageState";
import { retrieveReportDownloadPresignedUrl, retrieveReportDownloadState } from "../services/ReportDownloadService";

export const useReportDownloadPage = (
  setState: (state: ReportDownloadPageState) => void,
): void =>
  useEffect(() => {
    retrieveReportDownloadState()
      .then((res) => {
        setState({ content: res.meterReadingsReports, isLoading: false });
      })
      .catch(() =>
        setState({
          content: [],
          isLoading: false,
        }),
      );
  }, [setState]);

export const useReportDownloadLink = (
    reportId: string
  ): void => {

    retrieveReportDownloadPresignedUrl(reportId)
      .then((res) => {
        window.location.href = res.presignedUrl;
      })
      .catch((error: Error) => {
        console.log(error);
      });
  };
