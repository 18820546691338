/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Moment } from "moment-timezone";
import { isEmpty, trim } from "lodash";
import { Alert, Dialog, DialogContent, DialogTitle, Divider, Typography, } from "@mui/material";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { qualities, reasonCodes, scenarios } from "../../commons/scenarioData";
import { DateRangeTypes } from "../../types/DateRangeTypes";
import { SelectItemType } from "../../types/SelectItemType";
import { DataStream } from "../../types/DataStream";
import { ManualSubstitutionDataStream } from "../../types/ManualSubstitutionDataStream";
import { useSetSubScenarios } from "../../hooks/useSetSubScenarios";
import { SubScenarioDialogActions } from "../SubScenarioDialog";
import { DateTimeRangeBlock } from "./DateTimeRangeBlock";
import { SelectBoxBlock } from "../SelectBoxBlock";
import { TextFlowBlock } from "../TextFlowBlock";
import { AgreedAverageDailyLoadForm } from "../AgreedAverageDailyLoadForm";
import { defaultDateRangeState } from "../DateRangePicker/DateRangePicker";
import WarningIcon from "@mui/icons-material/Warning";
import { getStartOfToday } from "../../commons/dates";
import { i18n } from "../../global/i18n";

const { MULTIPLE_DAYS } = DateRangeTypes;

export const MINIMUM_ADL_STRING_VALUE = "0.000";
export const MINIMUM_ADL_VALUE = parseFloat(MINIMUM_ADL_STRING_VALUE);
export const MAXIMUM_ADL_VALUE = 999.999;
// eslint-disable-next-line max-len
export const MIN_MAX_EXAMPLE = `${i18n.t("SUB_SCENARIO_DIALOG.ERROR_MESSAGES.MAX")} ${MAXIMUM_ADL_VALUE}, ${i18n.t("SUB_SCENARIO_DIALOG.ERROR_MESSAGES.MIN")} ${MINIMUM_ADL_STRING_VALUE}`;

export interface ManualSubstitutionDataStreamInputItem extends ManualSubstitutionDataStream {
  errorMessage?: string;
  value?: string;
}

export const toManualSubstitutionDataStreams
  = (dataStreamItems: ManualSubstitutionDataStreamInputItem[]): ManualSubstitutionDataStream[] => {
  return dataStreamItems.map(item => ({
    name: item.name,
    intent: item.intent,
    ...item.value && { agreedAverageDailyLoad: parseFloat(item.value) },
  }));
}

export const isValidDateRange = (
  dateRange: DateRange<Moment>,
  dateRangeType: string,
  isFutureUdsFeatureEnabled: boolean,
  canApplyFutureUds: boolean
): boolean => {

  const startDate = dateRange[0];
  const endDate = dateRange[1];

  if (!startDate?.isValid() || !endDate?.isValid()) {
    return false;
  }

  if (startDate.isAfter(endDate)) {
    return false;
  }

  if (dateRangeType === MULTIPLE_DAYS) {
    return isValidMultipleDays(isFutureUdsFeatureEnabled, canApplyFutureUds, startDate, endDate);
  }

  return startDate.isBefore(endDate, "minutes")
    && startDate.isSame(endDate, "days");
}

export const isValidADLValueRange = (value: number): boolean => {
  return value <= MAXIMUM_ADL_VALUE && value >= MINIMUM_ADL_VALUE;
}

function validateAndGetErrorMessage(value: string | null) {
  if (value === null || isEmpty(trim(value))) {
    return i18n.t("SUB_SCENARIO_DIALOG.ERROR_MESSAGES.REQUIRED", {ns: "component"});
  }

  if (isNaN(Number(value)) || !isValidADLValueRange(Number(value))) {
    return i18n.t("SUB_SCENARIO_DIALOG.ERROR_MESSAGES.RANGE_VALIDATION", {ns: "component", minMaxExample: MIN_MAX_EXAMPLE});
  }

  return null;
}

export const setErrorMessages
  = (dataStreamItems: ManualSubstitutionDataStreamInputItem[]): ManualSubstitutionDataStreamInputItem[] => {
  return dataStreamItems.map(item => {
    const newItem = { ...item };
    newItem.errorMessage = "";

    const errorMessage = validateAndGetErrorMessage(newItem.value ?? null);
    if (errorMessage) {
      newItem.errorMessage = errorMessage;
    }

    return newItem;
  });
}

interface Props {
  open: boolean;
  selectedDataStreams?: DataStream[];
  onOkay: (
    methods: string[],
    dateRangeType: string,
    scenario: SelectItemType | null,
    quality: SelectItemType | null,
    reasonCode: SelectItemType | null,
    dateRange: DateRange<Moment>,
    manualSubstitutionDataStreams: ManualSubstitutionDataStream[],
  ) => void;
  onCancel: () => void;
  canApplyFutureUds?: boolean;
}

export const initialDateRangeState: DateRange<Moment> = defaultDateRangeState;

function isValidMultipleDays(isFutureUdsFeatureEnabled: boolean, canApplyFutureUds: boolean, startDate: Moment, endDate: Moment) {
  return (isFutureUdsFeatureEnabled && canApplyFutureUds) || (startDate.isBefore(getStartOfToday()) && endDate.isBefore(getStartOfToday()));
}

export const UserDefinedScenarioDialog: React.FC<Props> = ({
  open,
  selectedDataStreams = [],
  onOkay,
  onCancel,
  canApplyFutureUds = true
}) => {

  const [dateRangeType, setDateRangeType] = useState<string>(MULTIPLE_DAYS);
  const [dateRange, setDateRange] = useState<DateRange<Moment>>(initialDateRangeState);
  const [dataStreamItems, setDataStreamItems] = useState<ManualSubstitutionDataStreamInputItem[]>([]);

  const {
    loading,
    setLoading,
    methods,
    disabledOkayButton,
    onSubScenarioChanged,
    onQualityChanged,
    onReasonCodeChanged,
    selectedScenario,
    selectedQuality,
    selectedReasonCode,
  } = useSetSubScenarios();

  const isFutureUdsFeatureEnabled = useFeatureFlag("enable_future_uds");

  const isADLShow = useMemo(
    () => selectedScenario && selectedScenario.isManual,
    [selectedScenario]
  );

  const futureUdsError = useMemo(() => {

    if (!isFutureUdsFeatureEnabled) {
      return false;
    }

    if (dateRangeType !== MULTIPLE_DAYS) {
      return false;
    }

    const startDate = dateRange[0];
    const endDate = dateRange[1];

    if (!startDate?.isValid() || !endDate?.isValid()) {
      return false;
    }

    return !isValidMultipleDays(isFutureUdsFeatureEnabled, canApplyFutureUds, startDate, endDate);
  }, [dateRange, dateRangeType, isFutureUdsFeatureEnabled, canApplyFutureUds]);

  const validateAndSetError = useCallback((): boolean => {
    const newDataStreamItems = setErrorMessages(dataStreamItems);
    const hasError = newDataStreamItems.some(item => item.errorMessage);

    if (hasError) {
      setDataStreamItems(newDataStreamItems);
    }
    return !hasError;
  }, [dataStreamItems]);

  const handleOkay = useCallback(() => {
    if (disabledOkayButton) {
      return;
    }

    if (isADLShow && !validateAndSetError()) {
      return;
    }

    if (!isValidDateRange(dateRange, dateRangeType, isFutureUdsFeatureEnabled, canApplyFutureUds)) {
      return;
    }

    setLoading(true);

    const manualSubstitutionDataStreams = toManualSubstitutionDataStreams(dataStreamItems);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onOkay(
      methods.map(item => item.value),
      dateRangeType,
      selectedScenario,
      selectedQuality,
      selectedReasonCode,
      dateRange,
      manualSubstitutionDataStreams,
    );
  }, [
    onOkay,
    disabledOkayButton,
    dateRangeType,
    selectedScenario,
    selectedQuality,
    selectedReasonCode,
    setLoading,
    dateRange,
    validateAndSetError,
    isFutureUdsFeatureEnabled
  ]);

  const handleAgreedAverageDailyLoadChanged = useCallback((changedManualSubstitutionDataStream: ManualSubstitutionDataStreamInputItem) => {
    const index = dataStreamItems.findIndex(item => item.name === changedManualSubstitutionDataStream.name);

    if (index < 0) {
      return;
    }

    const newList = [...dataStreamItems];

    if (changedManualSubstitutionDataStream.errorMessage) {
      const errorMessage = validateAndGetErrorMessage(changedManualSubstitutionDataStream.value ?? null);
      changedManualSubstitutionDataStream.errorMessage = errorMessage || "";
    }

    newList[index] = {
      ...newList[index],
      ...changedManualSubstitutionDataStream,
    };

    setDataStreamItems(newList);

  }, [dataStreamItems]);

  useEffect(() => {
    setDataStreamItems(selectedDataStreams.map(item => ({ name: item.name, intent: item.intent })));
  }, [selectedDataStreams]);

  return (
    <Dialog
      open={open}
      data-test={"scenario-modal"}
      PaperProps={{ sx: { minWidth: 700, maxWidth: 700 } }}
    >
      <DialogTitle>{i18n.t("SUB_SCENARIO_DIALOG.APPLY_SUB_SCENARIO")}</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        { futureUdsError && (
          <Alert
            severity="warning"
            icon={<WarningIcon sx={{ color: "warning.main" }} />}
            sx={{ mb: 1, alignItems: "center" }}
            data-test={"uds-dialog-error"}
          >
            {i18n.t("SUB_SCENARIO_DIALOG.FUTURE_UDS_ERROR_MESSAGE")}
          </Alert>
          )
        }
        <DateTimeRangeBlock
          dateRangeType={dateRangeType}
          onDateRangeTypeChange={setDateRangeType}
          onDateRangeChanged={setDateRange}
          label={i18n.t("DATE_TIME_RANGE_BLOCK.DATETIME_RANGE")}
          canSelectFuture={isFutureUdsFeatureEnabled}
        />
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Typography variant={"h6"} sx={{ fontSize: "16px", fontWeight: 500 }}>{i18n.t("SUB_SCENARIO_DIALOG.SCENARIO")}</Typography>
        <SelectBoxBlock
          data-test={"scenario"}
          selectedItem={selectedScenario}
          items={scenarios}
          onChange={onSubScenarioChanged}
          label={i18n.t("USER_DEFINED_SCENARIO_DIALOG.SCENARIO")}
          disabled={loading}
        />
        <SelectBoxBlock
          data-test={"quality"}
          selectedItem={selectedQuality}
          items={qualities}
          onChange={onQualityChanged}
          label={i18n.t("USER_DEFINED_SCENARIO_DIALOG.QUALITY")}
          disabled={loading}
        />
        <SelectBoxBlock
          data-test={"reason-code"}
          selectedItem={selectedReasonCode}
          items={reasonCodes}
          onChange={onReasonCodeChanged}
          label={i18n.t("USER_DEFINED_SCENARIO_DIALOG.REASON_CODE")}
          disabled={loading}
        />
        <TextFlowBlock data-test={"method"} label={i18n.t("USER_DEFINED_SCENARIO_DIALOG.METHOD")} items={methods} />
        {isADLShow && (
          <AgreedAverageDailyLoadForm
            sx={{ mt: 3, mb: 2 }}
            manualSubstitutionDataStreams={dataStreamItems}
            onChange={handleAgreedAverageDailyLoadChanged}
          />
        )}
      </DialogContent>
      <SubScenarioDialogActions
        onOkay={handleOkay}
        onCancel={onCancel}
        disabledOkayButton={disabledOkayButton}
        disabled={loading}
      />
    </Dialog>
  );
};
