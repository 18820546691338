import React, { useMemo, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import env from "../../environment";
import { PageHeader } from "../../components/PageHeader";
import { ExceptionCounts } from "../../types/ExceptionCounts";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import { exceptionTypeGetter, integerValueGetter, styleDataGridHeader } from "../../commons/dataGrid";
import {i18n} from "../../global/i18n";

const t = (key: string) => {
  return i18n.t(key, {ns: "page"});
}

const columnValueDef: GridColumns = [
  {
    field: 'exceptionType',
    headerName: t("EXCEPTION_PAGE.EXCEPTION_TYPE"),
    valueGetter: exceptionTypeGetter,
  },
  {
    field: 'meterTotalCount',
    headerName: t("EXCEPTION_PAGE.NUMBER_OF_EXCEPTIONS"),
    valueGetter: integerValueGetter,
    type: "number",
    headerAlign: "left", // default alignment for number is right
    align: "left"
  }
];

const columns = styleDataGridHeader(columnValueDef);

interface Props {
  content: ExceptionCounts;
}

export const ExceptionPageNoRowsOverlay = (): JSX.Element => <NoRowsOverlay />;

export const ExceptionPage: React.FC<Props> = ({content}) => {

  const [withMultipleTypes, setWithMultipleTypes] = useState(false);
  const [multipleTypesMeterCount, setMultipleTypesMeterCount] = useState(0);
  const navigate = useNavigate();

  const rows = useMemo(() => {
    return content.exceptionsCounts.map((item, index) => {
      if (item.exceptionType === env.exceptionTypeForMultiple) {
        setWithMultipleTypes(true);
        setMultipleTypesMeterCount(item.meterTotalCount);
      }
      return { id: index, ...item }
    })
  }, [content]);

  return (
    <>
      <PageHeader>
        <Typography variant={"h5"} data-test={"page-heading"}>{t("EXCEPTION_PAGE.EXCEPTIONS")}</Typography>
      </PageHeader>
      <Box
        data-test={"exception-dashboard-list"}
        sx={{
          width: 1,
          '& .grid-header-column--header': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
            color: "text.secondary",
          },
          '& .MuiDataGrid-root': {
            border: 0
          },
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
          p: 2
        }}
      >
        {
          withMultipleTypes &&
          <Box pb={2}>
            <Alert
              data-test={"alert-message-for-multiple-types"}
              severity="warning"
              action={
                <Button
                  to={`/exception/${env.exceptionTypeForMultiple}`}
                  component={RouterLink}
                  data-test={"link-for-multiple-types"}
                >
                  <Typography style={{ textTransform: 'none' }}>{t("EXCEPTION_PAGE.VIEW_DETAIL")}</Typography>
                </Button>
              }
            >
              <AlertTitle>{t("EXCEPTION_PAGE.PRIORITY")}</AlertTitle>
              {multipleTypesMeterCount} {t("EXCEPTION_PAGE.PRIORITY_MESSAGE")}.
            </Alert>
          </Box>
        }
        <DataGridPro
          disableColumnMenu
          disableColumnReorder
          autoHeight={true}
          rows={rows}
          columns={columns}
          hideFooterPagination={true}
          hideFooterSelectedRowCount={true}
          components={{NoRowsOverlay: ExceptionPageNoRowsOverlay}}
          onCellClick={row => navigate(`/exception/${row.row.exceptionType}`)}
          initialState={{
            sorting: {
              sortModel: [{ field: 'exceptionType', sort: 'asc' }],
            },
          }}
          localeText={{footerTotalRows: t("EXCEPTION_PAGE.TOTAL_ROWS")}}
        />
      </Box>

    </>
  );
};
