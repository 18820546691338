import React from "react";
import { Box, Typography } from "@mui/material";
import { Meter } from "../../types/Meter";
import { Helmet } from "react-helmet-async";
import { i18n } from "../../global/i18n";
import { MeterEventsTable} from "../../components/MeterEventsTable";
import { MeterEventsHeader } from "../../components/MeterEventsHeader";
import env from "../../environment"

interface Props {
  connectionPointId: string;
  meter: Meter
}

export const MeterEventsPage: React.FC<Props> = (
  {
    connectionPointId,
    meter
  }
) => {
  return (
    <Box sx={{ p: 3 }}>
      <Helmet>
        <title>{i18n.t("METER_EVENTS_PAGE.METER_EVENTS_TITLE", { ns: "component" })}: {meter.id}</title>
      </Helmet>

      <MeterEventsHeader connectionPointId={connectionPointId} meter={meter} timezone={env.displayTimezone}/>

      <Typography sx={{ pb: 1 }} variant={"h6"} data-test={"datastream-heading"}>
        {i18n.t("METER_EVENTS_PAGE.METER_EVENTS_TITLE", { ns: "component" })}
      </Typography>
      <MeterEventsTable meterId={meter.id}/>
    </Box>
  );
}
