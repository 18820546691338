import { useEffect } from "react";
import { getInitials } from "../commons/userInfo";
import { EMPTY, NepLayoutState } from "../types/NepLayoutState";

export const useNepLayout = (
  setState: (state: NepLayoutState) => void,
): void =>
  useEffect(() => {
    let state = null;
    const userInitials = getInitials();
    if (userInitials) {
      state = {userInitials};
    }
    setState(state ? state : EMPTY);
  }, [setState]);
