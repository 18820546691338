import React, { ReactNode, useMemo } from "react";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { DataStream } from "../../types/DataStream";
import { Helmet } from "react-helmet-async";
import { DataStreamHeader } from "../../components/DataStreamHeader";
import { DataStreamStatus } from "../../components/DataStreamStatus";
import { DataStreamBreadCrumbs } from "../../components/DataStreamBreadCrumbs";
import { ApplySubScenarioButton } from "../../components/ApplySubScenarioButton";
import { i18n } from "../../global/i18n";
import { FeatureFlag } from "../../components/FeatureFlag";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import { isEmpty, trim } from "lodash";
import env from "../../environment";

interface Props {
  connectionPointId: string;
  meterId: string;
  intent: string;
  commodity: string;
  serialNumber: string;
  dataStreamName: string;
  content: DataStream;
  canApplyFutureUds: boolean;
  showApplyScenarioButton: boolean;
  children: ReactNode;
}

export const renderToolTip = (params: any) => {
  const causeValue = params.value || "";

  return (
    <Tooltip
      title={causeValue}
      sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
    >
      <Typography noWrap sx={{ fontSize: "inherit" }}>
        {causeValue}
      </Typography>
    </Tooltip>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DataStreamIntervalNoRowsOverlay = () => (
  <NoRowsOverlay prefix="datastream-interval">
    {i18n.t("DATA_STREAM_INTERVALS.NO_DATA", { ns: "component" })}
  </NoRowsOverlay>
);

export const displayUnitOfMeasureColumnHeader = (
  unitOfMeasure: string,
): string => {
  const suffix = !isEmpty(trim(unitOfMeasure)) ? ` (${unitOfMeasure})` : "";
  return (
    i18n.t("DATA_STREAM_INTERVALS.HEADERS.LATEST_QUANTITY", {
      ns: "component",
    }) + suffix
  );
};

export const DataStreamDetails: React.FC<Props> = (
  {
    connectionPointId,
    meterId,
    dataStreamName,
    intent,
    commodity,
    serialNumber,
    content,
    canApplyFutureUds,
    showApplyScenarioButton,
    children
  }) => {
  const selectedDataStreams = useMemo(() => {
    return [content];
  }, [content]);

  const dataStreamHeaderState = Object.assign({}, content, {timeZone: env.displayTimezone});

  return (
    <Box sx={{p: 3}}>
      <Stack direction={"row"} justifyContent="space-between">
        <DataStreamBreadCrumbs
          connectionPointId={connectionPointId}
          meterId={meterId}
          commodity={commodity}
          serialNumber={serialNumber}
          dataStreamName={dataStreamName}
          intent={intent}
        />
        {showApplyScenarioButton && (
          <FeatureFlag flag="enable_uds">
          <ApplySubScenarioButton meterId={meterId} selectedDataStreams={selectedDataStreams} canApplyFutureUds={canApplyFutureUds} />
          </FeatureFlag>)
        }
      </Stack>
      <Helmet>
        <title>{i18n.t("DATA_STREAM_DETAILS_PAGE.TITLE", {ns: "page"})}: {content.name}</title>
      </Helmet>
      <DataStreamHeader content={dataStreamHeaderState} />
      <DataStreamStatus content={dataStreamHeaderState} />
      <Divider sx={{ mt: 2, mb: 2 }} />
      {children}
    </Box>
  );
};
