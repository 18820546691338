import React, { useMemo, useState } from "react";
import { Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import { ConnectionPoint } from "../../types/ConnectionPoint";
import { SearchResultTag } from "../SearchResultTag";
import { SeparatedList } from "../SeparatedList";
import { MeterSearchResult } from "../MeterSearchResult";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { StatusCount } from "../StatusCount";
import { useConnectionPointSearchContext } from "../../pages/ConnectionPointSearch/context";
import { formatDateTime } from "../../commons/dates";
import { useTranslation } from "react-i18next";

interface Props {
  content: ConnectionPoint;
  isExpanded: boolean;
}

export const ConnectionPointSearchResult: React.FC<Props> = (
  {content: {id, number, retailer, changeEffectiveDate, meterIds}, isExpanded}
) => {
  const { t } = useTranslation("component");
  const [params] = useSearchParams();
  const overlay = Boolean(params.get("overlay"));
  const link = `/connectionpoint/${id}${overlay ? `?overlay=true` : ''}`;
  const [expanded, setExpanded] = useState(isExpanded);

  const {getCounts} = useConnectionPointSearchContext();

  const counts = useMemo(() => {
    return getCounts(id);
  }, [getCounts, id]);

  return (
    <>
      <TableRow data-connectionpointid={id} data-test={"search-result-row"} sx={{height: "40px"}}>
        <TableCell sx={{p: 0}}>
          <IconButton
            aria-label={t("CONNECTION_POINT_SEARCH_RESULT.EXPAND_ROW")}
            data-test={"connectionpoint-expand-collapse-row"}
            size="small"
            onClick={() => setExpanded(!expanded)}>
            {expanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
          <SeparatedList>
            <Typography data-test={"search-result-row-connectionpoint-id"} component={"span"}>
              <SearchResultTag color="#FFDAC3">{t("CONNECTION_POINT_SEARCH_RESULT.CONNECTION_POINT")}</SearchResultTag>
              <Link to={link}>{number}</Link>
            </Typography>
            <Typography data-test={"search-result-row-connectionpoint-retailer"} component={"span"}>
              {retailer}
            </Typography>
          </SeparatedList>
        </TableCell>
        <TableCell sx={{pl: 2, pr: 0, py: 0, width: "200px"}} data-test={"search-result-row-connectionpoint-effective-date"}>
          {formatDateTime(changeEffectiveDate)}
        </TableCell>
        <TableCell sx={{pl: 2, pr: 0, py: 0, width: "150px"}} data-test={"search-result-row-connectionpoint-meter-status"}>
          {counts.numberOfMeters === meterIds.length
            ? ( <StatusCount activeCount={counts.meterCount.active} inActiveCount={counts.meterCount.inactive}/> )
            : ( <Skeleton animation="wave"/> )
          }
        </TableCell>
        <TableCell sx={{pl: 2, pr: 0, py: 0, width: "150px"}} data-test={"search-result-row-connectionpoint-datastream-status"}>
          {counts.numberOfMeters === meterIds.length
            ? ( <StatusCount activeCount={counts.dataStreamCount.active} inActiveCount={counts.dataStreamCount.inactive}/> )
            : ( <Skeleton animation="wave"/> )
          }
        </TableCell>
      </TableRow>
      <TableRow sx={{height: "40px"}}>
        <TableCell sx={{p: 0, borderBottom: "none"}} colSpan={4}>
          <Collapse in={expanded} timeout="auto">
            {meterIds.map((meterId, index) => <MeterSearchResult connectionPointId={id} meterId={meterId} key={index}/>)}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
