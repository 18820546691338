import React, { useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import env from "../../environment";
import { ExceptionListState } from "../../types/ExceptionListState";
import { PageHeader } from "../../components/PageHeader";
import { ExceptionListTable } from "../../components/ExceptionListTable";
import { ConnectionPointSearchToggle } from "../../components/ConnectionPointSearchToggle";
import { mapExceptionTypeDisplayValue } from "../../mappers/exceptionTypeMapper";
import {useTranslation} from "react-i18next";

interface Props {
  type: string
  content: ExceptionListState;
  postUpdate: () => Promise<void>;
}

export const ExceptionList: React.FC<Props> = ({ type, content, postUpdate }) => {

  const { t } = useTranslation("page");
  const [searchParams] = useSearchParams();
  const [filteredExceptions, setFilteredExceptions] = useState(content.meterExceptionCounts);

  useEffect(() => {
    const meterId = searchParams.get("meterId");
    const connectionPointId = searchParams.get("connectionPointId");

    if (meterId) {
      setFilteredExceptions(content.meterExceptionCounts.filter(exception => exception.meterId === meterId));
    } else if (connectionPointId) {
      setFilteredExceptions(content.meterExceptionCounts.filter(exception => exception.connectionPointId === connectionPointId));
    } else {
      setFilteredExceptions(content.meterExceptionCounts);
    }

  }, [searchParams, content]);

  const displayResolutionOptions = useMemo(() => {
    return type !== env.exceptionTypeForMultiple;
  }, [type]);

  const exceptionTypeDisplayName = useMemo(() => {
    return mapExceptionTypeDisplayValue(type);
  }, [type]);

  return (
    <>
      <Helmet>
        <title>{t("EXCEPTION_LIST_PAGE.TITLE")} | {exceptionTypeDisplayName}</title>
      </Helmet>
      <Breadcrumbs aria-label={"breadcrumb"} sx={{ px: 4, py: 2 }}>
        <Link underline={"hover"} color={"text.secondary"} to={"/exception"} component={RouterLink}>
          {t("EXCEPTION_LIST_PAGE.EXCEPTION_LINK")}
        </Link>
        <Typography color={"text.primary"}>{exceptionTypeDisplayName}</Typography>
      </Breadcrumbs>
      <PageHeader sx={{ height: "auto", px: 4, py: 0, paddingBottom: "24px" }}>
        <Typography variant={"h5"} mb={2} data-test={"page-heading"}>{exceptionTypeDisplayName}</Typography>
        <ConnectionPointSearchToggle />
      </PageHeader>
      <ExceptionListTable
        exceptionType={type}
        content={filteredExceptions}
        displayResolutionOptions={displayResolutionOptions}
        postUpdate={postUpdate}
      />
    </>
  );

};
