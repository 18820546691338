import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LOGIN_TOKEN_KEY } from "../../commons/userInfo"
import { LOGIN_CURRENT_PATHNAME_KEY, getExpiresAt } from "../../global/fetch-interceptors"
import { EMPTY, LoginToken } from "../../types/LoginToken";
import { retrieveLoginToken } from "../../services/LoginService";

export const LoginPageContainer: React.FC = () => {
  const [currentState, setState] = useState<LoginToken>(EMPTY);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code") || "";

  useEffect(() => {
    if (code) {
      window.sessionStorage.removeItem(LOGIN_TOKEN_KEY);
      setState(EMPTY);
      retrieveLoginToken(code).then(loginToken => {
        if (loginToken && loginToken.expires_in) {
          const expiresAt = getExpiresAt(loginToken.expires_in);
          loginToken.expires_at = expiresAt;
        }
        setState(loginToken);
      }).catch(() => setState(EMPTY));
    }
  }, [code]);

  useEffect(() => {
    if (currentState.id_token) {
      window.sessionStorage.setItem(LOGIN_TOKEN_KEY, JSON.stringify(currentState));
      const pathname = window.sessionStorage.getItem(LOGIN_CURRENT_PATHNAME_KEY);
      window.sessionStorage.removeItem(LOGIN_CURRENT_PATHNAME_KEY);
      window.location.href = pathname || "";
    }
  }, [currentState]);
  return null;
};
