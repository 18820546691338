import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { red } from "@mui/material/colors";
import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Meter } from "../../types/Meter";
import { SeparatedList } from "../SeparatedList";
import { SearchResultTag } from "../SearchResultTag";
import { DatastreamSearchResult } from "../DatastreamSearchResult";
import { formatDateTime } from "../../commons/dates";
import { useTranslation } from "react-i18next";

interface Props {
  connectionPointId: string;
  content: Meter;
}

export const MeterSearchResult: React.FC<Props> = (
  {
    connectionPointId,
    content: {
      id,
      serialNumber,
      installationType,
      installDateTime,
      deviceConfiguration: {configurationId},
      model,
      status,
      dataStreams
    }
  }
) => {
  const { t } = useTranslation("component");
  const [params] = useSearchParams();
  const overlay = Boolean(params.get("overlay"));
  const link = `/connectionpoint/${connectionPointId}/meter/${id}${overlay ? `?overlay=true` : ''}`;
  return (
    <Table>
      <TableBody>
        <TableRow data-connectionpointid={connectionPointId} data-meterid={id} data-test={"search-result-meter"} sx={{height: "40px"}}>
          <TableCell sx={{p: 0, pl: 10}}>
            <SeparatedList>
              <Typography component={"span"}>
                <SearchResultTag color="#2196F34A">{t("METERS_RESULT.METER")}</SearchResultTag>
                <Link data-test={"search-result-meter-serial"} to={link}>{serialNumber}</Link>
              </Typography>
              <Typography data-test={"search-result-meter-installation-type"} component={"span"}>
                {installationType}
              </Typography>
              <Typography data-test={"search-result-meter-device-configuration-type"} component={"span"}>
                {configurationId}
              </Typography>
              <Typography data-test={"search-result-meter-model"} component={"span"}>
                {model}
              </Typography>
            </SeparatedList>
          </TableCell>
          <TableCell
            sx={{pl: 2, pr: 0, py: 0, width: "200px"}}
            data-test={"search-result-row-meter-install-date-time"}
          >
            {formatDateTime(installDateTime)}
          </TableCell>
          <TableCell
            sx={{
              pl: 2,
              pr: 0,
              py: 0,
              width: "150px",
              color: status === "Active" ? "auto" : red[500],
            }}
            data-test={"search-result-row-meter-status"}
          >
            {status?.toLowerCase() === "active" ? t("METER_SEARCH_RESULT.ACTIVE") : t("METER_SEARCH_RESULT.INACTIVE")}
          </TableCell>
          <TableCell sx={{pl: 2, pr: 0, py: 0, width: "150px"}} data-test={"search-result-row-meter-datastream-status"}>
            {"-"}
          </TableCell>
        </TableRow>
        {dataStreams.map((ds, index) => (
          <DatastreamSearchResult connectionPointId={connectionPointId} meterId={id} content={ds} key={index} meterStatus={status} />
        ))}
      </TableBody>
    </Table>
  );
}
