import React from "react";
import { MeterStatus } from "../../components/MeterStatus";
import { DeviceConfiguration } from "../../components/DeviceConfiguration";
import { DataStreams } from "../../components/DataStreams";
import { Snapshot } from "../../components/Snapshot";
import { CircularProgress, Divider } from "@mui/material";
import { Meter } from "../../types/Meter";
import { Helmet } from "react-helmet-async";
import { Exceptions } from "../../components/Exceptions";
import { AuditLog } from "../../components/AuditLog";
import { useTranslation } from "react-i18next";
import { FeatureFlag } from "../../components/FeatureFlag";

interface Props {
  connectionPointNumber: string;
  content: Meter;
  meterEventsUrl: string;
}

export const MeterDetails: React.FC<Props> = ({ connectionPointNumber, content, meterEventsUrl}) => {
  const { t }  = useTranslation("page");
  if (!content || !content.id) return (<CircularProgress />);
  return (
    <>
      <Helmet>
        <title>{t("METER_DETAILS_PAGE.TITLE")}: {content.id}</title>
      </Helmet>
      <MeterStatus content={content} />
      <Divider sx={{mt: 2, mb: 2}} />
      <DeviceConfiguration content={content.deviceConfiguration}
                           churnedContent={content.churnedDeviceConfiguration}
                           meterEventsUrl={meterEventsUrl}/>
      <FeatureFlag flag={"enable_exceptions"}>
        <Exceptions meterId={content.id} meterSerial={content.serialNumber} connectionPointNumber={connectionPointNumber} />
      </FeatureFlag>
      <Divider sx={{mt: 2, mb: 2}} />
      <DataStreams content={content.dataStreams} />
      <Divider sx={{mt: 2, mb: 2}} />
      <FeatureFlag flag="enable_snapshot">
        <Snapshot meterId={content.id} />
        <Divider sx={{mt: 2, mb: 2}} />
      </FeatureFlag>
      <FeatureFlag flag="enable_audit_logs">
        <AuditLog meterId={content.id} />
      </FeatureFlag>
    </>
  );
};