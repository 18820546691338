/* eslint-disable @typescript-eslint/no-explicit-any */

import { MeterException } from "./MeterException";

export interface MeterExceptions {
  exceptions: MeterException[];
}

export const EMPTY: MeterExceptions = {
  exceptions: [],
};

export interface ParamMeterExceptionService {
  params: {
    index: number;
    meterId: string;
    unitOfWorkId?: string;
    exceptionType: string;
    displayResolutionOptions?: boolean;
  };
  action: string;
  bodyData: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
  };
}

export interface ResultParamMeterExceptionService {
  errors: ParamMeterExceptionService[];
  success: ParamMeterExceptionService[];
}

export const EMPTY_PARAM_METER_EXCEPTION_SERVICE = {
  params: {
    meterId: "",
    unitOfWorkId: "",
    exceptionType: "",
  },
  action: "",
  bodyData: {},
};
