import { ReactNode, useEffect, useState } from "react";
import { AlertColor } from "@mui/material";

export const alertType : {
  [key: string]: AlertColor
} = {
  success: "success",
  error: "error",
  warning: "warning",
};

interface MessageState {
  state: {
    type: AlertColor;
    message: string | ReactNode;
    messageContent: { [key: string]: string | ReactNode }[];
    openAlert: boolean;
    closeAlert: () => void;
  }
}

const closeAlertFn = (
  setOpenAlert: (open: boolean) => void
) => {
  return () => setOpenAlert(false);
};

const hasSingleResult = (
  result: string
) => result && Object.values(alertType).some(r => r === result);

export const useMessage = (
  result: string | [],
  messageOptions: { [key: string]: string | ReactNode }
): MessageState => {
  const [type, setType] = useState<AlertColor>(alertType.success);
  const [message, setMessage] = useState<string | ReactNode>("");
  const [messageContent, setMessageContent] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (hasSingleResult(result as string)) {
      const type = result as AlertColor;
      setType(type);
      setMessage(messageOptions[type]);
      setMessageContent([]);
      setOpenAlert(true);

    } else if (Array.isArray(result)) {
      setType(alertType.error);
      setMessage(messageOptions["errorList"]);
      setMessageContent(result);
      setOpenAlert(true);
    }
  }, [result, messageOptions]);

  return {
    state: {
      type,
      message,
      messageContent,
      openAlert,
      closeAlert: closeAlertFn(setOpenAlert)
    }
  }
};
