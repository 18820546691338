export type TypeStatus = "Pending" | "Complete" | "Failed";
export interface ReportDownloadData {
  reportId: string,
  connectionPointId: string,
  reportName: string,
  status: TypeStatus,
  fileSize: number,
  generatedDateTime: string,
  presignedURL: string,

}
export interface ReportDownloadPageState {
  content: ReportDownloadData[];
  isLoading: boolean;
}

export const EMPTY: ReportDownloadPageState = {
  content: [],
  isLoading: true,
};

