import React, { useCallback, useMemo, useState } from "react";
import { Box, Stack, TextField, useTheme } from "@mui/material";
import { DataGridPro, GridCellParams, GridColumns, GridRowParams } from "@mui/x-data-grid-pro";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isEmpty } from "lodash";
import { Moment } from "moment-timezone";
import { EMPTY, IntervalsConsumption } from "../../types/IntervalConsumption";
import { VersionItem } from "../../types/IntervalVersion";
import { IntervalVersionDropdown } from "./IntervalVersionDropdown";
import { DataStreamIntervalsSummary } from "../DataStreamIntervalsSummary";
import { datetimeValueFormatter, dateValueGetter, styleDataGridHeader } from "../../commons/dataGrid";
import { DATE_PATTERN } from "../../commons/dates";
import { i18n } from "../../global/i18n";
import {
  DataStreamIntervalNoRowsOverlay,
  displayUnitOfMeasureColumnHeader,
  renderToolTip
} from "../../pages/DataStreamDetails/DataStreamDetails";

interface Props {
  content: IntervalsConsumption;
  date: Moment;
  versions: VersionItem[];
  selectedVersion: VersionItem | undefined;
  onSelectDate: (date: Moment) => void;
  onSelectVersion: (versionItem: VersionItem) => void;
  loading: boolean;
  unitOfMeasure: string;
}

export const styleDataGridRow = (params: GridRowParams) => {
  return params?.row?.cause && params?.row?.isException ? "exception-row" : "";
};

export const styleDataGridCell = (params: GridCellParams<string>) => {
  return params?.field === "cause" && params?.value && params?.row?.isException
    ? "exception-cell"
    : "";
};

export const DataStreamConsumptionIntervals: React.FC<Props> = ({
  content,
  date,
  versions,
  onSelectDate,
  onSelectVersion,
  selectedVersion,
  loading = true,
  unitOfMeasure,
}) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState<Moment>(date);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, selectedItem: VersionItem) => {
      setAnchorEl(null);
      onSelectVersion(selectedItem);
    },
    [onSelectVersion],
  );

  const handleDateChange = useCallback(
    (newDate: Moment | null) => {
      if (!newDate || !newDate.isValid() || newDate.isSame(date)) {
        return;
      }

      setSelectedDate(newDate);
      onSelectDate(newDate);
    },
    [date, onSelectDate],
  );

  const rows = useMemo(() => {
    return content.intervals.map((item, index) => ({
      id: index,
      ...item,
      dateTime: item.dateTime,
    }));
  }, [content]);

  const dataGridComponents = useMemo(() => {
    if (!loading && (isEmpty(versions) || content === EMPTY)) {
      return {
        components: {
          NoRowsOverlay: DataStreamIntervalNoRowsOverlay,
        },
      };
    }
  }, [loading, versions, content]);

  const columns = useMemo(() => {
    const columnsValueDef: GridColumns = [
      {
        field: "dateTime",
        headerName: i18n.t("DATA_STREAM_INTERVALS.HEADERS.DATETIME", {
          ns: "component",
        }),
        valueGetter: dateValueGetter,
        type: "dateTime",
        valueFormatter: datetimeValueFormatter,
      },
      {
        field: "latestQuantity",
        headerName: displayUnitOfMeasureColumnHeader(unitOfMeasure),
        sortable: false,
      },
      {
        field: "quality",
        headerName: i18n.t("DATA_STREAM_INTERVALS.HEADERS.QUALITY", {
          ns: "component",
        }),
        sortable: false,
      },
      {
        field: "method",
        headerName: i18n.t("DATA_STREAM_INTERVALS.HEADERS.METHOD", {
          ns: "component",
        }),
        sortable: false,
      },
      {
        field: "reasonCode",
        headerName: i18n.t("DATA_STREAM_INTERVALS.HEADERS.REASON_CODE", {
          ns: "component",
        }),
        sortable: false,
      },
      {
        field: "cause",
        headerName: i18n.t("DATA_STREAM_INTERVALS.HEADERS.CAUSE", {
          ns: "component",
        }),
        sortable: false,
        renderCell: renderToolTip,
      },
    ];

    return styleDataGridHeader(columnsValueDef);
  }, [unitOfMeasure]);

  return (
    <Box
      data-test={"datastream-interval"}
      sx={{
        width: 1,
        "& .grid-header-column--header": {
          backgroundColor: "rgba(0, 0, 0, 0.03)",
          color: "text.secondary",
        },
        "& .MuiDataGrid-root": {
          border: 0,
          position: "relative",
        },
      }}
    >
      <Box
        data-test={"page-heading-exceptions"}
        sx={{
          mb: theme.spacing(2),
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          {i18n.t("DATA_STREAM_INTERVALS.INTERVALS_HEADING", {
            ns: "component",
          })}
        </Box>
        <Box data-test={"intervals"}>
          <Stack
            spacing={2}
            direction={"row"}
            sx={{
              ".date-picker > .MuiFormControl-root.MuiTextField-root": {
                minHeight: "40px",
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <Box
              data-test={"interval-date"}
              sx={{ minHeight: "40px" }}
              className={"date-picker"}
            >
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                inputFormat={DATE_PATTERN}
                disableFuture
                renderInput={(params) => (
                  <TextField
                    sx={{ maxWidth: 200, input: { fontSize: 14 } }}
                    {...params}
                  />
                )}
                desktopModeMediaQuery={"@media (min-width:368px)"}
              />
            </Box>
            <IntervalVersionDropdown
              versions={versions}
              selectedVersion={selectedVersion}
              anchorEl={anchorEl}
              onClose={handleClose}
              onClick={handleClick}
              onMenuItemClick={handleMenuItemClick}
            />
          </Stack>
        </Box>
      </Box>
      <DataStreamIntervalsSummary
        ingestionDateTime={content.ingestionDateTime}
        processedDateTime={content.processedDateTime}
      />
      <Box
        sx={{
          height: 400,
          width: "100%",
          "& .exception-row": {
            background: "rgba(244, 67, 54, 0.08)"
          },
          "& .exception-row:hover": {
            background: "rgba(244, 67, 54, 0.08) !important"
          },
          "& .exception-cell": {
            color: "#E31B0C",
          }
        }}
      >
        <DataGridPro
          autoHeight
          hideFooterPagination
          hideFooterSelectedRowCount
          disableColumnMenu
          disableColumnReorder
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: 'dateTime', sort: 'asc' }],
            },
          }}
          rows={rows}
          columns={columns}
          loading={loading}
          getRowClassName={styleDataGridRow}
          getCellClassName={styleDataGridCell}
          {...dataGridComponents}
          localeText={{footerTotalRows: i18n.t("EXCEPTION_PAGE.TOTAL_ROWS", { ns: "page",})}}
        />
      </Box>
    </Box>
  );
};
