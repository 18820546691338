import React from "react";
import { ChurnedDeviceConfigurationState, EMPTY } from "../../types/ChurnedDeviceConfigurationState";
import { NamedItem } from "../NamedItem";
import { formatDateTime } from "../../commons/dates";
import { useTranslation } from "react-i18next";

interface Props {
  content: ChurnedDeviceConfigurationState;
}

export const ChurnedDeviceConfiguration: React.FC<Props> = ({ content = EMPTY }) => {
  const { t } = useTranslation("component");
  return (
    <NamedItem
      label={t("METER_STATUS.CHURN_DE_ENERGISATION_DATETIME")}
      content={formatDateTime(content.removalDateTime)}
    />
  );
};
