import { useMemo } from "react";

import environment from "../environment";

const MISSING_CONFIG_DEFAULT: string[] = new Array<string>()

export const useAllowedSubScenarios = (
): string[] => {

  return useMemo(() => {
    const allowedSubScenarios: Array<string> = environment.allowedSubScenarios;
    const isValidConfig = allowedSubScenarios && Array.isArray(allowedSubScenarios);

    return isValidConfig ? allowedSubScenarios : MISSING_CONFIG_DEFAULT;
  }, []);
}


