import React from "react";
import { Box } from "@mui/material";
import { NamedItem } from "../NamedItem";
import { Meter } from "../../types/Meter";
import { SeparatedList } from "../SeparatedList";
import { formatDate, formatDateTime } from "../../commons/dates";
import { useTranslation } from "react-i18next";

interface Props {
  content: Meter;
  timezone?: string;
}

export const MeterStatus: React.FC<Props> = ({ content, timezone=null }) => {
  const { t } = useTranslation("component");
  const isDisplayTimeZone = timezone !== null;
  return (
    <Box data-test={"meter-status-banner"} sx={ { display: "flex", flexWrap: "wrap", pt: 2 } }>
      <SeparatedList>
        <NamedItem
          value-data-test={ "meter-status" }
          label={ t("METER_STATUS.STATUS") }
          content={content.status?.toLowerCase() === "active" ? t("METER_SEARCH_RESULT.ACTIVE") : t("METER_SEARCH_RESULT.INACTIVE")}
        />
        <NamedItem
          value-data-test={ "meter-installation-type" }
          label={ t("METER_STATUS.INSTALLATION_TYPE") }
          content={ content.installationType }
        />
        <NamedItem
          value-data-test={ "meter-serial-number" }
          label={ t("METER_STATUS.SERIAL_NUMBER") }
          content={ content.serialNumber }
        />
        <NamedItem
          value-data-test={ "meter-nsrd" }
          label={ t("METER_STATUS.NSRD") }
          content={ formatDate(content.nsrd) }
        />
        <NamedItem
          value-data-test={ "meter-install-datetime" }
          label={ t("METER_STATUS.INSTALL_DATETIME") }
          content={ formatDateTime(content.installDateTime) }
        />
        <NamedItem
          value-data-test={ "meter-model" }
          label={ t("METER_STATUS.MODEL") }
          content={ content.model }
        />
        <NamedItem
          value-data-test={ "meter-effective-date" }
          label={ t("METER_STATUS.EFFECTIVE_DATE") }
          content={ formatDate(content.changeEffectiveDate) }
        />
        { isDisplayTimeZone &&
          <NamedItem
            value-data-test={ "meter-timezone" }
            label={ t("METER_STATUS.TIMEZONE") }
            content={ timezone }
          />
        }
      </SeparatedList>
    </Box>
  );
};


