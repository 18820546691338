import { useCallback, useMemo, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { SelectItemType } from "../types/SelectItemType";
import { ScenarioMethod, EMPTY_LIST } from "../types/ScenarioMethod";
import { qualities, reasonCodes, scenarios } from "../commons/scenarioData";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const useSetSubScenarios = () => {

  const [loading, setLoading] = useState(false);
  const [scenario, setScenario] = useState<SelectItemType | null>(scenarios[0]);
  const [quality, setQuality] = useState<SelectItemType | null>(qualities[0]);
  const [reasonCode, setReasonCode] = useState<SelectItemType | null>(reasonCodes[0]);

  const methods: ScenarioMethod[] = useMemo(() => {
    if (!scenario || !scenario.methods) {
      return EMPTY_LIST;
    }

    return (scenario.methods as unknown) as ScenarioMethod[];
  }, [scenario]);

  const disabledOkayButton = useMemo(() => {
    return !(scenario && quality && reasonCode);
  }, [scenario, quality, reasonCode]);

  const onSubScenarioChanged = useCallback((event: SelectChangeEvent<unknown>) => {
    const id = event.target.value as string;
    setScenario(scenarios.find(s => s.id === id) || null);
  }, []);

  const onQualityChanged = useCallback((event: SelectChangeEvent<unknown>) => {
    const id = event.target.value as string;
    setQuality(qualities.find(s => s.id === id) || null);
  }, []);

  const onReasonCodeChanged = useCallback((event: SelectChangeEvent<unknown>) => {
    const id = event.target.value as string;
    setReasonCode(reasonCodes.find(s => s.id === id) || null);
  }, []);

  return {
    loading,
    setLoading,
    methods,
    disabledOkayButton,
    onSubScenarioChanged,
    onQualityChanged,
    onReasonCodeChanged,
    selectedScenario: scenario,
    selectedQuality: quality,
    selectedReasonCode: reasonCode,
  }

}
