import React from "react";
import { SearchResults } from "../../components/SearchResults";
import { Box, CircularProgress } from "@mui/material";
import { ConnectionPoints, EMPTY } from "../../types/ConnectionPoints";

interface Props {
  content: ConnectionPoints | null
}

export const PageContentResult: React.FC<Props> = ({ content }) => {
  if (!content) {
    return <SearchResults content={EMPTY} />
  } else if (content.connectionPoints.length <= 0) {
    return (
      <Box sx={{ 
        position: 'absolute',
        top: '55%',
        left: '55%',
      }}>
        <CircularProgress data-test="content-result-spinner" />
      </Box>
    )
  } else {
    return <SearchResults content={content} />
  }
};
