import { useEffect } from "react";
import { ExceptionCounts } from "../types/ExceptionCounts";
import { retrieveExceptionState } from "../services/ExceptionService";

export const useExceptionPage = (
  setState: (state: ExceptionCounts) => void,
): void =>
  useEffect(() => {
    retrieveExceptionState().then(setState).catch(() => setState({
      exceptionsCounts: []
    }));
  }, [setState]);
