import React, { useEffect, useState } from "react";
import { Exceptions } from "./Exceptions";
import { useExceptions } from "../../hooks/useExceptions";
import { MeterExceptions } from "../../types/MeterExceptions";

interface Props {
  meterId: string;
  meterSerial: string;
  connectionPointNumber: string;
}

export const ExceptionsContainer: React.FC<Props> = ({meterId, meterSerial, connectionPointNumber}) => {
  const [currentState, setState ] = useState<MeterExceptions | null>(null);
  useEffect(() => {
    useExceptions(setState, meterId);
  }, [meterId]);
  return <Exceptions 
    content={currentState}
    meterSerial={meterSerial}
    connectionPointNumber={connectionPointNumber}
    postUpdate={() => useExceptions(setState, meterId)}
  />;
};
