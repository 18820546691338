import { IntervalAuditLogsState } from "../types/IntervalAuditLogsState";
import { getStartOfDateString, toDayRange } from "../commons/dates";

export const retrieveIntervalAuditLogs = async (
  meterId: string,
  date: string,
): Promise<IntervalAuditLogsState> => {

  return fetch(
    `/service/v1/meter/${meterId}/auditlog/interval?` +
    new URLSearchParams(toDayRange(getStartOfDateString(date))),
    {
      headers: new Headers({
        "Content-Type": "application/json; charset=utf-8",
      }),
    },
  ).then((response) => {
    if (!response.ok) {
      response.text().then(text => console.error(text));
      throw new Error(`HTTP error: ${response.status}`);
    }
    return response.json();
  });
}
