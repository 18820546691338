import { useEffect } from "react";
import { EMPTY, Meter } from "../types/Meter";
import { retrieveMeterState } from "../services/MeterDetailsService";


export const useMeterDetails = (
  setState: (state: Meter) => void,
  setError: (state: string) => void,
  meterId: string,
  connectionPointId: string,
): void =>
  useEffect(() => {
    setState(EMPTY);
    retrieveMeterState(meterId, connectionPointId).then(meter => {
      setError("");
      setState(meter);
    }).catch(function (err) {
      setError(err.message)
    });
  }, [meterId, connectionPointId, setState, setError]);
