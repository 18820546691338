/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useMemo } from "react";
import { Box, CircularProgress } from "@mui/material";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import {
  activeLicense,
  getCommonChartConfig,
} from "../DataStreamChart/DataStreamChart";
import {
  ChartSchema,
  DataStreamChartComparisonState,
  YaxisPlot,
  COMPARE,
  COMPARISON_SUFFIX,
} from "../../types/DataStreamChartComparisonState";
import { ComparisonItem } from "../../types/ComparisonItem";
import WarningIcon from "@mui/icons-material/Warning";
import Alert, { AlertColor } from "@mui/material/Alert";
import { i18n } from "../../global/i18n";

interface Props {
  content: DataStreamChartComparisonState;
  comparisonItems: ComparisonItem[];
  callback: (data: any) => void;
}

ReactFC.fcRoot(FusionCharts, TimeSeries, FusionTheme);

FusionCharts.options = activeLicense(FusionCharts.options);

const dataSource: any = {
  chart: { enableChartMouseMoveEvent: "1" },
  ...getCommonChartConfig(),
  tooltip: {
    enabled: "0",
  },
};

const chartConfigs: any = {
  id: "datastream-chart-comparison",
  type: "timeseries",
  renderAt: "container",
  width: "100%",
  height: "450",
  dataSource,
};

export const getYaxisPlotsConfig = (schemas: ChartSchema[]): YaxisPlot[] => {
  const plots = schemas.map((schema: ChartSchema) => {
    const plot: YaxisPlot = {
      value: schema.name,
      aggregation: "First",
      connectnulldata: true,
    };

    if (schema.plotStyle === COMPARE) {
      plot.style = {
        plot: {
          "stroke-dasharray": "3,3",
        },
      };
    }
    return plot;
  });

  return plots;
};

export const getChartColors = (schemas: ChartSchema[]): string[] => {
  const colors = schemas
    .filter((schema) => schema.type === "number")
    .map((schema) => schema.plotColor || "")
    .filter((color) => color);

  return colors;
};

export const removeComparisonLegends = (
  testingComparisonLegends?: HTMLCollectionOf<SVGTextElement>,
): void => {
  const legendTextTags = testingComparisonLegends
    ? testingComparisonLegends
    : document.getElementsByTagName("text");
  const legendTagsToRemove = [];

  for (let i = 0; i < legendTextTags.length; i++) {
    const legendTextTag = legendTextTags[i];

    if (legendTextTag.innerHTML.trim().endsWith(COMPARISON_SUFFIX)) {
      const legendIconTag = legendTextTag.nextElementSibling;
      legendTagsToRemove.push(legendTextTag);
      legendTagsToRemove.push(legendIconTag);
    }
  }

  legendTagsToRemove.forEach((tag) => tag?.remove());
};

export const showHideAllLinesByLegendsPlotColor = (
  schemaName: string,
  originalSchemas: ChartSchema[],
  testingLines?: NodeListOf<Element>,
): void => {
  const selectedSchema = originalSchemas.find(
    (schema) => schema.name === schemaName,
  );
  if (!selectedSchema) {
    return;
  }

  const showHideLegends: any = [];

  const allShowingChartLines =
    testingLines && testingLines.length > 0
      ? testingLines
      : document.querySelectorAll(
          `[stroke="#${selectedSchema.plotColor?.toLowerCase()}"]`,
        );

  allShowingChartLines.forEach((line: Element) => {
    const htmlLine = line as HTMLElement;
    if (htmlLine.style.display === "block" || htmlLine.style.display === "") {
      showHideLegends.push({ legend: htmlLine, show: false });
    }
    if (htmlLine.style.display === "none") {
      showHideLegends.push({ legend: htmlLine, show: true });
    }
  });

  showHideLegends.forEach((item: any) => {
    item.legend.style.display = item.show ? "block" : "none";
  });
};

export const showHideDataPointsLegends = (
  testingSvgPathLines?: HTMLCollectionOf<SVGPathElement>,
  testingSvgPatPoints?: NodeListOf<SVGPathElement>,
): void => {
  const svgChartLines = testingSvgPathLines
    ? testingSvgPathLines
    : document.getElementsByTagName("path");

  const hiddenColor = getHiddenColorByChartLines(svgChartLines);

  if (hiddenColor) {
    const allShowingPoints = testingSvgPatPoints
      ? testingSvgPatPoints
      : (document.querySelectorAll(
          `[fill="${hiddenColor}"]`,
        ) as NodeListOf<SVGPathElement>);

    hideSvgPointsOfHiddenLegends(allShowingPoints);
  } else {
    showSvgPointsOfShowingLegends(svgChartLines);
  }
};

export const getHiddenColorByChartLines = (
  testingSvgPathElements: HTMLCollectionOf<SVGPathElement>,
): string => {
  let hiddenColor = "";

  if (!testingSvgPathElements || testingSvgPathElements.length <= 0) {
    return "";
  }

  for (let i = 0; i < testingSvgPathElements.length; i++) {
    const svgPathElement = testingSvgPathElements[i];
    if (
      svgPathElement.hasAttribute("stroke") &&
      svgPathElement.style.display === "none"
    ) {
      hiddenColor = svgPathElement.getAttribute("stroke") || "";
      break;
    }
  }

  return hiddenColor;
};

export const hideSvgPointsOfHiddenLegends = (
  testingSvgPathElements: NodeListOf<Element>,
): void => {
  testingSvgPathElements.forEach((point: Element) => {
    const htmlPoint = point as HTMLElement;
    htmlPoint.style.display = "none";
  });
};

export const showSvgPointsOfShowingLegends = (
  testingSvgPathElements: HTMLCollectionOf<SVGPathElement>,
): void => {
  if (!testingSvgPathElements || testingSvgPathElements.length <= 0) {
    return;
  }

  for (let i = 0; i < testingSvgPathElements.length; i++) {
    const svgPathElement = testingSvgPathElements[i];
    if (
      svgPathElement.hasAttribute("fill") &&
      svgPathElement.style.display === "none"
    ) {
      svgPathElement.style.display = "block";
    }
  }
};

export const DataStreamChartComparison: React.FC<Props> = ({
  content,
  comparisonItems,
  callback,
}) => {
  const timeSeries = useMemo(() => {
    if (content.response.schemas.length <= 0) {
      return;
    }

    dataSource.chart.palettecolors = getChartColors(content.response.schemas);
    dataSource.yaxis = [
      {
        plot: getYaxisPlotsConfig(content.response.schemas),
        title: "kwh",
      },
    ];

    chartConfigs.dataSource.data = new FusionCharts.DataStore().createDataTable(
      content.response.data,
      content.response.schemas,
    );

    chartConfigs.events = {
      rendered: function () {
        removeComparisonLegends();
      },
      legendItemClicked: function (eventObj: any) {
        const schemaName = eventObj.data.datasetName;
        showHideAllLinesByLegendsPlotColor(
          schemaName,
          content.response.schemas,
        );
      },
      dataPlotRollOver: function () {
        showHideDataPointsLegends();
      },
      chartMouseMove: function () {
        showHideDataPointsLegends();
      },
      dataPlotClick: callback,
    };

    return chartConfigs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.response.data, content.response.schemas]);

  if (content.loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "450px",
        }}
      >
        <CircularProgress sx={{ mt: 2 }} />
      </Box>
    );
  }

  if (
    content.response.data.length <= 0 ||
    content.response.schemas.length <= 0
  ) {
    // only show error message if datastream has selected
    if (comparisonItems.length > 0) {
      // eslint-disable-next-line max-len
      const warningMessage = i18n.t("DATA_STREAM_CHART_COMPARISON.WARNING_MESSAGE", {ns: "component"});
      return <Alert
              elevation={6}
              variant="filled"
              severity="warning"
              sx={{
                color: "#764823",
                backgroundColor: "#fdf1e6",
                borderRadius: "4px",
                boxShadow: "inherit",
                fontWeight: "inherit",
                mb: 3,
                "& .MuiAlert-message": {
                  mt: "2px",
                },
              }}
              icon={<WarningIcon sx={{color: "warning.main"}} />}
              data-test={"message-no-data"}
            >{warningMessage}</Alert>
    }
    return null;
  }
  return <ReactFC {...timeSeries} />;
};
