import React, { useCallback, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment-timezone";
import { Box, Stack, TextField, Typography, useTheme } from "@mui/material";
import { AuditLogTable } from "../AuditLogTable";
import { IntervalAuditLogs } from "../IntervalAuditLogs";
import { FeatureFlag } from "../FeatureFlag";
import { DATE_PATTERN } from "../../commons/dates";
import { useTranslation } from "react-i18next";

interface Props {
  meterId: string;
}

export const AuditLog: React.FC<Props> = ({ meterId }) => {
  const { t }  = useTranslation("component");
  const theme = useTheme();
  const [date, setDate] = useState<Moment>(moment());

  const handleDateChange = useCallback(
    (newDate: Moment | null) => {
      if (!newDate || !newDate.isValid() || newDate.isSame(date)) {
        return;
      }

      setDate(newDate);
    },
    [date],
  );

  return (
    <section>
      <Typography data-test = {"audit-log-heading"} variant={"h5"}>{t("AUDIT_LOG.AUDIT_LOG_HEADING")}</Typography>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <Box
          data-test={"log-date"}
          className={"date-picker"}
          display={"flex"}
          alignItems={"center"}
        >
          <Typography sx={{ mr: 1 }}>{t("AUDIT_LOG.READ_DATE")}</Typography>
          <DatePicker
            value={date}
            onChange={handleDateChange}
            inputFormat={DATE_PATTERN}
            renderInput={(params) => (
              <TextField sx={{ maxWidth: 200 }} {...params} />
            )}
            desktopModeMediaQuery={"@media (min-width:368px)"}
          />
        </Box>
        <Typography variant={"h6"} sx={{ mt: theme.spacing(1) }}>
          {t("AUDIT_LOG.INTERVAL_AUDIT_LOG")}
        </Typography>
        <IntervalAuditLogs source={"system"} meterId={meterId} date={date} />
        <FeatureFlag flag={"enable_exceptions"}>
          <Typography variant={"h6"} sx={{ mt: theme.spacing(1) }}>
            {t("AUDIT_LOG.EXCEPTION_AUDIT_LOG")}
          </Typography>
          <AuditLogTable source={"exceptions"} meterId={meterId} date={date} />
        </FeatureFlag>
        <FeatureFlag flag="enable_uds">
          <Typography variant={"h6"} sx={{ mt: theme.spacing(1) }}>
            {t("AUDIT_LOG.UDS_AUDIT_LOG")}
          </Typography>
          <AuditLogTable source={"uds"} meterId={meterId} date={date} />
        </FeatureFlag>
      </Stack>
    </section>
  );
};
