import { useMemo } from "react";
import environment from "../environment";

export const useFeatureFlag = (flag: string): boolean => {
  return useMemo(() => {
    if (!flag) {
      return false;
    }

    const featureFlag = environment.feature_flags[flag];
    return featureFlag === true || featureFlag === "true";

  }, [flag]);
}
