import React from "react";
import { Box, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface Props {
  "data-test"?: string;
  "value-data-test"?: string;
  label: string;
  content: string;
  sx?: SxProps<Theme>,
  contentSx?: SxProps<Theme>,
}

export const NamedItem: React.FC<Props> = ({
  label,
  content,
  sx,
  contentSx,
  ...props
}) => (
  <Box
    {...(props["data-test"] ? { "data-test": props["data-test"] } : {})}
    sx={{ m: 0, p: 0, ...sx }}
  >
    <Typography variant={"body2"} component={"span"} color={"text.label"} sx={{ mr: 1 }}>
      {label}
    </Typography>
    <Typography
      variant={"body2"}
      component={"span"}
      sx={contentSx}
      {...(props["value-data-test"] ? { "data-test": props["value-data-test"] } : {})}
    >
      {content}
    </Typography>
  </Box>
);
