import React, { useCallback, useMemo } from "react";
import { Button, Menu, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isEmpty } from "lodash";
import { Loading } from "../Loading";
import { getDisplayTextOfVersionItem } from "./IntervalVersionDropdownItemText";
import { VersionItem, VersionType } from "../../types/IntervalVersion";
import { IntervalVersionDropdownMenuItem } from "./IntervalVersionDropdownMenuItem";
import { useTranslation } from "react-i18next";

export const hasBorderBottom = (index: number, versions: VersionItem[]): boolean => {
  return (index > 0 && versions.length > index + 1 && versions[index + 1].type === VersionType.UNPROCESSED)
}

export const isAfterBorder = (index: number, versions: VersionItem[]): boolean => {
  return (index > 0 && versions.length > index + 1 && versions[index].type === VersionType.UNPROCESSED)
}

interface Props {
  versions: VersionItem[];
  selectedVersion: VersionItem | undefined;
  anchorEl: HTMLElement | null;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMenuItemClick: (event: React.MouseEvent<HTMLElement>, selectedVersion: VersionItem) => void;
  onClose: () => void;
}

export const IntervalVersionDropdown: React.FC<Props> = ({
  selectedVersion,
  versions,
  anchorEl,
  onClose,
  onClick,
  onMenuItemClick
}) => {
  const { t }  = useTranslation("component");
  const isSelected = useCallback(
    (option: VersionItem): boolean => (selectedVersion === option),
    [selectedVersion]
  );

  const versionsAreEmpty = useMemo(
    () => (isEmpty(versions)),
    [versions]
  );

  const displayText = useMemo(
    () => (getDisplayTextOfVersionItem(selectedVersion) || t("INTERVAL_VERSION_DROPDOWN.INTERVAL_VERSION")),
    [selectedVersion]
  );

  return (
    <>
      <Button
        data-test={"interval-version"}
        disableElevation
        disabled={versionsAreEmpty}
        onClick={onClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{
          textTransform: "none",
          backgroundColor: "#F4F5F7",
          fontWeight: "normal",
          borderRadius: "2px",
          padding: "0 14px",
          color: "black",
          minWidth: "220px",
        }}
      >
        <Typography
          component={"span"}
          sx={{ width: "100%", ...versionsAreEmpty ? { color: "text.secondary" } : {} }}
        >
          {displayText}
        </Typography>
      </Button>
      <Menu
        data-test={"interval-version-menu"}
        MenuListProps={{
          "aria-labelledby": "long-button",
          "dense": true,
        }}
        sx={{
          ".MuiList-root": {
            position: "relative",
            maxHeight: 256,
            ...versionsAreEmpty ? {
              minHeight: 160,
              minWidth: 200,
              overflow: "hidden",
              padding: 0,
            } : {}
          }
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClose}
      >
        {versionsAreEmpty && (<Loading />)}
        {versions.map((option, index) => (
          <IntervalVersionDropdownMenuItem
            key={index}
            versionItem={option}
            onClick={onMenuItemClick}
            selected={isSelected(option)}
            hasBorderBottom={hasBorderBottom(index, versions)}
            isAfterBorder={isAfterBorder(index, versions)}
          />
        ))}
      </Menu>
    </>
  );
}
