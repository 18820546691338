import React, { useState } from "react";
import { DataStreamDetails } from "./DataStreamDetails";
import { useMeterDetails } from "../../hooks/useMeterDetails";
import { EMPTY, Meter } from "../../types/Meter";
import { EMPTY as EMPTY_DATA_STREAM } from "../../types/DataStream";
import { useParams } from "react-router-dom";
import { DataStreamChart } from "../../components/DataStreamChart";
import { DataStreamConsumptionIntervals } from "../../components/DataStreamConsumptionIntervals";
import { DataStreamQualityIntervals } from "../../components/DataStreamQualityIntervals";

type Params = {
  connectionPointId: string;
  meterId: string;
  dataStreamName: string;
  intent: string;
};

export const DataStreamDetailsContainer: React.FC = () => {
  const { connectionPointId = "", meterId = "", dataStreamName = "", intent = "" } = useParams<Params>();
  const [currentState, setState] = useState<Meter>(EMPTY);
  const [, setError] = useState<string>("");
  useMeterDetails(setState, setError, meterId, connectionPointId);
  const dataStream = currentState.dataStreams.filter(d => d.name === dataStreamName).pop();
  const content = dataStream ?? EMPTY_DATA_STREAM;
  const canApplyFutureUds = currentState.dataStreams.length === 1;

  return intent === "QUALITY" ?
    (
      <DataStreamDetails
        connectionPointId={connectionPointId}
        meterId={meterId}
        dataStreamName={dataStreamName}
        intent={intent}
        commodity={currentState.commodity}
        serialNumber={currentState.serialNumber}
        content={content}
        canApplyFutureUds={canApplyFutureUds}
        showApplyScenarioButton={false}>
        {
          <DataStreamQualityIntervals
            meterId={meterId}
            datastreamName={content.name}
            intent={intent}
            unitOfMeasure={content.unitOfMeasure}
          />
        }
      </DataStreamDetails>
    )
    :
    (
      <DataStreamDetails
        connectionPointId={connectionPointId}
        meterId={meterId}
        dataStreamName={dataStreamName}
        intent={intent}
        commodity={currentState.commodity}
        serialNumber={currentState.serialNumber}
        content={content}
        canApplyFutureUds={canApplyFutureUds}
        showApplyScenarioButton={true}
      >
        <DataStreamChart
          meterId={meterId}
          datastreamName={content.name}
          intent={intent}
        />
        <DataStreamConsumptionIntervals
          meterId={meterId}
          datastreamName={content.name}
          intent={intent}
          unitOfMeasure={content.unitOfMeasure}
        />
      </DataStreamDetails>
    );
};


