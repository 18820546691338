import React from "react";
import { Box } from "@mui/material";
import { ConnectionPoint } from "../../types/ConnectionPoint";
import { NamedItem } from "../NamedItem";
import { SeparatedList } from "../SeparatedList";
import { formatDate } from "../../commons/dates";
import { i18n } from "../../global/i18n";

interface Props {
  content: ConnectionPoint;
}

export const ConnectionPointDetails: React.FC<Props> = ({ content }) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <SeparatedList>
        <NamedItem
          data-test={"connectionpoint-number"}
          label={i18n.t("CONNECTION_POINT_DETAILS.NUMBER", { ns: "component" })}
          content={content.number}
        />
        <NamedItem
          data-test={"connectionpoint-status"}
          label={i18n.t("CONNECTION_POINT_DETAILS.STATUS", { ns: "component" })}
          content={content.status ? i18n.t(`CONNECTION_POINT_STATUS_MAP.${content.status}`, { ns: "component" }) : ""}
          contentSx={{
            textTransform: "lowercase",
            display: "inline-block",
            "&::first-letter": {
              textTransform: "uppercase",
            },
          }}
        />
        <NamedItem
          data-test={"connectionpoint-status-effective-date"}
          label={i18n.t("CONNECTION_POINT_DETAILS.STATUS_EFFECTIVE_DATE", {
            ns: "component",
          })}
          content={formatDate(content.changeEffectiveDate)}
        />
        <NamedItem
          data-test={"connectionpoint-retailer"}
          label={i18n.t("CONNECTION_POINT_DETAILS.RETAILER", { ns: "component" })}
          content={content.retailer}
        />
      </SeparatedList>
    </Box>
  );
};
