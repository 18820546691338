import React, { ReactElement } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { NepLayout } from "./pages/NepLayout";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { ExceptionList } from "./pages/ExceptionList";
import { ExceptionPage } from "./pages/ExceptionPage";
import { ReportDownloadPage } from "./pages/ReportDownloadPage";
import { DataStreamDetails } from "./pages/DataStreamDetails";
import { Overlay } from "./components/Overlay";
import { goToConnectionPointSearch, ConnectionPointSearch } from "./pages/ConnectionPointSearch";
import { ConnectionPointPage } from "./pages/ConnectionPointPage";
import { NotFound } from "./pages/NotFound";
import { LoadingProvider } from "./context/LoadingContext";
import { SnackbarMessageProvider } from "./context/SnackbarMessageContext";
import { MeterEventsPage } from "./pages/MeterEventsPage";

function App(): ReactElement {
  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SnackbarMessageProvider>
          <LoadingProvider>
            <NepLayout>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="exception/:exceptionType" element={<ExceptionList />} />
                <Route path="exception" element={<ExceptionPage />} />
                <Route path="connectionpoint/:connectionPointId/meter/:meterId/event" element={<MeterEventsPage />} />
                <Route
                  path="connectionpoint/:connectionPointId/meter/:meterId/datastream/:dataStreamName"
                  element={<Navigate to={"CONSUMPTION"} />}
                />
                <Route
                  path="connectionpoint/:connectionPointId/meter/:meterId/datastream/:dataStreamName/:intent"
                  element={<DataStreamDetails />}
                />
                <Route path="connectionpoint/:connectionPointId/*" element={
                  <Overlay background={<ConnectionPointSearch />} onClose={goToConnectionPointSearch}>
                    <ConnectionPointPage />
                  </Overlay>
                } />
                <Route path="connectionpoint/*" element={<ConnectionPointSearch />} />
                <Route path={"*"} element={<NotFound />} />
                <Route path="download" element={<ReportDownloadPage />} />
              </Routes>
            </NepLayout>
          </LoadingProvider>
        </SnackbarMessageProvider>
      </LocalizationProvider>
    </Router>
  );
}

export default App;
