import env from "../environment";
import { LoginToken } from "../types/LoginToken";

export const retrieveLoginToken = async (authorizationCode: string): Promise<LoginToken> =>
  fetch(
    `https://${env.auth.domain}/oauth2/token`,
    {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/x-www-form-urlencoded" }),
      body: new URLSearchParams({
        client_id: env.auth.clientId,
        grant_type: "authorization_code",
        code: authorizationCode,
        redirect_uri: env.auth.redirectUri
      })
    }
  ).then((response) => response.json());

export const refreshLoginToken = async (refreshToken: string): Promise<LoginToken> =>
  fetch(
    `https://${env.auth.domain}/oauth2/token`,
    {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/x-www-form-urlencoded" }),
      body: new URLSearchParams({
        client_id: env.auth.clientId,
        grant_type: "refresh_token",
        refresh_token: refreshToken
      })
    }
  ).then((response) => response.json());
