import React from "react";
import { Box, Stack, useTheme } from "@mui/material";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { Moment } from "moment-timezone";
import { DateRange } from "@mui/x-date-pickers-pro";
import { formatDate } from "../../commons/dates";
import { i18n } from "../../global/i18n";

interface Props {
    firstDateRange: DateRange<Moment>;
    secondDateRange: DateRange<Moment> | null;
    openPopup: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const DateRangeLabel: React.FC<Props> = ({ firstDateRange, secondDateRange, openPopup }) => {
    const theme = useTheme();
    const firstStartDate = firstDateRange[0]?.toDate();
    const firstEndDate = firstDateRange[1]?.toDate();
    const secondStartDate = secondDateRange && secondDateRange[0]?.toDate();
    const secondEndDate = secondDateRange && secondDateRange[1]?.toDate();

    return (
        <>
            <Stack
                direction={"row"}
                sx={{
                    alignItems: "center",
                    backgroundColor: "#f4f5f7",
                    height: "32px",
                    borderRadius: 1,
                    pl: 2, pr: 1, py: 1,
                    mt: 2, mr: 2
                }}
                onClick={openPopup}
            >
                <Box>{ firstStartDate ? formatDate(firstStartDate) : "" }</Box>
                <Box sx={{ width: "18px", textAlign: "center" }}> - </Box>
                <Box>{ firstEndDate ? formatDate(firstEndDate) : "" }</Box>
                <InsertInvitationIcon sx={{ ml: 1, "path": { fill: theme.palette.text.secondary } }} />
            </Stack>
            {
                secondDateRange &&
                <Stack
                    direction={"row"}
                    sx={{
                        alignItems: "center",
                        fontSize: "0.9rem",
                        mt: 1
                    }}
                >
                    <Box>{i18n.t("DATE_RANGE_LABEL.COMPARE", { ns: "component" })}: {
                      secondStartDate ? formatDate(secondStartDate) : "" }
                    </Box>
                    <Box sx={{ width: "18px", textAlign: "center" }}> - </Box>
                    <Box>{ secondEndDate ? formatDate(secondEndDate) : "" }</Box>
                </Stack>
            }
        </>
    );
};
