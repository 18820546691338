import React from "react";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SearchResultTag } from "../SearchResultTag";


interface Props {
  tag: string;
  tagColor: string;
  text: string;
  hyperLink: string;
}


export const HyperlinkTextWithTag: React.FC<Props> = ({tag, tagColor, text, hyperLink}) => {
  return <>
    <SearchResultTag color={tagColor}>{tag}</SearchResultTag>
    <Link variant={"body2"} to={hyperLink} underline={"hover"} component={RouterLink}>
      {text}
    </Link>
  </>;
};
