export interface SnapshotState {
  dateTime: string;
  dataStreamName: string;
  snapshotValue: string;
}

export interface Snapshots {
  snapshots: SnapshotState[];
}

export const EMPTY: Snapshots = {
  snapshots: [],
}