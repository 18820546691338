export interface IntervalVersion {
  unprocessedVersion: UnprocessedVersion;
  processedVersions: ProcessedVersion[];
}

export interface UnprocessedVersion {
  version: number;
  enabled: boolean;
}

export interface ProcessedVersion {
  version: number;
  onMarket: boolean;
}

export interface VersionItem {
  type: string;
  version: number;
  isLatest: boolean;
  isLatestOnMarket: boolean;
  onMarket: boolean;
  enabled: boolean;
}

export enum VersionType  {
  PROCESSED = "processed",
  UNPROCESSED = "unprocessed",
}

export const EMPTY_LIST: IntervalVersion[] = [];

export interface LatestVersion {
  processed: number;
  unprocessed: number;
}
