import React from "react";
import { Stack, Typography } from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";

interface Props {
  activeCount: number;
  inActiveCount: number;
}

export const StatusCount: React.FC<Props> = ({ activeCount, inActiveCount }) => (
  <Stack
    direction="row"
    spacing={1}
    alignItems="center"
  >
    <CheckCircle style={{ fill: green[500], width: "19px" }} />
    <Typography component={"span"} data-test={"status-active-count"}>
      {activeCount}
    </Typography>
    <Cancel style={{ fill: red[500], width: "19px" }} />
    <Typography component={"span"} data-test={"status-inactive-count"}>
      {inActiveCount}
    </Typography>
  </Stack>
);
