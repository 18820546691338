import { useCallback, useEffect, useState } from "react";
import { findLast, isEmpty } from "lodash";
import { Moment } from "moment-timezone";
import { IntervalVersion, VersionItem, VersionType } from "../types/IntervalVersion";
import { retrieveDataStreamIntervalVersions } from "./retrieveDataStreamIntervalVersions";

export const convertToVersionItems = (versions: IntervalVersion[]): VersionItem[] => {

  versions.sort((a, b) =>
    a.unprocessedVersion.version - b.unprocessedVersion.version);

  const versionsForDisplay: VersionItem[] = [];

  // Build VersionItems for display on dropdown
  versions.forEach((version) => {
    const unprocessedVersion: VersionItem = {
      type: VersionType.UNPROCESSED,
      version: version.unprocessedVersion.version,
      isLatest: false,
      isLatestOnMarket: false,
      onMarket: false,
      enabled: version.unprocessedVersion.enabled,
    }
    versionsForDisplay.push(unprocessedVersion);

    version.processedVersions.forEach((item) => {
      const processedVersion: VersionItem = {
        type: VersionType.PROCESSED,
        isLatest: false,
        isLatestOnMarket: false,
        enabled: true,
        ...item,
      }
      versionsForDisplay.push(processedVersion);
    });
  });

  if (isEmpty(versionsForDisplay)) {
    return versionsForDisplay;
  }

  versionsForDisplay[versionsForDisplay.length - 1].isLatest = true;

  const latestOnMarketVersionItem = findLast(versionsForDisplay, versionItem => versionItem.onMarket)
  if (latestOnMarketVersionItem) {
    latestOnMarketVersionItem.isLatestOnMarket = true;
  }

  return versionsForDisplay;
}

interface Props {
  meterId: string;
  datastreamName: string;
  intent: string;
  startDate: Moment;
  endDate: Moment;
  setLoading: (loading: boolean) => void;
  setError: (error: string) => void;
}

export interface DataStreamIntervalVersionsHook {
  setSelectedVersion: (state: VersionItem | undefined) => void
  setLoading: (loading: boolean) => void;
  versionItems: VersionItem[];
  selectedVersion: VersionItem | undefined;
  loading: boolean;
}

export const useDataStreamIntervalVersions = ({
  meterId,
  datastreamName,
  intent,
  startDate,
  endDate,
  setLoading,
  setError,
}: Props): DataStreamIntervalVersionsHook => {
  const [versions, setVersions] = useState<IntervalVersion[]>([]);
  const [versionItems, setVersionItems] = useState<VersionItem[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<VersionItem | undefined>();
  const [loadingVersions, setLoadingVersions] = useState<boolean>(true);
  const [isProcessed, setIsProcessed] = useState<boolean>(false);

  const handleSetVersions = useCallback((newVersions) => {
    setVersions(newVersions);
    setLoadingVersions(false);
  }, []);

  const handleSetError = useCallback((error) => {
    setError(error);
    setVersions([]);
    setLoadingVersions(false);
  }, [setError]);

  useEffect(() => {
    if (!meterId || !datastreamName || !startDate.isValid() || !endDate.isValid()) {
      return;
    }

    setLoadingVersions(true);
    setIsProcessed(false);
    setVersionItems([]);
    setSelectedVersion(undefined);
    setLoading(true);
    retrieveDataStreamIntervalVersions(
      handleSetVersions,
      handleSetError,
      meterId,
      datastreamName,
      intent,
      startDate.toISOString(),
      endDate.toISOString()
    );
  
    // using date.toString() for trigger exactly when date change, cuz using moment will return new Object and will pass the deps checking
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meterId, datastreamName, startDate.toString(), endDate.toString()]);

  useEffect(() => {
    if (loadingVersions) {
      return;
    }

    if (isEmpty(versions)) {
      setIsProcessed(true);
      return;
    }

    const versionsForDisplay = convertToVersionItems(versions);
    setSelectedVersion(versionsForDisplay[versionsForDisplay.length - 1]);
    setVersionItems(versionsForDisplay);
    setIsProcessed(true);
  }, [versions, loadingVersions]);

  return {
    loading: loadingVersions || !isProcessed,
    setSelectedVersion,
    setLoading,
    versionItems,
    selectedVersion,
  }
}
