import { EMPTY, MeterExceptions } from "../types/MeterExceptions";
import { retrieveMeterExceptionsState } from "../services/MeterExceptionService";

export const useExceptions = (
  setState: (state: MeterExceptions) => void,
  meterId: string,
): Promise<void> =>
  retrieveMeterExceptionsState(meterId)
  .then(setState)
  .catch(() => {
    setState(EMPTY)
  });
