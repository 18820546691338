import { AuditLogs } from "../types/AuditLogs";
import { getStartOfDateString, toDayRange } from "../commons/dates";

export const retrieveAuditLogs = async (
  source: string,
  meterId: string,
  connectionPointId: string,
  date: string,
): Promise<AuditLogs> => {
  const connectionPointIdQueryString =
    source === "uds" ? `&connectionPointId=${connectionPointId}` : "";

  return fetch(
    `/service/v1/meter/${meterId}/auditlog?source=${source}${connectionPointIdQueryString}&` +
      new URLSearchParams(toDayRange(getStartOfDateString(date))),
    {
      headers: new Headers({
        "Content-Type": "application/json; charset=utf-8",
      }),
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`);
    }
    return response.json();
  });
};
