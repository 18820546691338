import React, { useState } from "react";
import { EMPTY, ExceptionCounts } from "../../types/ExceptionCounts";
import { ExceptionPage } from "./ExceptionPage";
import { useExceptionPage } from "../../hooks/useExceptionPage";
import { Skeleton } from "@mui/material";

export const ExceptionPageContainer: React.FC = () => {

  const [currentState, setState] = useState<ExceptionCounts>(EMPTY);
  useExceptionPage(setState);

  if (currentState === EMPTY) {
    return <>
      <Skeleton animation="wave"/>
      <Skeleton animation="wave"/>
      <Skeleton animation="wave"/>
    </>
  } else {
    return <ExceptionPage content={currentState}/>;
  }

};
