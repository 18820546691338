import React, { ReactNode, useCallback } from "react";
import { SnackbarKey, useSnackbar } from "notistack";
import { AlertColor } from "@mui/material/Alert";
import { SnackbarMessage } from "../components/SnackbarMessage";

interface ShowSnackMessageProps {
  type?: AlertColor;
  onClose?: () => void;
  showExpandData?: boolean;
  keepShowing?: boolean;
  dataTestPrefix?: string;
  title: string | ReactNode;
  body?: string | ReactNode;
}

interface SnackbarMessageProps {
  showSnackMessage: (param: ShowSnackMessageProps) => void;
}

export const DEFAULT = Object.freeze({
  type: 'success' as AlertColor,
  onClose: () => {
    // do nothing
  },
  showExpandData: false,
  keepShowing: false,
  dataTestPrefix: "global",
  body: null,
});

export const useSnackbarMessage = (): SnackbarMessageProps => {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSnackMessage = useCallback(({
    type,
    onClose,
    showExpandData,
    keepShowing,
    dataTestPrefix,
    title,
    body
  }: ShowSnackMessageProps) => {

    const keyHolder: { key: SnackbarKey } = {
      key: -1
    }

    const handleClose = () => {
      if (onClose) {
        onClose();
      }
      closeSnackbar(keyHolder.key);
    }

    const action = (key: SnackbarKey) => {
      keyHolder.key = key;
      return null;
    }

    enqueueSnackbar((
      <SnackbarMessage
        title={title}
        onClose={handleClose}
        type={type || DEFAULT.type}
        dataTestPrefix={dataTestPrefix || DEFAULT.dataTestPrefix}
        showExpandData={showExpandData || DEFAULT.showExpandData}
        keepShowing={keepShowing || DEFAULT.keepShowing}
      >
        {body}
      </SnackbarMessage>
    ),
      {
        variant: "default",
        autoHideDuration: (showExpandData || keepShowing) ? null : 3000,
        action: action,
        onClose: (event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          if (onClose) {
            onClose();
          }
        }
      }
    );

  }, [enqueueSnackbar, closeSnackbar]);

  return { showSnackMessage };

}
