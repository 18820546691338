import React from "react";
import { useViewableIntent } from "../../hooks/useViewableIntent";
import { Link } from "react-router-dom";


interface Props {
  name: string;
  link: string;
  intent: string;
}

export const DataStreamLink: React.FC<Props> = ({
  name, link, intent,
}) => {
  return useViewableIntent(intent) ?
    <Link data-test="data-stream-link" to={link}>{name}</Link> : <>{name}</>;
};
