/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import {
  CHART_COLORS,
  COMPARISON_SUFFIX,
  DynamicChartLine,
} from "../../types/DataStreamChartComparisonState";
import { ComparisonItem } from "../../types/ComparisonItem";
import { SelectItemType } from "../../types/SelectItemType";
import { formatDateTime } from "../../commons/dates";

const ARRAY_CHART_COLOR = CHART_COLORS.split(",");
interface Props {
  data: DynamicChartLine[];
  comparisonItems: ComparisonItem[];
  eventId: number;
  compareOption: SelectItemType | null;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTable = (
  comparisonItems: ComparisonItem[],
  compareOption: SelectItemType | null,
  data: DynamicChartLine[],
) => {
  const headers = comparisonItems.map((item) => ({
    label: item.datastreamName,
    name: `${item.datastreamName} ${item.meterId.replace(
      "-EDMI-ELECTRICITY",
      "",
    )}`,
    type: "line",
  }));
  if (compareOption) {
    headers.forEach((header) => {
      headers.push({
        label: header.label,
        name: `${header.name} ${COMPARISON_SUFFIX}`,
        type: "dot-line",
      });
    });
  }
  const rows = data.map((item) => {
    const tmp = {
      ...item,
      time: formatDateTime(new Date(item.timestamp)),
      [`time ${COMPARISON_SUFFIX}`]: "",
    };
    if (compareOption && item[`timestamp ${COMPARISON_SUFFIX}`]) {
      tmp[`time ${COMPARISON_SUFFIX}`] = formatDateTime(
        new Date(item[`timestamp ${COMPARISON_SUFFIX}`]),
      );
    }
    return tmp;
  });
  return { headers, rows };
};

export const TableChartComparison: React.FC<Props> = ({
  data,
  comparisonItems,
  eventId,
  compareOption,
}) => {
  const [table, setTable] = useState(
    getTable(comparisonItems, compareOption, data),
  );

  useEffect(() => {
    setTable(getTable(comparisonItems, compareOption, data));
  }, [eventId]);

  return (
    <Box marginTop={2}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F5F5F5", boxShadow: "none", paddingX: 5 }}
      >
        <Table sx={{ minWidth: 650 }} data-test="table-chart-comparison">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: "none", pd: 0 }}></TableCell>
              {table.headers
                .filter((item) => item.type === "line")
                .map((item) => (
                  <TableCell
                    sx={{ borderBottom: "none", pb: 0 }}
                    key={item.name}
                    data-test="hovered-datastream-name"
                  >
                    {item.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>

          {table.rows.map((row: any, index) => (
            <TableBody key={index}>
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  className="cell-date-time"
                  sx={{ fontWeight: 500 }}
                  component="th"
                  scope="row"
                  data-test="hovered-date-time"
                >
                  {row.time}
                </TableCell>

                {table.headers
                  .filter((item) => item.type === "line")
                  .map((header, index) => (
                    <TableCell
                      className="cell-datastream-value"
                      key={header.name + header.type}
                      align="left"
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        data-test="hovered-interval-value"
                      >
                        <FiberManualRecordIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            color: `#${ARRAY_CHART_COLOR[index]}`,
                            marginRight: "10px",
                          }}
                        />
                        <div>{row[header.name]} / S</div>
                      </Box>
                    </TableCell>
                  ))}
              </TableRow>
              {compareOption && (
                <TableRow
                  key={row[`time ${COMPARISON_SUFFIX}`]}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    className="cell-date-time"
                    sx={{ fontWeight: 500 }}
                    component="th"
                    scope="row"
                  >
                    {row[`time ${COMPARISON_SUFFIX}`]}
                  </TableCell>

                  {table.headers
                    .filter((item) => item.type === "dot-line")
                    .map((header, index) => (
                      <TableCell
                        className="cell-datastream-value"
                        key={header.name + header.type}
                        align="left"
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          data-test="hovered-interval-value-dotted-line"
                        >
                          <FiberManualRecordOutlinedIcon
                            sx={{
                              width: "16px",
                              height: "16px",
                              color: `#${ARRAY_CHART_COLOR[index]}`,
                              marginRight: "10px",
                            }}
                          />
                          <div>{row[header.name]} / S</div>
                        </Box>
                      </TableCell>
                    ))}
                </TableRow>
              )}
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </Box>
  );
};
