import * as React from "react";
import { ReactNode } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  useTheme,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";

type Props = {
  icon: React.ReactElement<SvgIconProps>;
  to: string;
  prefix?: string;
  children: ReactNode;
};

export const NavItem: React.FC<Props> = ({ icon, to, prefix, children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ".nav-link": {
          textDecoration: "none",
          color: "inherit",
        },
        ".nav-link.active .MuiListItem-root": {
          backgroundColor: theme.palette.action.selected,
        },
        ".nav-link:hover .MuiListItem-root": {
          backgroundColor: theme.palette.action.selected,
        },
      }}
    >
      <NavLink
        className={"nav-link"}
        to={to}
        color="inherit"
        data-test={prefix ? `${prefix}-menu-item` : null}
      >
        <ListItem>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={children} />
        </ListItem>
      </NavLink>
    </Box>
  );
};
