/* eslint-disable @typescript-eslint/no-explicit-any */
import { getEmail } from "../commons/userInfo";
import moment from "moment-timezone";

export const actionExport = async (
  connectionPointId: string,
  dateRange: { start: string, end: string },
): Promise<any> => {

  // set timezone to GMT+0 before toISOString
  const requestDateRange = {
    startTime: moment(dateRange.start).tz("Etc/GMT+0").startOf("day").toISOString(),
    endTime: moment(dateRange.end).tz("Etc/GMT+0").endOf("day").toISOString()
  };

  return fetch(
    `/service/v1/extract/report/connectionpoint/${connectionPointId}?`
      + new URLSearchParams(requestDateRange) + `&requestUserId=${getEmail()}`,
    {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
    }
  ).then((response) => {
    if (!response.ok) {
      throw new Error("500")
    }
    return response.json();
  });
}
