import { useEffect } from "react";
import { EMPTY, MeterEventsState } from "../types/MeterEvents";
import { DateRange } from "@mui/x-date-pickers-pro";
import { Moment } from "moment-timezone";
import { retrieveMeterEvents } from "../services/MeterEventsService";

export const useMeterEvents = (
  setState: (state: MeterEventsState) => void,
  setError: (state: string) => void,
  meterId: string,
  dateRange: DateRange<Moment>
): void => {

  useEffect(() => {

    if (!meterId) {
      setState(EMPTY);
      return;
    }

    retrieveMeterEvents(meterId, dateRange[0], dateRange[1])
      .then(meterEventsResponse => {
        setState(meterEventsResponse);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          return;
        }
        setError(err.message)
      })

  }, [setState, meterId, dateRange]);
}
