import React, { useEffect, useState } from "react";
import { ExceptionList } from "./ExceptionList";
import { useExceptionList } from "../../hooks/useExceptionList";
import { EMPTY, ExceptionListState } from "../../types/ExceptionListState";
import { useParams } from "react-router-dom";
import { NotFound } from "../NotFound";

type Params = {
  exceptionType: string;
}

export const ExceptionListContainer: React.FC = () => {
  const {exceptionType = ""} = useParams<Params>();
  const [currentState, setState] = useState<ExceptionListState>(EMPTY);
  const [error, setError] = useState<string>("");
  useEffect(() => {
    useExceptionList(setState, setError, exceptionType)
  }, [exceptionType]);
  if (error === "404") {
    return <NotFound />
  }

  return <ExceptionList
    content={currentState}
    type={exceptionType}
    postUpdate={() => useExceptionList(setState, setError, exceptionType)} />;
};
