export interface DataStreamChartData {
  Time: string;
  [x: string]: string;
}
export const EMPTY_CHART: DataStreamChartData[] = [];

export interface DataStreamChartState {
  loading: boolean;
  data: DataStreamChartData[];
}

export interface DateRange {
  start: Date;
  end: Date;
}
