import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import { i18n } from "../../global/i18n";

interface Props {
    onClose: () => void;
    onApply: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isValidDateRange: boolean;
}

export const DateRangeAction: React.FC<Props> = ({ onClose, onApply, isValidDateRange }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: theme.spacing(2),
                flex: "0 0 auto",
                fontWeight: 500,
                fontSize: "0.8125rem"
            }}
        >
            <Button
                size="small"
                onClick={onClose}
                sx={{ ml: 1, textTransform: "capitalize" }}
            >
                {i18n.t("DATE_RANGE_ACTION.CANCEL", {ns: "component"})}
            </Button>
            <Box sx={{ marginLeft: theme.spacing(1) }}>
                <Button
                    disabled={!isValidDateRange}
                    size="small"
                    onClick={onApply}
                    variant="contained"
                    sx={{ ml: 1, textTransform: "capitalize" }}
                    data-test={"chart-comparison-apply-button"}
                >
                    {i18n.t("DATE_RANGE_ACTION.APPLY", {ns: "component"})}
                </Button>
            </Box>
        </Box>
    );
};
