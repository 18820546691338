import React from "react";
import { styled } from "@mui/styles";
import { styleFn } from "styled-system";
import theme from "../theme";

export const withSeparator: styleFn = (Component: React.ElementType, extraStyle?: Record<string, unknown>) => {
  return styled(Component)(() => ({
      position: "relative",
      '&:after': {
        content: '""',
        height: "70%",
        width: "1px",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        borderRight: `2px solid ${theme.palette.custom.border}`,
        position: "absolute",
        right: 0,
        top: "50%",
        transform: "translateY(-50%)",
        ...extraStyle,
      },
    }));
}
