import { IntervalConsumption, IntervalsConsumption } from "../types/IntervalConsumption";
import { retrieveDefaultIntervals } from "../services/IntervalService";
import { mapExceptionTypeDisplayValue } from "../mappers/exceptionTypeMapper";
import {i18n} from "../global/i18n";

const MISSING_DATA_ENDS_WITH = "rule applied";
export const MISSING_DATA = i18n.t("EXCEPTION_TYPE_MAPPING.MISSING_DATA", {ns: "component"});

export function mapIntervalCause(interval: IntervalConsumption): void {
  const cause = interval.cause as string;
  // convert substitutions to "Missing Data" and map validation rules to exception types
  const causes = Array.from(new Set(cause.split(",")
    .map(c => c.toLowerCase().endsWith(MISSING_DATA_ENDS_WITH) ? MISSING_DATA : mapExceptionTypeDisplayValue(c))));

  if (causes.length === 1 && causes[0] === MISSING_DATA) {
    // if the converted list contains only "Missing Data", set it as the cause
    interval.cause = MISSING_DATA;
    interval.isException = false;
  } else {
    // if the converted list contains any exceptions types, drop all "Missing Data" and set to the cause, and set isException to true
    interval.cause = causes.filter(c => c !== MISSING_DATA).join(",");
    interval.isException = true;
  }
}

export const retrieveDataStreamDefaultIntervals = (
  setState: (state: IntervalsConsumption) => void,
  setError: (state: string) => void,
  meterId: string,
  datastreamName: string,
  intent: string,
  version: number,
  date: string,
  type: string,
): void => {

  if (!datastreamName) {
    return;
  }

  retrieveDefaultIntervals(
    meterId,
    datastreamName,
    intent,
    version,
    date,
    type,
  ).then(intervalsConsumptionResponse => {
    intervalsConsumptionResponse.intervals
      .filter(interval => interval.cause)
      .forEach(mapIntervalCause);
    setError("");
    setState(intervalsConsumptionResponse);
  }).catch((err) => {
    if (err.name === "AbortError") {
      return;
    }
    setError(err.message)
  });
}
