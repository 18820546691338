import React from "react";
import { Chip } from "@mui/material";
import { CheckCircle, Info, MoreHoriz } from "@mui/icons-material";
import { green, orange } from "@mui/material/colors";
import { i18n } from "../../global/i18n";

interface Props {
  content: string;
}


export const ExceptionStatusChip: React.FC<Props> = ({content}) => {
  switch (content) {
    case "Closed":
      return (
        <Chip variant={"outlined"}
              size={"small"}
              icon={<CheckCircle style={{fill: green[500]}} />}
              label={i18n.t("EXCEPTIONS.STATUS.CLOSED", {ns: "component"})}
              data-test={"EXCEPTIONS.STATUS.CLOSED"}
        />
      )
    case "In Progress":
      return (
        <Chip variant={"outlined"}
              size={"small"}
              icon={<MoreHoriz />}
              label={i18n.t("EXCEPTIONS.STATUS.IN_PROGRESS", {ns: "component"})}
              data-test={"EXCEPTIONS.STATUS.IN_PROGRESS"}
        />
      )
    case "Open":
      return (
        <Chip variant={"outlined"}
              size={"small"}
              icon={<Info style={{fill: orange[500]}} />}
              label={i18n.t("EXCEPTIONS.STATUS.OPEN", {ns: "component"})}
              data-test={"EXCEPTIONS.STATUS.OPEN"}
        />
      );
    default:
      return (null);
  }
};
