import { useMemo } from "react";

import environment from "../environment";

const MissingConfigDefault = false;

export const useViewableIntent = (
  intent: string
): boolean => {

  return useMemo(() => {
    const viewableIntents: Array<string> = environment.viewableIntents;
    const isValidConfig = viewableIntents && Array.isArray(viewableIntents);

    return isValidConfig ? viewableIntents.includes(intent) : MissingConfigDefault;
  }, [intent]);
}


