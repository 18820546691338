import { AuditLogs, EMPTY } from "../types/AuditLogs";
import { retrieveAuditLogs } from "../services/AuditLogService";
import { useEffect } from "react";
import { useSnackbarMessage } from "./useSnackbarMessage";
import { mapAuditSourceDisplayValue } from "../mappers/auditSourceMapper";
import { i18n } from "../global/i18n";

export const useAuditLogs = (
  setState: (state: AuditLogs) => void,
  source: string,
  meterId: string,
  connectionPointId: string,
  date: string,
): void => {
  const { showSnackMessage } = useSnackbarMessage();

  useEffect(() => {
    retrieveAuditLogs(source, meterId, connectionPointId, date)
      .then((auditLogs) => {
        if (auditLogs) {
          setState(auditLogs);
        } else {
          setState(EMPTY);
        }
      })
      .catch(() => {
        setState(EMPTY);
        showSnackMessage({
          type: "error",
          title: i18n.t("AUDIT_LOG.ERROR_RESPONSE", {ns: "component", source: mapAuditSourceDisplayValue(source)}),
          keepShowing: true,
        });
      });
  }, [source, meterId, connectionPointId, date, setState, showSnackMessage]);
};
