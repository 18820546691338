import moment, { Moment } from "moment-timezone";
import { Meter } from "../types/Meter";
import {
  getStartOfDay,
  getStartOfToday,
  getStartOfYesterday,
  getUnitOfWorkTimeStampOf,
  isEndOfDay,
  isMidNight,
  toMomentFromDateString
} from "../commons/dates";
import { ManualSubstitutionDataStream } from "../types/ManualSubstitutionDataStream";

import { DateRangeTypes } from "../types/DateRangeTypes";
import { initialDateRangeState } from "../components/UserDefinedScenarioDialog/UserDefinedScenarioDialog";

const {PARTIAL_DAY} = DateRangeTypes;

export const retrieveMeterState = async (meterId: string, connectionPointId: string): Promise<Meter> =>
  fetch(`/service/v1/meter/${meterId}?connectionpointid=${connectionPointId}`, {
    headers: new Headers({"Content-Type": "application/json; charset=utf-8"}),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("404")
    }
    return response.json();
  });

export interface ManualSubstitutionRequest {
  connectionPointId: string;
  meterId: string;
  dataStreams: ManualSubstitutionDataStream [];
  dateFrom: Moment;
  dateTo: Moment;
  userId: string;
  scenario: string;
  scenarioLabel: string;
  qualityFlag: string;
  qualityDescription: string;
  reasonCode: string;
  reasonCodeDescription: string;
  methods: string[];
}

export interface ManualSubstitutionRequestBody {
  connectionPointId: string;
  dataStreams: ManualSubstitutionDataStream [];
  dateFrom?: string;
  dateTo?: string;
  reprocessingDateFrom?: string;
  reprocessingDateTo?: string;
  userId: string;
  scenario: string;
  scenarioLabel: string;
  qualityFlag: string;
  qualityDescription: string;
  reasonCode: string;
  reasonCodeDescription: string;
  readDateRange?: {
    firstReadDate: string,
    lastReadDate: string,
  },
  readingDateTime?: string,
  methods: string[],
}

export const applyManualSubstitution = async (dateRangeType: string, request: ManualSubstitutionRequest): Promise<string> => {

  const bodyData = preparePostBodyForManualSubstitutionRequest(dateRangeType, request);

  return fetch(
    `/service/v1/meter/${request.meterId}/substitution`,
    {
      method: "POST",
      headers: new Headers({"Content-Type": "application/json; charset=utf-8"}),
      body: JSON.stringify(bodyData),
    }
  ).then((response) => {

    if (!response.ok) {
      throw new Error("500");
    }
    return response.json();

  });
}

export const isFutureDateRange = (dateFromMoment: moment.Moment): boolean => {
  return dateFromMoment.isSameOrAfter(getStartOfToday());
}

export const isDefaultDateTime = (dateFromMoment: moment.Moment, dateToMoment: moment.Moment): boolean => {
  const [initialDateTimeFrom, initialDateTimeTo] = initialDateRangeState;
  return (
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    dateFromMoment.isSame(toMomentFromDateString(initialDateTimeFrom!.toISOString()))
    &&
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    dateToMoment.isSame(toMomentFromDateString(initialDateTimeTo!.toISOString()))
  );
}

export const processDateTimeFrom = (dateFromMoment: moment.Moment): Moment => {
  return isMidNight(dateFromMoment)
    ? moment(dateFromMoment).add(1, "seconds")
    : dateFromMoment;
}

function processDateTimeTo(dateToMoment: moment.Moment): Moment {
  return isEndOfDay(dateToMoment)
    ? getStartOfDay(dateToMoment).add(1, "days")
    : dateToMoment
}

export const getReprocessingDateTimeBoundary = (dateToMoment: moment.Moment): string => {
  const yesterdayUnitOfWorkTimeStamp = getStartOfToday();
  return dateToMoment.isSameOrAfter(yesterdayUnitOfWorkTimeStamp)
    ? yesterdayUnitOfWorkTimeStamp.format()
    : getUnitOfWorkTimeStampOf(dateToMoment);
}

function convertToPartialDayPayload(
  dateFromMoment: moment.Moment,
  dateToMoment: moment.Moment,
) {

  const [reprocessingDateTimeFrom, reprocessingDateTimeTo] = isDefaultDateTime(dateFromMoment, dateToMoment)
    ? [processDateTimeFrom(getStartOfYesterday()), processDateTimeTo(getStartOfToday())]
    : [processDateTimeFrom(dateFromMoment), processDateTimeTo(dateToMoment)];

  const readingDateTimeString = getUnitOfWorkTimeStampOf(reprocessingDateTimeFrom);

  return {
    reprocessingDateFrom: reprocessingDateTimeFrom.format(),
    reprocessingDateTo: reprocessingDateTimeTo.format(),
    readingDateTime: readingDateTimeString,
    selectedDateTimeFrom: reprocessingDateTimeFrom.format(),
    selectedDateTimeTo: dateToMoment.format(),
  }
}

function convertToDateRangePayload(
  dateFromMoment: moment.Moment,
  dateToMoment: moment.Moment,
) {
  const dateFromUnitOfWorkTimeStamp = getUnitOfWorkTimeStampOf(dateFromMoment);
  const dateToUnitOfWorkTimeStamp = getUnitOfWorkTimeStampOf(dateToMoment);

  // the DDS endpoint didn't want the start date to be in Unit of Work date,
  // so we're passing the date that is displayed in the date picker
  const ddsDateFromTimestamp = getStartOfDay(dateFromMoment).format();

  if (isFutureDateRange(dateFromMoment)) {
    return {
      // full date range for DDS
      dateFrom: ddsDateFromTimestamp,
      dateTo: dateToUnitOfWorkTimeStamp,
      selectedDateTimeFrom: dateFromMoment.format(),
      selectedDateTimeTo: dateToMoment.endOf('day').format(),
    };
  }

  const reprocessingDateFromString = dateFromUnitOfWorkTimeStamp;
  const reprocessingDateToString = getReprocessingDateTimeBoundary(dateToMoment);

  // past - past
  // past - future
  return {
    // full date range for DDS
    dateFrom: ddsDateFromTimestamp,
    dateTo: dateToUnitOfWorkTimeStamp,
    // past date range for SNS
    reprocessingDateFrom: reprocessingDateFromString,
    reprocessingDateTo: reprocessingDateToString,
    readDateRange: {
      firstReadDate: reprocessingDateFromString,
      lastReadDate: reprocessingDateToString,
    },
    selectedDateTimeFrom: dateFromMoment.format(),
    selectedDateTimeTo: dateToMoment.endOf('day').format(),
  };
}

export const preparePostBodyForManualSubstitutionRequest = (dateRangeType: string, {
  connectionPointId,
  dataStreams,
  dateFrom,
  dateTo,
  userId,
  scenario,
  scenarioLabel,
  qualityFlag,
  qualityDescription,
  reasonCode,
  reasonCodeDescription,
  methods
}: ManualSubstitutionRequest): ManualSubstitutionRequestBody | undefined => {

  const bodyWithoutDates = {
    connectionPointId,
    dateRangeType,
    dataStreams,
    userId,
    scenario,
    scenarioLabel,
    qualityFlag,
    qualityDescription,
    reasonCode,
    reasonCodeDescription,
    methods
  };

  const dateFromMoment = toMomentFromDateString(dateFrom.toISOString());
  const dateToMoment = toMomentFromDateString(dateTo.toISOString());

  const bodyWithDates = dateRangeType === PARTIAL_DAY
    ? convertToPartialDayPayload(dateFromMoment, dateToMoment)
    : convertToDateRangePayload(dateFromMoment, dateToMoment)

  return {
    ...bodyWithoutDates,
    ...bodyWithDates
  };
}
