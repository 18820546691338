import React, { useCallback, useEffect, useState } from "react";
import moment, { Moment } from "moment-timezone";
import { DataStreamQualityIntervals } from "./DataStreamQualityIntervals";
import { retrieveDataStreamQualityIntervals } from "../../hooks/useDataStreamQualityIntervals";
import { EMPTY, IntervalsQuality } from "../../types/IntervalQuality";

interface Props {
  meterId: string;
  datastreamName: string;
  intent: string;
  unitOfMeasure: string;
}

export const defaultDate: Moment = moment().subtract(1, "days");

export const DataStreamQualityIntervalsContainer: React.FC<Props> = (
  {
    meterId,
    datastreamName,
    unitOfMeasure
  }) => {
  const [currentState, setState] = useState<IntervalsQuality>(EMPTY);
  const [, setError] = useState<string>("");
  const [date, setDate] = useState<Moment>(defaultDate);
  const [loading, setLoading] = useState(true);

  const handleSetState = useCallback((intervals: IntervalsQuality) => {
    setLoading(false);
    setState(intervals);
  }, []);

  const handleSetError = useCallback((error: string) => {
    setError(error);
    setLoading(false);
    setState(EMPTY);
  }, []);

  const handleChangeDate = useCallback((selectedDate: Moment) => {
    setDate(selectedDate);
  }, []);

  useEffect(() => {
    setState(EMPTY);
  }, [date]);

  useEffect(() => {
      setLoading(true);

      retrieveDataStreamQualityIntervals(
        handleSetState,
        handleSetError,
        meterId,
        datastreamName,
        date.toISOString()
      );
  }, [date, handleSetState, handleSetError, meterId, datastreamName]);

  return (
    <DataStreamQualityIntervals
      loading={loading}
      content={currentState}
      date={date}
      onSelectDate={handleChangeDate}
      unitOfMeasure={unitOfMeasure}
    />
  );
};
