import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { MenuButtonCompareData } from "../../components/MenuButtonCompareData";
import { GroupAddComparison } from "../../components/GroupAddComparison";
import { DataStreamChartComparison } from "../../components/DataStreamChartComparison";
import { ComparisonItem } from "../../types/ComparisonItem";
import { i18n } from "../../global/i18n";

interface Props {
  openSideNav?: boolean;
}

interface IState {
  openDialog: boolean;
  data: ComparisonItem[];
}

export const CompareDataPage: React.FC<Props> = ({ openSideNav = true }) => {
  const [state, setState] = useState<IState>({
    openDialog: false,
    data: [],
  });

  const onOpenDialog = () => {
    setState({ ...state, data: [], openDialog: true });
  };
  const onCloseDialog = () => {
    setState({ ...state, data: [], openDialog: false });
  };

  const onChangeData = (data: ComparisonItem[]) => {
    setState({ ...state, data });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "48px",
        }}
      >
        <MenuButtonCompareData
          onClick={onOpenDialog}
          openSideNav={openSideNav}
        />
      </Box>
      <Dialog
        fullScreen
        open={state.openDialog}
        data-test="comparison-dialog"
        sx={{
          ".MuiDialog-paper": {
            backgroundColor: "#FFF",
            pb: 5,
          },
        }}
      >
        <Box data-test="main-container" flexDirection="column">
          <Box
            sx={{ display: "flex" }}
            justifyContent="flex-end"
            padding="0 8px"
            data-test="tool-bar"
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={onCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box data-test="compare-container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 200px",
            }}
          >
            <Box component="span" mb={1.25}>
              <Typography sx={{ fontSize: "24px" }} data-test={"chart-comparison-title"}>
                {i18n.t("COMPARE_DATA_PAGE.TITLE", {ns: "page"})}
              </Typography>
              <Typography sx={{ pt: 1 }} variant={"body2"}>
                {i18n.t("COMPARE_DATA_PAGE.DISCLAIMER", {ns: "page"})}
              </Typography>
            </Box>
            <Box component="span" mt={2.0}
                sx={{
                    fontFamily: "Roboto",
                    fontStyle: "Mixed",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20.02px",
                    letterSpacing: 0.0125,
                    color: "rgba(0, 0, 0, 0.38)",
                }}
            >
                <Box component="span"
                    sx={{ fontWeight: 500, lineHeight: "21.98px", color: "rgba(0, 0, 0, 0.87)", }}
                >
                    {state.data.length}
                </Box> {i18n.t("COMPARE_DATA_PAGE.OUT_OF_ITEM", {ns: "page", "maxItem": 2})}
            </Box>
          </Box>
          <GroupAddComparison onChangeData={onChangeData} />
          <Box sx={{ padding: "0 200px" }}>
            <DataStreamChartComparison params={state.data} />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
