import React from "react";
import { Typography } from "@mui/material";


interface Props {
  color: string;
  fonstSize?: string;
}


export const SearchResultTag: React.FC<Props> = ({color, fonstSize, children}) => {
  return <Typography sx={{
    display: "inline-block",
    backgroundColor: color,
    mr: 2, pl: 1, pr: 1,
    borderRadius: "4px",
    fontSize: fonstSize
  }} component={"span"}>{children}</Typography>;
};
