import { getStartOfDateString, toDateRange, toDayRange } from "../commons/dates";
import { IntervalVersion } from "../types/IntervalVersion";
import { IntervalsConsumption } from "../types/IntervalConsumption";
import { IntervalsQuality } from "../types/IntervalQuality";

let intervalsAbortController: AbortController | null = null;
let intervalVersionsAbortController: AbortController | null = null;

export const cancelRetrieveIntervals = (): void => {
  if (intervalsAbortController) {
    intervalsAbortController.abort();
  }
  intervalsAbortController = null;
}

export const retrieveDefaultIntervals = async (
  meterId: string,
  datastreamName: string,
  intent: string,
  version: number,
  date: string,
  type: string,
): Promise<IntervalsConsumption> => {
  if (intervalsAbortController) {
    intervalsAbortController.abort();
  }
  intervalsAbortController = new AbortController();
  return fetch(
    `/service/v1/meter/${meterId}/datastream/${datastreamName}/${intent}/interval?` +
    new URLSearchParams({
      ...toDayRange(getStartOfDateString(date)),
      version: version.toString(),
      type,
    }),
    {
      signal: intervalsAbortController.signal,
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
    }
  ).then((response) => {
    intervalsAbortController = null;

    if (!response.ok) {
      throw new Error("404")
    }
    return response.json();
  });
}

export const retrieveQualityIntervals = async (
  meterId: string,
  datastreamName: string,
  date: string,
): Promise<IntervalsQuality> => {
  if (intervalsAbortController) {
    intervalsAbortController.abort();
  }
  intervalsAbortController = new AbortController();
  return fetch(
    `/service/v1/meter/${meterId}/datastream/${datastreamName}/QUALITY/interval?` +
    new URLSearchParams({
      ...toDayRange(getStartOfDateString(date))
    }),
    {
      signal: intervalsAbortController.signal,
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
    }
  ).then((response) => {
    intervalsAbortController = null;

    if (!response.ok) {
      throw new Error("404")
    }
    return response.json();
  });
}

export const retrieveIntervalVersions = async (
  meterId: string,
  datastreamName: string,
  intent: string,
  startDate: string,
  endDate: string,
): Promise<IntervalVersion[]> => {
  if (intervalVersionsAbortController) {
    intervalVersionsAbortController.abort();
  }
  intervalVersionsAbortController = new AbortController();
  return fetch(
    `/service/v1/meter/${meterId}/datastream/${datastreamName}/${intent}/version?` +
    new URLSearchParams({
      startTime: toDayRange(getStartOfDateString(startDate)).startTime,
      endTime: toDayRange(getStartOfDateString(endDate)).endTime,
    }),
    {
      signal: intervalVersionsAbortController.signal,
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
    }
  ).then((response) => {
    intervalVersionsAbortController = null;

    if (!response.ok) {
      throw new Error("404")
    }
    return response.json().then(res => res.versions);
  });
};

export const retrieveChartIntervalVersions = (
  meterId: string,
  datastreamName: string,
  intent: string,
  startDate: string,
  endDate: string,
): Promise<IntervalVersion[]> => {
 
  return fetch(
    `/service/v1/meter/${ meterId }/datastream/${ datastreamName }/${intent}/version?` +
    new URLSearchParams({
      startTime: toDayRange(getStartOfDateString(startDate)).startTime,
      endTime: toDayRange(getStartOfDateString(endDate)).endTime,
    }),
    {
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
    }
  ).then((response) => {
    if (!response.ok) {
      throw new Error("404")
    }
    return response.json().then(res => res.versions);
  });
};

export const retrieveChartIntervals = async (
  meterId: string,
  datastreamName: string,
  intent: string,
  dateRange: {
    start: string;
    end: string;
  },
  version: number,
  type: string
): Promise<IntervalsConsumption> => {
  return fetch(
    `/service/v1/meter/${meterId}/datastream/${datastreamName}/${intent}/interval?` +
      new URLSearchParams({
        ...toDateRange(getStartOfDateString(dateRange.start), getStartOfDateString(dateRange.end)),
        version: version.toString(),
        type
      }),
    {
      headers: new Headers({
        "Content-Type": "application/json; charset=utf-8",
      }),
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error("404");
    }
    return response.json();
  });
};
