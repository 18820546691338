import React, { ReactElement, ReactNode } from "react";
import { Box, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../theme";
import { Location, useLocation, useParams, useSearchParams } from "react-router-dom";
import { Params } from "react-router";

interface Props {
  background: ReactElement;
  children: ReactNode;
  onClose: (params: Params, location: Location) => () => void;
}

export const Overlay: React.FC<Props> = ({ background, children, onClose }) => {
  const [ searchParams ] = useSearchParams();
  const overlay = Boolean(searchParams.get("overlay"));
  const params = useParams();
  const location = useLocation();
  return !overlay
    ? (
      <Box sx={ { p: 3.5 } }>
        { children }
      </Box>
    )
    : (
      <>
        <Box>
          { background }
        </Box>
        <Drawer
          data-test={"overlay-detail"}
          anchor="right"
          open={overlay}
          sx={{
            zIndex: theme.zIndex.snackbar,
            width: "50%",
            bottom: "initial",
            left: "initial"
          }}
          BackdropProps={{
            sx: {
              backgroundColor: "transparent",
              width: "50%",
              bottom: "initial",
              left: "initial"
            }
          }}
          PaperProps={{
            sx: {
              p: 3.5,
              width: "50%",
              bottom: "initial",
              left: "initial",
              backgroundColor: theme.palette.background.default
            }
          }}
        >
          <Box>
            <IconButton
              data-test={"close-overlay"}
              onClick={onClose(params, location)}
              sx={{
                pt: 0.5,
                pl: 3,
                pr: 0,
                pb: 0,
                display: "flex",
                justifyContent: "flex-end",
                float: "right"
              }}
            >
              <CloseIcon/>
            </IconButton>
            { children }
          </Box>
        </Drawer>
      </>
    );
};
