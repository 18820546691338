import React, { useCallback } from "react";
import { Moment } from "moment-timezone";
import { InputLabel, Stack } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DateRangeTypes } from "../../types/DateRangeTypes";
import { UserDefinedScenarioToggle } from "./UserDefinedScenarioToggle";
import { DateRangePicker } from "../DateRangePicker";
import { DateTimeRangePicker } from "../DateTimeRangePicker";
import { FormControlLabel } from "../FormControlLabel";
import { FormControlContent } from "../FormControlContent";

interface Props {
  label?: string;
  dateRangeType?: string;
  onDateRangeTypeChange: (dateRangeType: string) => void;
  onDateRangeChanged: (dateRage: DateRange<Moment>) => void;
  canSelectFuture?: boolean;
}

const { PARTIAL_DAY, MULTIPLE_DAYS } = DateRangeTypes;

export const DateTimeRangeBlock: React.FC<Props> = ({
  label = "Date/time range",
  dateRangeType = MULTIPLE_DAYS,
  onDateRangeTypeChange,
  onDateRangeChanged,
  canSelectFuture = true,
}) => {

  const handleDateRangeTypeChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, selectedDateRangeType: string) => {
      if (selectedDateRangeType) {
        onDateRangeTypeChange(selectedDateRangeType);
      }

    },
    [onDateRangeTypeChange]
  );

  return (
    <Stack direction={"row"}>
      <FormControlLabel>
        <InputLabel sx={{ overflow: "visible", transform: "translate(8px, 12px)" }}>{label}</InputLabel>
      </FormControlLabel>
      <FormControlContent>
        <Stack direction={"column"}>
          <UserDefinedScenarioToggle data-test={"date-range-toggle"} onChange={handleDateRangeTypeChange} value={dateRangeType} />
          {MULTIPLE_DAYS === dateRangeType && (
            <DateRangePicker onDateRangeChanged={onDateRangeChanged} canSelectFuture={canSelectFuture} />
          )}
          {PARTIAL_DAY === dateRangeType && (
            <DateTimeRangePicker onDateRangeChanged={onDateRangeChanged} />
          )}
        </Stack>
      </FormControlContent>
    </Stack>
  );
}
