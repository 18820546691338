import React, { useEffect } from "react";
import { useDateToISOString } from "../../hooks/useDateToISOString";
import { Moment } from "moment-timezone";
import { useIntervalAuditLogs } from "../../hooks/useIntervalAuditLogs";
import { useSnackbarMessage } from "../../hooks/useSnackbarMessage";
import { IntervalAuditLogs } from "./IntervalAuditLogs";
import { mapAuditSourceDisplayValue } from "../../mappers/auditSourceMapper";
import { i18n } from "../../global/i18n";

interface Props {
  source: string;
  meterId: string;
  date: Moment | null;
}

const source = "system";

export const IntervalAuditLogsContainer: React.FC<Props> = ({
  meterId,
  date,
}) => {
  const { showSnackMessage } = useSnackbarMessage();
  const isoDateString = useDateToISOString(date);
  const { loading, error, intervalAuditLogs } = useIntervalAuditLogs(meterId, isoDateString);

  useEffect(() => {
    if (error) {
      showSnackMessage({
        type: "error",
        title: i18n.t("AUDIT_LOG.ERROR_RESPONSE", {ns: "component", source: mapAuditSourceDisplayValue(source)}),
        keepShowing: true,
      });
    }
  }, [error, showSnackMessage]);

  return (
    <IntervalAuditLogs source={source} content={intervalAuditLogs} loading={loading} />
  );
};
