import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { MeterEventsBreadCrumbs } from "../MeterEventsBreadCrumbs";
import { i18n } from "../../global/i18n";
import { MeterStatus } from "../MeterStatus";
import { Meter } from "../../types/Meter";


interface Props {
  connectionPointId: string;
  meter: Meter;
  timezone: string;
}

export const MeterEventsHeader: React.FC<Props> = ({
  connectionPointId,
  meter,
  timezone
}) => {
  return (
    <>
      <Stack direction={"row"} justifyContent="space-between">
        <MeterEventsBreadCrumbs connectionPointId={connectionPointId}
                                meterId={meter.id}
                                serialNumber={meter.serialNumber}
                                commodity={meter.commodity} />
      </Stack>
      <Box sx={{ pt: 1.5, pb: 0.5 }}>
        <Typography sx={{ pb: 1 }} variant={"h5"} data-test={"datastream-heading"}>
          {i18n.t("METER_EVENTS_PAGE.METER", { ns: "component" })} - {meter.serialNumber}
        </Typography>
        <MeterStatus content={ meter } timezone={ timezone } />
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
    </>
  );
};
