export interface ConnectionPointHeaderState {
  address: ConnectionPointAddress;
  timeZone: string;
  market: string;
}

export interface ConnectionPointAddress {
  postcode: string;
  country: string;
}

export const EMPTY: ConnectionPointHeaderState = {
  address: {
    postcode: "",
    country: "",
  },
  timeZone: "",
  market: "",
}
