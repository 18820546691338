/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { SearchResultComparison } from "./SearchResultComparison";
import { SearchResultComparisonState } from "../../types/SearchResultComparisonState";
import { CALLBACK_NAME } from "../GroupAddComparison/GroupAddComparison";
import { ConnectionPoints } from "../../types/ConnectionPoints";
import { useFetchMeterByConnectionPointId } from "../../hooks/useSearchResultComparison";
import { ConnectionPoint } from "../../types/ConnectionPoint";
import { Box } from "@mui/material";

interface Props {
  maxItems: number;
  connectionPointId: string;
  meterId: string;
  datastreamSelected: Set<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (type: CALLBACK_NAME, payload?: any) => void;
}

export const SearchResultComparisonContainer: React.FC<Props> = ({
  maxItems,
  connectionPointId,
  meterId,
  datastreamSelected,
  callback,
}) => {
  const [meterState, setMeterState] =
    useState<SearchResultComparisonState | null>(null);
  const [loading, setLoading] = useState(false);

  useFetchMeterByConnectionPointId(setLoading, setMeterState, callback, connectionPointId);

  return (
    <Box
      sx={{ backgroundColor: "#FFFFFF" }}
      data-test={"search-result-comparison-popup"}
    >
      <SearchResultComparison
        maxItems={maxItems}
        loading={loading}
        connectionPointId={connectionPointId}
        meterId={meterId}
        datastreamSelected={datastreamSelected}
        content={meterState}
        callback={callback}
      />
    </Box>
  );
};
