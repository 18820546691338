import React, { useCallback } from "react";
import { MenuItem } from "@mui/material";
import { VersionItem } from "../../types/IntervalVersion";
import { getTypeName, IntervalVersionDropdownItemText } from "./IntervalVersionDropdownItemText";

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement>, selectedVersion: VersionItem) => void;
  selected: boolean;
  versionItem: VersionItem;
  hasBorderBottom: boolean,
  isAfterBorder: boolean,
}

export const IntervalVersionDropdownMenuItem: React.FC<Props> =({
  onClick,
  selected,
  versionItem,
  hasBorderBottom,
  isAfterBorder
}: Props) => {

  const handleClick = useCallback(
    (event) => onClick(event, versionItem),
    [onClick, versionItem]
  );

  return (
    <MenuItem
      onClick={handleClick}
      selected={selected}
      data-test={getTypeName(versionItem.type) + versionItem.version}
      disabled={!versionItem.enabled}
      sx={{
        lineHeight: "20px",
        letterSpacing: "0.15px",
        minHeight: "40px",
        ...hasBorderBottom ? {
          borderBottom: "1px solid",
          borderColor: "custom.border",
          paddingBottom: 2,
        } : {},
        ...isAfterBorder ? {
          paddingTop: 2,
        } : {},
      }}
    >
      <IntervalVersionDropdownItemText versionItem={versionItem} />
    </MenuItem>
  );
}