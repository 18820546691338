/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useMemo } from "react";
import { Box, CircularProgress } from "@mui/material";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { DataStreamChartState } from "../../types/DataStreamChartState";
import env from "../../environment";
import {
  PROCESSED_LABEL,
  UNPROCESSED_LABEL,
} from "../../hooks/useDataStreamChart";
import { i18n } from "../../global/i18n";

const KMW_LABEL = i18n.t("DATA_STREAM_CHART.KWH", {ns: "component"});
const FUSION_DATE_TIME_PATTERN = i18n.t("FUSION_TIME_DATE_TIME_PATTERN", {ns: "component"});
interface Props {
  content: DataStreamChartState;
}

export const activeLicense = (chartOptions: any) => {
  chartOptions.license({
    key: env.fusionChartLicenseKey,
    creditLabel: false,
  });
  return chartOptions;
};

ReactFC.fcRoot(FusionCharts, TimeSeries, FusionTheme);

FusionCharts.options = activeLicense(FusionCharts.options);

export const getCommonChartConfig = (extraTooltipConfigs?: any) => ({
  xaxis: {
    binning: {
      year: [],
      month: [],
      day: [],
      hour: [1],
      minute: [5],
      second: [],
      millisecond: [],
    },
  },
  extensions: {
    customRangeSelector: {
      enabled: "0",
    },
    standardRangeSelector: {
      enabled: "0",
    },
  },
  navigator: {
    enabled: 1,
  },
  tooltip: {
    enabled: "true", // enabled customization
    outputTimeFormat: {
      minute: FUSION_DATE_TIME_PATTERN,
      hour: FUSION_DATE_TIME_PATTERN,
      day: FUSION_DATE_TIME_PATTERN,
    },
    style: {
      container: {
        padding: "4px",
      },
      text: {
        "font-size": "12px",
      },
    },
    ...extraTooltipConfigs,
  },
});

export const dataSource: any = {
  chart: {
    palettecolors: "0DCF5A,0B79D0",
    enableChartMouseMoveEvent: "1",
  },
  yaxis: [
    {
      plot: [
        {
          value: PROCESSED_LABEL,
          aggregation: "First",
          connectnulldata: true,
        },
        {
          value: UNPROCESSED_LABEL,
          aggregation: "First",
          connectnulldata: true,
        },
      ],
      title: KMW_LABEL,
    },
  ],
  ...getCommonChartConfig({
    toolText: `<div data-test="datastream-chart-tooltip">
        <b data-test="datastream-chart-tooltip-header">$binStart</b>
        <div data-test="datastream-chart-tooltip-processed"
            style="padding-top:4px; display: flex; justify-content: space-between;">
          <span>$series.0.name:</span> <span>$series.0.dataValue</span>
        </div>
        <div data-test="datastream-chart-tooltip-unprocessed"
            style="padding-top:4px; display: flex; justify-content: space-between;">
          <span>$series.1.name:</span> <span>$series.1.dataValue</span>
        </div>
      </div>`,
  }),
};

const schema = [
  {
    name: PROCESSED_LABEL,
    type: "number",
  },
  {
    name: UNPROCESSED_LABEL,
    type: "number",
  },
  {
    name: "Time",
    type: "date",
    format: FUSION_DATE_TIME_PATTERN,
  },
];

const chartConfigs = {
  id: "datastream-chart",
  type: "timeseries",
  renderAt: "container",
  width: "100%",
  height: "450",
  dataSource,
};

export const DataStreamChart: React.FC<Props> = ({ content }) => {
  const timeSeries = useMemo(() => {
    const fusionTable = new FusionCharts.DataStore().createDataTable(
      content.data,
      schema,
    );
    chartConfigs.dataSource.data = fusionTable;

    return chartConfigs;
  }, [content.data]);

  if (content.loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "450px",
        }}
      >
        <CircularProgress sx={{ mt: 2 }} />
      </Box>
    );
  }

  if (content.data.length <= 0) {
    return <Box sx={{ height: "400" }}>{i18n.t("DATA_STREAM_CHART.NO_DATA", {ns: "component"})}</Box>;
  }

  return <ReactFC {...timeSeries} />;
};
