/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React, { ReactNode, useEffect, useMemo, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AuditLog,
  CollapsibleContent,
  CollapsibleHeader,
  CollapsibleHeaderValue,
  RowExpanded,
  CustomFormatContentFields,
} from "../../types/AuditLog";
import { AuditLogs } from "../../types/AuditLogs";
import { CSSProperties } from "styled-components";
import {
  convertToJsonString,
  isJson,
  prettyJson,
  prettyJsonExcludedFields,
} from "../../commons/jsons";
import { AuditLogDataGrid } from "../AuditLog/AuditLogDataGrid";
import {
  formatDate,
  formatDateTime,
  formatToYesterdayDefaultTimezone,
} from "../../commons/dates";

interface Props {
  source: string;
  content: AuditLogs;
  loading: boolean;
  headers: CollapsibleHeader[];
  excludedFields?: string[];
  dateContentFields?: string[];
  dateTimeContentFields?: string[];
  customFormatContentFields?: CustomFormatContentFields;
}

const headerStyle = {
  headerClassName: "auditlog-grid-header-column--header",
  flex: 1,
};

export const readDateOrder = 1;
export const headerLineCount = 1.8;
export const extraPaddingLines = 6;
export const firstHeaderColumnIndex = 1;

export const renderCollapsibleContentCell = ({
  value,
}: GridRenderCellParams): ReactNode => (
  <Box sx={{ overflow: "hidden", width: "100%" }}>{value}</Box>
);

export const createCollapsibleRowContent = (
  json: string,
  headers: CollapsibleHeader[],
  dateContentFields: string[],
  dateTimeContentFields: string[],
  customFormatContentFields?: CustomFormatContentFields,
): CollapsibleContent => {
  let nestedLevel = 0;
  let allLineCounts = 0;
  const headerValues: CollapsibleHeaderValue[] = [];

  const createHtmlContent = (
    json: string,
    css?: CSSProperties,
  ): React.ReactNode[] => {
    const content = JSON.parse(json);

    if (customFormatContentFields) {
      customFormatContentFields.dependantFields =
        mapValuesForCustomDependantFields(
          customFormatContentFields.dependantFields,
          content,
        );
    }

    const htmlContent = Object.keys(content).map((key) => {
      const value = content[key];
      let displayLine = value;

      addHeaderValues(key, value, headers, headerValues);

      displayLine = formatContentValue(key, value, {
        dateContentFields,
        dateTimeContentFields,
        customFormatContentFields,
      });

      allLineCounts++;

      if (value) {
        const isJsonString = isNaN(value) && isJson(value);
        const isJsonObject = typeof value === "object";

        if (isJsonString || isJsonObject) {
          nestedLevel++;
          const jsonValue = convertToJsonString(isJsonObject, value);
          displayLine = createHtmlContent(jsonValue, {
            paddingLeft: "24px",
          });
        }
      } else {
        displayLine = "N/A";
      }

      return (
        <div
          key={key}
          style={{
            paddingTop: "2px",
            paddingBottom: "2px",
            ...(css || null),
          }}
        >
          <Box
            sx={{
              pr: 1,
              display: "inline-block",
              ":first-letter": { textTransform: "uppercase" },
            }}
          >
            {key}:
          </Box>
          {displayLine}
        </div>
      );
    });

    return htmlContent;
  };

  const htmlContent = createHtmlContent(json);
  const lineCounts = returnLineCounts(allLineCounts, nestedLevel);

  return {
    headerValues: headerValues,
    lines: lineCounts,
    content: <Box sx={{ pl: 4, pt: 2 }}>{htmlContent}</Box>,
  };
};

export const mapValuesForCustomDependantFields = (
  dependantFields: {
    key: string;
    value?: any;
  }[],
  content: any,
): { key: string; value?: any }[] => {
  const mappedFields = dependantFields.map((field) => {
    field.value = content[field.key];
    return field;
  });
  return mappedFields;
};

export const formatContentValue = (
  key: string,
  value: any,
  formats: {
    dateContentFields: string[];
    dateTimeContentFields: string[];
    customFormatContentFields?: CustomFormatContentFields;
  },
): string => {
  const {
    dateContentFields,
    dateTimeContentFields,
    customFormatContentFields,
  } = formats;

  if (
    customFormatContentFields &&
    customFormatContentFields.formatFields.includes(key)
  ) {
    value = customFormatContentFields.formatFn(
      customFormatContentFields.formatFields,
      customFormatContentFields.dependantFields,
      {
        key,
        value,
      },
    );
  } else if (dateContentFields.length > 0 && dateContentFields.includes(key)) {
    value = formatDate(value);
  } else if (
    dateTimeContentFields.length > 0 &&
    dateTimeContentFields.includes(key)
  ) {
    value = formatDateTime(value);
  }

  return value;
};

export const addHeaderValues = (
  logKey: string,
  logValue: any,
  headers: CollapsibleHeader[],
  headerValues: CollapsibleHeaderValue[],
): void => {
  if (headers.some((header) => header.field === logKey)) {
    const existingHeaderValue = headerValues.find(
      (headerValue) => headerValue.key === logKey,
    );
    if (!existingHeaderValue) {
      headerValues.push({ key: logKey, value: logValue?.toString() || "" });
    }
  }
};

export const returnLineCounts = (
  allLineCounts: number,
  nestedLevel: number,
) => {
  const lineCounts =
    allLineCounts +
    extraPaddingLines +
    (nestedLevel > 2 ? nestedLevel + 0.6 : nestedLevel) * 0.6;
  return lineCounts;
};

export const setHeaderValues = (
  headers: CollapsibleHeader[],
  headerValues: CollapsibleHeaderValue[],
  timestamp?: string | undefined,
): CollapsibleHeader[] => {
  const getFirstColumnValue = (
    firstColumnHeader: CollapsibleHeader,
  ): string | JSX.Element => {
    if (firstColumnHeader?.getValueFromJson) {
      const value = firstColumnHeader.getValueFromJson(headerValues);
      return value;
    }

    const value =
      firstColumnHeader.format === "date"
        ? formatToYesterdayDefaultTimezone(timestamp)
        : timestamp;

    return value || "";
  };

  const mappedHeaders = headers.map((header) => {
    if (header.order === firstHeaderColumnIndex) {
      header.value = getFirstColumnValue(header);
      return header;
    }

    header.value = headerValues?.find(
      (headerValue) => headerValue.key === header.field,
    )?.value;

    if (header.format === "date") {
      header.value = formatDate(header.value);
    }

    if (header.format === "dateTime") {
      header.value = formatDateTime(header.value);
    }

    if (header.customFormat) {
      header.value = header.customFormat(header.value, headerValues);
    }

    return header;
  });

  return mappedHeaders;
};

export const returnSortedHeaders = (
  headers: CollapsibleHeader[],
): CollapsibleHeader[] => {
  const sortedHeaders: CollapsibleHeader[] = headers.sort(
    (compare: CollapsibleHeader, compareTo: CollapsibleHeader) => {
      if (compare.order < compareTo.order) {
        return -1;
      }
      if (compare.order > compareTo.order) {
        return 1;
      }
      return 0;
    },
  );
  return sortedHeaders;
};

const renderHeaderColumns = (
  headers: CollapsibleHeader[],
  rowIndex: number,
  rowsExpandedState: {
    rows: RowExpanded[];
    setRows: (state: RowExpanded[]) => void;
  },
): React.ReactNode => {
  const width = 100 / headers.length + "%";
  const sortedHeaders = returnSortedHeaders(headers);

  return (
    <TableRow>
      <TableCell sx={{ p: 0, border: "none" }}>
        <Table>
          <TableBody>
            <TableRow>
              {sortedHeaders.map((header) => {
                if (header.order === firstHeaderColumnIndex) {
                  return renderCollapsibleHeaderColumn(
                    rowIndex,
                    width,
                    header,
                    rowsExpandedState,
                  );
                } else if (header.renderCustomHeaderColumn) {
                  return header.renderCustomHeaderColumn(
                    width,
                    header.field,
                    header.value,
                  );
                } else {
                  return renderCommonHeaderColumn(
                    width,
                    header.field,
                    header.value,
                  );
                }
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  );
};

export const isRowExpanded = (index: number, rows: RowExpanded[]): boolean =>
  rows.find((row) => row.index === index)?.expanded || false;

export const renderCollapsibleHeaderColumn = (
  rowIndex: number,
  width: string,
  header: CollapsibleHeader,
  rowsExpandedState: {
    rows: RowExpanded[];
    setRows: (state: RowExpanded[]) => void;
  },
): React.ReactNode => {
  const { rows, setRows } = rowsExpandedState;
  const currentRow = rowsExpandedState.rows.find(
    (row) => row.index === rowIndex,
  );

  const isExpanded = isRowExpanded(rowIndex, rows);

  const setExpanded = () => {
    const updatedExpandedRows = rows.map((row) => {
      if (row.index === currentRow?.index) {
        row.expanded = !currentRow?.expanded;
      }
      return row;
    });
    setRows(updatedExpandedRows);
  };

  return (
    <TableCell
      className="collapsible-grid-sub-header-column--header"
      key={header.field}
      sx={{
        p: 0,
        fontWeight: 500,
        maxWidth: { width },
        minWidth: { width },
        border: "none",
        whiteSpace: "break-spaces",
      }}
    >
      <IconButton
        aria-label="expand row"
        size="small"
        sx={{ padding: "3px", verticalAlign: "top" }}
        onClick={setExpanded}
      >
        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      <Box
        sx={{ display: "inline-block", marginTop: "6px", maxWidth: "190px" }}
      >
        {header.value}
      </Box>
    </TableCell>
  );
};

export const renderCommonHeaderColumn = (
  width: string,
  field: string,
  value: string | JSX.Element | undefined,
): React.ReactNode => {
  return (
    <TableCell
      className="collapsible-grid-sub-header-column--header"
      key={field}
      sx={{
        p: 0,
        maxWidth: { width },
        minWidth: { width },
        border: "none",
        whiteSpace: "break-spaces",
      }}
      data-test={field}
    >
      <Box sx={{ ml: 3.75 }}>{value}</Box>
    </TableCell>
  );
};

const renderAuditLog = (
  headerColumns: React.ReactNode,
  content: React.ReactNode,
  isExpanded: boolean,
): React.ReactNode => {
  return (
    <TableContainer sx={{ borderRadius: "4px 4px 0px 0px" }}>
      <Table>
        <TableBody>
          {headerColumns}
          <TableRow>
            <TableCell colSpan={4} sx={{ p: 0, borderBottom: "none" }}>
              <Collapse in={isExpanded} timeout={{ enter: 500, exit: 0 }}>
                {content}
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const returnGridColumns = (
  headers: CollapsibleHeader[],
): GridColDef[] => {
  const columns: GridColDef[] = headers.map((header) => {
    if (header.order === firstHeaderColumnIndex) {
      return {
        field: header.field,
        headerName: header.headerName,
        colSpan: headers.length,
        sortable: false,
        renderCell: renderCollapsibleContentCell,
        ...headerStyle,
      };
    } else {
      return {
        field: header.field,
        headerName: header.headerName,
        sortable: false,
        ...headerStyle,
      };
    }
  });
  return columns;
};

export const getFirstColumnName = (headers: CollapsibleHeader[]): string => {
  const columnName =
    headers.find((header) => header.order === firstHeaderColumnIndex)?.field ||
    "";
  return columnName;
};

export const renderRow = (
  index: number,
  item: AuditLog,
  headers: CollapsibleHeader[],
  rowsExpandedState: {
    rows: RowExpanded[];
    setRows: (state: RowExpanded[]) => void;
  },
  excludedFields: string[],
  dateContentFields: string[],
  dateTimeContentFields: string[],
  customFormatContentFields?: CustomFormatContentFields,
) => {
  const logs =
    excludedFields.length > 0
      ? prettyJsonExcludedFields(item.log, excludedFields)
      : prettyJson(item.log);

  const { headerValues, content, lines } = createCollapsibleRowContent(
    logs,
    headers,
    dateContentFields,
    dateTimeContentFields,
    customFormatContentFields,
  );

  const headerColumnsMappedValues = setHeaderValues(
    headers,
    headerValues,
    item.timestamp,
  );

  const headerColumns = renderHeaderColumns(
    headerColumnsMappedValues,
    index,
    rowsExpandedState,
  );

  const isExpanded = isRowExpanded(index, rowsExpandedState.rows);
  const logContent = renderAuditLog(headerColumns, content, isExpanded);
  const firstColumnName = getFirstColumnName(headers);

  return {
    id: index,
    lineCounts: isExpanded ? lines + headerLineCount : headerLineCount,
    ...{ [firstColumnName]: logContent },
  };
};

export const AuditLogCollapsibleTable: React.FC<Props> = ({
  source,
  content,
  loading,
  headers,
  excludedFields = [],
  dateContentFields = [],
  dateTimeContentFields = [],
  customFormatContentFields,
}) => {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rowsExpanded, setRowsExpanded] = useState<RowExpanded[]>([]);

  useEffect(() => {
    const expandedRows: RowExpanded[] = [];

    content.auditLogs.forEach((item: any, index: number) => {
      expandedRows.push({ index: index, expanded: false });
    });

    if (expandedRows.length > 0) {
      setRowsExpanded(expandedRows);
    }
  }, [content]);

  useEffect(() => {
    const columns = returnGridColumns(headers);
    setColumns(columns);
  }, [headers]);

  const rows = useMemo(() => {
    return content.auditLogs
      .filter((item) => item.log)
      .map((item, index) => {
        const row = renderRow(
          index,
          item,
          headers,
          {
            rows: rowsExpanded,
            setRows: setRowsExpanded,
          },
          excludedFields,
          dateContentFields,
          dateTimeContentFields,
          customFormatContentFields,
        );
        return row;
      });
  }, [
    content,
    rowsExpanded,
    headers,
    excludedFields,
    dateContentFields,
    dateTimeContentFields,
    customFormatContentFields,
  ]);

  return (
    <AuditLogDataGrid
      source={source}
      containerDataTest={`${source}-audit-log-rows`}
      rows={rows}
      columns={columns}
      loading={loading}
    />
  );
};
