import React, { ReactNode } from "react";
import { AppBar, Box, Toolbar, Badge, IconButton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import logo from "../../assets/images/logo.svg";
import Popover from "@mui/material/Popover";
import { LanguageSelector } from "../LanguageSelector";
import environment from "../../environment";

type Props = {
  avatarText: string;
  children: ReactNode;
  showIcons?: boolean;
};

export const Header: React.FC<Props> = ({
  avatarText,
  children,
  showIcons = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (environment.feature_flags.enable_language_selection === true || environment.feature_flags.enable_language_selection === "true") {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "background.default",
          color: "text.primary",
          boxShadow: "none",
          borderBottom: "1px solid #ccc",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          {children}
          <Box
            component="img"
            alt="Vector Logo."
            src={logo}
            sx={{ maxWidth: 95 }}
          />
          <Box sx={{ flex: 1 }} />
          <Box>
            {showIcons && (
              <>
                <IconButton size="large" color="inherit">
                  <SearchIcon />
                </IconButton>
                <IconButton size="large" color="inherit">
                  <HelpIcon />
                </IconButton>
                <IconButton size="large" color="inherit">
                  <Badge badgeContent={0} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </>
            )}
            <IconButton
              data-test="icon-button-avatar"
              onClick={handleClick}
              size="large"
              edge="end"
              color="inherit"
            >
              <Avatar alt="Profile">{avatarText ? avatarText : null}</Avatar>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Popover
        sx={{
          ".MuiPaper-root": { width: 250, backgroundColor: "#FFF", py: 2 },
        }}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <LanguageSelector />
      </Popover>
    </>
  );
};
