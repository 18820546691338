import React from "react";
import { EMPTY, ConnectionPoints } from "../../types/ConnectionPoints";
import { EmptySearchResults } from "../EmptySearchResults";
import { SearchResultsTable } from "../SearchResultsTable";

interface Props {
  content: ConnectionPoints;
}

export const SearchResults: React.FC<Props> = ({content}) => {
  if (content === EMPTY) {
    return <EmptySearchResults />
  }
  return <SearchResultsTable content={content} disableOrder={true}/>;
};
