import React from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import { DataStream } from "../../types/DataStream";
import { SeparatedList } from "../SeparatedList";
import { SearchResultTag } from "../SearchResultTag";
import { useSearchParams } from "react-router-dom";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { DataStreamLink } from "../DataStreamLink";

interface Props {
  connectionPointId: string;
  meterId: string;
  meterStatus: string;
  content: DataStream;
}

export const DatastreamSearchResult: React.FC<Props> = (
  {
    connectionPointId,
    meterId,
    meterStatus,
    content: {
      name,
      serviceCategory,
      intent,
      registerType,
      channelName,
      status,
    }
  }
) => {
  const { t } = useTranslation("component");
  const [params] = useSearchParams();
  const overlay = Boolean(params.get("overlay"));
  const link = `/connectionpoint/${connectionPointId}/meter/${meterId}/datastream/${name}/${intent}${overlay ? `?overlay=true` : ''}`;
  const finalStatus = meterStatus?.toLowerCase() === "inactive" ? "Inactive" : status;
  return (
    <TableRow data-connectionpointid={connectionPointId} data-meterid={meterId} data-test={"search-result-datastream"}
              sx={{height: "40px"}}>
      <TableCell sx={{p: 0, pl: 18}}>
        <SeparatedList>
          <Typography data-test={"search-result-datastream-name"} component={"span"}>
            <SearchResultTag color="#0000001F">{t("DATASTREAM_SEARCH_RESULT.DATASTREAM")}</SearchResultTag>
            <DataStreamLink name={name} link={link} intent={intent}/>
          </Typography>
          <Typography data-test={"search-result-datastream-service-category"} component={"span"}>
            {serviceCategory}
          </Typography>
          <Typography data-test={"search-result-datastream-register-type"} component={"span"}>
            {registerType}
          </Typography>
          <Typography data-test={"search-result-datastream-channel-name"} component={"span"}>
            {channelName}
          </Typography>
        </SeparatedList>
      </TableCell>
      <TableCell sx={{pl: 2, pr: 0, py: 0, width: "200px"}} data-test={"search-result-datastream-install-date-time"}>
        {"-"}
      </TableCell>
      <TableCell sx={{pl: 2, pr: 0, py: 0, width: "150px"}} data-test={"search-result-datastream-meter-status"}>
        {"-"}
      </TableCell>
      <TableCell
        sx={{
          pl: 2,
          pr: 0,
          py: 0,
          width: "150px",
          color: finalStatus?.toLowerCase() === "active" ? "auto" : red[500]
        }}
        data-test={"search-result-datastream-status"}
      >
        {finalStatus?.toLowerCase() === "active" ? t("DATASTREAM_SEARCH_RESULT.ACTIVE") : t("DATASTREAM_SEARCH_RESULT.INACTIVE")}
      </TableCell>
    </TableRow>
  );
};
