import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { MeterDetails } from "./MeterDetails";
import { EMPTY, Meter } from "../../types/Meter";
import { useMeterDetails } from "../../hooks/useMeterDetails";
import { NotFound } from "../NotFound";

interface Props {
  connectionPointNumber: string;
  connectionPointId: string;
}

type Params = {
  meterId: string;
};

const meterEventsUrl = (connectionPointId: string, meterId: string): string => {
  return `/connectionpoint/${connectionPointId}/meter/${meterId}/event`;
};

export const MeterDetailsContainer: React.FC<Props> = ({
  connectionPointNumber,
  connectionPointId
}) => {
  const {meterId = ""} = useParams<Params>();
  const [currentState, setState] = useState<Meter>(EMPTY);
  const [error, setError] = useState<string>("");

  useMeterDetails(setState, setError, meterId, connectionPointId);

  if (error === '404') {
    return <NotFound />
  }

  return <MeterDetails content={currentState}
                       connectionPointNumber={connectionPointNumber}
                       meterEventsUrl={meterEventsUrl(connectionPointId, meterId)}/>;
};
