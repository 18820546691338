import { actionExport } from "../services/AdhocExtractService";

export const useAdhocExtract = (
  setLoading: (loading: boolean) => void,
  setActionResult: (state: string) => void,
  connectionPointId: string,
  dateRange: { start: string, end: string }
): void => {
  setActionResult("loading");
  setLoading(true);
  actionExport(connectionPointId, dateRange)
    .then((response) => {
      if (response) {
        setActionResult("warning");
        setLoading(false);
      }
    })
    .catch(() => {
      setActionResult("error");
      setLoading(false);
    });
};
