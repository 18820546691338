/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { DragDropFile } from "./DragDropFile";
import { mapTypeUploadOptions } from "../IngestionActionUpload/IngestionActionUpload";
import { useUploadAction } from "../../hooks/useUploadAction";
import { i18n } from "../../global/i18n";

interface Props {
  type: string | null;
  onCancel: () => void;
  onOkay: (files: File[]) => void;
}

export const uploadMessageOption = (errorsCount?: number) => {
  return {
    success: i18n.t("FILE_UPLOAD_DIALOG.SUCCESS_MESSAGE", { ns: "component" }),
    error: i18n.t("FILE_UPLOAD_DIALOG.ERROR_MESSAGE", { ns: "component" }),
    errorList: (
      <Box>
        {errorsCount}{" "}
        {i18n.t("FILE_UPLOAD_DIALOG.ERROR_LIST", { ns: "component" })}
      </Box>
    ),
  };
};

export const uploadFile = (
  files: File[],
  setLoading: (loading: boolean) => void,
  setResult: (result: string) => void,
  fileUploadType: string,
): void => {
  const formData = new FormData();
  formData.append("file", files[0]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useUploadAction(setLoading, setResult, formData, fileUploadType);
};

export const FileUploadDialog: React.FC<Props> = ({
  type,
  onCancel,
  onOkay,
}) => {
  const theme = useTheme();
  const [files, setFiles] = useState<File[]>([]);
  const [message, setMessage] = useState("");

  const uploadButtonDisabled = files.length <= 0 || message.length > 0;
  const handleOkay = useCallback(() => {
    onOkay(files);
  }, [files, onOkay]);

  return (
    <Dialog
      open={true}
      data-test={"file-upload-modal"}
      PaperProps={{ sx: { minWidth: 500, maxWidth: 500 } }}
    >
      <DialogTitle>
        {i18n.t("FILE_UPLOAD_DIALOG.FILE_UPLOAD", { ns: "component" })}
        {type !== null
          ? ` - ${mapTypeUploadOptions[type]}`
          : ""}
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <DragDropFile
          setFilesUploadDialog={setFiles}
          setMessageUploadDialog={setMessage}
        />
      </DialogContent>
      <DialogActions sx={{ p: theme.spacing(2) }}>
        <Button
          onClick={onCancel}
          sx={{
            textTransform: "capitalize",
            fontSize: "1rem",
            fontWeight: 400,
            ml: 1,
          }}
        >
          {i18n.t("FILE_UPLOAD_DIALOG.CANCEL", {ns : "component"})}
        </Button>
        <Box sx={{ position: "relative", marginLeft: theme.spacing(1) }}>
          <Button
            onClick={handleOkay}
            variant="contained"
            disabled={uploadButtonDisabled}
            data-test={"upload-button"}
            sx={{
              textTransform: "capitalize",
              fontSize: "1rem",
              fontWeight: 400,
              ml: 1,
            }}
          >
            {i18n.t("FILE_UPLOAD_DIALOG.UPLOAD", {ns : "component"})}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
