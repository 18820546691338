import { ExceptionListState } from "../types/ExceptionListState";
import { retrieveExceptionList } from "../services/ExceptionListService";

export const useExceptionList = (
  setState: (state: ExceptionListState) => void,
  setError: (state: string) => void,
  exceptionType: string,
): Promise<void> =>
  retrieveExceptionList(exceptionType).then(result => {
      setError("");
      setState(result);
    }
  ).catch(err => setError(err.message));
