export interface DeviceConfigurationState {
  configurationId: string;
  changeEffectiveDateTime: string;
  installationDateTime: string;
  churnDateTime: string;
  exceptionalId: string;
}

export const EMPTY: DeviceConfigurationState = {
  configurationId: "",
  changeEffectiveDateTime: "",
  installationDateTime: "",
  churnDateTime: "",
  exceptionalId: "",
}
