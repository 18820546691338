import React from "react";
import { Box, Typography } from "@mui/material";
import { i18n } from "../../global/i18n";

interface Props {
  connectionPointId: string;
}

export const ConnectionPointResult: React.FC<Props> = ({ connectionPointId }) => {
  return (
    <Box
      sx={{
        pt: 3,
      }}
    >
      <Typography
        sx={{
          px: 3,
          color: "rgba(0, 0, 0, 0.38)",
          fontSize: "0.8125rem",
          fontWeight: 500,
        }}
      >
        {i18n.t("CONNECTION_POINT_RESULT.CONNECTION_POINT", {ns: "component"})}
      </Typography>
      <Box sx={{ mt: 1, px: 3, py: 1.25, backgroundColor: "#EBEBEB" }}>
        {(connectionPointId + "").replace("-ELECTRICITY", "")}
      </Box>
    </Box>
  );
};
