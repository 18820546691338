import { MeterEventsState } from "../types/MeterEvents";
import { Moment } from "moment-timezone";
import { getEndOfDay, getStartOfDay } from "../commons/dates";

let eventsAbortController: AbortController | null = null;

export const retrieveMeterEvents = async (
  meterId: string,
  startDate: Moment | null,
  endDate: Moment | null
): Promise<MeterEventsState> => {
  if (eventsAbortController) {
    eventsAbortController.abort();
  }
  eventsAbortController = new AbortController();

  return fetch(`/service/v1/meter/${meterId}/event?`
    + new URLSearchParams({
      startTime: startDate ? getStartOfDay(startDate).toISOString() : "",
      endTime: endDate ? getEndOfDay(endDate).toISOString() : ""
    }), {
      signal: eventsAbortController.signal,
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" })
    })
    .then((response) => {
    eventsAbortController = null;

    if (!response.ok) {
      throw new Error("404")
    }

    return response.json();
  });
}