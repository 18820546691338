import React, { useState } from "react";
import { ReportDownloadPage } from "./ReportDownloadPage";
import { useReportDownloadPage } from "../../hooks/useReportDownloadPage";
import { EMPTY, ReportDownloadPageState } from "../../types/ReportDownloadPageState";

export const ReportDownloadPageContainer: React.FC = () => {
  const [currentState, setState] = useState<ReportDownloadPageState>(EMPTY);
  useReportDownloadPage(setState);
  return (
    <ReportDownloadPage
      content={currentState.content}
      isLoading={currentState.isLoading}
    />
  );
};
