import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DateRangeTypes } from "../../types/DateRangeTypes";
import { useTranslation } from "react-i18next";

interface Props {
  "data-test"?: string;
  value?: string;
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => void;
}

const { PARTIAL_DAY, MULTIPLE_DAYS } = DateRangeTypes;

export const UserDefinedScenarioToggle: React.FC<Props> = ({
  value = MULTIPLE_DAYS,
  onChange,
  ...props
}) => {
  const { t } = useTranslation("component");
  return (
    <ToggleButtonGroup
      data-test={props["data-test"] || "search-toggle"}
      exclusive={true}
      size={"small"}
      value={value}
      onChange={onChange}
      sx={{ mr: 2 }}
    >
      <ToggleButton
        value={PARTIAL_DAY}
        sx={{ height: "30px", px: "10px", py: "4px" }}
      >
        {t("USER_DEFINED_SCENARIO_TOGGLE.PARTIAL_DAY")}
      </ToggleButton>
      <ToggleButton
        value={MULTIPLE_DAYS}
        sx={{ height: "30px", px: "10px", py: "4px" }}
      >
        {t("USER_DEFINED_SCENARIO_TOGGLE.MULTIPLE_DAYS")}
      </ToggleButton>
    </ToggleButtonGroup>
  )
};
