import { ExceptionListState } from "../types/ExceptionListState";
import { getEmail } from "../commons/userInfo";

export const retrieveExceptionList = async (
  exceptionType: string,
): Promise<ExceptionListState> =>
  fetch(`/service/v1/exception/${ exceptionType }`, {
    headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.status.toString());
  });

export const actionMeterException = async (
  meterId: string,
  exceptionType: string,
  action: string,
  bodyData: {
    [x: string]: string
  },
): Promise<string> =>

  fetch(
    `/service/v1/meter/${ meterId }/exception/${ exceptionType }/${ action }`,
    {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
      body: (bodyData && Object.keys(bodyData).length) ? JSON.stringify({ assignedTo: getEmail(), ...bodyData }) : "",
    }
  ).then((response) => {

    if (!response.ok) {
      throw new Error("500");
    }
    return response.json();

  });

export const actionMeterExceptionStatus = async (
  meterId: string,
  exceptionType: string,
  action: string,
  bodyData: {
    [x: string]: string
  },
): Promise<string> =>

  fetch(
    `/service/v1/meter/${ meterId }/exception/${ exceptionType }/status/${ action }`,
    {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
      body: (bodyData && Object.keys(bodyData).length) ?
        JSON.stringify({ assignedTo: getEmail(), ...bodyData }) : JSON.stringify({ assignedTo: getEmail() }),
    }
  ).then((response) => {

    if (!response.ok) {
      throw new Error("500");
    }
    return response.json();

  });

export const actionSingleMeterException = async (
  params: {
    meterId: string,
    unitOfWorkId?: string,
    exceptionType: string,
  },
  action: string,
  bodyData: {
    [x: string]: string
  },
): Promise<string> =>

  fetch(
    `/service/v1/meter/${ params.meterId }/exception/${ action }`,
    {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
      body: JSON.stringify({ assignedTo: getEmail(), ...bodyData }),
    }
  ).then((response) => {

    if (!response.ok) {
      throw new Error("500");
    }
    return response.json();

  });

export const actionSingleMeterExceptionStatus = async (
  params: {
    meterId: string,
    unitOfWorkId?: string,
    exceptionType: string,
  },
  action: string,
  bodyData: {
    [x: string]: string
  },
): Promise<string> =>

  fetch(
    `/service/v1/meter/${ params.meterId }/exception/status/${ action }`,
    {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
      body: JSON.stringify({ assignedTo: getEmail(), ...bodyData }),
    }
  ).then((response) => {

    if (!response.ok) {
      throw new Error("500");
    }
    return response.json();

  });

export const commodity = "electricity";
export const intent = "consumption";

export const actionUploadFileIngestion = async (
  bodyData: FormData,
  actionType: string
): Promise<string> =>

  fetch(
    `/service/v1/${ commodity }/${ intent }/${ actionType }/upload`,
    {
      method: "POST",
      body: bodyData,
    }
  ).then(async (response) => {

    if (response.ok) {
      return response.json();

    } else {
      if (response.status === 400) {
        const result = await response.json();
        const messages = result.error.messages;
        throw new Error(messages.join("#"));
      } else {
        throw new Error("500");
      }
    }
  });
