/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react";
import { AuditLogs } from "../../types/AuditLogs";
import {
  AuditLogCollapsibleTable,
  renderCommonHeaderColumn,
} from "./AuditLogCollapsibleTable";
import { CollapsibleHeader } from "../../types/AuditLog";
import { formatDate, formatDateTime } from "../../commons/dates";
import { Box } from "@mui/material";
import { i18n } from "../../global/i18n";

interface Props {
  source: string;
  content: AuditLogs;
  loading: boolean;
}

export const UDS_ACTION_PARTIAL_DAY = "TIME_RANGE_OVERRIDE";
export const UDS_ACTION_MULTIPLE_DAYS = "DATE_RANGE_OVERRIDE";

export const formatDateAndTimeByActionType = (
  actionType: string | undefined,
  date: string | undefined,
): string => {
  if (actionType === UDS_ACTION_MULTIPLE_DAYS) {
    return formatDate(date);
  } else if (actionType === UDS_ACTION_PARTIAL_DAY) {
    return formatDateTime(date);
  } else {
    return date || "";
  }
};

export const getReadDateColumnValueFromJson = (
  keyValues: { key: string; value: string | undefined }[],
): JSX.Element => {
  let fromDate = "";
  let toDate = "";

  const actionType = keyValues.find(
    (keyValue) => keyValue.key === "actionType",
  );

  const rawFromDate = keyValues.find(
    (keyValue) => keyValue.key === "fromDateTime",
  );

  if (rawFromDate) {
    fromDate = formatDateAndTimeByActionType(
      actionType?.value,
      rawFromDate.value,
    );
  }

  const rawToDate = keyValues.find((keyValue) => keyValue.key === "toDateTime");
  if (rawToDate) {
    toDate = formatDateAndTimeByActionType(actionType?.value, rawToDate.value);
  }

  const value = (
    <Box>
      {i18n.t("AUDIT_LOG_TABLE_UDS.READ_DATE_COLUMN_FROM", {ns : "component"}) + ": " + fromDate}
      <br />
      {i18n.t("AUDIT_LOG_TABLE_UDS.READ_DATE_COLUMN_TO", {ns : "component"}) + ": " + toDate}
    </Box>
  );

  return value;
};

export const renderActionTypeColumn = (
  width: string,
  field: string,
  value: string | JSX.Element | undefined,
): React.ReactNode => {
  let actionType = "";

  if (value === UDS_ACTION_PARTIAL_DAY) {
    actionType = i18n.t("AUDIT_LOG_TABLE_UDS.UDS_ACTION_TYPE_PARTIAL_DAY", {ns : "component"});
  } else if (value === UDS_ACTION_MULTIPLE_DAYS) {
    actionType = i18n.t("AUDIT_LOG_TABLE_UDS.UDS_ACTION_TYPE_MULTIPLES_DAYS", {ns : "component"});
  }

  return renderCommonHeaderColumn(width, field, actionType);
};

export const customFormatDate = (
  date: string | undefined,
  keyValues: { key: string; value: string | undefined }[],
): string | JSX.Element => {
  if (!date) {
    return "";
  }

  const actionType = keyValues.find(
    (keyValue) => keyValue.key === "actionType",
  );

  const dateResult = formatDateAndTimeByActionType(actionType?.value, date);
  return dateResult;
};

export const getHeaders = () : CollapsibleHeader[] => ([
  {
    field: "readDate",
    headerName: i18n.t("AUDIT_LOG_TABLE_UDS.HEADERS.READ_DATE", {
      ns: "component",
    }),
    order: 1,
    getValueFromJson: getReadDateColumnValueFromJson,
  },
  {
    field: "actionDateTime",
    headerName: i18n.t("AUDIT_LOG_TABLE_UDS.HEADERS.ACTION_DATE", {
      ns: "component",
    }),
    order: 2,
    format: "dateTime",
  },
  {
    field: "actionType",
    headerName: i18n.t("AUDIT_LOG_TABLE_UDS.HEADERS.ACTION_TYPE", {
      ns: "component",
    }),
    order: 3,
    renderCustomHeaderColumn: renderActionTypeColumn,
  },
  {
    field: "userId",
    headerName: i18n.t("AUDIT_LOG_TABLE_UDS.HEADERS.USER_ID", {
      ns: "component",
    }),
    order: 4,
  },
  {
    field: "scenario",
    headerName: i18n.t("AUDIT_LOG_TABLE_UDS.HEADERS.SCENARIO", {
      ns: "component",
    }),
    order: 5,
  },
  {
    field: "fromDateTime",
    headerName: i18n.t("AUDIT_LOG_TABLE_UDS.HEADERS.FROM_DATE", {
      ns: "component",
    }),
    order: 6,
    customFormat: customFormatDate,
  },
  {
    field: "toDateTime",
    headerName: i18n.t("AUDIT_LOG_TABLE_UDS.HEADERS.TO_DATE", {
      ns: "component",
    }),
    order: 7,
    customFormat: customFormatDate,
  },
]);

export const customFormatFromDateToDateContentFields = (
  formatFields: string[],
  dependantFields: {
    key: string;
    value?: any;
  }[],
  field: {
    key: string;
    value: any;
  },
): string => {
  if (formatFields.includes(field.key)) {
    const actionType = dependantFields.find((f) => f.key === "actionType");

    if (!actionType) {
      return field.value;
    }

    const actionTypeValue = actionType.value;

    const dateResult = formatDateAndTimeByActionType(
      actionTypeValue,
      field.value,
    );
    return dateResult;
  }

  return "";
};

export const AuditLogTableUDS: React.FC<Props> = ({
  source,
  content,
  loading,
}) => {
  const headers = useMemo(() => getHeaders(), []);
  return (
    <AuditLogCollapsibleTable
      source={source}
      content={content}
      loading={loading}
      headers={headers}
      dateTimeContentFields={["actionDateTime"]}
      customFormatContentFields={{
        formatFields: ["fromDateTime", "toDateTime"],
        dependantFields: [{ key: "actionType" }],
        formatFn: customFormatFromDateToDateContentFields,
      }}
    />
  );
};
