/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from "react";
import { findLast } from "lodash";
import { Moment } from "moment-timezone";
import { DateRange } from "@mui/x-date-pickers-pro";
import { IntervalConsumption, IntervalsConsumption, EMPTY } from "../types/IntervalConsumption";
import { DataStreamChartData, EMPTY_CHART } from "../types/DataStreamChartState";
import { retrieveChartIntervals } from "../services/IntervalService";
import { convertToVersionItems } from "./useDataStreamIntervalVersions";
import { IntervalVersion, LatestVersion, VersionItem, VersionType } from "../types/IntervalVersion";
import { formatDateTime, toMomentFromDateString } from "../commons/dates";
import { i18n } from "../global/i18n";

export const DEFAULT_CHART_VALUE = "0";
export const PROCESSED_LABEL = i18n.t("DATA_STREAM_CHART.PROCESSED", {ns: "component"});
export const UNPROCESSED_LABEL = i18n.t("DATA_STREAM_CHART.UNPROCESSED", {ns: "component"});

export const useDataStreamChart = (
  setLoading: (state: boolean) => void,
  setError: (state: string) => void,
  setState: (state: DataStreamChartData[]) => void,
  meterId: string,
  datastreamName: string,
  intent: string,
  dateRange: DateRange<Moment>,
  versions: IntervalVersion[]
): void => {

  useEffect(() => {
    if (!datastreamName || versions.length == 0) {
      return;
    }

    setLoading(true);

    const latestVersions = getLatestVersions(versions);
    if (!latestVersions || (latestVersions.processed === 0 && latestVersions.unprocessed === 0)) {
      resetEmptyState(setLoading, setError, setState);
      return;
    }

    const dateRangeParam = returnDateRangeParams(dateRange);
    if (dateRangeParam.start.length <= 0 || dateRangeParam.end.length <= 0) {
      resetEmptyState(setLoading, setError, setState);
      return;
    }

    const executeServices = async () => {
      try {
        let processedResponse: IntervalsConsumption = EMPTY;
        if (latestVersions.processed > 0) {
          processedResponse = await retrieveChartIntervals(
            meterId, datastreamName, intent, dateRangeParam, latestVersions.processed, "processed"
          );
        }
        
        let unprocessedResponse: IntervalsConsumption = EMPTY;
        if (latestVersions.unprocessed > 0) {
          unprocessedResponse = await retrieveChartIntervals(
            meterId, datastreamName, intent, dateRangeParam, latestVersions.unprocessed, "unprocessed"
          );
        }

        const chartResponse = transformToChartData(processedResponse.intervals, unprocessedResponse.intervals);
      
        setLoading(false);
        setState(chartResponse);
        setError("");

      } catch (error: any) {
        setLoading(false);
        setState(EMPTY_CHART);
        setError(error.message);
      }
    };
  
    executeServices();
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [meterId, datastreamName, dateRange, versions]);
};

export const getLatestVersions = (versions: IntervalVersion[]): LatestVersion | null => {
  const versionItems = convertToVersionItems(versions);

  if (versionItems.length <= 0) {
      return null;
  }

  const processed = getDefaultVersion(versionItems);
  const unprocessed = findLast(versionItems, versionItem => versionItem.type === VersionType.UNPROCESSED && versionItem.enabled);

  return {
      processed: processed?.version || 0,
      unprocessed: unprocessed?.version || 0
  }
};

export const getDefaultVersion = (versionItems: VersionItem[]): VersionItem | undefined => {
  const latestOnMarketProcessedVersion = versionItems.find(
    (v) => v.type === VersionType.PROCESSED && v.isLatestOnMarket,
  );

  if (!latestOnMarketProcessedVersion) {
    return versionItems.find(
      (v) => v.type === VersionType.PROCESSED && v.isLatest,
    );
  }
  return latestOnMarketProcessedVersion;
};

export const returnDateRangeParams = (
  dateRange: DateRange<Moment>
): { start: string, end: string } => {
  return {
    start: dateRange[0] ? dateRange[0].toDate().toISOString() : "",
    end: dateRange[1] ? dateRange[1].toDate().toISOString() : ""
  }
};

const resetEmptyState = (
  setLoading: (state: boolean) => void,
  setError: (state: string) => void,
  setState: (state: DataStreamChartData[]) => void,
) => {
  setLoading(false);
  setState(EMPTY_CHART);
  setError("");
};

export const transformToChartData = (
  processedIntervals: IntervalConsumption[], unprocessedIntervals: IntervalConsumption[]
) : DataStreamChartData[] => {

  let data: DataStreamChartData[] = [];

  if (processedIntervals.length > 0 && unprocessedIntervals.length <= 0) {
    data = processedIntervals.map((processedItem: IntervalConsumption) => {
      return {  
        [PROCESSED_LABEL]: processedItem.latestQuantity || DEFAULT_CHART_VALUE,
        [UNPROCESSED_LABEL]: DEFAULT_CHART_VALUE,
        Time: calculateDateTimeWithTimeZone(processedItem.dateTime)
      };
    });
  }
  
  if (unprocessedIntervals.length > 0 && processedIntervals.length <= 0) {
    data = unprocessedIntervals.map((unprocessedItem: IntervalConsumption) => {
      return {  
        [PROCESSED_LABEL]: DEFAULT_CHART_VALUE,
        [UNPROCESSED_LABEL]: unprocessedItem.latestQuantity || DEFAULT_CHART_VALUE,
        Time: calculateDateTimeWithTimeZone(unprocessedItem.dateTime)
      };
    });
  }

  if (processedIntervals.length > 0 && unprocessedIntervals.length > 0) {
    const dateTimes = processedIntervals.length > unprocessedIntervals.length 
                    ? processedIntervals.map(interval => interval.dateTime)
                    : unprocessedIntervals.map(interval => interval.dateTime);

    data = dateTimes.map((dateTime: string) => {
      const processed = processedIntervals.find(interval => interval.dateTime === dateTime);
      const unprocessed = unprocessedIntervals.find(interval => interval.dateTime === dateTime);
      return {
        [PROCESSED_LABEL]: processed ? processed.latestQuantity : DEFAULT_CHART_VALUE,
        [UNPROCESSED_LABEL]: unprocessed ? unprocessed.latestQuantity : DEFAULT_CHART_VALUE,
        Time: calculateDateTimeWithTimeZone(dateTime)
      };
    });
  }

  return data;
};

/**
 * Convert date time to new date time with update value before pass to the chart
 * Using timezone different between browser's timezone and env.defaultTimeZone
 */
export function calculateDateTimeWithTimeZone(dateTime: string): string {
  const output = formatDateTime(toMomentFromDateString(dateTime).toDate());
  return output;
}