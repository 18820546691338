import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Moment } from "moment-timezone";
import { AuditLogs, EMPTY } from "../../types/AuditLogs";
import { useAuditLogs } from "../../hooks/useAuditLogs";
import { useDateToISOString } from "../../hooks/useDateToISOString";
import { AuditLogTableExceptions } from "./AuditLogTableExceptions";
import { AuditLogTableUDS } from "./AuditLogTableUDS";

interface Props {
  source: string;
  meterId: string;
  date: Moment | null;
}

type Params = {
  connectionPointId: string;
};

export const AuditLogTableContainer: React.FC<Props> = ({
  source,
  meterId,
  date,
}) => {
  const { connectionPointId = "" } = useParams<Params>();
  const [auditLogs, setAuditLogs] = useState<AuditLogs>(EMPTY);
  const [loading, setLoading] = useState<boolean>(true);
  const isoDateString = useDateToISOString(date);

  const handleSetAuditLogs = useCallback((newAuditLogs) => {
    setLoading(false);
    setAuditLogs(newAuditLogs);
  }, []);

  useAuditLogs(handleSetAuditLogs, source, meterId, connectionPointId, isoDateString);

  useEffect(() => {
    setLoading(true);
    setAuditLogs(EMPTY);
  }, [date]);

  if (source === "exceptions") {
    return (
      <AuditLogTableExceptions
        source={source}
        content={auditLogs}
        loading={loading}
      />
    );
  } else if (source === "uds") {
    return (
      <AuditLogTableUDS source={source} content={auditLogs} loading={loading} />
    );
  } else {
    return <></>;
  }
};
