import React, { useMemo, useState } from "react";
import {
  Box,
  Collapse,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  Radio,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PublicIcon from "@mui/icons-material/Public";
import i18next from "i18next";
import environment from "../../environment";
import { getLanguages } from "../../global/i18n/i18n";

export const LanguageSelector: React.FC = () => {
  const currentLanguage =
    environment.feature_flags.enable_language_selection === true ||
    environment.feature_flags.enable_language_selection === "true"
      ? i18next.language
      : "en_au";
  const [activeLanguage, setActiveLanguage] = useState(currentLanguage);
  const [open, setOpen] = React.useState(true);

  const languages = useMemo(() => getLanguages(), []);

  const handleClick = () => {
    setOpen(!open);
  };

  const onChangeLanguage = (language: string) => {
    if (activeLanguage !== language) {
      setActiveLanguage(language);
      // reload if active language is different with incoming language
      window.localStorage.setItem("i18nextLng", language);
      // reload page
      window.location.reload();
    }
  };

  return (
    <List
      sx={{
        paddingTop: "2px",
        pl: 0,
        ".MuiListItem-root": { cursor: "pointer" },
      }}
    >
      <ListItemButton
        onClick={handleClick}
        sx={{
          justifyContent: "space-between",
          ":hover": { backgroundColor: "#FFF" },
        }}
      >
        <Box
          display={"flex"}
          sx={{ textTransform: "uppercase", fontSize: "1rem" }}
        >
          <PublicIcon sx={{ mr: 1 }} /> Language
        </Box>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse sx={{ pl: 3 }} in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          sx={{
            px: 2,
            py: 0,
            ".MuiListItem-root": {
              "&.language-active": {
                backgroundColor: "rgb(240, 247, 255)",
                borderColor: "rgb(0, 127, 255)",
                color: "rgb(0, 127, 255)",
                borderRadius: "10px",
              },
              ":hover": {
                color: "rgb(0, 127, 255)",
                borderRadius: "10px",
              },
            },
            ".MuiRadio-root": {
              padding: 0,
              paddingRight: "4px",
            },
          }}
        >
          {languages.map((language) => (
            <ListItem
              key={language.key}
              className={
                activeLanguage === language.key ? "language-active" : ""
              }
              onClick={() => onChangeLanguage(language.key)}
              data-test={`language-option-${language.key}`}
            >
              <FormControlLabel
                value={language.key}
                checked={activeLanguage === language.key}
                control={<Radio />}
                label={language.label}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  );
};
