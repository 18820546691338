import { useEffect, useState } from "react";
import { EMPTY, IntervalAuditLogsState } from "../types/IntervalAuditLogsState";
import { retrieveIntervalAuditLogs } from "../services/IntervalAuditLogService";

interface IntervalAuditLogsResult {
  error?: string,
  loading: boolean,
  intervalAuditLogs: IntervalAuditLogsState
}

export const useIntervalAuditLogs = (
  meterId: string,
  dateString: string,
): IntervalAuditLogsResult => {

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [intervalAuditLogs, setIntervalAuditLogs] = useState<IntervalAuditLogsState>(EMPTY);

  useEffect(() => {
    setLoading(true);
    setError("");
    retrieveIntervalAuditLogs(meterId, dateString)
      .then(setIntervalAuditLogs)
      .catch(error => {
        console.error(`An error occurred while fetching interval audit logs for ${meterId}, ${dateString}\n${error}`);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [meterId, dateString]);

  return {
    error,
    loading,
    intervalAuditLogs
  }

}
