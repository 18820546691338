/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { IntervalVersion } from "../types/IntervalVersion";
import { returnDateRangeParams } from "./useDataStreamChart";
import { retrieveChartIntervalVersions } from "../services/IntervalService";
import {
  EMPTY_VERSIONS,
  DataStreamChartComparisonVersionState,
  DataStreamChartComparisonParams,
  DataStreamChartComparisonVersionParams,
} from "../types/DataStreamChartComparisonState";

import { Moment } from "moment";
import { DateRange } from "@mui/x-date-pickers-pro";
import { ComparisonItem } from "../types/ComparisonItem";

const convertToVersionParams = (
  params: ComparisonItem[],
  dateRange: DateRange<Moment>,
): DataStreamChartComparisonVersionParams[] => {
  return params.map((param: ComparisonItem) => {
    return { ...param, dateRange: dateRange };
  });
};

export const useDataStreamChartComparisonVersions = (
  setLoading: (state: boolean) => void,
  setError: (state: string) => void,
  setState: (state: DataStreamChartComparisonVersionState) => void,
  params: ComparisonItem[],
  firstDateRange: DateRange<Moment>,
  secondDateRange: DateRange<Moment> | null,
  compareOption?: string | undefined,
): void => {
  useEffect(() => {
    const versionParams = convertToVersionParams(params, firstDateRange);
    const compareVersionParams =
      secondDateRange !== null
        ? convertToVersionParams(params, secondDateRange)
        : null;
    setLoading(true);

    (async () => {
      try {
        const versionsData: DataStreamChartComparisonVersionState = {
          versions: [],
          compareVersions: [],
        };

        const services = retrieveChartIntervalVersionServices(versionParams);
        if (services.length === 0) {
          setLoading(false);
          setState(EMPTY_VERSIONS);
          setError("");
          return;
        }

        const versionResponses = await Promise.allSettled(services);
        versionsData.versions = transformToComparisonVersions(
          versionParams,
          versionResponses,
        );

        const isComparing =
          compareOption && compareOption?.length > 0 && compareVersionParams;
        if (isComparing) {
          const compareServices =
            retrieveChartIntervalVersionServices(compareVersionParams);
          const compareVersionResponses = await Promise.allSettled(
            compareServices,
          );
          versionsData.compareVersions = transformToComparisonVersions(
            compareVersionParams,
            compareVersionResponses,
          );
        }
        setLoading(false);
        setState(versionsData);
        setError("");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setLoading(false);
        setState(EMPTY_VERSIONS);
        setError(error.message);
      }
    })();
  }, [params, firstDateRange, secondDateRange, compareOption]);
};

export const retrieveChartIntervalVersionServices = (
  params: DataStreamChartComparisonVersionParams[],
): Promise<IntervalVersion[]>[] => {
  const services: Promise<IntervalVersion[]>[] = [];

  params.forEach((param) => {
    const dateRangeParam = returnDateRangeParams(param.dateRange);

    if (
      !param.datastreamName ||
      !param.intent ||
      dateRangeParam.start.length <= 0 ||
      dateRangeParam.end.length <= 0
    ) {
      return [];
    }

    services.push(
      retrieveChartIntervalVersions(
        param.meterId,
        param.datastreamName,
        param.intent,
        dateRangeParam.start,
        dateRangeParam.end,
      ),
    );
  });

  return services;
};

export const transformToComparisonVersions = (
  params: DataStreamChartComparisonVersionParams[],
  versionResponses: PromiseSettledResult<IntervalVersion[]>[],
): DataStreamChartComparisonParams[] => {
  const data = versionResponses.map(
    (
      versionResponse: PromiseSettledResult<IntervalVersion[]>,
      index: number,
    ) => {
      const versions = getVersions(versionResponse);
      return {
        versions: versions,
        meterId: params[index].meterId,
        datastreamName: params[index].datastreamName,
        intent: params[index].intent,
        dateRange: params[index].dateRange,
      };
    },
  );

  return data;
};

export const getVersions = (
  response: PromiseSettledResult<IntervalVersion[]>,
): IntervalVersion[] => {
  if (response.status === "fulfilled") {
    return response.value;
  }
  return [];
};
