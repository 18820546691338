import React, { useContext, useEffect, useState } from "react";
import { Box, Link } from "@mui/material";
import { ConnectionPoint } from "../../types/ConnectionPoint";
import { ConnectionPointHeader } from "../../components/ConnectionPointHeader";
import { MeterDetails } from "../MeterDetails";
import { Link as RouterLink, Route, Routes, useLocation } from "react-router-dom";
import { ConnectionPointTabs } from "../../components/ConnectionPointTabs";
import { ConnectionPointDetails } from "../../components/ConnectionPointDetails";
import { Helmet } from "react-helmet-async";
import { NotFound } from "../NotFound";
import { AdhocExtractExport } from "../../components/AdhocExtractExport";
import { useSnackbarMessage } from "../../hooks/useSnackbarMessage";
import { useAdhocExtract } from "../../hooks/useAdhocExtract";
import { useMessage } from "../../hooks/useMessage";
import { LoadingContext } from "../../context/LoadingContext";
import env from "../../environment"
import { i18n } from "../../global/i18n";
import { FeatureFlag } from "../../components/FeatureFlag";

interface Props {
  content: ConnectionPoint;
  connectionPointId: string;
}

export const extractExportMessageOption = {
  "error": i18n.t("CONNECTION_POINT_PAGE.ERROR_MESSAGE", {ns: "page"}),
  "warning": (
    <Box>
      {i18n.t("CONNECTION_POINT_PAGE.WARNING_MESSAGE", {ns: "page"})}
      <Link to="/download" component={RouterLink}>{i18n.t("CONNECTION_POINT_PAGE.WARNING_MESSAGE_LINK", {ns: "page"})}</Link>
      {i18n.t("CONNECTION_POINT_PAGE.WARNING_MESSAGE_EXPIRY", {ns: "page"})}
    </Box>
  )
};

const applyExport = (
  connectionPointId: string,
  setLoading: (loading: boolean) => void,
  setResult: (result: string) => void,
) => {
  return (fromDate: string, toDate: string) => {
    useAdhocExtract(
      setLoading,
      setResult,
      connectionPointId,
      { start: fromDate, end: toDate }
    );
  }
};


export const ConnectionPointPage: React.FC<Props> = ({ content, connectionPointId }) => {
  const { pathname } = useLocation();
  const [extractExportResult, setExtractExportResult] = useState("");
  const { setLoading } = useContext(LoadingContext);
  const { showSnackMessage } = useSnackbarMessage();
  const extractExportMessage = useMessage(extractExportResult, extractExportMessageOption);

  useEffect(() => {
    if (extractExportResult.length > 0) {
      showSnackMessage({
        type: extractExportMessage.state.type,
        onClose: extractExportMessage.state.closeAlert,
        dataTestPrefix: "history-report",
        showExpandData: false,
        title: extractExportMessage.state.message,
      });
    }
    setExtractExportResult("");
  },
  [ extractExportMessage.state.openAlert, showSnackMessage ]);

  return (
    <>
      <Helmet>
        <title>{i18n.t("CONNECTION_POINT_PAGE.TITLE", {ns: "page"})}: {content.number}</title>
      </Helmet>
      <FeatureFlag flag="enable_adhoc_extracts">
        <AdhocExtractExport onApply={applyExport(connectionPointId, setLoading, setExtractExportResult)} />
      </FeatureFlag>
      <ConnectionPointHeader
        id={content.number}
        address={content.address}
        timeZone={env.displayTimezone}
        market={env.displayMarket}
      />
      <ConnectionPointTabs content={content} path={pathname} />
      <Routes>
        <Route path={"/"} element={<ConnectionPointDetails content={content} />} />
        <Route
          path={"/meter/:meterId"}
          element={<MeterDetails connectionPointNumber={content.number} connectionPointId={connectionPointId} />}
        />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </>
  );
};
