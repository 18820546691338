import React, { ReactNode, useCallback, useState } from "react";
import { Box, IconButton, Toolbar } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import WarningIcon from "@mui/icons-material/Warning";
import DownloadIcon from "@mui/icons-material/Download";
import MenuIcon from "@mui/icons-material/Menu";
import { Header } from "../../components/Header";
import { NavItem, SideNav } from "../../components/SideNav";
import { CompareDataPage } from "../../pages/CompareDataPage";
import { useTranslation } from "react-i18next";
import { FeatureFlag } from "../../components/FeatureFlag";


interface Props {
  userInitials: string;
  children: ReactNode;
}

export const NepLayout: React.FC<Props> = ({
  userInitials,
  children
}) => {
  const [openSideNav, setOpenSideNav] = useState(true);

  const toggleDrawer = useCallback(() => {
    setOpenSideNav(prev => !prev);
  }, []);

  const { t } = useTranslation("page");

  return (
    <Box sx={{display: "flex", p: 0}}>
      <Header 
        avatarText={userInitials}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{mr: 2}}
          onClick={toggleDrawer}
        >
          <MenuIcon/>
        </IconButton>
      </Header>

      <SideNav openSideNav={openSideNav}>
        <FeatureFlag flag={"enable_compare_data"}>
          <CompareDataPage openSideNav={openSideNav} />
        </FeatureFlag>
        <NavItem icon={<ArticleIcon/>} to={"/connectionpoint"}>{t("NEP_LAYOUT_PAGE.CONNECTION_POINTS")}</NavItem>
        <FeatureFlag flag={"enable_exceptions"}>
          <NavItem icon={<WarningIcon/>} to={"/exception"}>{t("NEP_LAYOUT_PAGE.EXCEPTIONS")}</NavItem>
        </FeatureFlag>
        <FeatureFlag flag="enable_adhoc_extracts">
          <NavItem icon={<DownloadIcon/>} prefix="report-download" to={"/download"}>{t("NEP_LAYOUT_PAGE.DOWNLOAD")}</NavItem>
        </FeatureFlag>
      </SideNav>

      <Box component="main" sx={{flexGrow: 1}}>
        <Toolbar/>
        <Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
}
