import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import { en_test, en_au, vi, ja, en_nz} from ".";
import environment from "../../environment";
import { Language } from "../../types/Language";

const ARR_LANGUAGES = [
  {
    key: "enable_english_australia_language",
    value: { key: "en_au", label: "English Australia - EN AU" },
  },
  {
    key: "enable_english_newzealand_language",
    value: { key: "en_nz", label: "English New Zealand - EN NZ" },
  },
  {
    key: "enable_english_test_language",
    value: { key: "en_test", label: "English Test - EN Test" },
  },
  {
    key: "enable_vietnamese_language",
    value: { key: "vi", label: "Việt nam - VI" },
  },
  { key: "enable_japanese_language", value: { key: "ja", label: "日本 - JA" } },
];

const MAP_LANGUAGES: { [x: string]:  Language } = {
  en_au: ARR_LANGUAGES[0].value,
  en_nz: ARR_LANGUAGES[1].value,
  en_test: ARR_LANGUAGES[2].value,
  vi: ARR_LANGUAGES[3].value,
  ja: ARR_LANGUAGES[4].value,
};

export const getDefaultLng = () : Language => {
  const defaultLanguageKey = (environment.feature_flags && environment.feature_flags.default_ui_language) || "en_au";
  return MAP_LANGUAGES[defaultLanguageKey] || {
    key: "en_au",
    label: "English Australia - EN AU",
  };
}
export const defaultLng = getDefaultLng().key;
export const defaultNS = "component";
export const resources = {
  en_au: { page: en_au.page, component: en_au.component },
  en_nz: { page: en_nz.page, component: en_nz.component },
  en_test: { page: en_test.page, component: en_test.component },
  vi: { page: vi.page, component: vi.component },
  ja: { page: ja.page, component: ja.component },
} as const;

const defaultLanguage = getDefaultLng();

export const getLanguages = (): Language[] => {
  const languages: Language[] = [];
  const { feature_flags } = environment;
  ARR_LANGUAGES.forEach((language) => {
    if (
      feature_flags && (feature_flags[language.key] === true ||
      feature_flags[language.key] === "true")
    ) {
      languages.push(language.value);
    }
  });
  if (languages.length === 0) {
    languages.push(defaultLanguage);
  }
  return languages;
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    defaultNS: defaultNS,
    resources,
    fallbackLng: defaultLng,
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
      lookupLocalStorage: "i18nextLng",
    },
    supportedLngs: getLanguages().map(item => item.key),
    saveMissing: true,
    saveMissingTo: "all",
    interpolation: {
      escapeValue: false,
    },
  });

export { i18n };
