/**
 * This is the default MUˆ theme object.
 * Theme UI Spec (see: https://mui.com/customization/default-theme/#main-content)
 * Override the theme
 * @see: https://mui.com/customization/theme-components/#global-style-overrides
 * @see: https://mui.com/customization/palette/#heading-adding-new-colors
 */

import { createTheme } from '@mui/material/styles';

const PRIMARY = "#0053DE";
const SECONDARY = "#FFFFFF";
const GREY = "#7A869A";
const PAPER = "#F4F5F7";
const WARNING = "#ED6C02";

const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          "&.MuiDrawer-paper": {
            backgroundColor: SECONDARY,
          }
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: PRIMARY,
          borderColor: PRIMARY,
          ":hover": {
            background: 'none',
          },
          "&.Mui-selected": {
            backgroundColor: PRIMARY,
            color: SECONDARY,
          },
          "&.Mui-selected:hover": {
            background: 'none',
            backgroundColor: PRIMARY,
            color: SECONDARY,
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: PAPER,
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: PAPER,
          },
          "& fieldset": {
            borderColor: PAPER,
          },
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          ".date-range-picker-component .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
            background: "#2196F314",
          },
          ".date-range-picker-component .MuiDateRangePickerDay-dayInsideRangeInterval": {
            color: "rgba(0, 0, 0, 0.87)"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: PAPER,
          borderColor: PAPER,
          borderRadius: "4px",
          height: "32px",
          "& p.MuiFormHelperText-root": {
            backgroundColor: "transparent",
            margin: 0,
            padding: "4px 14px 0px 14px",
          },
          "& .MuiOutlinedInput-root": {
            height: "32px",
            paddingLeft: "11px",
            "&.Mui-focused fieldset": {
              borderColor: PAPER,
            },

          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& div.MuiOutlinedInput-root": {
            padding: "0 8px",

            "& input": {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              width: "80px",
              fontSize: "14px",
            }
          }
        }
      }
    }
  },
  palette: {
    text: {
      secondary: "rgba(0,0,0,0.6)",
      label: GREY,
    },
    custom: {
      border: "#0000001F",
    },
    primary: {
      main: PRIMARY,
    },
    secondary: {
      main: SECONDARY,
    },
    warning: {
      main: WARNING,
    },
    background: {
      default: SECONDARY,
      paper: PAPER,
    }
  }
});

declare module "@mui/material/styles/createPalette" {
  interface TypeText {
    label?: string;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    custom?: {
      border: string;
    };
  }
}


export default theme;