export interface MeterEventsState {
  meterEvents: MeterEvents[]
}

export interface MeterEvents {
  eventType: string;
  timestamp: string;
  parameters: EventParameters[]
}

export interface EventParameters {
  name: string;
  value: string;
}

export const EMPTY: MeterEventsState = {
  meterEvents: [],
};

