import React from "react";
import { Box, Typography } from "@mui/material";
import { Archive } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const EmptySearchResults: React.FC = () => {
  const { t }  = useTranslation("component");

  return <Box
    display={"flex"}
    flexDirection={"column"}
    justifyContent={"center"}
    alignItems={"center"}
    data-test={"empty-search-result"}
    sx={{
      backgroundColor: "background.paper",
      minHeight: `calc(100vh - 192px)`,
    }}>
    <Archive sx={{fontSize: 72}} color={"disabled"}/>
    <Typography variant={"h5"} color={"text.label"}>{t("EMPTY_SEARCH_RESULTS.NO_RESULTS")}</Typography>
    <Typography color={"text.label"}>{t("EMPTY_SEARCH_RESULTS.ADJUST_MESSAGE")}</Typography>
  </Box>;
};
