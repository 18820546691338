import React from "react";
import { Box, SxProps } from "@mui/material";

interface Props {
  children: React.ReactNode;
  sx?: SxProps,
}

export const PageHeader: React.FC<Props> = ({ children, sx }) => {
  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: 1,
        borderColor: "divider",
        px: 4,
        py: 2,
        ...sx,
      }}
      component={"section"}
    >
      {children}
    </Box>
  );
};
