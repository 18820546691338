import React, { useState } from "react";
import { MoreVert } from "@mui/icons-material";
import { Button, IconButton, PopoverOrigin } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { i18n } from "../../global/i18n";

interface OnclickType {
  (event: Event, content?: string): void;
}
interface Option {
  option: string;
  optionValue: string;
}
interface Props {
  type?: "icon" | "button";
  anchorOriginType?: "left" | "right";
  content: string;
  onclick: OnclickType;
  options?: Option[];
}

const getComponentLocalizationText = (key: string) : string => {
    return i18n.t(key, {ns: "component"});
};

export const resolutionOptions: Option[] = [
  {
    option: getComponentLocalizationText("EXCEPTION_ACTION_OPTIONS.RESOLUTION_OPTIONS.FINALISE"),
    optionValue: "Finalise and Send to Market"
  },
  {
    option: getComponentLocalizationText("EXCEPTION_ACTION_OPTIONS.RESOLUTION_OPTIONS.ACCEPT"),
    optionValue: "Accept and Send to Market"
  },
  {
    option: getComponentLocalizationText("EXCEPTION_ACTION_OPTIONS.RESOLUTION_OPTIONS.SEND"),
    optionValue: "Send Latest Ingested Values to Market"
  },
  {
    option: getComponentLocalizationText("EXCEPTION_ACTION_OPTIONS.RESOLUTION_OPTIONS.PENDING"),
    optionValue: "Mark as pending"
  },
  {
    option: getComponentLocalizationText("EXCEPTION_ACTION_OPTIONS.RESOLUTION_OPTIONS.APPLY"),
    optionValue: "Apply Sub scenario"
  },
  {
    option: getComponentLocalizationText("EXCEPTION_ACTION_OPTIONS.RESOLUTION_OPTIONS.IGNORE"),
    optionValue: "Ignore/close"
  },
];

type PopoverOriginType = {
  [x: string]: PopoverOrigin;
};

const anchorOrigin: PopoverOriginType = {
  right: {
    vertical: "bottom",
    horizontal: "right",
  },
  left: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const transformOrigin: PopoverOriginType = {
  right: {
    vertical: "top",
    horizontal: "right",
  },
  left: {
    vertical: "top",
    horizontal: "left",
  },
};

export const ExceptionActionOptions: React.FC<Props> = ({
  type = "icon",
  anchorOriginType= "right",
  content,
  onclick,
  options,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (event: any) => {
    setAnchorEl(null);
    if (event.target.innerText) {
      onclick(event, content);
    }
  };

  return (
    <>
      {type === "icon" && (
        <IconButton data-test={"option-menu"} onClick={handleClick}>
          <MoreVert />
        </IconButton>
      )}
      {type === "button" && (
        <Button
          data-test="bulk-exceptions-action"
          sx={{ textTransform: "capitalize", boxShadow: "none" }}
          aria-controls={anchorEl ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? "true" : undefined}
          variant="contained"
          size="small"
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {i18n.t("EXCEPTION_ACTION_OPTIONS.ACTIONS", {ns: "component"})}
        </Button>
      )}
      <Menu
        sx={{ ".MuiMenu-paper": { backgroundColor: "#FFF" } }}
        id="long-menu"
        data-test={"popover-menu"}
        MenuListProps={{
          "aria-labelledby": "long-button",
          dense: true,
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin[anchorOriginType]}
        transformOrigin={transformOrigin[anchorOriginType]}
      >
        <MenuItem disabled>{getComponentLocalizationText("EXCEPTION_ACTION_OPTIONS.RESOLUTION_OPTIONS.RESOLUTION_ACTIONS")}</MenuItem>
        {(options || resolutionOptions).map((option) => (
          <MenuItem key={option.optionValue} onClick={handleClose} data-test={option.optionValue}>
            {option.option}
          </MenuItem>
        ))}

        {/* commented for now, keep for later use */}
        {/* <Divider />
        <MenuItem disabled>Others</MenuItem>
        {otherOptions.map((option) => (
          <MenuItem key={option} onClick={handleClose}>
            {option}
          </MenuItem>
        ))} */}
      </Menu>
    </>
  );
};
