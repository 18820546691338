import React, { useMemo } from "react";
import { Box, InputLabel, TextField, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { FormControlLabel } from "../FormControlLabel";
import { FormControlContent } from "../FormControlContent";

interface Props {
  "data-test"?: string;
  label: string,
  value?: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  disabled: boolean,
  errorMessage?: string,
  placeholder?: string,
  extraInputStyle?: SxProps<Theme>;
}

export const LabelledInput: React.FC<Props> = ({
  label,
  value = "",
  onChange,
  disabled,
  errorMessage,
  placeholder = "Enter a value",
  extraInputStyle,
  ...props
}) => {

  const containerDataTest = useMemo(() => props["data-test"] || "labelled-input", [props]);

  return (
    <Box
      data-test={containerDataTest}
      component="form"
      sx={{ display: 'flex', mb: 1 }}
    >
      <FormControlLabel>
        <InputLabel data-test={containerDataTest + "-input-label"}>{label}</InputLabel>
      </FormControlLabel>
      <FormControlContent>
        <TextField
          data-test={containerDataTest + "-input-field"}
          variant={"outlined"}
          size={"small"}
          sx={{
            "& p.MuiFormHelperText-root": {
              backgroundColor: "transparent",
              margin: 0,
              padding: "4px 0 0 2px",
              fontSize: "10px",
              fontWeight: 500,
            },
            "& div.MuiOutlinedInput-root": {
              minHeight: "32px",
              width: "190px",
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              borderRadius: "4px",

              "& input": {
                width: "100%",
                minHeight: "32px",
                padding: "4px 0 0 8px",
              }
            },
            ...extraInputStyle,
          }}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          {...(errorMessage && { error: true, helperText: errorMessage })}
        />
      </FormControlContent>
    </Box>
  );
}
