/* eslint-disable @typescript-eslint/no-explicit-any */
import env from "../../environment";
import { LOGIN_TOKEN_KEY } from "../../commons/userInfo"
import { LoginToken } from "../../types/LoginToken";
import { refreshLoginToken } from "../../services/LoginService";

const LOGIN_CURRENT_PATHNAME_KEY = "login_current_pathname";

const getExpiresAt = (expiresIn: number): number => {
  return Math.floor(new Date().getTime() / 1000) + expiresIn - 10;
}

const getAuthUrl = (): string => {
  const domain = env.auth.domain;
  const params = new URLSearchParams({
    client_id: env.auth.clientId,
    response_type: "code",
    redirect_uri: env.auth.redirectUri
  });
  return `https://${domain}/oauth2/authorize?${params.toString()}`;
};

const authRedirect = (): void => {
  window.sessionStorage.setItem(LOGIN_CURRENT_PATHNAME_KEY, window.location.pathname);
  window.location.href = getAuthUrl();
};

const getLoginToken = async (): Promise<LoginToken> => {
  const json = window.sessionStorage.getItem(LOGIN_TOKEN_KEY);
  if (json) {
    const loginToken = JSON.parse(json);
    const expiresAt = loginToken.expires_at;
    if (!expiresAt) {
      // if you don't know, just try using it and the backend will return 401 if it's expired
      return loginToken;
    }
    if (expiresAt > new Date().getTime() / 1000) {
      // the token is still not expired
      return loginToken;
    }
    const refreshToken = loginToken.refresh_token;
    if (refreshToken) {
      try {
        const newLoginToken = await refreshLoginToken(refreshToken);
        if (newLoginToken && newLoginToken.id_token) {
          // copy and save the current refresh token because refresh request does not return it
          newLoginToken.refresh_token = refreshToken;
          if (newLoginToken.expires_in) {
            const newExpiresAt = getExpiresAt(newLoginToken.expires_in);
            newLoginToken.expires_at = newExpiresAt;
          }
          window.sessionStorage.setItem(LOGIN_TOKEN_KEY, JSON.stringify(newLoginToken));
          return newLoginToken;
        } else {
          // there was something wrong with the call
          console.error(newLoginToken);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
  authRedirect();
  throw new Error("Not logged in");
};

const AuthInterceptor = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  request: async (urlOrPath: string, config: any) => {
    if (urlOrPath.startsWith("/service/")) {
      const loginToken = await getLoginToken();
      config.headers = config.headers || new Headers();
      config.headers.append("Authorization", `${loginToken.token_type} ${loginToken.id_token}`);
    }
    return [urlOrPath, config];
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  response: (response: any) => {
    if (new URL(response.request.url).pathname.startsWith("/service/") && response.status === 401) {
      authRedirect();
      throw new Error(response.statusText);
    }
    return response;
  }
};

export { authRedirect, AuthInterceptor, LOGIN_CURRENT_PATHNAME_KEY, getExpiresAt };
