import React from "react";
import { Button, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  onCancel: () => void;
  onOkay: () => void;
  disabledOkayButton: boolean;
  disabled: boolean;
}

export const SubScenarioDialogActions: React.FC<Props> = ({
  onCancel,
  disabled,
  onOkay,
  disabledOkayButton,
}) => {
  const { t } = useTranslation("component");
  return (
    <DialogActions sx={{ p: 2 }}>
      <Button onClick={onCancel} disabled={disabled}>{t("SUB_SCENARIO_DIALOG_ACTIONS.CANCEL")}</Button>
      <Button onClick={onOkay} variant="contained" disabled={disabled || disabledOkayButton} data-test={"apply-button"}>
        {t("SUB_SCENARIO_DIALOG_ACTIONS.APPLY")}
      </Button>
    </DialogActions>
  )
};
