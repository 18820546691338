import React, { useState } from "react";
import { NepLayout } from "./NepLayout";
import { useNepLayout } from "../../hooks/useNepLayout";
import { EMPTY, NepLayoutState } from "../../types/NepLayoutState";

export const NepLayoutContainer: React.FC = ({children}) => {
  const [currentState, setState] = useState<NepLayoutState>(EMPTY);
  useNepLayout(setState);
  return <NepLayout userInitials={currentState.userInitials}>{children}</NepLayout>;
};
