import React, { useState } from "react";
import { ConnectionPointSearch } from "./ConnectionPointSearch";
import { useConnectionPointSearch } from "../../hooks/useConnectionPointSearch";
import { ConnectionPoints } from "../../types/ConnectionPoints";
import { Location, useParams, useSearchParams } from "react-router-dom";
import { Params } from "react-router";
import { ConnectionPointSearchContextProvider } from "./context";

type SearchParams = {
  connectionPointId: string;
};

export const ConnectionPointSearchContainer: React.FC = () => {
  const {connectionPointId = null} = useParams<SearchParams>();
  const [searchParams] = useSearchParams();
  const [currentState, setState] = useState<ConnectionPoints | null>(null);
  const connectionPointIdSearchParam = searchParams.get("connectionPointId");
  const meterId = searchParams.get("meterId");
  const connectionPointIdParam = connectionPointIdSearchParam ? connectionPointIdSearchParam : connectionPointId;

  useConnectionPointSearch(setState, {
    connectionPointId: connectionPointIdParam,
    meterId: meterId ? meterId : null
  });

  return (
    <ConnectionPointSearchContextProvider searchParam={connectionPointIdParam || meterId}>
      <ConnectionPointSearch content={currentState}/>
    </ConnectionPointSearchContextProvider>
  );
};

export const goToConnectionPointSearch = ({connectionPointId}: Params, location: Location): () => void =>
  () => window.location.href = `/connectionpoint?connectionPointId=${connectionPointId}&${location.search.replace("?", "")}`;
