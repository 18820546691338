import React, { useCallback, useMemo, useState } from "react";
import { Box, Stack, TextField, useTheme } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Moment } from "moment-timezone";
import { DataStreamIntervalsSummary } from "../DataStreamIntervalsSummary";
import { datetimeValueFormatter, dateValueGetter, styleDataGridHeader } from "../../commons/dataGrid";
import { DATE_PATTERN } from "../../commons/dates";
import { i18n } from "../../global/i18n";
import { DataStreamIntervalNoRowsOverlay, displayUnitOfMeasureColumnHeader } from "../../pages/DataStreamDetails/DataStreamDetails";
import { EMPTY, IntervalsQuality } from "../../types/IntervalQuality";

interface Props {
  content: IntervalsQuality;
  date: Moment;
  onSelectDate: (date: Moment) => void;
  loading: boolean;
  unitOfMeasure: string;
}

export const DataStreamQualityIntervals: React.FC<Props> = ({
  content,
  date,
  onSelectDate,
  loading = true,
  unitOfMeasure,
}) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState<Moment>(date);

  const handleDateChange = useCallback(
    (newDate: Moment | null) => {
      if (!newDate || !newDate.isValid() || newDate.isSame(date)) {
        return;
      }

      setSelectedDate(newDate);
      onSelectDate(newDate);
    },
    [date, onSelectDate],
  );

  const rows = useMemo(() => {
    return content.intervals.map((item, index) => ({
      id: index,
      ...item,
      dateTime: item.dateTime,
      events: item.events.join(",").replaceAll(",", ", ")
    }));
  }, [content]);

  const dataGridComponents = useMemo(() => {
    if (!loading && content === EMPTY) {
      return {
        components: {
          NoRowsOverlay: DataStreamIntervalNoRowsOverlay,
        },
      };
    }
  }, [loading, content]);

  const columns = useMemo(() => {
    const columnsValueDef: GridColumns = [
      {
        field: "dateTime",
        headerName: i18n.t("DATA_STREAM_INTERVALS.HEADERS.DATETIME", {
          ns: "component",
        }),
        valueGetter: dateValueGetter,
        type: "dateTime",
        valueFormatter: datetimeValueFormatter,
      },
      {
        field: "latestQuantity",
        headerName: displayUnitOfMeasureColumnHeader(unitOfMeasure),
        sortable: false,
      },
      {
        field: "events",
        headerName: i18n.t("DATA_STREAM_INTERVALS.HEADERS.EVENT", {
          ns: "component",
        }),
        sortable: false,
      }
    ];

    return styleDataGridHeader(columnsValueDef);
  }, [unitOfMeasure]);

  return (
    <Box
      data-test={"datastream-interval"}
      sx={{
        width: 1,
        "& .grid-header-column--header": {
          backgroundColor: "rgba(0, 0, 0, 0.03)",
          color: "text.secondary",
        },
        "& .MuiDataGrid-root": {
          border: 0,
          position: "relative",
        },
      }}
    >
      <Box
        data-test={"page-heading-exceptions"}
        sx={{
          mb: theme.spacing(2),
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          {i18n.t("DATA_STREAM_INTERVALS.INTERVALS_HEADING", {
            ns: "component",
          })}
        </Box>
        <Box data-test={"intervals"}>
          <Stack
            spacing={2}
            direction={"row"}
            sx={{
              ".date-picker > .MuiFormControl-root.MuiTextField-root": {
                minHeight: "40px",
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <Box
              data-test={"interval-date"}
              sx={{ minHeight: "40px" }}
              className={"date-picker"}
            >
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                inputFormat={DATE_PATTERN}
                disableFuture
                renderInput={(params) => (
                  <TextField
                    sx={{ maxWidth: 200, input: { fontSize: 14 } }}
                    {...params}
                  />
                )}
                desktopModeMediaQuery={"@media (min-width:368px)"}
              />
            </Box>
          </Stack>
        </Box>
      </Box>
      <DataStreamIntervalsSummary
        ingestionDateTime={content.ingestionDateTime}
        processedDateTime={content.processedDateTime}
      />
      <Box
        sx={{
          height: 400,
          width: "100%",
          "& .exception-row": {
            background: "rgba(244, 67, 54, 0.08)"
          },
          "& .exception-row:hover": {
            background: "rgba(244, 67, 54, 0.08) !important"
          },
          "& .exception-cell": {
            color: "#E31B0C",
          }
        }}
      >
        <DataGridPro
          autoHeight
          hideFooterPagination
          hideFooterSelectedRowCount
          disableColumnMenu
          disableColumnReorder
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: 'dateTime', sort: 'asc' }],
            },
          }}
          rows={rows}
          columns={columns}
          loading={loading}
          {...dataGridComponents}
          localeText={{footerTotalRows: i18n.t("EXCEPTION_PAGE.TOTAL_ROWS", { ns: "page",})}}
        />
      </Box>
    </Box>
  );
};
