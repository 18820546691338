import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { SearchResultTag } from "../SearchResultTag";
import { i18n } from "../../global/i18n";

interface Props {
  connectionPointId: string;
  meterId: string;
  commodity: string;
  serialNumber: string;
  dataStreamName: string;
  intent: string;
}

export const getConnectionPointLink = (id: string, overlay: boolean): string => {
  return `/connectionpoint/${id}${overlay ? `?overlay=true` : ''}`;
}

export const getMeterLink = (connectionPointId: string, id: string, overlay: boolean): string => {
  return `/connectionpoint/${connectionPointId}/meter/${id}${overlay ? `?overlay=true` : ''}`;
};

export const getDataStreamLink = (connectionPointId: string, meterId: string, name: string, intent: string, overlay: boolean): string => {
  return `/connectionpoint/${connectionPointId}/meter/${meterId}/datastream/${name}/${intent}${overlay ? `?overlay=true` : ''}`;
};

/**
 * Remove commodity from connectionPointId
 *
 * ex: (123-ELECTRICITY, ELECTRICITY)
 *      => return "123"
 * @param connectionPointId
 * @param commodity
 * @returns
 */
export const getConnectionPointNumberFromConnectionPointId = (connectionPointId = "", commodity = ""): string => {
  return connectionPointId.substring(0, connectionPointId.length - (commodity.length + 1));
}

export const DataStreamBreadCrumbs: React.FC<Props> = ({ connectionPointId, meterId, commodity, serialNumber, dataStreamName, intent }) => {
  const [ params ] = useSearchParams();
  const overlay = Boolean(params.get("overlay"));
  const isContentDisplay = serialNumber.length > 0 ;

  return (
    <Box role="presentation">
      <Breadcrumbs aria-label="breadcrumb" data-test={"datastream-breadcrumb"}>
        <Typography component={"span"} sx={{ fontSize: "0.8rem" }}>
          <SearchResultTag color="#FFDAC3" fonstSize="0.8rem">
            {i18n.t("CONNECTION_POINT_SEARCH_TOGGLE.CONNECTION_POINT", {ns: "component"})}
          </SearchResultTag>
          {isContentDisplay && (
            <Link to={getConnectionPointLink(connectionPointId, overlay)}>
              {getConnectionPointNumberFromConnectionPointId(connectionPointId, commodity)}
            </Link>
          )}
        </Typography>

        <Typography component={"span"} sx={{ fontSize: "0.8rem" }}>
          <SearchResultTag color="#2196F34A" fonstSize="0.8rem">
            {i18n.t("CONNECTION_POINT_SEARCH_TOGGLE.METER", {ns: "component"})}
          </SearchResultTag>
          {isContentDisplay && (
            <Link to={getMeterLink(connectionPointId, meterId, overlay)}>{serialNumber}</Link>
          )}
        </Typography>

        <Typography component={"span"} sx={{ fontSize: "0.8rem" }}>
          <SearchResultTag color="#0000001F" fonstSize="0.8rem">
            {i18n.t("DATA_STREAMS_RESULT.DATASTREAM", {ns: "component"})}
          </SearchResultTag>
          {isContentDisplay && (
            <Link to={getDataStreamLink(connectionPointId, meterId, dataStreamName, intent, overlay)}>{dataStreamName}</Link>
          )}
        </Typography>
      </Breadcrumbs>
    </Box>
  );
};
