import React, { useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { NamedItem } from "../NamedItem";
import { SeparatedList } from "../SeparatedList";
import { formatDateTime } from "../../commons/dates";
import { useTranslation } from "react-i18next";

interface Props {
  ingestionDateTime: string
  processedDateTime: string
}

export const convertToDateForDisplay = (dateTime: string): string => {
  return formatDateTime(dateTime);
}

export const DataStreamIntervalsSummary: React.FC<Props> = ({
  ingestionDateTime,
  processedDateTime,
}) => {
  const { t }  = useTranslation("component");
  const theme = useTheme();

  const ingestionDateTimeForDisplay = useMemo(
    () => convertToDateForDisplay(ingestionDateTime),
    [ingestionDateTime]
  );

  const processedDateTimeForDisplay = useMemo(
    () => convertToDateForDisplay(processedDateTime),
    [processedDateTime]
  );

  return (
    <Box
      data-test={"datastream-summary"}
      sx={{
        border: "1px solid #E8E8E8",
        borderRadius: "4px",
        minHeight: "50px",
        display: "flex",
        alignItems: "center",
        padding: `0 ${theme.spacing(2)}`,
        marginBottom: theme.spacing(2),
      }}
    >
      <SeparatedList>
        <NamedItem
          label={t("DATA_STREAM_INTERVALS_SUMMARY.INGESTION_DATETIME")}
          content={ingestionDateTimeForDisplay}
          sx={{ minWidth: "275px" }}
        />
        <NamedItem
          label={t("DATA_STREAM_INTERVALS_SUMMARY.PROCESSED_DATETIME")}
          content={processedDateTimeForDisplay}
          sx={{ minWidth: "275px" }}
        />
      </SeparatedList>
    </Box>
  );
}