import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { Moment } from "moment-timezone";
import { Button } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LoadingContext } from "../../context/LoadingContext";
import { SelectItemType } from "../../types/SelectItemType";
import { DataStream } from "../../types/DataStream";
import { getEmail } from "../../commons/userInfo";
import { useSnackbarMessage } from "../../hooks/useSnackbarMessage";
import { useApplyManualSubstitution } from "../../hooks/useApplyManualSubstitution";
import { UserDefinedScenarioDialog } from "../UserDefinedScenarioDialog";
import { ManualSubstitutionDataStream } from "../../types/ManualSubstitutionDataStream";
import { useTranslation } from "react-i18next";

export enum ApplyResult {
  SUCCESS = "success",
  FAIL = "fail",
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (result: ApplyResult): void => {
  // ignore
};

interface Props {
  meterId: string;
  selectedDataStreams?: DataStream[];
  onFinish?: (result: ApplyResult) => void;
  canApplyFutureUds: boolean;
}

type SearchParams = {
  connectionPointId: string;
};

export const ApplySubScenarioButton: React.FC<Props> = ({
  meterId,
  selectedDataStreams = [],
  onFinish = noop,
  canApplyFutureUds
}) => {

  const { t } = useTranslation("component");
  const { connectionPointId = "" } = useParams<SearchParams>();
  const { setLoading } = useContext(LoadingContext);
  const { showSnackMessage } = useSnackbarMessage();
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [{ error: apiError, loading: apiCalling, isFinished: apiCallingFinished }, applyManualSubstitution] = useApplyManualSubstitution();

  const handleApplyClicked = () => {
    setIsOpenDialog(true);
  };

  const handleCancel = () => {
    setIsOpenDialog(false);
  }

  const handleOkayClicked = async (
    methods: string[],
    dateRangeType: string,
    scenario: SelectItemType | null,
    quality: SelectItemType | null,
    reasonCode: SelectItemType | null,
    dateRange: DateRange<Moment>,
    manualSubstitutionDataStreams: ManualSubstitutionDataStream[],
  ) => {

    if (isEmpty(selectedDataStreams) || !dateRange[0] || !dateRange[1]) {
      return;
    }

    await applyManualSubstitution(dateRangeType, {
      connectionPointId,
      meterId,
      dateFrom: dateRange[0],
      dateTo: dateRange[1],
      userId: getEmail(),
      scenario: scenario?.value || "",
      scenarioLabel: scenario?.label || "",
      qualityFlag: quality?.qualityFlag || "",
      qualityDescription: quality?.qualityDescription || "",
      reasonCode: reasonCode?.reasonCode || "",
      reasonCodeDescription: reasonCode?.reasonCodeDescription || "",
      dataStreams: manualSubstitutionDataStreams,
      methods,
    });

    handleCancel();
  }

  useEffect(() => {
    if (apiError) {
      showSnackMessage({
        type: "error",
        dataTestPrefix: "apply-manual-substitution",
        title: t("APPLY_SUB_SCENARIO_BUTTON.SAVE_ERROR"),
      });
      onFinish(ApplyResult.FAIL);
    }
  }, [apiError, showSnackMessage]);

  useEffect(() => {
    setLoading(apiCalling);
  }, [apiCalling]);

  useEffect(() => {
    if (apiCallingFinished && !apiError && !apiCalling) {
      onFinish(ApplyResult.SUCCESS);
      showSnackMessage({
        dataTestPrefix: "apply-manual-substitution",
        title: t("APPLY_SUB_SCENARIO_BUTTON.SAVE_SUCCESSED"),
      });
    }
  }, [apiCallingFinished]);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        sx={{ textTransform: "none" }}
        disabled={!selectedDataStreams.length}
        data-test={"apply-substitution-scenario-button"}
        onClick={handleApplyClicked}
      >
        {t("APPLY_SUB_SCENARIO_BUTTON.APPLY_SUB_SCENARIO")}
      </Button>

      {isOpenDialog && (
        <UserDefinedScenarioDialog
          selectedDataStreams={selectedDataStreams}
          open={isOpenDialog}
          onCancel={handleCancel}
          onOkay={handleOkayClicked}
          canApplyFutureUds={canApplyFutureUds}
        />
      )}
    </>
  );
}
