import { Moment } from "moment";
import { DateRange } from "@mui/x-date-pickers-pro";
import { IntervalVersion } from "./IntervalVersion";

export interface YaxisPlot {
  value: string;
  connectnulldata: boolean;
  aggregation?: string;
  style?: unknown;
  format?: unknown;
}

export interface ChartSchema {
  name: string;
  type: string;
  format?: string;
  plotStyle?: string;
  plotColor?: string;
}

export interface DynamicChartLine {
  [key: string]: string | number;
}

export interface ChartPlot {
  timeXaxis: string;
  plotYaxis: string;
}
export interface DataStreamChartComparisonResponse {
  data: DynamicChartLine[];
  schemas: ChartSchema[];
}

export const EMPTY_CHART_RESPONSE: DataStreamChartComparisonResponse = {
  data: [],
  schemas: []
};

export interface DataStreamChartComparisonState {
  response: DataStreamChartComparisonResponse;
  loading: boolean;
}

export interface DataStreamChartComparisonParams {
  versions: IntervalVersion[];
  meterId: string;
  datastreamName: string;
  intent: string;
  dateRange: DateRange<Moment>;
}

export interface DataStreamChartComparisonVersionParams {
  meterId: string,
  datastreamName: string,
  intent: string,
  dateRange: DateRange<Moment>
}

export interface DataStreamChartComparisonVersionState {
  versions: DataStreamChartComparisonParams[];
  compareVersions?: DataStreamChartComparisonParams[];
  compareOption?: string;
}

export const EMPTY_VERSIONS: DataStreamChartComparisonVersionState = {
  versions: [],
  compareVersions: []
};

export const CHART_COLORS = "0DCF5A,579AFF,F7BE00,F50057,64B6F7,9E9E9E";

export const YESTERDAY = "yesterday";
export const COMPARE = "compare";
export const SAME_PERIOD_LAST_YEAR_OPTION = "same period last year";
export const CUSTOM  = "custom";

export const COMPARISON_SUFFIX = "(comparison)";
export const TIME_SCHEMA = "Time";
export const TIME_COMPARISON_SCHEMA = `Time ${COMPARISON_SUFFIX}`;
