import { useEffect } from "react";
import { Moment } from "moment";
import { DateRange } from "@mui/x-date-pickers-pro";
import { IntervalVersion } from "../types/IntervalVersion";
import { returnDateRangeParams } from "./useDataStreamChart";
import { retrieveChartIntervalVersions } from "../services/IntervalService";

export const useDataStreamChartVersions = (
  setLoading: (state: boolean) => void,
  setError: (state: string) => void,
  setState: (state: IntervalVersion[]) => void,
  meterId: string,
  datastreamName: string,
  intent: string,
  dateRange: DateRange<Moment>,
): void => {

  useEffect(() => {
    if (!datastreamName) {
      return;
    }

    setLoading(true);

    const dateRangeParam = returnDateRangeParams(dateRange);

    retrieveChartIntervalVersions(meterId, datastreamName, intent, dateRangeParam.start, dateRangeParam.end)
    .then((versions: IntervalVersion[]) => {
      if(versions.length == 0){
        setLoading(false);
      }
      setState(versions);
      setError("");
    })
    .catch((error) => {
      setLoading(false);
      setState([]);
      setError(error.message);
    });
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [meterId, datastreamName, dateRange]);
}
