/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useContext, useEffect, useMemo, useState, } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AlertColor, Box, Link, Skeleton } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridComparatorFn,
  gridNumberComparator,
  GridRenderCellParams,
  GridSelectionModel,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { MeterExceptionCount } from "../../types/MeterExceptionCount";
import { EMPTY_LIST } from "../../types/ExceptionListState";
import { ParamMeterExceptionService, ResultParamMeterExceptionService, } from "../../types/MeterExceptions";
import { HyperlinkTextWithTag } from "../HyperlinkTextWithTag";
import { ExceptionActionOptions } from "../ExceptionActionOptions";
import {
  useBulkExceptionAction,
  useBulkExceptionStatusAction,
  useExceptionAction,
  useExceptionStatusAction,
} from "../../hooks/useExceptionAction";
import { LoadingContext } from "../../context/LoadingContext";
import { SubScenarioDialog } from "../SubScenarioDialog";
import { SelectItemType } from "../../types/SelectItemType";
import { FileUploadDialog } from "../FileUploadDialog";
import { getActionDetailsBody, mapOptions } from "../Exceptions/Exceptions";
import { IngestionActionUpload } from "../IngestionActionUpload";
import { uploadFile } from "../FileUploadDialog/FileUploadDialog";
import { ErrorMessageList } from "../DeviceConfiguration/DeviceConfiguration";
import { useSnackbarMessage } from "../../hooks/useSnackbarMessage";
import { NoRowsOverlay } from "../NoRowsOverlay";
import { dateValueFormatter, dateValueGetter, styleDataGridHeader } from "../../commons/dataGrid";
import { ExceptionStatusChip } from "../ExceptionStatusChip";
import {i18n} from "../../global/i18n";
import { FeatureFlag } from "../../components/FeatureFlag";

interface Props {
  exceptionType: string;
  content: MeterExceptionCount[];
  postUpdate: () => Promise<void>;
  displayResolutionOptions: boolean;
}

export const ExceptionListTableNoRowsOverlay = (): JSX.Element => <NoRowsOverlay />

export const submitMessages: { [key: string]: string } = {
  default: "",
  success: i18n.t("EXCEPTION_LIST_TABLE.SUCCESS_MESSAGE", {ns: "component"}),
  error: i18n.t("EXCEPTION_LIST_TABLE.ERROR_MESSAGE", {ns: "component"}),
};

export const renderConnectionPointCell = ({ value }: GridRenderCellParams): ReactNode => (
  <HyperlinkTextWithTag
    tag={i18n.t("EXCEPTION_LIST_TABLE.CONNECTION_POINT_TAG", {ns: "component"})}
    tagColor={"#FFDAC3"}
    text={value.connectionPointNumber}
    hyperLink={`/connectionpoint/${value.connectionPointId}`}
  />
);

export const connectionPointCellComparator: GridComparatorFn = (
  v1, v2, param1, param2) => {
  return gridStringOrNumberComparator(
    v1.connectionPointNumber as string,
    v2.connectionPointNumber as string,
    param1,
    param2,
  );
};

export const renderMeterCell = ({value}: GridRenderCellParams): ReactNode => (
  <HyperlinkTextWithTag
    tag={i18n.t("EXCEPTION_LIST_TABLE.METER_TAG", {ns: "component"})}
    tagColor={"#2196F34A"}
    text={value.label}
    hyperLink={value.meterUrl}
  />
);

export const meterCellComparator: GridComparatorFn = (
  v1, v2, param1, param2) => {
  return gridStringOrNumberComparator(
    v1.label as string,
    v2.label as string,
    param1,
    param2,
  );
};

export const renderExceptionCountCell = ({value}: GridRenderCellParams): ReactNode => (
  <Link variant={"body2"} to={value.meterUrl} underline={"hover"} component={RouterLink}>
    {value.count}
  </Link>
);

export const renderExceptionStatus = ({value}: GridRenderCellParams): ReactNode => (
  <ExceptionStatusChip content={value} />
);

const renderActionMenu = (
  { value }: GridRenderCellParams,
  submitActionOption: any,
) =>
  value.displayResolutionOptions 
  ? (
    <ExceptionActionOptions
      content={value.meterId}
      onclick={submitActionOption}
    />
  ) : (
    <></>
  );

export const renderResolutionOptions = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  submitActionOption: any,
): ((params: GridRenderCellParams) => ReactNode) => {
  return (params: GridRenderCellParams): ReactNode =>
    renderActionMenu(params, submitActionOption);
};

export const submitActionOption = (
  exceptionType: string,
  setLoading: (loading: boolean) => void,
  setResult: (result: string) => void,
  postUpdate: () => Promise<void>,
  setMeterIdForSubScenarioDialog: (meterId: string) => void,
) => {
  return (event: any, value: string): void => {
    const dataValue = event.target.getAttribute("data-test");
    const { actionValue, option } = mapOptions[dataValue];
    if (actionValue === "substitution") {
      return setMeterIdForSubScenarioDialog(value);
    }

    setResult("loading");
    
    const actionDetailsBody = getActionDetailsBody(actionValue, option);

    const body = option ? { option, actionDetails: actionDetailsBody } : { actionDetails: actionDetailsBody };
    
    let useFunctionService = useExceptionAction;
    if (actionValue === "pending" || actionValue === "close") {
      useFunctionService = useExceptionStatusAction;
    }
    useFunctionService(
      setLoading,
      setResult,
      value,
      exceptionType,
      actionValue,
      body,
      postUpdate,
    );
  };
};

export const exceptionCountComparator: GridComparatorFn = (v1, v2,
  param1, param2) => {
  return gridNumberComparator(
    v1.count as number,
    v2.count as number,
    param1,
    param2,
  );
};

export const getLocalText = () => ({
  footerRowSelected: (count: number) => {
    // FIXME a work around because the built in pluralization does not work with our format of the language code like en_au
    const rowSelectedKey = `EXCEPTION_LIST_TABLE.FOOTER.ROW_SELECTED_LABEL_${count === 1 ? "one" : "other"}`;
    return i18n.t(rowSelectedKey, { ns: "component", count: count })
  },
  MuiTablePagination: {
    labelRowsPerPage: i18n.t("EXCEPTION_LIST_TABLE.FOOTER.PAGINATION_ROWS_PER_PAGE_LABEL",
      {ns: "component"}),
    labelDisplayedRows: ({from, to, count} : {from: number, to: number, count: number}) =>
      i18n.t("EXCEPTION_LIST_TABLE.FOOTER.PAGINATION_DISPLAYED_ROWS_LABEL",
        {ns: "component", from: from, to: to, count: count})
  }
})

interface IStateMessage {
  message: string | ReactNode;
  openMessage: boolean;
  type: AlertColor;
  messageItems: MeterExceptionCount[];
}

export const initialStateMessage: IStateMessage = {
  message: "",
  openMessage: false,
  type: "success",
  messageItems: [],
};

export const ExceptionListTable: React.FC<Props> = ({
  exceptionType,
  content,
  displayResolutionOptions,
  postUpdate,
}) => {
  const [result, setResult] = useState<string | ResultParamMeterExceptionService>("");
  const [fileUploadResult, setFileUploadResult] = useState<string | any>("");
  const [stateMessage, setStateMessage] = useState<IStateMessage>(initialStateMessage);
  const [meterIdForSubScenarioDialog, setMeterIdForSubScenarioDialog] = useState<string | null>(null);
  const [typeFileUpload, setTypeFileUpload] = useState<string | null>(null);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { setLoading } = useContext(LoadingContext);

  const closeSubScenarioDialog = () => {
    setMeterIdForSubScenarioDialog(null);
  };

  const applySubScenario = (
    meterId: string,
    scenario: SelectItemType,
    quality: SelectItemType,
    reasonCode: SelectItemType,
  ) => {
    setResult("loading");

    const actionDetail = {
      scenario: scenario.label,
      quality: quality.label,
      reasonCode: reasonCode.label,
      method: scenario.methods?.map(item => item.value).join(",")
    };

    const mapOption = mapOptions["Apply Sub scenario"];
    const actionDetailsBody = getActionDetailsBody(mapOption.actionValue, mapOption.option, actionDetail);

    if (meterIdForSubScenarioDialog !== "bulk") {
      const bodyData = {
        scenario: scenario.value,
        qualityFlag: quality.qualityFlag,
        qualityDescription: quality.qualityDescription,
        reasonCode: reasonCode.reasonCode,
        reasonCodeDescription: reasonCode.reasonCodeDescription,
        actionDetails: actionDetailsBody
      };

      useExceptionAction(
        setLoading,
        setResult,
        meterId,
        exceptionType,
        "substitution",
        bodyData,
        postUpdate,
      );
    } else {
      const selectedRows = selectionModel.map((item) => rows[Number(item)]);
      const params: ParamMeterExceptionService[] = selectedRows.map((item) => ({
        params: item.resolutionOptions,
        action: "substitution",
        bodyData: {
          scenario: scenario.value,
          qualityFlag: quality.qualityFlag,
          qualityDescription: quality.qualityDescription,
          reasonCode: reasonCode.reasonCode,
          reasonCodeDescription: reasonCode.reasonCodeDescription,
          actionDetails: actionDetailsBody
        },
      }));

      setResult("loading");
      useBulkExceptionAction(setLoading, setResult, params, postUpdate);
    }
    // To close Substitution Scenario Dialog
    setMeterIdForSubScenarioDialog(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submitBulkAction = (event: any, value: any) => {
    const dataValue: string = event.target.getAttribute("data-test");
    const { actionValue, option } = mapOptions[dataValue];
    if (actionValue === "substitution") {
      setMeterIdForSubScenarioDialog("bulk");
      return;
    }

    const actionDetailsBody = getActionDetailsBody(actionValue, option);

    const selectedRows = selectionModel.map((item) => rows[Number(item)]);

    const params: ParamMeterExceptionService[] = selectedRows.map((item) => ({
      params: item.resolutionOptions,
      action: actionValue,
      bodyData: { option, actionDetails: actionDetailsBody },
    }));

    setResult("loading");
    let useFunctionService = useBulkExceptionAction;
    if (actionValue === "pending" || actionValue === "close") {
      useFunctionService = useBulkExceptionStatusAction;
    }

    return useFunctionService(setLoading, setResult, params, postUpdate);
  };

  const openFileUploadDialog = (typeFileUpload: string) => {
    setTypeFileUpload(typeFileUpload);
  };

  const closeFileUploadDialog = () => {
    setTypeFileUpload(null);
  };

  const handleCloseMessage = (): void => {
    setStateMessage({ ...stateMessage, openMessage: false });
  };

  const { showSnackMessage } = useSnackbarMessage();

  useEffect(() => {
    if (!stateMessage.openMessage) {
      return;
    }

    if (fileUploadResult.length > 0) {
      showSnackMessage({
        type: stateMessage.type,
        onClose: handleCloseMessage,
        showExpandData: stateMessage.messageItems.length > 0,
        dataTestPrefix: "upload",
        title: stateMessage.message,
        body: (
          <ErrorMessageList errorMessages={stateMessage.messageItems} />
        )
      });
    } else {
      showSnackMessage({
        type: stateMessage.type,
        onClose: handleCloseMessage,
        showExpandData: stateMessage.messageItems.length > 0,
        dataTestPrefix: "bulk",
        title: stateMessage.message,
        body: (
          <>
            {stateMessage.messageItems.map((item: MeterExceptionCount, index: number) => (
              <Link
                sx={{ mt: 1 }}
                data-test={"bulk-details-error-message"}
                key={index}
                underline="hover"
                color="inherit"
                to={`/connectionpoint/${item.connectionPointId}/meter/${item.meterId}`}
                component={RouterLink}
              >
                {`${i18n.t("EXCEPTION_LIST_TABLE.CONNECTION_POINT_TAG", {ns: "component"})} ${item.connectionPointNumber}
                 / ${i18n.t("EXCEPTION_LIST_TABLE.METER_TAG", {ns: "component"})} ${item.meterSerial}`}
              </Link>
            ))}
          </>
        )
      });
    }

  }, [stateMessage, showSnackMessage]);

  useEffect(() => {
    // TODO we maybe want to keep the sub-scenario dialog when failed.
    if (result === "success" || result === "fail") {
      const newStateMessage = { ...stateMessage, messageItems: [] };
      let type: AlertColor = "error";
      if (result === "success") {
        type = "success";
      }

      newStateMessage.openMessage = true;
      newStateMessage.message = submitMessages[type];
      newStateMessage.type = type;

      // TODO we maybe want to keep the sub-scenario dialog when failed.
      closeSubScenarioDialog();
      setStateMessage(newStateMessage);
      setFileUploadResult("");

    } else if (typeof result === "object") {
      // output bulk exception
      const newStateMessage = { ...stateMessage, openMessage: true };
      // TODO we maybe want to keep the sub-scenario dialog when failed.
      if (result.errors.length === 0) {
        newStateMessage.message = i18n.t("EXCEPTION_LIST_TABLE.BULK_SUCCESS_MESSAGE",
          {ns: "component", count: result.success.length});
        newStateMessage.type = "success";
        newStateMessage.messageItems = [];
        
      } else {
        newStateMessage.message = (
          <>
            <Box sx={{ display: "inline" }}>
              {i18n.t("EXCEPTION_LIST_TABLE.BULK_ERROR_MESSAGE",
                {ns: "component", count: result.errors.length})}
            </Box>
            <Box sx={{ color: "#F88078", display: "inline" }}>
              {" "}{i18n.t("EXCEPTION_LIST_TABLE.BULK_TOTAL_ERROR_MESSAGE",
              {ns: "component", count: result.success.length + result.errors.length})}
            </Box>
          </>
        );
        newStateMessage.type = "error";
        newStateMessage.messageItems = result.errors.map(
          (item) => content[item.params.index],
        );
      }
      
      closeSubScenarioDialog();
      setStateMessage(newStateMessage);
      setFileUploadResult("");
    }
  }, [result]);

  useEffect(() => {
    if (typeFileUpload) {
      closeFileUploadDialog();
    }

    if (fileUploadResult !== "" && fileUploadResult !== "loading") {
      const newStateMessage = { ...stateMessage };
      newStateMessage.openMessage = true;

      if (fileUploadResult === "success" || fileUploadResult === "fail") {
        const type: AlertColor = fileUploadResult === "success" ? "success" : "error";
        newStateMessage.type = type;
        newStateMessage.message = submitMessages[type];
        newStateMessage.messageItems = [];
      } else {
        newStateMessage.type = "error";
        newStateMessage.message = (<Box>
          {fileUploadResult.length} {i18n.t("FILE_UPLOAD_DIALOG.ERROR_LIST", {ns: "component"})}
        </Box>);
        newStateMessage.messageItems = fileUploadResult;
      }
      setStateMessage(newStateMessage);
    }

    closeSubScenarioDialog();
    
  }, [fileUploadResult]); 

  const rows = useMemo(() => {
    return content.filter(item => item.status?.toLocaleLowerCase() !== 'closed').map((item, index) => ({
      id: index,
      ...item,
      connectionPoint: {
        connectionPointId: item.connectionPointId,
        connectionPointNumber: item.connectionPointNumber,
      },
      meterLink: {
        meterUrl: `/connectionpoint/${item.connectionPointId}/meter/${item.meterId}`,
        label: item.meterSerial,
      },
      exceptions: {
        count: item.exceptionCount,
        meterUrl: `/connectionpoint/${item.connectionPointId}/meter/${item.meterId}`,
      },
      resolutionOptions: {
        index,
        meterId: item.meterId,
        meterSerial: item.meterSerial,
        exceptionType,
        displayResolutionOptions,
      },
    }));
  }, [content]);

  const getColumnValueDef = () : GridColDef[] => ([
    {
      field: "connectionPoint",
      headerName: i18n.t("EXCEPTION_LIST_TABLE.HEADERS.CONNECTION_POINT", {ns: "component"}),
      sortComparator: connectionPointCellComparator,
      renderCell: renderConnectionPointCell,
    },
    {
      field: "meterLink",
      headerName: i18n.t("EXCEPTION_LIST_TABLE.HEADERS.METER", {ns: "component"}),
      sortComparator: meterCellComparator,
      renderCell: renderMeterCell,
    },
    {
      field: "exceptions",
      headerName: i18n.t("EXCEPTION_LIST_TABLE.HEADERS.EXCEPTIONS", {ns: "component"}),
      renderCell: renderExceptionCountCell,
      sortComparator: exceptionCountComparator,
    },
    {
      field: "status",
      headerName: i18n.t("EXCEPTION_LIST_TABLE.HEADERS.STATUS", {ns: "component"}),
      renderCell: renderExceptionStatus,
    },
    {
      field: "newestDate",
      headerName: i18n.t("EXCEPTION_LIST_TABLE.HEADERS.NEWEST_DATE", {ns: "component"}),
      type: "date",
      valueGetter: dateValueGetter,
      valueFormatter: dateValueFormatter,
    },
    {
      field: "oldestDate",
      headerName: i18n.t("EXCEPTION_LIST_TABLE.HEADERS.OLDEST_DATE", {ns: "component"}),
      type: "date",
      valueGetter: dateValueGetter,
      valueFormatter: dateValueFormatter,
    }
  ]);
  const columns = styleDataGridHeader(getColumnValueDef());
  const resolutionOptionHeaderDef = {
    field: "resolutionOptions",
    headerName: i18n.t("EXCEPTION_LIST_TABLE.HEADERS.ACTIONS", {ns: "component"}),
    renderCell: renderResolutionOptions(
      submitActionOption(
        exceptionType,
        setLoading,
        setResult,
        postUpdate,
        setMeterIdForSubScenarioDialog,
      ),
    ),
    width: 80,
    headerClassName:
      "grid-header-column--header grid__header-name--transparent",
    disableColumnMenu: true,
    sortable: false,
  }
  columns.push(resolutionOptionHeaderDef);

  if (content === EMPTY_LIST) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  }

  return (
    <>
      <Box
        data-test={"page-heading-exceptions"}
        sx={{
          mb: 0,
          display: "flex",
          justifyContent: "space-between",
          height: "56px",
        }}
      >
        <Box sx={{ verticalAlign: "middle", p: "1% 1% 1% 32px" }}>
          {exceptionType !== "Multiple" && selectionModel.length > 0 && (
            <ExceptionActionOptions
              type="button"
              anchorOriginType="left"
              content={""}
              onclick={submitBulkAction}
            />
          )}
          <FeatureFlag flag="enable_file_upload">
            <IngestionActionUpload
              onClick={openFileUploadDialog}
              sx={{ml: exceptionType !== "Multiple" && selectionModel.length > 0 ? 1 : 0}}
            />
          </FeatureFlag>
        </Box>
      </Box>
      <Box
        data-test={"exception-list"}
        sx={{
          height: 300,
          width: 1,
          pl: "32px",
          pr: "32px",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(0, 0, 0, 0.03)",
            color: "text.secondary",
          },
          "& .MuiDataGrid-root": {
            border: 0,
          },
          "& .grid__header-name--transparent": {
            color: "rgba(0, 0, 0, 0)",
          },
          pb: 3,
        }}
      >
        <DataGridPro
          disableColumnMenu
          disableColumnReorder
          disableSelectionOnClick
          checkboxSelection={true}
          autoHeight={true}
          rows={rows}
          columns={columns}
          pagination={true}
          initialState={{
            pagination: {
              pageSize: 25,
            },
            sorting: {
              sortModel: [{field: "newestDate", sort: "desc"}],
            },
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          components={{NoRowsOverlay: ExceptionListTableNoRowsOverlay}}
          localeText={getLocalText()}
        />

        {meterIdForSubScenarioDialog && (
          <SubScenarioDialog
            open
            meterId={meterIdForSubScenarioDialog}
            onOkay={applySubScenario}
            onCancel={closeSubScenarioDialog}
          />
        )}

        {typeFileUpload !== null && (
          <FileUploadDialog
            type= {typeFileUpload}
            onCancel={closeFileUploadDialog}
            onOkay={(files: File[]) => uploadFile(files, setLoading, setFileUploadResult, typeFileUpload)}
          />
        )}
      </Box>
    </>
  );
};
