import React, { useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { ConnectionPoints } from "../../types/ConnectionPoints";
import { ConnectionPointSearchResult } from "../ConnectionPointSearchResult";
import { Box } from "@mui/system";
import { ConnectionPoint } from "../../types/ConnectionPoint";
import { useTranslation } from "react-i18next";

const visuallyHidden: import("react").CSSProperties = {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: "1px",
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  whiteSpace: "nowrap",
  width: "1px",
};

interface Props {
  content: ConnectionPoints;
  disableOrder: boolean;
}

type Order = "asc" | "desc";
interface IPropsTableSortItem {
  order: Order;
  orderBy: keyof ConnectionPoint;
  disableOrder: boolean;
  label: string;
  field: keyof ConnectionPoint;
  handleOrder: (property: keyof ConnectionPoint) => (event: React.MouseEvent<unknown>) => void;
}

export const TableSortItem: React.FC<IPropsTableSortItem> = ({
  disableOrder,
  order,
  orderBy,
  label,
  field,
  handleOrder,
}) => {
  const { t } = useTranslation("component");
  if (disableOrder) {
    return <span>{label}</span>;
  }
  return (
    <TableSortLabel
      active={orderBy === field}
      direction={orderBy === field ? order : "asc"}
      onClick={handleOrder(field)}
    >
      <span>{label}</span>
      {orderBy === field ? (
        <Box component="span" sx={visuallyHidden}>
          {order === "desc" ? t("SEARCH_RESULTS_TABLE.SORTED_DESCENDING") : t("SEARCH_RESULTS_TABLE.SORTED_ASCENDING")}
        </Box>
      ) : null}
    </TableSortLabel>
  );
};

export function getComparator(
  a: ConnectionPoint,
  b: ConnectionPoint,
  order: Order,
  orderBy: keyof ConnectionPoint,
): number {
  let result = 0;
  if (a[orderBy] < b[orderBy]) {
    result = -1;
  } else if (a[orderBy] > b[orderBy]) {
    result = 1;
  }
  return order === "asc" ? result : -result;
}

export const SearchResultsTable: React.FC<Props> = ({
  content,
  disableOrder,
}) => {
  const { t } = useTranslation("component");
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof ConnectionPoint>(
    "changeEffectiveDate",
  );

  const handleOrder = (property: keyof ConnectionPoint) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const rows = useMemo(() => {
    return content.connectionPoints.sort((a, b) => getComparator(a, b, order, orderBy));
  }, [content.connectionPoints, order, orderBy]);

  return (
    <Box sx={{ px: 4, pt: 4 }}>
      <TableContainer sx={{ borderRadius: "4px 4px 0px 0px" }}>
        <Table data-test={"search-results"}>
          <TableHead sx={{ bgcolor: "background.paper" }}>
            <TableRow>
              <TableCell sortDirection="desc" data-test={"search-results-type-information"}>
                <TableSortItem
                  label={t("SEARCH_RESULTS_TABLE.HEADERS.TYPE_INFORMATION")}
                  disableOrder={disableOrder}
                  field="number"
                  order={order}
                  orderBy={orderBy}
                  handleOrder={handleOrder}
                />
              </TableCell>
              <TableCell data-test={"search-results-mip-start-datetime"}>
                <TableSortItem
                  label={t("SEARCH_RESULTS_TABLE.HEADERS.MIP_START_DATETIME")}
                  disableOrder={disableOrder}
                  field="changeEffectiveDate"
                  order={order}
                  orderBy={orderBy}
                  handleOrder={handleOrder}
                />
              </TableCell>
              <TableCell data-test={"search-results-meter-status"}>
                <span>{t("SEARCH_RESULTS_TABLE.HEADERS.METER_STATUS")}</span>
              </TableCell>
              <TableCell data-test={"search-results-datastream-status"}>
                <span>{t("SEARCH_RESULTS_TABLE.HEADERS.DS_STATUS")}</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((connectionPoint, index) => (
              <ConnectionPointSearchResult
                content={connectionPoint}
                key={index}
                isExpanded={rows.length === 1}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
