import { useCallback, useState } from "react";
import { applyManualSubstitution, ManualSubstitutionRequest } from "../services/MeterDetailsService";

interface ApplyManualSubstitutionResponseValues {
  error?: string;
  loading: boolean;
  isFinished: boolean;
}

export type ApplyManualSubstitutionResult = [
  ApplyManualSubstitutionResponseValues,
  (dateRangeType: string, params: ManualSubstitutionRequest) => Promise<string | void>,
];

export const useApplyManualSubstitution = (): ApplyManualSubstitutionResult => {

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const apply = useCallback((dateRangeType: string, params: ManualSubstitutionRequest): Promise<string | void> => {
    setError("");
    setLoading(true);
    setIsFinished(false);

    return applyManualSubstitution(dateRangeType, params)
      .catch(error => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setIsFinished(true);
      });

  }, []);

  return [{ error, loading, isFinished }, apply];

}
