export interface ChurnedDeviceConfigurationState {
  serialNumber: string;
  commodity: string;
  manufacturer: string;
  meteringType: string;
  removalDateTime: string;
  replacementType: string;
}

export const EMPTY: ChurnedDeviceConfigurationState = {
  serialNumber: "",
  commodity: "",
  manufacturer: "",
  meteringType: "",
  removalDateTime: "",
  replacementType: "",
}
