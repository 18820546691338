/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { ReactNode, useMemo } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { PageHeader } from "../../components/PageHeader";
import {
  DataGridPro,
  GridCellValue,
  GridColumns,
  GridComparatorFn,
  GridRenderCellParams,
  gridStringOrNumberComparator,
  GridValueFormatterParams,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { ReportDownloadData } from "../../types/ReportDownloadPageState";
import { ReportDownloadStatusChip } from "../../components/ReportDownloadStatusChip";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import { useReportDownloadLink } from "../../hooks/useReportDownloadPage";
import {
  datetimeValueFormatter,
  dateValueGetter,
  integerValueGetter,
  styleDataGridHeader
} from "../../commons/dataGrid";
import {i18n} from "../../global/i18n";

interface Props {
  content: ReportDownloadData[];
  isLoading: boolean;
}

export const ReportDownloadPageNoRowsOverlay = (): JSX.Element => (
  <NoRowsOverlay>
    <Box
      sx={{justifyContent: "center", display: "flex"}}
      data-test="history-report-download-no-data"
    >
      {i18n.t("NO_ROWS_OVERLAY.NO_DATA", {ns: "component"})}
    </Box>
  </NoRowsOverlay>
);

export const renderCellStatus = ({
  value,
}: GridRenderCellParams): ReactNode => (
  <ReportDownloadStatusChip content={value} onclick={getPresignedUrl} />
);


export const getPresignedUrl = (reportId: string) => {
  return (event: React.MouseEvent<HTMLElement>): void => {
    useReportDownloadLink(reportId);
  };
}

export const formatFileSizeWithDigits = (bytes: number, decimals: number): string => {
  if (bytes === 0) return "0KB";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["KB", "MB", "GB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
};

export const formatFileSize = (
  {value}: GridValueFormatterParams,
): GridCellValue => {
  const fileSize = Number(value);
  return isNaN(fileSize) ? undefined : formatFileSizeWithDigits(fileSize, 0);
}

export const downloadStatusComparator: GridComparatorFn = (
  v1, v2, param1, param2) => {
  return gridStringOrNumberComparator(
    v1.status.toLowerCase(),
    v2.status.toLowerCase(),
    param1,
    param2,
  );
};

export const statusValueGetter = (
  {value}: GridValueGetterParams,
): GridCellValue => {
  return value;
}

export const ReportDownloadPage: React.FC<Props> = ({content, isLoading}) => {
  const rows = useMemo(() => {
    return content.map((item, index) => {
      return {
        id: index,
        ...item,
        cellStatus: {
          reportId: item.reportId,
          status: item.status,
        },
      };
    });
  }, [content]);

  const columnValueDef: GridColumns = [
    {
      field: "reportName",
      headerName: i18n.t("REPORT_DOWNLOAD_PAGE.REPORT_NAME", {ns: "page"}),
      flex: 1,
    },
    {
      field: "fileSize",
      headerName: i18n.t("REPORT_DOWNLOAD_PAGE.FILE_SIZE", {ns: "page"}),
      valueGetter: integerValueGetter,
      type: "number",
      align: "left",
      headerAlign: "left",
      valueFormatter: formatFileSize,
      width: 100,
    },
    {
      field: "generatedDateTime",
      headerName: i18n.t("REPORT_DOWNLOAD_PAGE.GENERATED_DATE", {ns: "page"}),
      type: "date",
      valueGetter: dateValueGetter,
      valueFormatter: datetimeValueFormatter,
      width: 200,
    },
    {
      field: "cellStatus",
      headerName: i18n.t("REPORT_DOWNLOAD_PAGE.STATUS", {ns: "page"}),
      renderCell: renderCellStatus,
      valueGetter: statusValueGetter,
      sortComparator: downloadStatusComparator,
      width: 200,
    },
  ];

  const columns = styleDataGridHeader(columnValueDef, {});

  return (
    <Box>
      <PageHeader>
        <Typography variant={"h5"} mb={1} data-test={"page-heading"}>
          {i18n.t("REPORT_DOWNLOAD_PAGE.HEADER", {ns: "page"})}
        </Typography>
      </PageHeader>

      <Box
        sx={{
          width: 1,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(0, 0, 0, 0.03)",
            color: "text.secondary",
          },
          "& .MuiDataGrid-root": {
            border: 0,
          },
          "& .grid__header-name--transparent": {
            color: "rgba(0, 0, 0, 0)",
          },
          p: 4,
        }}
      >
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
            }}
          >
            <CircularProgress sx={{ mt: 0 }} />
          </Box>
        )}
        {!isLoading && (
          <Box data-test={"history-report-list"}>
            <DataGridPro
              disableColumnMenu
              disableColumnReorder
              disableSelectionOnClick
              autoHeight={true}
              rows={rows}
              columns={columns}
              hideFooter={true}
              components={{
                NoRowsOverlay: ReportDownloadPageNoRowsOverlay,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'generatedDateTime', sort: 'desc' }],
                },
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
