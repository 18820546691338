import React, {useCallback, useEffect, useState} from "react";
import { MeterSearchResult } from "./MeterSearchResult";
import { useMeterDetails } from "../../hooks/useMeterDetails";
import { EMPTY, Meter } from "../../types/Meter";
import { LinearProgress } from "@mui/material";
import { useConnectionPointSearchContext } from "../../pages/ConnectionPointSearch/context";

interface Props {
  connectionPointId: string;
  meterId: string;
}

export const MeterSearchResultContainer: React.FC<Props> = ({connectionPointId, meterId}) => {
  const [currentState, setState] = useState<Meter>(EMPTY);
  const { addMeter } = useConnectionPointSearchContext();

  const setError = useCallback((error: string) => {
    if (error) {
      addMeter(connectionPointId, {...EMPTY, id: meterId});
    }
  }, [meterId]);

  useMeterDetails(setState, setError, meterId, connectionPointId);

  useEffect(() => {
    addMeter(connectionPointId, currentState);
  }, [currentState]);

  if (currentState === EMPTY) {
    return <LinearProgress/>;
  }
  return <MeterSearchResult connectionPointId={connectionPointId} content={currentState}/>;
};
