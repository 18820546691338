import { cancelRetrieveIntervals, retrieveIntervalVersions } from "../services/IntervalService";
import { IntervalVersion } from "../types/IntervalVersion";

export const retrieveDataStreamIntervalVersions = (
  setState: (state: IntervalVersion[]) => void,
  setError: (state: string) => void,
  meterId: string,
  datastreamName: string,
  intent: string,
  startDate: string,
  endDate: string,
): void => {

  cancelRetrieveIntervals();
  retrieveIntervalVersions(meterId, datastreamName, intent, startDate, endDate).then(intervalVersions => {
    setError("");

    if (intervalVersions) {
      setState(intervalVersions);
    }
  }).catch((err) => {
    if (err.name === "AbortError") {
      return;
    }
    setError(err.message);
  });
};
