/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  actionMeterException,
  actionMeterExceptionStatus,
} from "../services/ExceptionListService";
import {
  ParamMeterExceptionService,
  ResultParamMeterExceptionService,
} from "../types/MeterExceptions";

export const useExceptionAction = (
  setLoading: (loading: boolean) => void,
  setActionResult: (state: string) => void,
  meterId: string,
  exceptionType: string,
  action: string,
  bodyData: {
    [x: string]: any;
  },
  postUpdate?: () => Promise<void>,
): void => {
  setActionResult("");
  setLoading(true);
  actionMeterException(meterId, exceptionType, action, bodyData)
    .then((response) => {
      handelResponseSuccessSinglePromise(
        response,
        setLoading,
        setActionResult,
        postUpdate,
      );
    })
    .catch(() => {
      setActionResult("fail");
      setLoading(false);
    });
};

export const useExceptionStatusAction = (
  setLoading: (loading: boolean) => void,
  setActionResult: (state: string) => void,
  meterId: string,
  exceptionType: string,
  action: string,
  bodyData: {
    [x: string]: any;
  },
  postUpdate?: () => Promise<void>,
): void => {
  setActionResult("");
  setLoading(true);
  actionMeterExceptionStatus(meterId, exceptionType, action, bodyData)
    .then((response) => {
      handelResponseSuccessSinglePromise(
        response,
        setLoading,
        setActionResult,
        postUpdate,
      );
    })
    .catch(() => {
      setActionResult("fail");
      setLoading(false);
    });
};

export const useBulkExceptionAction = (
  setLoading: (loading: boolean) => void,
  setActionResult: (state: any, paramsReturn?: any) => void,
  params: ParamMeterExceptionService[],
  postUpdate?: () => Promise<void>,
): void => {
  setActionResult("");
  setLoading(true);

  const inputs = params.map((item) =>
    actionMeterException(
      item.params.meterId,
      item.params.exceptionType,
      item.action,
      item.bodyData,
    ),
  );
  handelPromiseAll(inputs, setLoading, setActionResult, params, postUpdate);
};

export const useBulkExceptionStatusAction = (
  setLoading: (loading: boolean) => void,
  setActionResult: (state: any, paramsReturn?: any) => void,
  params: ParamMeterExceptionService[],
  postUpdate?: () => Promise<void>,
): void => {
  setActionResult("");
  setLoading(true);

  const actions = params.map((item) =>
    actionMeterExceptionStatus(
      item.params.meterId,
      item.params.exceptionType,
      item.action,
      item.bodyData,
    ),
  );
  handelPromiseAll(actions, setLoading, setActionResult, params, postUpdate);
};

function handelResponseSuccessSinglePromise(
  response: string,
  setLoading: (loading: boolean) => void,
  setActionResult: (state: any, paramsReturn?: any) => void,
  postUpdate?: () => Promise<void>,
) {
  if (response) {
    setActionResult("success");
    if (postUpdate) {
      postUpdate().finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }
}

function handelPromiseAll(
  actions: Promise<string>[],
  setLoading: (loading: boolean) => void,
  setActionResult: (state: any, paramsReturn?: any) => void,
  params: ParamMeterExceptionService[],
  postUpdate?: () => Promise<void>,
) {
  Promise.allSettled(actions)
    .then((response) => {
      const resultObject: ResultParamMeterExceptionService = {
        success: [],
        errors: [],
      };

      response.forEach((item, index) => {
        if (item.status === "fulfilled") {
          resultObject.success.push(params[index]);
        } else {
          resultObject.errors.push(params[index]);
        }
      });

      const isFetchData = resultObject.success.length > 0;

      if (postUpdate && isFetchData) {
        postUpdate().finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
      setActionResult(resultObject);
    })
    .catch(() => {
      setLoading(false);
      setActionResult("fail");
    });
}
