import { useEffect } from "react";
import { SearchResultComparisonState } from "../types/SearchResultComparisonState";
import { retrieveConnectionPointByConnectionPointId } from "../services/ConnectionPointPageService";
import { DataStream } from "../types/DataStream";
import { retrieveMeterState } from "../services/MeterDetailsService";
import { CALLBACK_NAME } from "../components/GroupAddComparison/GroupAddComparison";

export const useFetchMeterByConnectionPointId = (
  setLoading: (loading: boolean) => void,
  setState: (state: SearchResultComparisonState | null) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (type: CALLBACK_NAME, payload?: any) => void,
  connectionPointId: string,
): void =>
  useEffect(() => {
    setLoading(true);
    retrieveConnectionPointByConnectionPointId(connectionPointId)
      .then((connectionPoint) => {
        setState({
          connectionPointId: connectionPoint.id,
          meterIds: connectionPoint.meterIds,
        });
        if (connectionPoint.meterIds.length > 0) {
          callback("CHANGE_METER_ID", connectionPoint.meterIds[0]);
        }
        setLoading(false);
      })
      .catch(() => {
        setState(null);
        setLoading(false);
      });
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionPointId]);

export const useFetchDatastreamByMeterId = (
  setLoading: (loading: boolean) => void,
  setState: (state: DataStream[]) => void,
  { connectionPointId, meterId }: { connectionPointId: string; meterId: string },
): void =>
  useEffect(() => {
    if (meterId.length === 0) {
      setState([]);
      return;
    }
    setLoading(true);
    retrieveMeterState(meterId, connectionPointId)
      .then((meter) => {
        setLoading(false);
        // combine with meterId for unit data-stream name
        setState(
          meter.dataStreams
            .filter((item) => item.registerType === "FIXED_TIME_INTERVAL")
            .map((item) => ({
              ...item,
              key: `${connectionPointId}@@${meterId}@@${item.name}@@${item.intent}`,
            })),
        );
      })
      .catch(function () {
        setState([]);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meterId]);
