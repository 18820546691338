import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DataGridPro, ElementSize, GridColumns, GridRowHeightParams, GridRowsProp, useGridApiRef, } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { NoRowsOverlay } from "../NoRowsOverlay";
import { i18n } from "../../global/i18n";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRowHeight = ({ model }: GridRowHeightParams) =>
  (model.lineCounts || 1) * 20 + 32;

export const AuditLogNoRowsOverlay = (): JSX.Element => <NoRowsOverlay />;

interface Props {
  source: string;
  containerDataTest: string;
  columns: GridColumns;
  rows: GridRowsProp;
  loading: boolean;
  defaultSortField?: string;
}

export const minExceptionsContainerWidth = 915;
export const minUdsContainerWidth = 1540;

export const resizeHeaderColumnWidths = (
  container: string,
  width: string,
  headerClassName: string,
  testingDocument?: Document,
): void => {
  const gridContainer = (
    testingDocument ? testingDocument : document
  ).querySelector('[data-test="' + container + '"]');

  if (!gridContainer) {
    return;
  }

  const headers = gridContainer?.getElementsByClassName(headerClassName);

  for (let i = 0; i < headers.length; i++) {
    const header = headers[i] as HTMLElement;
    header.style.minWidth = width;
    header.style.maxWidth = width;
    header.style.width = width;
  }
};

export const setScrollableHeaderContainer = (
  container: string,
  scrollable: boolean,
  testingDocument?: Document,
): void => {
  const gridContainer = (
    testingDocument ? testingDocument : document
  ).querySelector('[data-test="' + container + '"]');

  if (!gridContainer) {
    return;
  }

  const headerContainer = gridContainer?.getElementsByClassName(
    "MuiDataGrid-columnHeaders",
  )[0];

  if (!headerContainer) {
    return;
  }

  (headerContainer as HTMLElement).style.overflowX = scrollable
    ? "visible"
    : "hidden";
};

export const resizeHeaderColumnWidthsBasedOnGridSize = (
  source: string,
  apiRef: React.MutableRefObject<GridApiPro>,
  minContainerWidth: number,
  currentContainerWidth: number,
  columnCount: number,
): void => {
  const gridContainer = source + "-audit-log-rows";

  const isReCalculateColumnWidth = currentContainerWidth < minContainerWidth;

  if (isReCalculateColumnWidth) {
    const gridContainer = source + "-audit-log-rows";
    const columnWidth = minContainerWidth / columnCount + "px";

    resizeHeaderColumnWidths(
      gridContainer,
      columnWidth,
      "auditlog-grid-header-column--header",
    );

    resizeHeaderColumnWidths(
      gridContainer,
      columnWidth,
      "collapsible-grid-sub-header-column--header",
    );

    setScrollableHeaderContainer(gridContainer, true);
  } else {
    apiRef.current.resize();
    setScrollableHeaderContainer(gridContainer, false);
  }
};

export const AuditLogDataGrid: React.FC<Props> = ({
  source,
  containerDataTest,
  columns,
  rows,
  loading,
  defaultSortField = "timestamp"
}) => {
  const [currentContainerSize, setCurrentContainerSize] = useState<ElementSize>(
    { width: 0, height: 0 },
  );
  const dataGridComponents = useMemo(() => {
    return {
      NoRowsOverlay: AuditLogNoRowsOverlay,
    };
  }, []);

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (source === "exceptions") {
      resizeHeaderColumnWidthsBasedOnGridSize(
        source,
        apiRef,
        minExceptionsContainerWidth,
        currentContainerSize.width,
        columns.length,
      );
    }
    if (source === "uds") {
      resizeHeaderColumnWidthsBasedOnGridSize(
        source,
        apiRef,
        minUdsContainerWidth,
        currentContainerSize.width,
        columns.length,
      );
    }
  }, [source, apiRef, currentContainerSize.width, columns.length]);

  const handleResizeGrid = useCallback((containerSize: ElementSize) => {
    setCurrentContainerSize(containerSize);
  }, []);

  return (
    <Box
      data-test={containerDataTest}
      sx={{
        width: 1,
        "& .auditlog-grid-header-column--header": {
          backgroundColor: "rgba(0, 0, 0, 0.03)",
          color: "text.secondary",
        },
        "& .MuiDataGrid-root": {
          border: 0,
        },
        ...((source === "exceptions" || source === "uds") && {
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            paddingLeft: "20px",
          },
          "& .MuiDataGrid-cell": {
            padding: "0 !important",
            whiteSpace: "break-spaces !important",
          },
        }),
      }}
    >
      <DataGridPro
        apiRef={apiRef}
        disableColumnMenu
        disableColumnReorder
        autoHeight
        hideFooterPagination
        hideFooterSelectedRowCount
        rows={rows}
        columns={columns}
        loading={loading}
        getRowHeight={getRowHeight}
        components={dataGridComponents}
        onResize={handleResizeGrid}
        initialState={{
          sorting: {
            sortModel: [{ field: defaultSortField, sort: "desc" }],
          },
        }}
        localeText={{footerTotalRows: i18n.t("EXCEPTION_PAGE.TOTAL_ROWS", { ns: "page",})}}
      />
      
    </Box>
  );
};
