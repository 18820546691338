export interface IntervalQuality {
  dateTime: string;
  latestQuantity: string;
  events: string[]
}

export interface IntervalsQuality {
  ingestionDateTime: string;
  processedDateTime: string;
  intervals: IntervalQuality[];
}

export const EMPTY_LIST: IntervalQuality[] = [];

export const EMPTY: IntervalsQuality = {
  ingestionDateTime: "",
  processedDateTime: "",
  intervals: EMPTY_LIST,
}