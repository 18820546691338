import React, { useCallback } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { CALLBACK_NAME } from "../GroupAddComparison/GroupAddComparison";
import { i18n } from "../../global/i18n";

interface Props {
  disableApply?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (type: CALLBACK_NAME, payload?: any) => void;
}

export const Footer: React.FC<Props> = ({ disableApply = false, callback }) => {
  const theme = useTheme();

  const handleClose = useCallback(() => {
    callback("CANCEL_POPUP");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApply = useCallback(() => {
    callback("APPLY_POPUP");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        px: theme.spacing(3),
        py: theme.spacing(2.5),
        flex: "0 0 auto",
        fontWeight: 500,
        fontSize: "0.8125rem",
      }}
    >
      <Button
        size="small"
        onClick={handleClose}
        sx={{ ml: 1, textTransform: "capitalize" }}
      >
        {i18n.t("FOOTER.CANCEL", {ns: "component"})}
      </Button>
      <Box sx={{ marginLeft: theme.spacing(1) }}>
        <Button
          disabled={disableApply}
          size="small"
          onClick={handleApply}
          variant="contained"
          sx={{ ml: 1, textTransform: "capitalize" }}
          data-test={"ds-daterange-apply-button"}
        >
          {i18n.t("FOOTER.APPLY", {ns: "component"})}
        </Button>
      </Box>
    </Box>
  );
};
