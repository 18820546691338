import { FormControl, styled } from "@mui/material";

export const FormControlLabel = styled(FormControl)(
  ({ theme }) => ({
    margin: `0 ${theme.spacing(1)}`,
    minWidth: 120,
    textAlign: "right",
    display: "flex",
    justifyContent: "center",

    "& .MuiFormLabel-root": {
      color: theme.palette.text.primary,
      width: "100%",
      fontSize: 14,
    }
  }),
);
