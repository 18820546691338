import { useMemo } from "react";
import moment, { Moment } from "moment-timezone";

export const useDateToISOString = (
  date: Moment | null
): string => {

  return useMemo(() => {
    return nowIfNotValid(date).toISOString();
  }, [date]);
};

const nowIfNotValid = (date: Moment | null): Moment => {
  if (!date || !date.isValid()) {
    return moment();
  }
  return date;
};
