/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DeviceConfigurationState } from "../../types/DeviceConfigurationState";
import { ChurnedDeviceConfigurationState } from "../../types/ChurnedDeviceConfigurationState";
import { NamedItem } from "../NamedItem";
import { ChurnedDeviceConfiguration } from '../ChurnedDeviceConfiguration';
import { LoadingContext } from "../../context/LoadingContext";
import { FileUploadDialog } from "../FileUploadDialog";
import { IngestionActionUpload } from "../IngestionActionUpload";
import { uploadFile, uploadMessageOption } from "../FileUploadDialog/FileUploadDialog";
import { useSnackbarMessage } from "../../hooks/useSnackbarMessage";
import { useMessage } from "../../hooks/useMessage";
import { formatDateTime } from "../../commons/dates";
import { useTranslation } from "react-i18next";
import { FeatureFlag } from "../../components/FeatureFlag";
import { Link } from "react-router-dom";
import theme from "../../theme";

interface Props {
  content: DeviceConfigurationState;
  churnedContent: ChurnedDeviceConfigurationState;
  meterEventsUrl: string;
}

const submitUploadFile = (
  fileUploadType: string | null,
  setLoading: (loading: boolean) => void,
  setResult: (result: string) => void,
  closeFileUploadDialog: () => void
) => {
  return (files: File[]) => {
    closeFileUploadDialog();
    uploadFile(
      files,
      setLoading,
      setResult,
      fileUploadType || "",
    );
  };
};

export const DeviceConfiguration: React.FC<Props> = ({ content, churnedContent, meterEventsUrl }) => {
  const [fileUploadType, setFileUploadType] = useState<string | null>(null);
  const [fileUploadResult, setFileUploadResult] = useState("");
  const { setLoading } = useContext(LoadingContext);
  const { showSnackMessage } = useSnackbarMessage();
  const { t } = useTranslation("component");

  const messageOption =
    Array.isArray(fileUploadResult) ? uploadMessageOption(fileUploadResult.length) : uploadMessageOption();

  const fileUploadMessage = useMessage(fileUploadResult, messageOption);

  useEffect(() => {
    if (fileUploadResult.length > 0) {
      showSnackMessage({
        type: fileUploadMessage.state.type,
        onClose: fileUploadMessage.state.closeAlert,
        dataTestPrefix: "upload",
        showExpandData: fileUploadMessage.state.messageContent.length > 0,
        title: fileUploadMessage.state.message,
        body: (
          <ErrorMessageList errorMessages={fileUploadMessage.state.messageContent} />
        )
      });
    }
    setFileUploadResult("");
  },
[ fileUploadMessage.state.openAlert, showSnackMessage ]);

  const openFileUploadDialog = useCallback((fileUploadType: string) => {
    setFileUploadType(fileUploadType);
  }, []);

  const closeFileUploadDialog = useCallback(() => {
    setFileUploadType(null);
  }, []);

  return (
    <Box data-test={"device-configuration"} sx={{ position: "relative" }}>
      <Typography data-test={"device-configuration-header"} variant={"h6"}>
        {t("DEVICE_CONFIGURATION.DEVICE_CONFIGURATION_HEADER")}
      </Typography>
      <NamedItem
        label={t("METER_STATUS.CONFIGURATION_ID")}
        content={content.configurationId}
      />
      <NamedItem
        label={t("DEVICE_CONFIGURATION.EFFECTIVE_DATETIME")}
        content={formatDateTime(content.changeEffectiveDateTime)}
      />
      <NamedItem
        label={t("DEVICE_CONFIGURATION.INSTALLATION_DATETIME")}
        content={formatDateTime(content.installationDateTime)}
      />
      <ChurnedDeviceConfiguration content={churnedContent} />
      <Box sx={{ position: "absolute", top: 0, right: 0 }}>
        <FeatureFlag flag="enable_meter_events">
            <Link
              data-test={"meter-events-link"}
              style={{
                textDecoration: 'none',
                fontSize: '0.9rem',
                fontWeight: 500,
                color: theme.palette.primary.main,
                marginRight: '10px'
              }}
              to={meterEventsUrl}
            >
              {t("METER_EVENTS_PAGE.METER_EVENTS_TITLE")}
            </Link>
        </FeatureFlag>
        <FeatureFlag flag="enable_file_upload">
          <IngestionActionUpload
            onClick={openFileUploadDialog}
          />
        </FeatureFlag>
        {fileUploadType !== null &&
          <FileUploadDialog
            type={fileUploadType}
            onCancel={closeFileUploadDialog}
            onOkay={
              submitUploadFile(
                fileUploadType,
                setLoading,
                setFileUploadResult,
                closeFileUploadDialog
              )
            }
          />
        }
      </Box>
    </Box>
  );
};

export const ErrorMessageList: React.FC<{ errorMessages: any[] }> = (
  { errorMessages }
) => {
  return (
    <>
      {errorMessages.map((message, index) => (
        <Box
          sx={{
            color: "#000",
            paddingLeft: "15px",
            margin: "6px 0 0 0"
          }}
          data-test={"upload-error-messages"}
          key={index}
          component="ul"
        >
          <Box component="li" sx={{ padding: "5px 0" }}>{message}</Box>
        </Box>
      ))}
    </>
  );
};
