/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Divider } from "@mui/material";
import { Moment } from "moment";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DataStreamChartComparison } from "./DataStreamChartComparison";
import {
  EMPTY_VERSIONS,
  DataStreamChartComparisonVersionState,
  EMPTY_CHART_RESPONSE,
  DataStreamChartComparisonResponse,
  DynamicChartLine,
} from "../../types/DataStreamChartComparisonState";
import { useDataStreamChartComparisonVersions } from "../../hooks/useDataStreamChartComparisonVersions";
import {
  filterTableDataByEventClickDataPlot,
  useDataStreamChartComparison,
} from "../../hooks/useDataStreamChartComparison";
import { DateRangeComparison } from "../DateRangeComparison";
import { TableChartComparison } from "../TableChartComparison";
import { initialDateRangeState } from "../DataStreamChart/DataStreamChartContainer";
import { SelectItemType } from "../../types/SelectItemType";
import { ComparisonItem } from "../../types/ComparisonItem";

interface Props {
  params: ComparisonItem[];
}

interface Param {
  meterId: string;
  datastreamName: string;
}

interface IStateTable {
  data: DynamicChartLine[];
  show: boolean;
  eventId: number;
}

export const DataStreamChartComparisonContainer: React.FC<Props> = ({
  params,
}) => {
  const [state, setState] =
    useState<DataStreamChartComparisonResponse>(EMPTY_CHART_RESPONSE);
  const [versionState, setVersionState] =
    useState<DataStreamChartComparisonVersionState>(EMPTY_VERSIONS);
  const [firstDateRange, setFirstDateRange] = useState<DateRange<Moment>>(
    initialDateRangeState,
  );
  const [secondDateRange, setSecondDateRange] =
    useState<DateRange<Moment> | null>(null);
  const [compareOption, setCompareOption] = useState<SelectItemType | null>(
    null,
  );
  const [, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [stateTable, setStateTable] = useState<IStateTable>({
    data: [],
    show: false,
    eventId: 0,
  });

  // One render, fusion re init event custom and declare eventIdRef for save current eventId avoid spam event from event of fusion chart.
  const eventIdRef = useRef();

  const onApply = useCallback(
    (
      firstDateRange: DateRange<Moment>,
      secondDateRange: DateRange<Moment> | null,
      compareOption: SelectItemType | null,
    ) => {
      setFirstDateRange(firstDateRange);
      setSecondDateRange(secondDateRange);
      setCompareOption(compareOption);
    },
    [],
  );

  const callback = useCallback(
    (eventObject: any) => {
      const data =
        filterTableDataByEventClickDataPlot(eventObject, state.data) || [];
      setStateTable({
        show: true,
        data,
        eventId: eventObject.eventId,
      });
    },
    [state.data],
  );

  useEffect(() => {
    setStateTable({
      data: [],
      show: false,
      eventId: 0,
    });
  }, [params, compareOption]);

  useDataStreamChartComparisonVersions(
    setLoading,
    setError,
    setVersionState,
    params,
    firstDateRange,
    secondDateRange,
    compareOption?.value,
  );

  useDataStreamChartComparison(
    setLoading,
    setError,
    setState,
    versionState,
    compareOption?.value,
    params,
  );

  return (
    <Box
      sx={{
        '& g[class$="veritcal-crossline-marker-group"]': {
          display: "none",
        },
        '& g[class$="horizontal-crossline-marker-group"]': {
          display: "none",
        },
      }}
    >
      <DateRangeComparison onApply={onApply} />
      <Divider sx={{ mt: 3, mb: 2 }} />
      <DataStreamChartComparison
        content={{ loading: loading, response: state }}
        comparisonItems={params}
        callback={callback}
      />
      {stateTable.show && state.data.length > 0 && (
        <TableChartComparison
          data={stateTable.data}
          comparisonItems={params}
          eventId={stateTable.eventId}
          compareOption={compareOption}
        />
      )}
    </Box>
  );
};
