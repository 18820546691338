import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import { ConnectionPoint } from "../../types/ConnectionPoint";
import { useTranslation } from 'react-i18next';

interface Props {
  content: ConnectionPoint
  path: string
}

const CONNECTION_POINT_BASE_PATH = "/connectionpoint";

const connectionPointPath = (connectionPointId: string): string => `${ CONNECTION_POINT_BASE_PATH }/${ connectionPointId }`;
const buildValueBase = (content: ConnectionPoint, path: string): string | null => {
  if (!path.includes(content.id)) {
    return CONNECTION_POINT_BASE_PATH;
  }
  const _connectionPointPath = connectionPointPath(content.id);
  if (!path.includes("/meter/")) {
    return _connectionPointPath;
  }
  const meterId = content.meterIds.filter(id => path.includes(id)).pop();
  if (meterId) {
    return `${ _connectionPointPath }/meter/${ meterId }`;
  }
  return null;
}
const buildConnectionPointPath = (connectionPointId: string, path: string): string =>
  path.includes(connectionPointId) ? connectionPointPath(connectionPointId) : "/connectionpoint";
const buildMeterPath = (connectionPointId: string, meterId: string): string =>
  `${ connectionPointPath(connectionPointId) }/meter/${ meterId }`;

export const ConnectionPointTabs: React.FC<Props> = ({ content, path }) => {
  const { search = "" } = useLocation();
  const _connectionPointPath = buildConnectionPointPath(content.id, path) + search;
  const valueBase = buildValueBase(content, path);
  const value = valueBase === null ? false : valueBase + search;
  const getMeterPath = (connectionPointId: string, meterId: string) => buildMeterPath(connectionPointId, meterId) + search;
  const { t } = useTranslation("component");
  return (
    <Box sx={ { width: '100%', borderBottom: 1, borderColor: "divider" } }>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        aria-label={t("CONNECTION_POINT_TABS.ARIA_TABS")}
        value={ value }
      >
        { /*connection point will always be the first one in the tab*/ }
        <Tab
          value={ _connectionPointPath }
          label={t("CONNECTION_POINT_TABS.CONNECTION_POINT_TAB")}
          to={ _connectionPointPath }
          component={ Link }
        />

        { content.meterIds.map((meterId, index) => (
          <Tab
            key={ meterId }
            value={ getMeterPath(content.id, meterId) }
            label={ t("CONNECTION_POINT_TABS.METER_TAB") + " " + (index + 1) }
            to={ getMeterPath(content.id, meterId) }
            component={ Link }
          />
        )) }
      </Tabs>
    </Box>
  )
};
