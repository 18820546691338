import { IntervalConsumption } from "./IntervalConsumption";

export interface DataStream {
  key?:string; // optional for reuse type in Comparison popup with cross Meter unique key
  name: string;
  serviceCategory: string;
  intent: string;
  status: string;
  registerId: string;
  registerType: string;
  channelName: string;
  multiplier: string;
  duration: number;
  unitOfMeasure: string;
  intervals: IntervalConsumption[];
}

export const EMPTY: DataStream = {
  name: "",
  serviceCategory: "",
  intent: "",
  status: "",
  registerId: "",
  registerType: "",
  channelName: "",
  multiplier: "",
  duration: 0,
  unitOfMeasure: "",
  intervals: [],
}
