import React, { useCallback } from "react";
import { Dialog, DialogContent, DialogTitle, Divider, } from "@mui/material";
import { SelectItemType } from "../../types/SelectItemType";
import { useSetSubScenarios } from "../../hooks/useSetSubScenarios";
import { qualities, reasonCodes, scenarios } from "../../commons/scenarioData";
import { TextFlowBlock } from "../TextFlowBlock";
import { SelectBoxBlock } from "../SelectBoxBlock";
import { SubScenarioDialogActions } from "./SubScenarioDialogActions";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  meterId: string;
  onOkay: (meterId: string, scenario: SelectItemType, quality: SelectItemType, reasonCode: SelectItemType) => void,
  onCancel: () => void,
}

export const SubScenarioDialog: React.FC<Props> = ({
  open,
  meterId,
  onOkay,
  onCancel,
}) => {

  const { t } = useTranslation("component");

  const {
    loading,
    setLoading,
    methods,
    disabledOkayButton,
    onSubScenarioChanged,
    onQualityChanged,
    onReasonCodeChanged,
    selectedScenario,
    selectedQuality,
    selectedReasonCode,
  } = useSetSubScenarios();

  const handleOkay = useCallback(() => {
    if (disabledOkayButton) {
      return;
    }

    setLoading(true);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onOkay(meterId, selectedScenario, selectedQuality, selectedReasonCode);
  }, [disabledOkayButton, meterId, selectedScenario, selectedQuality, selectedReasonCode, setLoading, onOkay]);

  return (
    <Dialog
      open={open}
      data-test={"scenario-modal"}
      PaperProps={{ sx: { minWidth: 700, maxWidth: 700 } }}
    >
      <DialogTitle>{t("SUB_SCENARIO_DIALOG.APPLY_SUB_SCENARIO")}</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <SelectBoxBlock
          data-test={"scenario"}
          label={t("USER_DEFINED_SCENARIO_DIALOG.SCENARIO")}
          disabled={loading}
          items={scenarios}
          selectedItem={selectedScenario}
          onChange={onSubScenarioChanged}
        />
        <Divider sx={{ m: 1 }} />
        <SelectBoxBlock
          data-test={"quality"}
          label={t("USER_DEFINED_SCENARIO_DIALOG.QUALITY")}
          disabled={loading}
          items={qualities}
          selectedItem={selectedQuality}
          onChange={onQualityChanged}
        />
        <SelectBoxBlock
          data-test={"reason-code"}
          label={t("USER_DEFINED_SCENARIO_DIALOG.REASON_CODE")}
          disabled={loading}
          items={reasonCodes}
          selectedItem={selectedReasonCode}
          onChange={onReasonCodeChanged}
        />
        <TextFlowBlock data-test={"method"} label={t("USER_DEFINED_SCENARIO_DIALOG.METHOD")} items={methods} />
      </DialogContent>
      <SubScenarioDialogActions
        onCancel={onCancel}
        onOkay={handleOkay}
        disabled={loading}
        disabledOkayButton={disabledOkayButton}
      />
    </Dialog>
  );
};
