import { EMPTY as connectionPointHeader, ConnectionPointHeaderState } from "./ConnectionPointHeaderState";

export interface ConnectionPoint extends ConnectionPointHeaderState {
  id: string;
  number: string;
  status: string;
  changeEffectiveDate: string;
  retailer: string;
  meterIds: string[];
}

export const EMPTY: ConnectionPoint = {
  id: "",
  ...connectionPointHeader,
  number: "",
  status: "",
  changeEffectiveDate: "",
  retailer: "",
  meterIds: []
}
