import React from "react";
import { Box, Typography } from "@mui/material";
import { DataStreamHeaderState } from "../../types/DataStreamHeaderState";
import { SeparatedList } from "../SeparatedList";
import { i18n } from "../../global/i18n";

interface Props {
  content: DataStreamHeaderState;
}


export const DataStreamHeader: React.FC<Props> = ({ content: { name, registerType, serviceCategory } }) => {
  return (
    <Box sx={{ pt: 1.5, pb: 2 }}>
      <Typography sx={{ pb: 1 }} variant={ "h5" } data-test={ "datastream-heading" }>
        {i18n.t("DATA_STREAM_HEADER.DATA_STREAM_HEADING", {ns: "component"})} - { name }
      </Typography>
      <SeparatedList>
        <Typography data-test={ "datastream-register-type" } variant={ "body2" }>{ registerType }</Typography>
        <Typography data-test={ "datastream-service-category" } variant={ "body2" }>{ serviceCategory }</Typography>
      </SeparatedList>
    </Box>
  );
};
