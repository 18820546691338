import React from "react";
import { Box, IconButton, Input } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ComparisonItem } from "../../types/ComparisonItem";
import { CALLBACK_NAME } from "../GroupAddComparison/GroupAddComparison";
import { CHART_COLORS } from "../../types/DataStreamChartComparisonState";
import { i18n } from "../../global/i18n";

const ARRAY_CHART_COLOR = CHART_COLORS.split(",");
interface Props {
  index: number;
  item: ComparisonItem;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (type: CALLBACK_NAME, payload: any) => void;
}

export const AddComparison: React.FC<Props> = ({ callback, item, index }) => {
  
  const onClickButton = () => {
    callback("CHANGE_ITEM", { index, item: { ...item, template: "input" } });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeyPressInput = (e: any) => {
    if (e.which === 13) {
      // enter key
      callback("OPEN_POPUP", { index, connectionPointId: e.target.value+ "-ELECTRICITY" });
    }
  };

  const onRemove = () => {
    callback("REMOVE_ITEM", { index });
  };

  const onClickPreview = () => {
    callback("PREVIEW_ITEM", { index });
  };

  return (
    <Box
      className="component-add-comparison"
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        background: "#FFF",
        height: "100%",
        width: "100%",
        borderLeft: "1px solid rgba(0, 0, 0, 0.08);",
      }}
    >
      {item.template === "button" && (
        <Box
          onClick={onClickButton}
          display={"flex"}
          sx={{ cursor: "pointer" }}
          data-test={"add-comparison-box"}
        >
          <AddCircleIcon sx={{ color: "#0053DE", marginRight: "6px" }} />
          {i18n.t("ADD_COMPARISON.ADD_COMPARISON", {ns: "component"})}
        </Box>
      )}
      {item.template === "input" && (
        <Box width="100%" paddingX="28px" data-test={"connectionpoint-search-box"}>
          <Input
            sx={{
                width: "100%",
            }}
            disableUnderline={true}
            onKeyPress={onKeyPressInput}
            placeholder={i18n.t("ADD_COMPARISON.ENTER_CONNECTION_POINT_NUMBER", {ns: "component"})}
          />
        </Box>
      )}
      {item.template === "preview" && (
        <Box
          width="100%"
          paddingX="36px"
          position={"relative"}
          data-test={"connectionpoint-preview-box"}
        >
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              left: "16px",
              top: "4px",
              ".MuiSvgIcon-root": {
                width: "16px",
                height: "16px",
                color: `#${ARRAY_CHART_COLOR[index]}`,
              },
            }}
          >
            <FiberManualRecordIcon />
          </Box>
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              right: "2px",
              top: "-10px",
            }}
          >
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              onClick={onRemove}
              aria-label="close"
              data-test={"container-close-icon"}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box
            data-test="container-preview"
            onClick={onClickPreview}
            sx={{
              cursor: "pointer",
            }}
          >
            <Box>{i18n.t("ADD_COMPARISON.CONNECTION_POINT", {ns: "component"})} {item.connectionPointId.replace("-ELECTRICITY", "")}</Box>
            <Box>{i18n.t("ADD_COMPARISON.METER", {ns: "component"})} {item.meterId.replace("-EDMI-ELECTRICITY", "")}</Box>
            <Box>{i18n.t("ADD_COMPARISON.DATASTREAM", {ns: "component"})} {item.datastreamName}</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
