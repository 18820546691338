export const prettyJson = (json: string): string => {
  const jsonContent = JSON.parse(json);
  return JSON.stringify(jsonContent, undefined, 2);
};

export const prettyJsonExcludedFields = (
  json: string,
  excludedFields: string[],
): string => {
  const jsonContent = JSON.parse(json);
  excludedFields.forEach((field: string) => delete jsonContent[field]);
  return JSON.stringify(jsonContent, undefined, 2);
};

export const isJson = (text: string): boolean => {
  try {
    JSON.parse(text);
  } catch (e) {
    return false;
  }
  return true;
};

export const convertToJsonString = (
  isJsonObject: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: string | any,
): string => {
  const jsonValue = isJsonObject ? JSON.stringify(value) : value;
  return jsonValue;
};
