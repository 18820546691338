/* eslint-disable @typescript-eslint/no-explicit-any */

import { actionUploadFileIngestion } from "../services/ExceptionListService";

export const useUploadAction = (
  setLoading: (loading: boolean) => void,
  setActionResult: (state: string) => void,
  bodyData: FormData,
  actionType: string,
): void => {
  setActionResult("loading");
  setLoading(true);
  actionUploadFileIngestion(bodyData, actionType).then(response => {
    if (response) {
      setActionResult("success");
      setLoading(false);
    }
  }).catch((error) => {
    if (error.message === "500") {
      setActionResult("error");
    } else {
      const result = error.message.split("#");
      setActionResult(result);
    }
    setLoading(false);
  });
}
