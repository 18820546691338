import React, { useState } from "react";
import { MeterEventsPage } from "./MeterEventsPage";
import { useParams } from "react-router-dom";
import { EMPTY, Meter } from "../../types/Meter";
import { useMeterDetails } from "../../hooks/useMeterDetails";
import { NotFound } from "../NotFound";

type Params = {
  connectionPointId: string;
  meterId: string;
};

export const MeterEventsPageContainer: React.FC = () => {
  const { connectionPointId = "", meterId = "" } = useParams<Params>();
  const [meter, setMeter] = useState<Meter>(EMPTY);
  const [error, setError] = useState<string>("");

  useMeterDetails(setMeter, setError, meterId, connectionPointId);

  if (error === '404') {
    return <NotFound />
  }

  return (
    <MeterEventsPage connectionPointId={connectionPointId}
                     meter={meter} />
  );
}