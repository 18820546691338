import React from "react";
import { Box } from "@mui/material";
import { DataStreamHeaderState } from "../../types/DataStreamHeaderState";
import { SeparatedList } from "../SeparatedList";
import { NamedItem } from "../NamedItem";
import { i18n } from "../../global/i18n";

interface Props {
  content: DataStreamHeaderState;
}

export const DataStreamStatus: React.FC<Props> = ({
  content: {
    name,
    status,
    channelName,
    multiplier,
    duration,
    unitOfMeasure,
    timeZone
  }
}) => (
  <Box data-test={"datastream-status"}>
    <SeparatedList>
      <NamedItem
        label={i18n.t("DATA_STREAM_STATUS.STATUS", {ns: "component"})}
        content={status?.toLowerCase() === "active" ? i18n.t("DATA_STREAM_STATUS.ACTIVE", {ns: "component"})
         : i18n.t("DATA_STREAM_STATUS.INACTIVE", {ns: "component"})}
        value-data-test={"datastream-status-status"}
        sx={{ pl: 0 }}
        contentSx={{
          textTransform: "lowercase",
          display: "inline-block",
          "&::first-letter": {
            textTransform: "uppercase",
          },
        }}
      />
      <NamedItem
        label={i18n.t("DATA_STREAM_STATUS.REGISTER_ID", {ns: "component"})}
        content={name}
        value-data-test={"datastream-status-register-id"}
      />
      <NamedItem
        label={i18n.t("DATA_STREAM_STATUS.TYPE", {ns: "component"})}
        content={channelName}
        value-data-test={"datastream-status-type"}
      />
      <NamedItem
        label={i18n.t("DATA_STREAM_STATUS.MULTIPLIER", {ns: "component"})}
        content={multiplier}
        value-data-test={"datastream-status-multiplier"}
      />
      <NamedItem
        label={i18n.t("DATA_STREAM_STATUS.INTERVAL_LENGTH", {ns: "component"})}
        content={duration !== 0 ? duration + " " + i18n.t("DATA_STREAM_STATUS.DURATION", {ns: "component"}) : ""}
        value-data-test={"datastream-status-interval-length"}
      />
      <NamedItem
        label={i18n.t("DATA_STREAM_STATUS.UNIT_OF_MEASURE", {ns: "component"})}
        content={unitOfMeasure}
        value-data-test={"datastream-status-unit-of-measure"}
      />
      <NamedItem
        label={i18n.t("DATA_STREAM_STATUS.TIMEZONE", {ns: "component"})}
        content={timeZone}
        value-data-test={"datastream-status-timezone"}
      />
    </SeparatedList>
  </Box>
);
