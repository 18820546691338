import React from "react";
import { useTranslation } from "react-i18next";
import { LabelledInput } from "../LabelledInput";
import { ManualSubstitutionDataStreamInputItem } from "../UserDefinedScenarioDialog/UserDefinedScenarioDialog";

interface Props {
  "data-test"?: string;
  item: ManualSubstitutionDataStreamInputItem;
  onChange: (item: ManualSubstitutionDataStreamInputItem) => void;
  disabled?: boolean;
}

export const AgreedAverageDailyLoadFormItem: React.FC<Props> = ({
  item,
  onChange,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation("component");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...item,
      value: e.target.value as string,
    });
  };

  return (
    <LabelledInput
      {...(props["data-test"] ? { "data-test": props["data-test"] } : {})}
      label={t("DATA_STREAMS_RESULT.DATASTREAM") + " " + item.name}
      value={(item.value ?? "").toString()}
      onChange={handleChange}
      disabled={disabled}
      errorMessage={item.errorMessage}
      placeholder={t("AGREED_AVERAGE_DAILY_LOAD_FORM_ITEM.ENTER_VALUE")}
    />
  );
};
