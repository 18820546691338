export interface LoginToken {
  access_token: string;
  id_token: string;
  refresh_token?: string;
  token_type: string;
  expires_in: number;
  expires_at?: number;
}

export const EMPTY: LoginToken = {
  access_token: "",
  id_token: "",
  token_type: "",
  expires_in: -1,
};
