/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Box, Paper, Popover } from "@mui/material";
import { AddComparison } from "../AddComparison";
import { SearchResultComparison } from "../SearchResultComparison";
import { ComparisonItem } from "../../types/ComparisonItem";
import { generateUuid } from "../../commons/uuid";

interface Props {
  data?: any;
  onChangeData: (data: ComparisonItem[]) => void;
}

interface IState {
  items: ComparisonItem[];
  activeIndex: number;
  connectionPointId: string;
  meterId: string;
  datastreamSelected: Set<string>;
}

const MAX_ITEMS = 2;

export type CALLBACK_NAME =
  | "OPEN_POPUP"
  | "CANCEL_POPUP"
  | "APPLY_POPUP"
  | "CHANGE_ITEM"
  | "CLICK_CHECKBOX_DATASTREAM"
  | "REMOVE_ITEM"
  | "PREVIEW_ITEM"
  | "CHANGE_METER_ID";

/**
 * Create new ComparisonItem with empty data, using for create new comparison on the panel
 * @returns new ComparisonItem
 */
export const createItem = (): ComparisonItem => ({
  id: generateUuid(),
  key: "",
  template: "button",
  connectionPointId: "",
  meterId: "",
  datastreamName: "",
  intent: ""
});

export const handelItemsWhenApply = (
  items: ComparisonItem[],
  datastreamSelected: Set<string>,
): ComparisonItem[] => {
  // params for checking exits item in current list in the comparison panel, only add if items doesn't exits
  const setItems = new Set(items.map((item) => item.key));

  const newItems: ComparisonItem[] = [];
  datastreamSelected.forEach((key) => {
    const arrTxt = key.split("@@");
    if (arrTxt.length === 4 && !setItems.has(key)) {
      newItems.push({
        id: generateUuid(),
        key: key,
        template: "preview",
        connectionPointId: arrTxt[0],
        meterId: arrTxt[1],
        datastreamName: arrTxt[2],
        intent: arrTxt[3]
      });
    }

    // item already exists, will keep it.
    if (setItems.has(key)) {
      const findItem = items.find((item) => item.key === key);
      findItem && newItems.push(findItem);
    }
  });

  if (newItems.length < MAX_ITEMS) {
    return [...newItems, createItem()];
  }

  return newItems;
};

export const handelItemsWhenRemove = (
  items: ComparisonItem[],
): ComparisonItem[] => {
  // previous items less than MAX_ITEMS
  if (
  items.length < MAX_ITEMS &&
    !items.find((item) => item.key === "")
  ) {
    // check if add comparison not exits, will create new one
    return [...items, createItem()];
  }
  return items;
};

export const mappingItemsToChart = (
  items: ComparisonItem[],
): ComparisonItem[] => items.filter((item) => item.key !== "");

export const GroupAddComparison: React.FC<Props> = ({ onChangeData }) => {
  const [state, setState] = useState<IState>({
    items: [createItem()],
    activeIndex: -1,
    connectionPointId: "",
    meterId: "",
    datastreamSelected: new Set([]),
  });
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const popupRef = React.useRef();

  const openPopupSearch = () => {
    setAnchorEl(popupRef.current);
  };

  const closePopupSearch = () => {
    setAnchorEl(null);
  };

  const callback = (type: CALLBACK_NAME, payload?: any) => {
    switch (type) {
      case "OPEN_POPUP": {
        setState({
          ...state,
          activeIndex: payload.index,
          connectionPointId: payload.connectionPointId,
          meterId: "",
        });
        openPopupSearch();
        break;
      }
      case "CANCEL_POPUP": {
        setAnchorEl(null);
        break;
      }
      case "APPLY_POPUP": {
        const newItems = handelItemsWhenApply(
          state.items,
          state.datastreamSelected,
        );
        setState({
          ...state,
          items: newItems,
        });
        setAnchorEl(null);
        onChangeData(mappingItemsToChart(newItems));
        break;
      }
      case "CHANGE_ITEM": {
        const { items } = state;
        items[payload.index] = payload.item;
        setState({ ...state, items });
        break;
      }
      case "CHANGE_METER_ID": {
        setState({ ...state, meterId: payload });
        break;
      }
      case "CLICK_CHECKBOX_DATASTREAM": {
        const { datastreamSelected } = state;

        // check if exits will remove else add
        if (datastreamSelected.has(payload)) {
          datastreamSelected.delete(payload);
        } else {
          datastreamSelected.add(payload);
        }
        setState({ ...state, datastreamSelected });
        break;
      }
      case "REMOVE_ITEM": {
        const { items, datastreamSelected } = state;
        const removeItems = items.splice(payload.index, 1);
        if (removeItems.length > 0) {
          // also remove on the datastreamSelected
          datastreamSelected.delete(removeItems[0].key);
        }

        setState({ ...state, datastreamSelected, items: handelItemsWhenRemove(items) });
        onChangeData(mappingItemsToChart(items));
        break;
      }
      case "PREVIEW_ITEM": {
        const { items } = state;
        const activeItem = items[payload.index];
        setState({
          ...state,
          activeIndex: payload.index,
          connectionPointId: activeItem.connectionPointId,
          meterId: activeItem.meterId,
        });
        openPopupSearch();
        break;
      }
    }
  };

  return (
    <Box
      data-test="group-button-comparison"
      display="flex"
      flexDirection="row"
      sx={{
        border: "1px solid rgba(0, 0, 0, 0.08)",
        height: "100px",
        width: "100%",
        padding: "0 200px",
      }}
    >
      <Box
        ref={popupRef}
        display="flex"
        flexDirection="row"
        sx={{
          width: "100%",
          ".component-add-comparison:last-child": {
            borderRight: "1px solid rgba(0, 0, 0, 0.08)",
          },
        }}
      >
        {state.items.map((item, index) => (
          <AddComparison
            key={item.id}
            index={index}
            item={item}
            callback={callback}
          />
        ))}
      </Box>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closePopupSearch}
      >
        <Paper>
          <Box
            sx={{
              height: "auto",
              width: "calc(100vw - 416px)",
            }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <SearchResultComparison
              maxItems={MAX_ITEMS}
              datastreamSelected={state.datastreamSelected}
              connectionPointId={state.connectionPointId}
              meterId={state.meterId}
              callback={callback}
            />
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};
