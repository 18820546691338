import React from "react";
import { Box, InputLabel, useTheme } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { FormControlLabel } from "../FormControlLabel";
import { FormControlContent } from "../FormControlContent";
import { ScenarioMethod } from "../../types/ScenarioMethod";
import { i18n } from "../../global/i18n";

interface Props {
  "data-test"?: string;
  label: string,
  items: ScenarioMethod[],
}

export const TextFlowBlock: React.FC<Props> = ({ label, items, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      {...(props["data-test"] ? { "data-test": props["data-test"] } : {})}
      component="form"
      sx={{ display: 'flex' }}
    >
      <FormControlLabel>
        <InputLabel color={"secondary"}>{label}</InputLabel>
      </FormControlLabel>
      <FormControlContent sx={{
        overflow: "hidden",
        "& .MuiTimeline-root ": {
          width: "100%",
          minHeight: 50,
          margin: "-4px 0 -24px -32px",
        },
        "& .MuiTimelineContent-root ": {
          minWidth: "100%"
        },
        "& .MuiTimelineItem-root ": {
          minHeight: 50
        },
        "& .MuiTimelineConnector-root ": {
          backgroundColor: theme.palette.primary.main
        },
      }}>
        <Timeline position={"right"}>
          {items.map((item, index: number) => (
            <TimelineItem key={item.value}>
              <TimelineSeparator>
                <TimelineDot color={"primary"} {...(index > 0) ? { variant: "outlined" } : {}} />
                {(index + 1) < items.length && (<TimelineConnector />)}
              </TimelineSeparator>
              <TimelineContent>{i18n.t(item.translateKey)}</TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </FormControlContent>
    </Box>
  );
};
