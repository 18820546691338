/* eslint-disable @typescript-eslint/no-explicit-any */

export const retrieveReportDownloadState = (): Promise<any> =>
  fetch(`/service/v1/extract/report/download`, {
    headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("404")
    }
    return response.json();
  });

  export const retrieveReportDownloadPresignedUrl = (reportId: string): Promise<any> =>
  fetch(`/service/v1/extract/report/downloadlink?reportid=${reportId}`, {
    headers: new Headers({ "Content-Type": "application/json; charset=utf-8" }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("404")
    }
    return response.json();
  });

