/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactNode, useContext, useEffect, useMemo, useState, } from "react";
import { Box, CircularProgress, Divider, Link, Stack, Typography, } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams, GridSelectionModel, } from "@mui/x-data-grid-pro";
import WarningIcon from "@mui/icons-material/Warning";
import Alert, { AlertColor } from "@mui/material/Alert";
import { ExceptionActionOptions } from "../ExceptionActionOptions";
import { MeterExceptions } from "../../types/MeterExceptions";
import { useSingleMeterExceptionAction, useSingleMeterExceptionStatusAction, } from "../../hooks/useSingleMeterExceptionAction";
import { LoadingContext } from "../../context/LoadingContext";
import { renderExceptionStatus, } from "../ExceptionListTable/ExceptionListTable";
import { SelectItemType } from "../../types/SelectItemType";
import { SubScenarioDialog } from "../SubScenarioDialog";
import { Link as RouterLink, useParams } from "react-router-dom";
import { MeterException, UnitOfWorkPart } from "../../types/MeterException";
import { useSnackbarMessage } from "../../hooks/useSnackbarMessage";
import { dateValueFormatter, dateValueGetter, exceptionTypeGetter, styleDataGridHeader } from "../../commons/dataGrid";
import { i18n } from "../../global/i18n";

interface Props {
  content: MeterExceptions | null;
  meterSerial: string;
  connectionPointNumber: string;
  postUpdate: () => Promise<void>;
}

export const submitMessages: { [key: string]: string } = {
  default: i18n.t("EXCEPTION_ACTION_OPTIONS.MESSAGES.DEFAULT", { ns: "component" }),
  success: i18n.t("EXCEPTION_ACTION_OPTIONS.MESSAGES.SUCCESS", { ns: "component" }),
  error: i18n.t("EXCEPTION_ACTION_OPTIONS.MESSAGES.ERROR", { ns: "component" }),
};

export const mapOptions: {
  [key: string]: { actionValue: string; option: string };
} = {
  "Finalise and Send to Market": {
    actionValue: "finalise",
    option: "USE_SUBSTITUTIONS",
  },
  "Accept and Send to Market": {
    actionValue: "finalise",
    option: "USE_NEW_VALUES",
  },
  "Send Latest Ingested Values to Market": {
    actionValue: "finalise",
    option: "USE_ORIGINALS",
  },
  "Mark as pending": {
    actionValue: "pending",
    option: "",
  },
  "Apply Sub scenario": {
    actionValue: "substitution",
    option: "",
  },
  "Ignore/close": {
    actionValue: "close",
    option: "",
  }
};

const renderActionMenu = (
  { value }: GridRenderCellParams,
  submitActionOption: any,
) => <ExceptionActionOptions content={value} onclick={submitActionOption} />;

export const renderResolutionOptions = (
  submitActionOption: any,
): ((params: GridRenderCellParams) => ReactNode) => {
  return (params: GridRenderCellParams): ReactNode =>
    renderActionMenu(params, submitActionOption);
};

export const submitActionOption = (
  setLoading: (loading: boolean) => void,
  setResult: (result: string) => void,
  setMeterIdForSubScenarioDialog: (meterId: string) => void,
  postUpdate: () => Promise<void>,
) => {
  return (event: any, value: any) => {
    const dataValue: string = event.target.getAttribute("data-test");
    const { actionValue, option } = mapOptions[dataValue];

    if (actionValue === "substitution") {
      return setMeterIdForSubScenarioDialog(value);
    }

    const actionDetailsBody = getActionDetailsBody(actionValue, option);

    const body = {
      unitOfWorkIds: [{ ...value.unitOfWorkPart }],
      option,
      actionDetails: actionDetailsBody
    };

    let useFunctionService = useSingleMeterExceptionAction;

    if (actionValue === "pending" || actionValue === "close") {
      useFunctionService = useSingleMeterExceptionStatusAction;
    }

    setResult("loading");

    useFunctionService(
      setLoading,
      setResult,
      { actionType: "single", ...value },
      actionValue,
      body,
      postUpdate,
    );
  };
};

export const getActionDetailsBody = (
  actionValue: string,
  option: string,
  actionDetail?: { [x: string]: any }
) => {

  const mapOptionName = Object.keys(mapOptions)
    .find(key => mapOptions[key].actionValue === actionValue
      && mapOptions[key].option === option);

  const body: { [x: string]: any } = {
    name: mapOptionName
  };

  if (actionDetail) {
    body.actionDetail = actionDetail;
  }

  return body;
};

export const removeDuplicateUnitOfWorkPart = (
  unitOfWorkParts: UnitOfWorkPart[],
) => {
  const exitsUnitOfWorkParts = new Set<string>();
  const output: UnitOfWorkPart[] = [];

  if (unitOfWorkParts.length < 2) return unitOfWorkParts;

  unitOfWorkParts.forEach((item) => {
    const key = `${item.manufacturer}-${item.commodity}-${item.readingDateTime}-${item.intent}-${item.serialNumber}`;
    if (!exitsUnitOfWorkParts.has(key)) {
      output.push(item);
      exitsUnitOfWorkParts.add(key);
    }
  });
  return output;
};

export const lookUpExceptionDataFromAPIResult = (
  exceptions: MeterException[] | undefined,
  result: any,
) => {
  const errorItems: MeterException[] = [];
  const successItems: MeterException[] = [];

  if (exceptions === undefined || exceptions.length < 1) {
    return { success: successItems, errors: errorItems };
  }

  result.errors.map((item: UnitOfWorkPart) => {
    const filterExceptionValid = exceptions.filter(
      (temp: MeterException) => {
        const readingDateTime = Date.parse(item.readingDateTime);
        const unitOfWorkDateTime = Date.parse(temp.unitOfWorkDateTime);
        return item.manufacturer === temp.manufacturer &&
          item.commodity === temp.commodity &&
          item.intent === temp.intent &&
          readingDateTime === unitOfWorkDateTime &&
          item.serialNumber === temp.serialNumber
      }
    );
    errorItems.push(...filterExceptionValid);
  });

  result.success.map((item: UnitOfWorkPart) => {
    const filterExceptionValid = exceptions.filter(
      (temp: MeterException) => {
        const readingDateTime = Date.parse(item.readingDateTime);
        const unitOfWorkDateTime = Date.parse(temp.unitOfWorkDateTime);
        return item.manufacturer === temp.manufacturer &&
          item.commodity === temp.commodity &&
          item.intent === temp.intent &&
          readingDateTime === unitOfWorkDateTime &&
          item.serialNumber === temp.serialNumber
      }
    );
    successItems.push(...filterExceptionValid);
  });
  return { success: successItems, errors: errorItems };
};

export interface IState {
  message: string | ReactNode;
  openMessage: boolean;
  type: AlertColor;
  triggerFetchData: string[];
  meterIdForSubScenarioDialog: any;
  selectionModel: GridSelectionModel;
  messageItems: MeterException[];
}

export const initialState: IState = {
  message: "",
  openMessage: false,
  type: "success",
  triggerFetchData: [],
  meterIdForSubScenarioDialog: null, // bulk if for apply bulk
  selectionModel: [],
  messageItems: [],
};

type Params = {
  connectionPointId: string;
  meterId: string;
};

export const Exceptions: React.FC<Props> = (
  {
    content,
    meterSerial,
    connectionPointNumber,
    postUpdate,
  }) => {
  const { connectionPointId = "", meterId } = useParams<Params>();
  const [state, setState] = useState<IState>(initialState);
  const [result, setResult] = useState<string | any>("");
  const { setLoading } = useContext(LoadingContext);

  const setSelectionModel = (selectionModel: any) => {
    setState({ ...state, selectionModel });
  };

  const setMeterIdForSubScenarioDialog = (meterIdForSubScenarioDialog: any) => {
    setState({ ...state, meterIdForSubScenarioDialog });
  };

  const handleCloseMessage = () => {
    setState({ ...state, openMessage: false });
  };

  const closeSubScenarioDialog = () => {
    setState({ ...state, meterIdForSubScenarioDialog: null });
  };

  const applySubScenario = (
    meterId: string,
    scenario: SelectItemType,
    quality: SelectItemType,
    reasonCode: SelectItemType,
  ) => {
    setResult("loading");

    const actionDetail = {
      scenario: scenario.label,
      quality: quality.label,
      reasonCode: reasonCode.label,
      method: scenario.methods?.map(item => item.value).join(",")
    };

    const mapOption = mapOptions["Apply Sub scenario"];
    const actionDetailsBody = getActionDetailsBody(mapOption.actionValue, mapOption.option, actionDetail);

    if (state.meterIdForSubScenarioDialog !== "bulk") {
      const bodyData = {
        scenario: scenario.value,
        qualityFlag: quality.qualityFlag,
        qualityDescription: quality.qualityDescription,
        reasonCode: reasonCode.reasonCode,
        reasonCodeDescription: reasonCode.reasonCodeDescription,
        unitOfWorkIds: [
          { ...state.meterIdForSubScenarioDialog.unitOfWorkPart },
        ],
        actionDetails: actionDetailsBody
      };

      useSingleMeterExceptionAction(
        setLoading,
        setResult,
        { actionType: "single", ...state.meterIdForSubScenarioDialog },
        "substitution",
        bodyData,
        postUpdate,
      );
    } else {
      const selectedRows = state.selectionModel.map(
        (item) => rows[Number(item)],
      );

      const bodyData = {
        scenario: scenario.value,
        qualityFlag: quality.qualityFlag,
        qualityDescription: quality.qualityDescription,
        reasonCode: reasonCode.reasonCode,
        reasonCodeDescription: reasonCode.reasonCodeDescription,
        unitOfWorkIds: removeDuplicateUnitOfWorkPart(
          selectedRows.map((item) => item.resolutionOptions.unitOfWorkPart),
        ),
        actionDetails: actionDetailsBody
      };

      setResult("loading");
      useSingleMeterExceptionAction(
        setLoading,
        setResult,
        { actionType: "bulk", ...selectedRows[0].resolutionOptions },
        "substitution",
        bodyData,
        postUpdate,
      );
    }

    // To close Substitution Scenario Dialog
    setState({ ...state, meterIdForSubScenarioDialog: null });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submitBulkAction = (event: any, value: any) => {
    const dataValue: string = event.target.getAttribute("data-test");
    const { actionValue, option } = mapOptions[dataValue];
    if (actionValue === "substitution") {
      setMeterIdForSubScenarioDialog("bulk");
      return;
    }

    setResult("loading");

    const actionDetailsBody = getActionDetailsBody(actionValue, option);

    const selectedRows = state.selectionModel.map((item) => rows[Number(item)]);
    const bodyData = {
      unitOfWorkIds: removeDuplicateUnitOfWorkPart(
        selectedRows.map((item) => item.resolutionOptions.unitOfWorkPart),
      ),
      option,
      actionDetails: actionDetailsBody
    };

    setResult("loading");
    let useFunctionService = useSingleMeterExceptionAction;
    if (actionValue === "pending" || actionValue === "close") {
      useFunctionService = useSingleMeterExceptionStatusAction;
    }
    useFunctionService(
      setLoading,
      setResult,
      { actionType: "bulk", ...selectedRows[0].resolutionOptions },
      actionValue,
      bodyData,
      postUpdate,
    );
  };

  const { showSnackMessage } = useSnackbarMessage();

  useEffect(() => {
    if (!state.openMessage) {
      return;
    }

    showSnackMessage({
      type: state.type,
      onClose: handleCloseMessage,
      showExpandData: state.messageItems.length > 0,
      dataTestPrefix: "bulk",
      title: state.message,
      body: (
        <>
          {state.messageItems.map((item: MeterException, index: number) => (
            <Link
              sx={{ mt: 1 }}
              data-test={"bulk-details-error-message"}
              key={index}
              underline="hover"
              color="inherit"
              to={`/connectionpoint/${connectionPointId}/meter/${meterId}`}
              component={RouterLink}
            >
              {`${i18n.t("EXCEPTIONS.CONNECTION_POINT", { ns: "component" })} ${connectionPointNumber} / 
              ${i18n.t("EXCEPTIONS.METER", { ns: "component" })} ${meterSerial}`}
            </Link>
          ))}
        </>
      )
    });

  }, [state.openMessage, showSnackMessage]);

  useEffect(() => {

    if (result === "success" || result === "fail") {
      const newState = { ...state, messageItems: [] };
      let type: AlertColor = "error";
      if (result === "success") {
        type = "success";
      }

      newState.openMessage = true;
      newState.message = submitMessages[type];
      newState.type = type;

      // TODO we maybe want to keep the sub-scenario dialog when failed.
      newState.meterIdForSubScenarioDialog = null;
      setState(newState);
    } else if (typeof result === "object") {
      // output bulk exception
      const newState = { ...state, openMessage: true };
      // TODO we maybe want to keep the sub-scenario dialog when failed.
      newState.meterIdForSubScenarioDialog = null;
      if (result.errors.length === 0) {
        newState.message = `${result.success.length} changes have been successfully saved.`;
        newState.type = "success";
        newState.messageItems = [];
      } else {
        newState.type = "error";

        const lookUpResult = lookUpExceptionDataFromAPIResult(
          content?.exceptions,
          result,
        );

        newState.messageItems = lookUpResult.errors;
        newState.message = (
          <>
            <Box sx={{ display: "inline" }}>{lookUpResult.errors.length} errors occurred</Box>
            <Box sx={{ color: "#F88078", display: "inline" }}>
              {" "}(out of {lookUpResult.errors.length + lookUpResult.success.length} processed)
            </Box>
          </>
        );
      }
      setState(newState);
    }
  }, [result]);

  const rows = useMemo(() => {
    if (!content || !content.exceptions) {
      return [];
    }

    const rowsWithStatus = content.exceptions.filter(item => item.status.toLocaleLowerCase() !== 'closed').map((item, index) => {
      return {
        id: index,
        meterId: item.deviceId,
        ...item,
        resolutionOptions: {
          index,
          meterId: item.deviceId,
          unitOfWorkId: item.unitOfWorkId,
          exceptionType: item.exceptionType,
          unitOfWorkPart: {
            manufacturer: item.manufacturer,
            commodity: item.commodity,
            readingDateTime: item.unitOfWorkDateTime,
            intent: item.intent,
            serialNumber: item.serialNumber,
          },
        },
      };
    });
    return rowsWithStatus;
  }, [content, state.triggerFetchData]);

  const columnValueDef: GridColDef[] = [
    {
      field: "dataDay",
      headerName: i18n.t("EXCEPTIONS.HEADERS.READ_DATE", { ns: "component" }),
      type: "date",
      valueGetter: dateValueGetter,
      valueFormatter: dateValueFormatter,
    },
    {
      field: "raisedDateTime",
      headerName: i18n.t("EXCEPTIONS.HEADERS.CREATED_DATE", { ns: "component" }),
      type: "date",
      valueGetter: dateValueGetter,
      valueFormatter: dateValueFormatter,
    },
    {
      field: "exceptionType",
      headerName: i18n.t("EXCEPTIONS.HEADERS.EXCEPTION_TYPE", { ns: "component" }),
      valueGetter: exceptionTypeGetter
    },
    {
      field: "status",
      headerName: i18n.t("EXCEPTIONS.HEADERS.STATUS", { ns: "component" }),
      renderCell: renderExceptionStatus,
      minWidth: 100,
    },
    {
      field: "datastreamName",
      headerName: i18n.t("EXCEPTIONS.HEADERS.DATASTREAM", { ns: "component" }),
      minWidth: 100,
    },
  ];

  const resolutionOptionHeaderDef = {
    field: "resolutionOptions",
    headerName: i18n.t("EXCEPTIONS.HEADERS.ACTIONS", { ns: "component" }),
    renderCell: renderResolutionOptions(
      submitActionOption(
        setLoading,
        setResult,
        setMeterIdForSubScenarioDialog,
        postUpdate,
      ),
    ),
    width: 80,
    headerClassName:
      "grid-header-column--header grid__header-name--transparent",
    disableColumnMenu: true,
    sortable: false,
  };

  const columns = styleDataGridHeader(columnValueDef, { minWidth: 150, flex: 1 });
  columns.push(resolutionOptionHeaderDef);

  if (!content || !content.exceptions) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress sx={{ mt: 2 }} />
      </Box>
    );
  }

  if (content.exceptions.length <= 0) return <Box />;

  return (
    <>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Alert
        elevation={6}
        variant="filled"
        severity="warning"
        sx={{
          color: "#764823",
          backgroundColor: "#fdf1e6",
          boxShadow: "inherit",
          fontWeight: "inherit",
          mb: 3,
          "& .MuiAlert-message": {
            mt: "2px",
          },
        }}
        icon={<WarningIcon sx={{ color: "warning.main" }} />}
        data-test={"exception-warning-message"}
      >
        {i18n.t("EXCEPTION_ACTION_OPTIONS.MESSAGES.TOTAL_EXCEPTIONS", { ns: "component", param1: rows.length })}
      </Alert>
      <Typography
        variant={"h6"}
        data-test={"page-heading-exceptions"}
        sx={{
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Stack
          sx={{ flexDirection: "row", alignItems: "center" }}
        >
          {i18n.t("EXCEPTIONS.EXCEPTION_HEADER", { ns: "component" })}
          <WarningIcon sx={{ color: "warning.main", ml: 1 }} />
        </Stack>
        <div>
          {state.selectionModel.length > 0 && (
            <ExceptionActionOptions
              type="button"
              content={""}
              onclick={submitBulkAction}
            />
          )}
        </div>
      </Typography>
      <Box
        data-test={"exception-list"}
        sx={{
          width: 1,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(0, 0, 0, 0.03)",
            color: "text.secondary",
          },
          "& .MuiDataGrid-root": {
            border: 0,
          },
          "& .grid__header-name--transparent": {
            color: "rgba(0, 0, 0, 0)",
          },
          pb: 3,
        }}
      >
        <DataGridPro
          disableColumnMenu
          disableColumnReorder
          disableSelectionOnClick
          sx={{
            '.MuiDataGrid-virtualScroller': { maxHeight: "260px", overflow: "auto !important" }
          }}
          autoHeight={true}
          rows={rows}
          columns={columns}
          checkboxSelection={true}
          hideFooter={true}
          hideFooterPagination={true}
          hideFooterSelectedRowCount={true}
          hideFooterRowCount={true}
          initialState={{
            sorting: {
              sortModel: [{ field: "dataDay", sort: "desc" }],
            },
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={state.selectionModel}
        />
      </Box>

      {state.meterIdForSubScenarioDialog && (
        <SubScenarioDialog
          open
          meterId={state.meterIdForSubScenarioDialog}
          onOkay={applySubScenario}
          onCancel={closeSubScenarioDialog}
        />
      )}
    </>
  );
};
