import React from "react";
import { Chip, Link } from "@mui/material";
import { Download, Downloading, Warning } from "@mui/icons-material";
import { TypeStatus } from "../../types/ReportDownloadPageState";
import {i18n} from "../../global/i18n";

interface Props {
  content: {
    reportId: string;
    status: TypeStatus;
  };
  onclick: (reportId: string) => (event: React.MouseEvent<HTMLElement>) => void;
}

export const ReportDownloadStatusChip: React.FC<Props> = ({ content, onclick }) => {
  const status = content.status.toLowerCase();
  switch (status) {
    case "pending":
      return (
        <Chip
          data-test="history-report-status-chip-pending"
          sx={{color: "rgba(0, 0, 0, 0.6)"}}
          variant={"outlined"}
          size={"small"}
          icon={<Downloading style={{fill: "#9E9E9E"}} />}
          label={i18n.t("REPORT_DOWNLOAD_STATUS_CHIP.PREPARING", {ns: "component"})}
        />
      );
    case "complete":
      return (
        <Link
          data-test="history-report-status-chip-complete"
          sx={{
            textDecoration: "none",
            "& .MuiChip-label": {cursor: "pointer"},
          }}
          target="_blank"
          onClick={onclick(content.reportId)}
        >
          <Chip
            sx={{ color: "#0053DE" }}
            variant={"outlined"}
            size={"small"}
            icon={<Download style={{ fill: "#0053DE" }} />}
            label={i18n.t("REPORT_DOWNLOAD_STATUS_CHIP.DOWNLOAD", {ns: "component"})}
          />
        </Link>
      );
    default:
      return (
        <Chip
          data-test={`history-report-status-chip-` + content.status.toLocaleLowerCase()}
          sx={{ color: "#E31B0C" }}
          variant={"outlined"}
          size={"small"}
          icon={<Warning style={{ fill: "#E31B0C" }} />}
          label={i18n.t("REPORT_DOWNLOAD_STATUS_CHIP.FAILED", {ns: "component"})}
        />
      );
  }
};
