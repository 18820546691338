import { useEffect } from "react";
import { EMPTY, ConnectionPoints } from "../types/ConnectionPoints";
import { retrieveConnectionPointByMeterId, retrieveConnectionPointByConnectionPointId, } from "../services/ConnectionPointPageService";

interface Params {
  connectionPointId?: string | null;
  meterId?: string | null;
}

export const useConnectionPointSearch = (
  setState: (state: ConnectionPoints | null) => void,
  { connectionPointId, meterId }: Params,
): void => {

  useEffect(() => {
    if (connectionPointId) {
      setState(EMPTY);
      retrieveConnectionPointByConnectionPointId(connectionPointId)
        .then((connectionPoint) => setState({ connectionPoints: [connectionPoint] }))
        .catch(() => setState(null));
    }
  }, [connectionPointId, setState]);

  useEffect(() => {
    if (meterId) {
      setState(EMPTY);
      retrieveConnectionPointByMeterId(meterId)
        .then((res) => {
          // real data has some case success with connectionPoints = [], need check length to fix loading
          res.connectionPoints.length > 0 ? setState(res) : setState(null);
        })
        .catch(() => setState(null));
    }
  }, [meterId, setState]);

  useEffect(() => {
    if (!connectionPointId && !meterId) {
      setState(null);
    }
  }, [connectionPointId, meterId, setState]);
}