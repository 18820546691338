/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from "react";
import { Box, Button, Divider, Paper, Popover, useTheme } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { DateRangePicker } from "../DateRangePicker";
import { DateRange } from "@mui/x-date-pickers-pro";
import moment, { Moment } from "moment";
import { i18n } from "../../global/i18n";

interface Props {
  onApply: (toDate: string, fromDate: string) => void;
  isWip?: boolean;
}

const currentDate = new Date();
const currentDateInMs = currentDate.valueOf();
const thirtyDaysInMs = 1000 * 60 * 60 * 24 * 30; //Thirty Days back
const thirtyDaysOldDate = new Date(currentDateInMs - thirtyDaysInMs);

export const initialDateRangeState: DateRange<Moment> = [
  moment(thirtyDaysOldDate).endOf("day"),
  moment(currentDate).endOf("day"),
];

export const AdhocExtractExport: React.FC<Props> = ({ onApply }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [dateRange, setDateRange] = useState<DateRange<Moment>>(
    initialDateRangeState,
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [anchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleApply = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(null);
      onApply(dateRange[0]?.endOf("day").toISOString() || moment(thirtyDaysOldDate).endOf("day").toISOString(), 
        dateRange[1]?.endOf("day").toISOString() || moment(currentDate).endOf("day").toISOString());
    },
    [dateRange],
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          data-test={"history-report-data-button"}
          sx={{ textTransform: "capitalize", boxShadow: "none", pr: 1.5 }}
          variant="contained"
          size="small"
          startIcon={<DownloadIcon />}
          onClick={handleClick}
        >
          {i18n.t("ADHOC_EXTRACT_EXPORT.EXPORT_DATA", {ns: "component"})}
        </Button>
      </Box>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper data-test={"history-report-data-popover"} sx={{ backgroundColor: theme.palette.common.white }}>
          <Box sx={{ padding: "4px 12px" }}>
            <DateRangePicker onDateRangeChanged={setDateRange} initialDateRangeState={initialDateRangeState} />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: theme.spacing(2),
              flex: "0 0 auto",
              fontWeight: 500,
              fontSize: "0.8125rem",
            }}
          >
            <Button
              size="small"
              onClick={handleClose}
              sx={{ ml: 1, textTransform: "capitalize" }}
            >
                {i18n.t("ADHOC_EXTRACT_EXPORT.CANCEL", {ns: "component"})}
            </Button>
            <Box sx={{ marginLeft: theme.spacing(1) }}>
              <Button
                size="small"
                onClick={handleApply}
                variant="contained"
                sx={{ ml: 1, textTransform: "capitalize" }}
                data-test={"history-report-apply-button"}
              >
                {i18n.t("ADHOC_EXTRACT_EXPORT.APPLY", {ns: "component"})}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};
