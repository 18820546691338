import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Meter } from "../../../types/Meter";

interface Props {
  searchParam: string | null;
  children: React.ReactNode;
}

interface ConnectionPointStatusCounts {
  connectionPointId: string;
  numberOfMeters: number;
  meterCount: {
    active: number,
    inactive: number,
  },
  dataStreamCount: {
    active: number,
    inactive: number,
  }
}

const connectionPointStatusEmptyCounts: ConnectionPointStatusCounts = {
  connectionPointId: "",
  numberOfMeters: 0,
  meterCount: {
    active: 0,
    inactive: 0,
  },
  dataStreamCount: {
    active: 0,
    inactive: 0,
  }
}

interface ConnectionPointSearchContextType {
  addMeter: (connectionPointId: string, meter: Meter) => void;
  getCounts: (connectionPointId: string) => ConnectionPointStatusCounts;
}

export const DEFAULT: ConnectionPointSearchContextType = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addMeter: (_connectionPointId: string, _meter: Meter) =>
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  {
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCounts: (_connectionPointId: string): ConnectionPointStatusCounts => connectionPointStatusEmptyCounts,
}

interface ConnectionPointMeters {
  [connectionPointId: string]: Meter[];
}

export const ConnectionPointSearchContext = createContext<ConnectionPointSearchContextType>(DEFAULT);

export const getOrCreateMeters = (connectionPointMeters: ConnectionPointMeters, connectionPointId: string): Meter[] => {
  if (!connectionPointMeters[connectionPointId]) {
    connectionPointMeters[connectionPointId] = [];
  }

  return connectionPointMeters[connectionPointId];
}

export const calculateConnectionPointStatusCounts = (connectionPointMeters: ConnectionPointMeters): ConnectionPointStatusCounts[] => {
  return Object.entries(connectionPointMeters).map(([connectionPointId, meters]) => ({
    connectionPointId,
    numberOfMeters: meters.length,
    meterCount: {
      active: meters.filter(meter => meter.status === "Active").length,
      inactive: meters.filter(meter => meter.status === "Inactive").length,
    },
    dataStreamCount: {
      active: meters.reduce(((sum: number, meter: Meter) => sum + meter.dataStreams.filter(ds => {
        return meter.status === "Active" && ds.status === "ACTIVE";
      }).length), 0),
      inactive: meters.reduce(((sum: number, meter: Meter) => sum + meter.dataStreams.filter(ds => {
        return meter.status === "Inactive" || ds.status === "INACTIVE";
      }).length), 0),
    }
  }));
}

export const ConnectionPointSearchContextProvider: React.FC<Props> = ({searchParam, children}) => {
  const [connectionPointMeters, setConnectionPointMeters] = useState<ConnectionPointMeters>({});

  const addMeter = useCallback((connectionPointId: string, meter: Meter) => {

    if (!connectionPointId || !meter.id) {
      return;
    }

    getOrCreateMeters(connectionPointMeters, connectionPointId).push(meter);

    setConnectionPointMeters({
      ...connectionPointMeters,
    });
  }, [connectionPointMeters]);

  const connectionPointCounts = useMemo((): ConnectionPointStatusCounts[] => {
    return calculateConnectionPointStatusCounts(connectionPointMeters);
  }, [connectionPointMeters]);

  const getCounts = useCallback((connectionPointId: string): ConnectionPointStatusCounts => {
    return connectionPointCounts.find(connectionPointCount =>
      connectionPointCount.connectionPointId === connectionPointId) ?? { ...connectionPointStatusEmptyCounts, connectionPointId };
  }, [connectionPointCounts]);

  useEffect(() => {
    setConnectionPointMeters({});
  }, [searchParam]);

  return (
    <ConnectionPointSearchContext.Provider value={{addMeter, getCounts}}>
      {children}
    </ConnectionPointSearchContext.Provider>
  )
};

export const useConnectionPointSearchContext = (): ConnectionPointSearchContextType => useContext(ConnectionPointSearchContext);
