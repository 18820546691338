import { DeviceConfigurationState, EMPTY as deviceConfigurationEmpty } from "./DeviceConfigurationState";
import { DataStream } from "./DataStream";
import { ChurnedDeviceConfigurationState, EMPTY as churnedDeviceConfigurationEmpty } from "./ChurnedDeviceConfigurationState";

export interface Meter {
  id: string;
  status: string;
  installationType: string;
  serialNumber: string;
  nsrd: string;
  installDateTime: string;
  model: string;
  changeEffectiveDate: string;
  deviceConfiguration: DeviceConfigurationState;
  churnedDeviceConfiguration: ChurnedDeviceConfigurationState;
  commodity: string;
  dataStreams: DataStream[];
}

export const EMPTY: Meter = {
  id: "",
  status: "",
  installationType: "",
  serialNumber: "",
  nsrd: "",
  installDateTime: "",
  model: "",
  changeEffectiveDate: "",
  deviceConfiguration: { ...deviceConfigurationEmpty },
  churnedDeviceConfiguration: {... churnedDeviceConfigurationEmpty},
  commodity: "",
  dataStreams: [],
}
