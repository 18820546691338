import React, { useState } from "react";
import { Box } from "@mui/material";
import moment, { Moment } from "moment";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "../DateRangePicker";
import { DataStreamChart } from "./DataStreamChart";
import { EMPTY_CHART, DataStreamChartData } from "../../types/DataStreamChartState";
import { useDataStreamChart } from "../../hooks/useDataStreamChart";
import { useDataStreamChartVersions } from "../../hooks/useDataStreamChartVersions";
import { IntervalVersion } from "../../types/IntervalVersion";

interface Props {
  meterId: string;
  datastreamName: string;
  intent: string;
}

export const initialDateRangeState: DateRange<Moment> = [
  moment(new Date()).subtract(1, "days"), 
  moment(new Date()).subtract(1, "days")
];

export const DataStreamChartContainer: React.FC<Props> = ({
  meterId,
  datastreamName,
  intent
}) => {
  const [state, setState] = useState<DataStreamChartData[]>(EMPTY_CHART);
  const [versions, setVersions] = useState<IntervalVersion[]>([]);
  const [, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState<DateRange<Moment>>(initialDateRangeState);

  useDataStreamChartVersions(setLoading, setError, setVersions, meterId, datastreamName, intent, dateRange);

  useDataStreamChart(setLoading, setError, setState, meterId, datastreamName, intent, dateRange, versions);

  return (
    <Box
      sx={{
        pt: 1,
        mb: 4,
        '& g[class$="veritcal-crossline-marker-group"]': {
          display: "none",
        },
      }}
    >
      <DateRangePicker 
        data-test={"datastream-chart-date-range-picker"}
        onDateRangeChanged={setDateRange} 
        isOnlyCallbackWhenClose={true} 
        initialDateRangeState={initialDateRangeState} 
      />
      <DataStreamChart content={{ loading: loading, data: state }} />
    </Box>
  );
};
