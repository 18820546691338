import React from "react";
import { Stack, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { AgreedAverageDailyLoadFormItem } from "../AgreedAverageDailyLoadFormItem";
import {
  ManualSubstitutionDataStreamInputItem,
  MIN_MAX_EXAMPLE,
} from "../UserDefinedScenarioDialog/UserDefinedScenarioDialog";
import { useTranslation } from "react-i18next";

interface Props {
  manualSubstitutionDataStreams: ManualSubstitutionDataStreamInputItem[];
  onChange: (item: ManualSubstitutionDataStreamInputItem) => void;
  sx: SxProps<Theme> | undefined;
}

export const AgreedAverageDailyLoadForm: React.FC<Props> = ({
  manualSubstitutionDataStreams,
  onChange,
  sx,
}) => {
  const { t } = useTranslation("component");
  return (
    <Stack
      direction={"column"}
      sx={sx}
      data-test={"agreed-average-daily-load-form"}
    >
      <Typography variant={"h6"} sx={{ fontSize: "16px", fontWeight: 500 }}>
        {t("AGREED_AVERAGE_DAILY_LOAD_FORM.AGREED_AVERAGE_DAILY_LOAD")}
      </Typography>
      <Typography variant={"body2"} sx={{ mt: 1 }}>
        {t("AGREED_AVERAGE_DAILY_LOAD_FORM.ENTER_ADL_VALUE")} {MIN_MAX_EXAMPLE}
        ).
      </Typography>

      <Stack direction={"column"} sx={{ mt: 3 }}>
        {manualSubstitutionDataStreams.map((item) => (
          <AgreedAverageDailyLoadFormItem
            key={item.name}
            data-test={`agreed-average-daily-load-form-item`}
            item={item}
            onChange={onChange}
          />
        ))}
      </Stack>
    </Stack>
  );
};
