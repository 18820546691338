import React, { useEffect, useState } from "react";
import { InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ConnectionPointSearchToggle: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchType, setSearchType] = useState("connectionpoint");
  const [searchString, setSearchString] = useState("");
  const location = useLocation();
  const { t }  = useTranslation("component");

  useEffect(() => {
    const meterId = searchParams.get("meterId");
    const connectionPointId = searchParams.get("connectionPointId");
    if (meterId && meterId !== "-EDMI-ELECTRICITY") {
      setSearchType("meter");
      setSearchString(meterId.replace("-EDMI-ELECTRICITY", ""));
    } else if (connectionPointId && connectionPointId !== "-ELECTRICITY") {
      setSearchType("connectionpoint");
      setSearchString(connectionPointId.replace("-ELECTRICITY", ""));
    } else {
      setSearchString(""); 
    }
  }, [searchParams]);

  const runSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;

    if (e.key !== "Enter") {
      return;
    }

    if (searchString === "") {
      navigate(location.pathname);
      return;
    }

    const searchPathname = `${location.pathname}?`
      + (searchType === "connectionpoint" ? `connectionPointId=${value.trim()}-ELECTRICITY` : `meterId=${value.trim()}-EDMI-ELECTRICITY`)
    navigate(searchPathname);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  }

  const handleToggleClick = (
    event: React.MouseEvent<HTMLElement>,
    newSearchType: string,
  ) => {
    if (newSearchType) {
      setSearchType(newSearchType);
    }
  };


  return (
    <>
      <ToggleButtonGroup
        data-test={"search-toggle"}
        exclusive={true}
        size={"small"}
        value={searchType}
        onChange={handleToggleClick}
        sx={{
          mr: 2
        }}
      >
        <ToggleButton value={"connectionpoint"} sx={{width: "46px", height: "30px", px: "10px", py: "4px"}}>
          {t("CONNECTION_POINT_SEARCH_TOGGLE.CONNECTION_POINT")}
        </ToggleButton>

        <ToggleButton value={"meter"}
            sx={{width: "57px", height: "30px", px: "10px", py: "4px"}}>{t("CONNECTION_POINT_SEARCH_TOGGLE.METER")}</ToggleButton>
      </ToggleButtonGroup>
      <TextField
        data-test={"search-field"}
        variant={"outlined"}
        size={"small"}
        sx={{width: "240px", "div.MuiOutlinedInput-root input": {width: "100%"} }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{width: "17.49px"}}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchString}
        onKeyUp={runSearch}
        onChange={handleChange}
      >

      </TextField>
    </>);
};
