import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ConnectionPointPage } from "./ConnectionPointPage";
import { useConnectionPointPage } from "../../hooks/useConnectionPointPage";
import { EMPTY, ConnectionPoint } from "../../types/ConnectionPoint";

type Params = {
  connectionPointId: string;
};

export const ConnectionPointPageContainer: React.FC = () => {
  const { connectionPointId = "" } = useParams<Params>();
  const [ currentState, setState ] = useState<ConnectionPoint>(EMPTY);
  useConnectionPointPage(setState, connectionPointId);

  return <ConnectionPointPage content={currentState} connectionPointId={connectionPointId} />;
};
