import { useEffect } from "react";
import { Snapshots } from "../types/SnapshotState";
import { useSnackbarMessage } from "./useSnackbarMessage";
import { retrieveSnapshots } from "../services/SnapshotService";
import { i18n } from "../global/i18n";

export const useSnapshots = (
  setState: (state: Snapshots) => void,
  meterId: string,
): void => {

  const { showSnackMessage } = useSnackbarMessage();

  useEffect(() => {
    retrieveSnapshots(meterId)
      .then(setState)
      .catch(() => {
        showSnackMessage({
          type: "error",
          title: i18n.t("SNAPSHOT.ERROR_RESPONSE", {ns: "component"}),
          keepShowing: true
        });
      });
  }, [meterId, setState, showSnackMessage]);
}
