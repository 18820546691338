import React, { ReactNode } from "react";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";

interface Props {
  flag: string;
  children: ReactNode;
}

export const FeatureFlag: React.FC<Props> = ({
  flag,
  children,
}) => {
  return useFeatureFlag(flag) ? (<>{children}</>) : null;
}
