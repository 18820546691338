import { MeterExceptionCount } from "./MeterExceptionCount";

export interface ExceptionListState {
  meterExceptionCounts: MeterExceptionCount[];
}

export const EMPTY_LIST: MeterExceptionCount[] = [];

export const EMPTY: ExceptionListState = {
  meterExceptionCounts: EMPTY_LIST,
};

