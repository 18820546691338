import React, { useCallback, useEffect, useState } from "react";
import { Snapshot } from "./Snapshot";
import { useSnapshots } from "../../hooks/useSnapshots";
import { EMPTY, Snapshots } from "../../types/SnapshotState";

interface Props {
  meterId: string;
}

export const SnapshotContainer: React.FC<Props> = ({ meterId }) => {
  const [currentState, setState] = useState<Snapshots>(EMPTY);
  const [loading, setLoading] = useState<boolean>(true);

  const handleSetSnapshots = useCallback((snapshots) => {
    setLoading(false);
    setState(snapshots);
  }, []);
  
  useSnapshots(handleSetSnapshots, meterId);

  useEffect(() => {
    setLoading(true);
  }, [meterId]);

  return <Snapshot content={currentState} loading={loading} />;
};
