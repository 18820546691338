import React from "react";
import { ConnectionPointSearchToggle } from "../../components/ConnectionPointSearchToggle";
import { PageHeader } from "../../components/PageHeader";
import { PageContent } from "../../components/PageContent";
import { PageContentResult } from "../../components/PageContentResult";
import { Typography } from "@mui/material";
import { ConnectionPoints } from "../../types/ConnectionPoints";
import { useTranslation } from "react-i18next";

interface Props {
  content: ConnectionPoints | null;
}

export const ConnectionPointSearch: React.FC<Props> = ({ content }) => {

  const { t } = useTranslation("page");

  return (
    <>
      <PageHeader>
        <Typography variant={"h5"} mb={2} data-test={"page-heading"}>
          {t("CONNECTION_POINT_SEARCH_PAGE.HEADER")}
        </Typography>
        <ConnectionPointSearchToggle />
      </PageHeader>
      <PageContent>
        <PageContentResult content={content} />
      </PageContent>
    </>
  );
};
